/* eslint-disable camelcase */
/* eslint-disable use-isnan */
/* eslint-disable max-len */
/* eslint-disable no-duplicate-imports */
import React from 'react'
import {Table} from 'ka-table'
import {kaPropsUtils} from 'ka-table/utils'
import {
  Flag as FlagIcon,
  ContentCopy as ContentCopyIcon,
  FormatListBulleted as FormatListBulletedIcon,
  // ExpandMore as ExpandMoreIcon,
  // ExpandCircleDownSharp,
  // ExpandCircleDownOutlined,
  // UpcomingOutlined,
  // ArrowCircleUpOutlined,
  // ArrowCircleDownOutlined,
  ArrowDropUp,
  ArrowDropDown,
} from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import {IconButton, Box, Typography, Tooltip} from '@mui/material'
import {SwitchIcon} from '../../Tables/CustomTable/components'
import {CustomButton} from '../../Inputs'
import {
  convertClassValueToClass,
  convertConditionValueToCondition,
  reOrderArray,
} from '../../../utils/Utils'
import {
  ColumnSettings,
  DeleteCell,
  EditCell,
  ExpandCell,
  PageSize,
  SelectionCell,
  SelectionHeader,
  ToggleSwitch,
} from './components'
import MyDiv from './customTable.style'

const CustomTable = ({
  tableProps,
  dispatch,
  columnSetting,
  handleSidebar,
  handlePopup = () => {
    return ''
  },
  handleEdit,
  handleDelete,
  handleCheck,
  handleOrder,
  handleExpandClose,
  handleExpandClick,
  expandMore,
  filterListData,
  handleChange,
  checked,
  handleDeleteLine,
  handleLocationCustomReport,
  filteringMode,
  setFilter,
  isFilter,
  isExpand,
  locId,
  title,
  Symbol,
  AddEditButton,
  flag,
  handleDrop,
  handleLocationReordering,
}) => {
  return (
    <MyDiv>
      <ColumnSettings
        tableProps={tableProps}
        dispatch={dispatch}
        columnSetting={columnSetting}
        handleSidebar={handleSidebar}
        setFilter={setFilter}
        isFilter={isFilter}
      />
      <Table
        {...tableProps}
        filteringMode={filteringMode}
        dispatch={dispatch}
        childComponents={{
          pagingSizes: {
            elementAttributes: (props) => ({
              title: 'Selected Page',
            }),
            content: (props) => <PageSize {...props} />,
          },
          pagingIndex: {
            elementAttributes: ({isActive}) => ({
              className: `page-item ${isActive ? 'active' : ''}`,
            }),
          },
          pagingPages: {
            elementAttributes: () => ({
              className: 'pagination',
            }),
          },
          table: {
            elementAttributes: () => ({
              id: 'table-to-xls',
            }),
          },
          // isFlagged
          // created_on
          // name
          //code
          //count
          //action-cell
          cellText: {
            elementAttributes: (props) => ({
              title:
                props?.column?.key === 'ref' && props?.rowData?.type === 'asset'
                  ? `Location Name : ${props.rowData?.location_address} \n \n Area Name:${props?.rowData?.area}`
                  : props?.column?.key === 'ref' &&
                    props?.rowData?.type === 'line'
                    ? `Location Name : ${props.rowData?.location_address}`
                    : props?.column?.key === 'selection-cell'
                      ? ''
                      : props?.column?.key === 'add-icon'
                        ? ''
                        : props?.column?.key === 'action-cell'
                          ? ''
                          : props?.column?.key === 'count'
                            ? ''
                            : props?.column?.key === 'code'
                              ? ''
                              : props?.column?.key === 'name'
                                ? ''
                                : props?.column?.key === 'created_on'
                                  ? ''
                                  : props?.column?.key === 'isFlagged'
                                    ? ''
                                    : props?.column?.key === 'flagged'
                                      ? ''
                                      : props?.column?.key === 'is_not_found'
                                        ? ''
                                        : props?.column?.key === 'condition_value'
                                          ? `${convertConditionValueToCondition(props?.value)}`
                                          : props?.column?.key === 'asset_class_code'
                                            ? `${convertClassValueToClass(props.value)}`
                                            : `${props?.value}` === 'null'
                                              ? '' :
                                              ((props.column.key === 'FLV' ||
                                                props.column.key === 'FMV' ||
                                                props.column.key === 'OLV' ||
                                                props.column.key === 'FMVIP' ||
                                                props.column.key === 'FV' ||
                                                props.column.key === 'CV' ||
                                                props.column.key === 'OLVIP' ||
                                                props.column.key === 'RCN' ||
                                                props.column.key === 'FAS') && props.value === 'NaN') ? '' : `${props?.value}` ||


                                              props.column.key === 'expand-cell' ? ''
                                                  :
                                                  (`${props?.value}` === undefined || `${props?.value}` === '') ? '' : `${props.value}`,
            }),
            content: (props) => {
              if (props.column.key === 'selection-cell') {
                return <SelectionCell {...props} />
              }
              if (props.column.key === 'asset_id' && title === 'findreplace') {
                return (
                  <Typography style={{fontWeight: 'bold'}}>
                    Asset Id #{props.value}
                  </Typography>
                )
              } else if (props.column.key === 'status') {
                return props.rowData.status === '1' ? 'Active' : 'InActive'
              } else if (props.column.key === 'square_footage_c') {
                return <Typography>{props.value}<sup>2</sup></Typography>
              } else if (props.column.key === 'action-cell') {
                return (
                  <>
                    <EditCell
                      className="icon_btn edit"
                      onClick={handleEdit}
                      {...props}
                    />
                    <DeleteCell
                      className="icon_btn delete"
                      onClick={handleDelete}
                      {...props}
                    />
                  </>
                )
              } else if (props.column.key === 'cross_icon') {
                return (
                  <CloseIcon
                    fontSize="small"
                    color="error"
                    onClick={() => handleDeleteLine(props.rowKeyValue)}
                  />
                )
              } else if (props.column.key === 'status-cell') {
                return <ToggleSwitch />
              } else if (props.column.key === 'isFlagged') {
                return (
                  <>
                    {props.rowData.isFlagged === 1 ? (
                      <IconButton
                        id={props.rowKeyValue}
                        style={{width: '61px'}}
                        className="icon_btn_flag delete"
                        onClick={handleCheck(
                          props.rowKeyValue,
                          props.rowData.isFlagged
                        )}
                      >
                        <FlagIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        id={props.rowKeyValue}
                        className="icon_btn_flag_default delete"
                        onClick={handleCheck(
                          props.rowKeyValue,
                          props.rowData.isFlagged
                        )}
                      />
                    )}
                  </>
                )
              } else if (props.column.key === 'area-ordering-cell') {
                return (
                  <Tooltip title="Select Assets">
                    <IconButton
                      className="cell_btn"
                      onClick={() => handleOrder(props.rowKeyValue)}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                )
              } else if (props.column.key === 'street') {
                return (
                  <Typography>
                    {`${props?.rowData?.street}, `}{' '}
                    {props?.rowData?.state
                      ? props?.rowData?.state
                      : props?.rowData?.city}
                  </Typography>
                )
              } else if (props.column.key === 'location-asset-cell') {
                return (
                  // eslint-disable-next-line no-alert
                  <Tooltip title="Select Assets">
                    <IconButton
                      className="cell_btn"
                      onClick={() =>
                        handleLocationCustomReport(props.rowKeyValue)
                      }
                    >
                      <FormatListBulletedIcon />
                    </IconButton>
                  </Tooltip>
                )
              } else if (props.column.key === 'expand-cell') {
                return (
                  <>
                    <IconButton
                      className="cell_btn"
                      onClick={(e) => handleExpandClick(e, props.rowKeyValue)}
                    >
                      {isExpand && locId === props.rowKeyValue ? (
                        <ArrowDropUp />
                      ) : (
                        <ArrowDropDown />
                      )}
                    </IconButton>
                    <ExpandCell
                      expandMore={expandMore}
                      handleExpandClose={handleExpandClose}
                      filterListData={filterListData}
                      checked={checked}
                      handleChange={handleChange}
                      locId={locId}
                    />
                  </>
                )
              } else if (props.column.key === 'edit-cell') {
                return (
                  <CustomButton
                    title="Resolve"
                    variant="contained"
                    className="btn_theme"
                    onClick={() => handleEdit(props)}
                  />
                )
              } else if (Symbol === 'research' && props?.column?.key === 'quantity') {
                return <Typography> {props?.rowData?.quantity_type === 'number' ? props?.value : props?.rowData?.quantity_type}</Typography>
              } else if (
                props.column.key === 'FLV' ||
                props.column.key === 'FMV' ||
                props.column.key === 'OLV' ||
                props.column.key === 'FMVIP' ||
                props.column.key === 'FV' ||
                props.column.key === 'CV' ||
                props.column.key === 'OLVIP' ||
                props.column.key === 'RCN' ||
                props.column.key === 'FAS'

              ) {
                if (
                  (props.value === undefined || props.value === 'NaN') &&
                  Symbol !== 'research'
                ) {
                  return <></>
                } else if (Symbol === 'research' && props.value === 'NaN') {
                  return <Typography>$0 </Typography>
                } else {
                  return (
                    <Typography>
                      {' '}
                      ${' '}
                      {props?.value
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 0}{' '}
                    </Typography>
                  )
                }
              }
            },
            // elementAttributes: (props) => ({
            //   onClick: () => {
            //     if (props.column.key === "flag") {
            //       return handleCheck(props.rowKeyValue);
            //     }
            //   },
            // }),
          },
          filterRowCell: {
            content: (props) => {
              if (
                props.column.key === 'selection-cell' ||
                props.column.key === 'action-cell' ||
                props.column.key === 'assetType' ||
                props.column.key === 'edit-cell' ||
                props.column.key === 'count'
              ) {
                return <></>
              }
            },
          },
          headCell: {
            elementAttributes: (props) => ({
              title:
                props.column.key === 'add-icon'
                  ? ''
                  : props.column.key === 'selection-cell'
                    ? ''
                    : `${props?.column?.title}`,
            }),
            content: (props) => {
              if (props.column.key === 'selection-cell') {
                return (
                  <SelectionHeader
                    {...props}
                    areAllRowsSelected={kaPropsUtils.areAllFilteredRowsSelected(
                      tableProps
                    )}
                  />
                  // <></>
                )
              }
              if (props.column.key === 'add-icon') {
                return (
                  <Box className="align_switch">
                    <SwitchIcon />
                  </Box>
                  // <></>
                )
              }
              // else if (props.column.key === "action-cell") {
              //   return (
              //       <></>
              //   );
              // }
            },
          },
          cell: {
            elementAttributes: (props) => ({
              onClick: () => {
                if (
                  props.column.key === 'selection-cell' ||
                  props.column.key === 'flag' ||
                  props.column.key === 'cross_icon' ||
                  props.column.key === 'edit-cell'
                ) {
                  return <></>
                } else {
                  handlePopup(props?.rowData?.type_id)
                }
              },
              onDrop: (e, extendedEvent) => {
                const draggableKeyValue = JSON.parse(
                  e.dataTransfer.getData('ka-draggableKeyValue')
                )
                const locationOrder = []
                tableProps?.data.forEach(({location_id}) => {
                  locationOrder.push(location_id)
                })
                const newLocationOrder = reOrderArray(locationOrder, locationOrder.indexOf(+draggableKeyValue), locationOrder.indexOf(+extendedEvent?.childProps?.rowKeyValue))
                handleLocationReordering(newLocationOrder)
              },
              onContextMenu: (event, cellProps) => {
                if (flag === 'lineListTable') {
                  event.preventDefault(false)
                  AddEditButton(event, cellProps)
                }
              },
            }),
          },
          cellEditor: {
            content: (props) => {
              if (props.column.key === 'selection-cell') {
                return <SelectionCell {...props} />
              }
            },
          },
          noDataRow: {
            content: () => 'No Data Available',
          },
          dataRow: {
            elementAttributes: (props) => ({
              className: 'group-line-child-row',
            }),
          },
        }}
      />
    </MyDiv>
  )
}

export default CustomTable
