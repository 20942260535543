/* eslint-disable max-len */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq*/
/* eslint-disable radix */
/* eslint-disable default-case */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, {useEffect, useMemo, useState} from 'react'
import {SortingMode, EditingMode, FilteringMode} from 'ka-table/enums'
import {Table, kaReducer} from 'ka-table'
import FlagSharpIcon from '@mui/icons-material/FlagSharp'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import {NavLink, useParams} from 'react-router-dom/cjs/react-router-dom.min'
import {Box, Typography, IconButton} from '@mui/material'
import {kaPropsUtils} from 'ka-table/utils'
import MenuIcon from '@mui/icons-material/Menu'
import {useSelector, useDispatch} from 'react-redux'
import {reorderReducer} from '../../../../reducers'
import DataGridStyled from '../../../../components/DataGrid/DataGridStyled.style'
import {ColumnToggle} from '../../../../components/DataGrid/Column'
import {fetchAssetDetails, fetchAssetList, fetchAssetListForReports} from '../../../../services/assetListService'
import {ColumnSettings, PageSize, SelectionCell, SelectionHeader, SwitchIcon} from '../../../../components/Tables/CustomTable/components'
import Loader from '../../../../components/Loader'
import {AlertMessage, CustomButton} from '../../../../components'
import {reportListActions} from '../../../../redux/reports/report'
import {assetsListActions} from '../../../../redux/assets/assets'
import {convertClassValueToClass, convertConditionToConditionValue, convertConditionValueToCondition} from '../../../../utils/Utils'
import {getAssetListDataAction} from '../../../../redux/assets/AssetsActions'
import TempSelectionLine from '../../../../components/Tables/CustomTable/components/tempSelection'
import MyDiv from './CustomReport.style'

import {tableConfig, columns} from './CustomReports.table'
import {ColumnConfig, column} from './CustomReports.column'


const PAGE_SIZE = localStorage.getItem('itemsPerPage') ? localStorage.getItem('itemsPerPage') : 50

const CustomReports = (props) => {

  const reduxDispatch = useDispatch()
  const state = useSelector((state) => {
    return state
  })
  let {id} = useParams()
  const {
    dataKeys: {attributes, orders},
  } = tableConfig
  const {
    dataKeys: {attribute, order},
  } = ColumnConfig
  const tablePropsInit = {
    columns: orders.map((key) => {
      return {
        ...columns[key],
        visible: attributes[key].visibility,
      }
    }),
    data: [],
    // height: '55vh',
    editingMode: EditingMode.Cell,
    filteringMode: FilteringMode.FilterRow,
    rowKeyField: 'type_id',
    rowReordering: false,
    groups: [
      {
        columnKey: 'location_address',
        key: 'location_id',
      },
      {
        columnKey: 'area',
        key: 'area_order',
      },
    ],
    sortingMode: SortingMode.Single,
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: +PAGE_SIZE,
      pageSizes: [50, 100, 150, 200],
    },
  }
  const columnPropsInit = {
    columns: order.map((key) => {
      return {
        ...column[key],
        visible: attribute[key].visibility,
      }
    }),
  }
  const [columnChooserProps, changeColumnChooserProps] = useState(tablePropsInit)
  const [columnProps, changeColumnProps] = useState(columnPropsInit)
  const [rows, setRows] = useState([])
  const [lines, setLines] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [fetchRequest, setFetchRequest] = useState(false)
  const [fetchAssetDetailsRequest, setFetchAssetDetailsRequest] = useState(false)
  const [valueCode, setValueCode] = useState([])
  const [columnSetting, setColumnSetting] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [isFilter, setFilter] = useState(false)
  const [getAssetListData, setGetAssetListData] = useState(true)
  const [assetCount, setAssetCount] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [assetDetails, setAssetDetails] = useState()
  const [areAllRowsSelected, setAreAllRowsSelected] = useState(false)


  const assetsState = useSelector((state) => state.assets)

  useEffect(() => {
    let projectID = localStorage.getItem('project_id')
    let locationData = {
      action: 'getLocation',
      project_id: Number(projectID),
    }
    reduxDispatch(getAssetListDataAction(locationData))
  }, [reduxDispatch])
  useEffect(() => { // ! ka-table loses the state so now we manage it with react
    columnChooserProps?.data?.length === columnChooserProps?.selectedRows?.[0]?.length ? setAreAllRowsSelected(true) : setAreAllRowsSelected(false)
  }, [columnChooserProps?.data, columnChooserProps?.selectedRows])
  const generateCell = (key, content, args, index) => {
    const cellSkeleton = (content, args) => {
      return (
        <td key={index} className="ka-group-cell" {...args}>
          <div className="ka-group-cell-content">
            <div className="ka-group-text">{content}</div>
          </div>
        </td>
      )
    }
    switch (key) {
      case 'quantity':
        return cellSkeleton('Mill', args)
      default:
        return cellSkeleton(content, args)
    }
  }

  const handleSidebar = () => {
    setColumnSetting(!columnSetting)
  }
  const dispatch = (action) => {
    changeColumnChooserProps((prevState) => {
      switch (action.type) {
        case 'ReorderRows':
          return reorderReducer(prevState, action)
        default:
          return kaReducer(prevState, action)
      }
    })
    changeColumnProps((prevState) => {
      switch (action.type) {
        case 'ReorderRows':
          return reorderReducer(prevState, action)
        default:
          return kaReducer(prevState, action)
      }
    })
  }
  const DataRowLine = (props) => {
    if (props.groupKey[props.groupIndex] !== '0') {
      const currentLine = props.groupKey[props.groupIndex]

      const currentLineDetail = lines[currentLine][0]
      // eslint-disable-next-line camelcase
      const {value_short_name, value} = currentLineDetail
      const valueArray = value.split('#')
      const values = value_short_name.split('#').reduce((acc, val, i) => {
        acc[val] = valueArray[i] !== '' ? +valueArray[i] : 0
        return acc
      }, {})
      // const assetValued = {...currentLineDetail, ...values};

      const lineAssets = rows.filter((row) => row.parent_line === +currentLine)
      const lineValues = lineAssets.reduce((acc, row) => {
        valueCode?.forEach((locationPriceValue) => {
          if (typeof acc[locationPriceValue] !== 'undefined') {
            acc[locationPriceValue] +=
              isNaN(row[locationPriceValue]) || Number.isInteger(!row[locationPriceValue])
                ? 0
                : row[locationPriceValue]
          } else {
            acc[locationPriceValue] =
              isNaN(row[locationPriceValue]) || Number.isInteger(!row[locationPriceValue])
                ? 0
                : row[locationPriceValue]
          }
        })
        return acc
      }, {})

      const currentLineDetails = {
        ...lines[currentLine][0],
        ...lineValues,
        ...values,
      }

      return (
        <>
          {/* <td className="ka-empty-cell" /> */}
          {/* <td className="ka-empty-cell" /> */}
          {/* <td className="ka-empty-cell" /> */}
          <td className="ka-group-cell">
            <div className="ka-group-cell-content">
              <div className="ka-group-text">{currentLine}</div>
            </div>
          </td>
          {orders.map((column, index) => {
            if (attributes[column].visibility && attributes[column].model?.includes('line')) {
              return generateCell(
                column,
                currentLineDetails[column] || currentLineDetails[attributes[column].secondaryKey],
                {
                  title: column,
                  ...(valueCode?.includes(column) &&
                    values[column] !== '' && {style: {color: 'red'}}),
                },
                index
              )
            } else if (attributes[column].visibility && attributes[column].toggable) {
              return <td key={index} className="ka-empty-cell" />
            }
          })}
        </>
      )
    } else {
      return null
    }
  }
  // ! new data row line function
  const newDataRowLine = (lines, rows, currentLine) => { //  lines, all rows, currentParentLineId
    if (rows && lines) { // not needed
      if (!lines[currentLine]) return // got it
      const currentLineDetail = lines[currentLine][0] // got it
      const {value_short_name, value} = currentLineDetail // got it
      const valueArray = value.split('#') // got it
      const values = value_short_name.split('#').reduce((acc, val, i) => { // got it
        acc[val] = valueArray[i] !== '' ? parseInt(valueArray[i]) : 0
        return acc
      }, {})
      const lineAssets = rows?.filter((row) => row.parent_line === parseInt(currentLine)) // got it
      const lineValues = lineAssets.reduce((acc, row) => {
        valueCode?.forEach((locationPriceValue) => {
          if (typeof acc[locationPriceValue] !== 'undefined') {
            acc[locationPriceValue] +=
              isNaN(row[locationPriceValue]) || Number.isInteger(!row[locationPriceValue])
                ? 0
                : row[locationPriceValue]
          } else {
            acc[locationPriceValue] =
              isNaN(row[locationPriceValue]) || Number.isInteger(!row[locationPriceValue])
                ? 0
                : row[locationPriceValue]
          }
        })
        return acc
      }, {})

      const currentLineDetails = {
        ...lines[currentLine][0],
        ...lineValues,
        ...values,
      }
      const generatedObject = {...currentLineDetails}
      return generatedObject
    }
  }
  const updateLocationOrder = (assets) => {
    const newAssetData = []
    // ! we now set a new field in the assets called locationOrder which we take from the locationData based on the indexof the location.
    //!  We then use this locationOrder field to order the assets rather than the location_id thus adding right ref to each asset/line
    assets.forEach((item, index) => {
      newAssetData[index] = {...item, locationOrder: assetsState?.getAssetListData?.getLocationtData.findIndex(({location_id}) => +location_id === +item?.location_id)}
    })
    return newAssetData

  }
  useEffect(() => {
    if (getAssetListData) {
      let locationId = null
      if (id > 0) {
        locationId = id
      }
      fetchAssetListForReports(locationId).then((res) => {
        let {line, asset, min_ref_no} = res
        console.log('🚀 ~ fetchAssetListForReports ~ asset:1', asset)
        asset = updateLocationOrder(asset)
        console.log('🚀 ~ fetchAssetListForReports ~ asset:2', asset)
        asset.sort((a, b) => {
          return (
            // a.location_id - b.location_id ||
            a.locationOrder - b.locationOrder ||
            a.area_order - b.area_order ||
            a.asset_area_order - b.asset_area_order
          )
        })
        setAssetCount(asset?.length)
        const refAssets = asset.map((asset, index) => {
          if (line && asset.parent_line && line.hasOwnProperty(asset.parent_line)) {
            if (
              line[asset.parent_line][0].area === '' ||
                !line[asset.parent_line][0].area_order
            ) {
              const objectToUpdate = line[asset.parent_line][0]
              Object.assign(objectToUpdate, {
                area_order: asset.area_order,
                // area_asset_order: asset.area_asset_order ,
                // special_notes: asset.special_notes,
                // mileage_type: asset.mileage_type,
                // mileage: asset.mileage,
                // serial_label_id: asset.serial_label_id,
                // asset_type_id: asset.asset_type_id,
                // model_label: asset.model_label,
                // serial_label: asset.serial_label,
                // appraiser: asset.appraiser,
                // user_id: asset.user_id,
                // condition_value: asset.condition_value,
                ///
                location_id: asset.location_id,
                area: asset.area,
                serial_number: asset.serial_number,
                project_id: asset.project_id,
                location_address: asset.location_address,
                condition_value: convertConditionToConditionValue(
                  objectToUpdate.condition
                ),
                capacity: objectToUpdate.line_name,
              })
              line[asset.parent_line][0] = objectToUpdate
            }
          }
          const {value_short_name, value} = asset
          const valueArray = value.split('#')
          const values = value_short_name.split('#').reduce((acc, val, i) => {
            acc[val] = valueArray[i] !== '' ? valueArray[i] : ''
            return acc
          }, {})

          return {...asset, ...values}
        })

        // const refAssets = asset?.map((asset, index) => {
        // // ! we are now updating the line here to actually add area to it so it now has all the properties needed to be handed over to the table
        //   if (asset.parent_line) {
        //     if (line.hasOwnProperty(asset.parent_line)) {
        //     // eslint-disable-next-line eqeqeq
        //       if (line[asset.parent_line][0].area == '') {
        //         let objectToUpdate = line[asset.parent_line][0] // ! our current structure always has it at the first index
        //         //! please don't uncomment or remove any of the lines in the Object.assign method. THIS IS CRITICAL.
        //         Object.assign(objectToUpdate, {
        //           area_order: asset.area_order,
        //           // area_asset_order: asset.area_asset_order ,
        //           // special_notes: asset.special_notes,
        //           // mileage_type: asset.mileage_type,
        //           // mileage: asset.mileage,
        //           // serial_label_id: asset.serial_label_id,
        //           // asset_type_id: asset.asset_type_id,
        //           // model_label: asset.model_label,
        //           // serial_label: asset.serial_label,
        //           // appraiser: asset.appraiser,
        //           // user_id: asset.user_id,
        //           // condition_value: asset.condition_value,
        //           ///
        //           location_id: asset.location_id,
        //           area: asset.area,
        //           serial_number: asset.serial_number,
        //           project_id: asset.project_id,
        //           location_address: asset.location_address,
        //           condition_value: convertConditionToConditionValue(
        //             objectToUpdate.condition
        //           ),
        //           capacity: objectToUpdate.line_name,
        //         })
        //         line[asset.parent_line][0] = objectToUpdate
        //       }
        //     }
        //   }
        //   const {value_short_name, value} = asset
        //   const valueArray = value.split('#')
        //   const values = value_short_name.split('#').reduce((acc, val, i) => {
        //   // eslint-disable-next-line radix
        //     acc[val] = valueArray[i] !== '' ? parseInt(valueArray[i]) : 0
        //     return acc
        //   }, {})
        //   const assetValued = {...asset, ...values}
        //   return assetValued
        // // if (asset.parent_line <= 0) {
        // //   return {
        // //     ...assetValued,
        // //     ref: index + 1,
        // //   }
        // // } else {
        // //   return assetValued
        // // }
        // })
        // handleAssetCount()
        // ! adding the new handle lines logic here
        let flag = {} // To track encountered x values

        for (let i = 0; i < refAssets.length; i++) {
          const currentObj = refAssets[i]
          const currentXValue = currentObj.parent_line

          if (currentXValue > 0 && !flag[currentXValue] && line) {
          // ! creating the new line here
            const generatedRow = newDataRowLine(line, refAssets, currentXValue)
            // Insert a new object before the current object
            refAssets.splice(i, 0, {...generatedRow}) // Adding a new object, you can replace it with your desired object

            // Set the flag for the current x value to true
            flag[currentXValue] = true

            // Increment the index to skip the newly added object
            i++
          }
        }
        // ! applying ref here since it is creating a mess everywhere else. This is our final data so we can now update it.
        let refCounter = min_ref_no // ! using this to set the ref now instead of the index
        refAssets.forEach((item) => {
          if (item.parent_line === 0) {
            item.ref = refCounter++
          }
        })
        // ! this is new, since the ref aren't supposed to be updated but rather stay the same
        // ! we are now first creating refs for all the assets for the project, once created
        //! if we have a location based filter applied (if the url has anything but -1 or 0)
        //! we then filter the assets once refs are assigned to have the refs intact and still
        //! show the updated refs with filter applied
        // if (id > 0) {
        //   const newRefAssets = refAssets.filter((item) => +item.location_id === +id)
        //   setRows(newRefAssets) // ! this would now be the location specific assets with correct refs
        //   const assetLength = asset.filter((asset) => +asset.location_id === +id).length
        //   setAssetCount(assetLength)
        //   //! the below line is duplicated in both if and else because react is stupid and won't update the state for use right away
        //   changeColumnChooserProps({
        //     ...columnChooserProps,
        //     data: newRefAssets,
        //   })

        // } else {
        //   setRows(refAssets) //! this would be all the assets with correct refs
        //   // const assetCount = refAssets
        //   // setAssetCount(refAssets.length)
        changeColumnChooserProps({
          ...columnChooserProps,
          data: refAssets,
        })
        // }
        const computedLines = refAssets.filter((item) => item?.type?.toLowerCase() === 'line')
        setLines(computedLines)
        reduxDispatch(assetsListActions.setDoFetchAssetList(false))
        setGetAssetListData(false)
        setIsLoading(false)
      })
    }
  }, [columnChooserProps, getAssetListData])

  //! not sure what this was being used for but not needed right now
  // React.useEffect(() => {
  //   if (!fetchAssetDetailsRequest) {
  //     fetchAssetDetails().then((res) => {
  //       setAssetDetails(res)
  //       const valueCodeRes = res?.locationPriceValues.reduce(
  //         (acc, curr) => [...acc, curr.value_short_name],
  //         []
  //       )
  //       setValueCode(valueCodeRes)
  //       setFetchAssetDetailsRequest(true)
  //     })
  //   }
  // }, [fetchAssetDetailsRequest])
  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
  }

  // Child Components
  const childComponents = {
    cell: {
      elementAttributes: (props) => ({
        className:
          props.rowData?.type === 'line'
            ? 'red'
            : '',
      }),
    },
    dataRow: {
      elementAttributes: (props) => ({
        className: `row-style ${props.rowData?.className} ${
          props.rowData?.parent_line > 0 ? 'group-line-child-row' : ''
        }`,
      }),
    },
    cellText: {
      elementAttributes: (props) => ({
        title: props?.column?.key === 'ref' && props?.rowData?.type === 'asset' ? `Location Name : ${props.rowData?.location_address} \n \n Area Name:${props?.rowData?.area}` : props?.column?.key === 'ref' && props?.rowData?.type === 'line' ? `Location Name : ${props.rowData?.location_address}` : props?.column?.key === 'selection-cell' ? '' : props?.column?.key === 'add-icon' ? '' :
          valueCode?.includes(props?.column?.key) ? '' : props?.column?.key === 'flagged' ? '' : props?.column?.key === 'is_not_found' ? '' : props?.column?.key === 'condition_value' ? `${convertConditionValueToCondition(props?.value)}` : props?.column?.key === 'asset_class_code' ? `${convertClassValueToClass(props.value)}` : `${props?.value}` === 'null' ? '' : `${props?.value}`,
      }),
      content: (props) => {
        switch (true) {
          case props?.column?.key === 'flagged':
            return props?.value ? <FlagSharpIcon fontSize="small" color="error" /> : ' '
          case props?.column?.key === 'is_not_found':
            return props?.value ? <CheckCircleOutlinedIcon fontSize="small" color="success" /> : ' '
          case valueCode?.includes(props?.column?.key):
            return props?.value !== '0' ? props.value : ' '
          case props?.column?.key === 'selection-cell' && props?.rowData?.type === 'line':
            return (<TempSelectionLine
              {...props}
              rows={columnChooserProps?.data}
            />)
          case props?.column?.key === 'selection-cell' :
            return <SelectionCell {...props} />
          default:
            return null
        }
      },
    },
    filterRowCell: {
      content: (props) => {
        if (props?.column?.key === 'selection-cell') {
          return <></>
        } else if (props?.column?.key === 'add-icon') {
          return <></>
        } else if (props?.column?.key === 'is_not_found') {
          return <></>
        } else if (props?.column?.key === 'ref') {
          return <></>
        } else if (props?.column?.key === 'flagged') {
          return <></>
        }
      },
    },
    headCell: {
      elementAttributes: (props) => ({
        title: props?.column?.key === 'selection-cell' ? '' : props?.column?.key === 'ref' ? '' : props?.column?.key === 'add-icon' ? '' : `${props?.column?.title}`,
      }),
      content: (props) => {
        if (props?.column?.key === 'selection-cell') {
          return (
            <SelectionHeader
              {...props}
              id={id}
              allIds={columnChooserProps?.data}
              areAllRowsSelected={areAllRowsSelected}
            />
          )
        } else if (props?.column?.key === 'add-icon') {
          return (<Box className="align_switch">
            {/* <SwitchIcon handleSelect={props.handleSelect} showIcon={props.showIcon} /> */}
          </Box>)
        }
      },
    },
    groupRow: {
      elementAttributes: (props) => ({
        className: `group-row-${props.column.key}`,
        hidden:
          !!(props.column.key === 'parent_line' && props.groupKey[props.groupIndex] <= '0'),
        onClick: (e) => {
          //
        },
      }),
      childComponents: {
        dataRow: {
          elementAttributes: (props) => ({
            className: 'group-line-child-row',
          }),
        },
      },
      contentColSpan: 1,
      content: (props) => {
        switch (props.column.key) {
          case 'parent_line':
            return DataRowLine(props)
          default:
            return null
        }
      },
    },
    groupCell: {
      content: (props) => {
        switch (props.column.key) {
          case 'area':
            return props.groupKey[props.groupIndex]
          default:
            return null
        }
      },
    },
    pagingSizes: {
      elementAttributes: (props) => ({
        title: 'Selected Page',
      }),
      content: (props) => <PageSize {...props} />,
    },
    pagingIndex: {
      elementAttributes: ({isActive}) => ({
        className: `page-item ${isActive ? 'active' : ''}`,
      }),
    },
    pagingPages: {
      elementAttributes: () => ({
        className: 'pagination',
      }),
    },
  }


  const extractDataFromLines = () => {
    let computedLines = lines
    if (id !== '-1') { // weird joke
      computedLines = lines.filter((line) => +line.location_id === +id)
    }
    const extractedArray = []
    if (computedLines) {
      computedLines.forEach((item) => {
        if (item.hasOwnProperty('type_id')) {
          extractedArray.push({type_id: item.type_id, type: item.type, ref_no: item.ref})
        }
      })
    } else {
      lines.forEach((item) => {
        if (item.hasOwnProperty('type_id')) {
          extractedArray.push({type_id: item.type_id, type: item.type, ref_no: item.ref})
        }
      })
    }
    return extractedArray
  }
  const handlePdfWithoutPriceValues = (type) => {

    // const myRowData = []
    // rowData.filter((rows) => row.type_id = )
    const filteredArray = columnChooserProps?.data?.filter((obj) => state.report.selectedItems.includes(obj.type_id))
    // const convertedList = []
    // const linesLength = Object.keys(lines).length
    // for (let i = 0; i < linesLength; i++) {
    //   convertedList.push({
    //     [i]: {
    //       type_id: lines.type_id,
    //       ref_no: lines.ref_no,
    //       type: lines.type,
    //     },
    //   })
    // }
    const convertedList = extractDataFromLines()
    switch (type) {
      case 'withoutPrice':
        props.handleCustomReports(filteredArray, convertedList, 'withoutPrice', 'custom_asset_list', 'pdf', 'pv')
        break
      case 'withoutCompany':
        props.handleCustomReports(filteredArray, convertedList, 'withoutCompany', 'custom_asset_list', 'pdf', 'c')
        break
      case 'withoutPriceAndCompany':
        props.handleCustomReports(filteredArray, convertedList, 'withoutPriceAndCompany', 'custom_asset_list', 'pdf', 'pvc')

        break
      case 'general':
        props.handleCustomReports(filteredArray, convertedList, 'general', 'custom_asset_list', 'pdf')
        break
      case 'excel':
        props.handleCustomReports(filteredArray, convertedList, 'excel', 'custom_excel_report', 'excel')
        break
      default:
        break
    }
  }

  const handleInvertSelection = () => {
    const selectionList = []
    const filteredRows = columnChooserProps?.data?.filter((row) => !state.report.selectedItems.includes(row.type_id))
    for (const row of filteredRows) {
      selectionList.push(row?.type_id)
    }
    selectionList?.length === columnChooserProps?.data?.length ? setAreAllRowsSelected(true) : setAreAllRowsSelected(false)
    reduxDispatch(reportListActions.setSelectedReportRows(selectionList))
  }
  const handleToggle = () => {
    setFilter(!isFilter)
  }

  useEffect(() => {
    return () => {
      reduxDispatch(reportListActions.setSelectedReportRows([]))
    }
  }, [])
  const getTable = useMemo(() => {
    return (<Table
      {...columnChooserProps}
      childComponents={childComponents}
      dispatch={dispatch}
      filteringMode={isFilter ? FilteringMode.FilterRow : ''}
    />)
  }, [columnChooserProps, isFilter, areAllRowsSelected])

  return (
    <MyDiv>
      {isLoading && <Loader />}
      <Box className="page_heading">
        <Typography>CUSTOM REPORTS</Typography>
      </Box>
      <Box className="buttons_flex">
        <Box className="button_group">
          {/* <CustomButton
            title="Select All"
            variant="contained"
            className="btn_theme"
          />
          <CustomButton
            title="Select None"
            variant="contained"
            className="btn_theme"
          /> */}
          <CustomButton
            title="Invert Selection"
            onClick={() => handleInvertSelection()}
            variant="contained"
            className="btn_theme"
          />
          <CustomButton
            title="Toggle Search Bar"
            variant="contained"
            className="btn_theme"
            onClick={() => handleToggle()}
          />
          <CustomButton
            title="PDF w/o Values"
            variant="contained"
            className="btn_theme"
            onClick={() => handlePdfWithoutPriceValues('withoutPrice')}
          />
          <CustomButton
            title="PDF w/o Company"
            variant="contained"
            className="btn_theme"
            onClick={() => handlePdfWithoutPriceValues('withoutCompany')}
          />
          <CustomButton
            title="PDF w/o Company & Values"
            variant="contained"
            className="btn_theme"
            onClick={() => handlePdfWithoutPriceValues('withoutPriceAndCompany')}
          />
          <CustomButton
            title="PDF"
            variant="contained"
            className="btn_theme"
            onClick={() => handlePdfWithoutPriceValues('general')}
          />
          <CustomButton
            title="Excel"
            variant="contained"
            className="btn_theme"
            onClick={() => handlePdfWithoutPriceValues('excel')}
          />
        </Box>
        <Typography className="total_assets">Total Assets - {assetCount}</Typography>
        <Box className="button_group">
          <NavLink activeClassName="activeMenu" style={{textDecoration: 'none'}} to="/asset-list-reports/">Back</NavLink>
        </Box>
      </Box>
      <ColumnSettings tableProps={columnProps} dispatch={dispatch} columnSetting={columnSetting} handleSidebar={handleSidebar} isFilter={isFilter} setFilter={setFilter} />
      <IconButton onClick={handleSidebar} className="column_setting">
        <MenuIcon />
      </IconButton>
      <DataGridStyled className="rows-reordering-demo">
        <ColumnToggle {...columnChooserProps} dispatch={dispatch} isFilter={isFilter} />
        {getTable}
      </DataGridStyled>
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
export default CustomReports
