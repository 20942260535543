import styled from 'styled-components'

const DataGridStyled = styled.div`
.ka{
  border: solid 1px #cecece;
}
.red{
background-color: #a4c8d0!important;
font-weight:700
}
.highlighted-row .red{
  background-color:#DDE46A!important;
}
.asset_highlight{
  // background-color:#DDE46A!important;
}
// .ka-row:nth-child(even) {
//   background: '';
// }
.group-cell-row {
  max-width: 50px;
}
ka-thead{
  position: sticky;
  top: 0;
  z-index: 1;
}
.ka-thead-cell{
  border-right: 1px solid #cecece;
}
.ka-thead-cell:last-child{
  border-right: 0;
}
.ka-thead-cell-content {
  font-weight: 600;
  color: #000;
  font-size: 15px;
  font-family: "Arial";
}
  .ka-row {
    box-sizing: border-box;
    border-bottom: none;
    border-top: none;
  }

  .ka-row:nth-child(even) {
    background: #5656561f;
  }
  .ka-row:nth-child(even) {
    background: #5656561f;
  }
  .ka-group-row {
    box-sizing: border-box;
    border-bottom: unset;
    border-top: unset;
  }
  .group-row-location_id {
    background-color: #95a2a5;
    color: #000;
  }
  .group-row-area {
    background-color: #b2c3cc;
    color: #333;
  }
  .ka-thead-cell-content,
  .ka-cell-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ka-thead-cell {
    padding: 4px;
    font-weight: 600;
    color: #000;
    font-size: 15px;
    font-family: "Arial";
    background-color: #eee;
    text-align: center;
  }
  .ka-row.ka-drag-over-row {
    background-color: #5cb30287;
  }
  .ka-table-wrapper {
    height: 100vh;
    overflow-x: hidden;
  }
  .ka-empty-cell {
    width: 0;
    min-width: 0;
  }
  .ka-row.droped-row {
    background-color: #dde46a;
  }
  .ka-group-row.group-row-parent_line {
    background: #a4c8d0;
    font-weight: bold;
  }
  .highlighted-row{
    // border-left: 4px solid #a4c8d0;
    background-color:#DDE46A!important;
  }
  .ka-group-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ka-row.group-line-child-row {
    border-left: 12px solid #a4c8d0;
  }

  .ka-cell {
    text-align: center;
    padding: 4px 2px;
    line-height: 20px;
    border-left: 1px solid #ccc;
    color: #333;
    font-size: 14px;
  }
  .ka-input{
    border: 1px solid #b1b1b1;
    height: 30px;
    padding: 0px 10px;
    border-radius: 4px;
}
.ka-input:focus-visible{
  outline: 0;
}
.selection{
  position: 'sticky',
  left: 0,
  background-color: '#eee',
  border: 1px solid lightgray
}
.ka-thead-fixed {
  position: initial;
}
.ka-paging-sizes-active{
  align-items: center;
  padding: 10px;
}
.ka-paging-page-index, .ka-paging-size{
  min-width: 32px;
  height: 32px;
  padding: 0px 6px;
  line-height: 30px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-family: "Arial";
}
.ka-paging-page-index-active, .ka-paging-size-active{
  color: #fff;
  background-color: #333;
  border: 1px solid transparent;
  font-weight: 600;
}
.ka-group-cell {
  padding: 2px 5px!important;
}
.table_inner_text{
  justify-content: center;
}
.icon_btn_flag{
  width: 24px;
  height: 24px;
}

.delete{
  color: #a43738;
}
.icon_btn_flag_default{
  width: 100%;
  height: 25px;
  border-radius: 0;
  padding: 0;
}
.ka-thead-fixed .ka-thead-cell-wrapper .ka-thead-cell-content-wrapper .MuiCheckbox-root {
  padding: 0;
}
.ka-row .ka-cell .ka-cell-text .MuiCheckbox-root {
  padding: 0;
}
.ka-row .ka-cell .ka-cell-editor .MuiCheckbox-root {
  padding: 0;
}
.ka-thead-cell-height{
  height: auto;
}
.align_switch{
  display: flex;
  align-items: center;
  justify-content: center;
}
.ka-icon-group-arrow{
  padding: 5px;
}
.group-row-location_address{
  background-color: #95A2A5;
  color: #000;
}
.area_flex{
  display: flex;
  align-items: center;
  // column-gap: 10px;
}
.icon_btn svg{
  width: 18px;
  height: 18px;
}
.edit{
  color: #333;
}
.ka-thead{
  position: sticky;
  z-index: 1;
  top: -1px;
  left: 0;
}
.ka-paging-sizes-active{
  background: #f6f6f6;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0px;
  z-index: 111;
}
.ka-paging-sizes-active {
  display: flex;
  justify-content: unset !important;
}

`
export default DataGridStyled
