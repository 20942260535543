import {blockMoveAsset} from '../../../services/assetListService'
import {assetsListActions} from '../assets'

const blockMoveAssetAction = (payload) => {
  return (dispatch) => {
    dispatch(assetsListActions.blockMoveAssetRequest())
    blockMoveAsset(payload).then((res) => {
      if (res.status === 200) {
        dispatch(assetsListActions.setMessageData({message: res.data.msg, status: 'success'}))
        dispatch(assetsListActions.setDoFetchAssetList(true))
        dispatch(assetsListActions.blockMoveAssetSuccess(res))

      }
    }).catch((error) => {
      dispatch(assetsListActions.blockMoveAssetFailure(error))
    })

  }
}

export default blockMoveAssetAction
