import React, {useState, useEffect} from 'react'
import {
  Box,
  Grid,
  IconButton,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material'
import {KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon} from '@mui/icons-material'
import MyDiv from './imagesList.style'

export default function ImagesList(props) {

  const [filteredImages, setFilteredImages] = useState([])

  useEffect(() => {
    const allImages = [...props.imagesList]
    if (props?.assetsData?.asset_photos_to_remove?.length) {
      props?.assetsData?.asset_photos_to_remove?.map((element) => {
        const currentIndex = allImages.indexOf(element)
        if (currentIndex > -1) {
          allImages.splice(currentIndex, 1)
        }
        setFilteredImages(allImages)
      })
    } else {
      setFilteredImages(allImages)
    }
  }, [props?.assetsData?.asset_photos_to_remove, props?.imagesList])
  return (
    <MyDiv>
      <Box className="wrapper">
        <Grid container columnSpacing={{md: 3}}>
          {filteredImages?.length > 0 ?
            <>
              {filteredImages?.map((item, index) => {
                return (
                  <Grid item md={3} key={index}>
                    <Tooltip title={item.split('/').pop()} placement="bottom-end">
                      <Box className={props.selectedIndex.filter((data) => data === item)[0] ? 'add_item list_item' : 'list_item'}>
                        <ListItemButton
                          selected={props.selectedIndex === item}
                          onClick={() => props.handleListItemClick(item)}
                          key={index}
                          className="list_btn"
                        >
                          <img src={props.url + item} alt={item.split('/').pop()} />
                          <ListItemText primary={item.split('/').pop()} className="label_name" />
                        </ListItemButton>
                      </Box>
                    </Tooltip>
                  </Grid>
                )
              })}
            </> : <Typography className="label_title">No Images found</Typography> }
        </Grid>
        <IconButton className="add_all" onClick={props.handleAssign}>
          <KeyboardDoubleArrowRightIcon />
        </IconButton>
      </Box>
    </MyDiv>
  )
}
