import {deleteAssets} from '../../../services/assetListService'
import {assetsListActions} from '../assets'

const deleteAssetAction = (payload) => {
  return (dispatch) => {
    dispatch(assetsListActions.deleteAssetRequest())
    deleteAssets(payload).then((res) => {
      if (res.status === 200) {
        dispatch(assetsListActions.setMessageData({message: 'Delete Asset(s) Successfully.', status: 'success'}))
        dispatch(assetsListActions.deleteAssetSuccess(res))
        dispatch(assetsListActions.setDoFetchAssetList(true))
      }
    }).catch((error) => {
      dispatch(assetsListActions.deleteAssetFailure(error))
    })

  }
}

export default deleteAssetAction
