/* eslint-disable no-undef */
import React, {useState, useEffect} from 'react'
import 'jspdf-autotable'
import {kaReducer} from 'ka-table'
// import { Table, useTableInstance } from 'ka-table';
import {
  DataType,
  SortingMode,
  EditingMode,
} from 'ka-table/enums'
// import { kaPropsUtils } from 'ka-table/utils'
import {Box} from '@mui/material'
import {useDispatch} from 'react-redux'
// import {deselectAllFilteredRows, deselectAllRows} from 'ka-table/actionCreators'
// import {deselectRow} from 'ka-table/actionCreators'
import {CustomTable, CustomButton, AlertMessage} from '../../../../../components'
import {postAssignAppraiserUserAction} from '../../../../../redux/admin/AdminActions'
import {adminListActions} from '../../../../../redux/admin/admin'
import MyDiv from './manageUserTable.style'

export default function ManageUserTable(props) {

  const tablePropsInit = {
    columns: [
      {
        key: 'selection-cell',
        style: {width: '2%'},
      },
      {
        dataType: DataType.Number,
        key: 'user_id',
        style: {width: 50},
        title: 'User Id',
        isEditable: false,

      },
      {
        dataType: DataType.String,
        key: 'username',
        style: {width: 120},
        title: 'Username',
        isEditable: false,

      },
      {
        dataType: DataType.String,
        key: 'first_name',
        style: {width: 120},
        title: 'First Name',
        isEditable: false,

      },
      {
        dataType: DataType.String,
        key: 'name',
        style: {width: 120},
        title: 'Name',
        isEditable: false,

      },
      {
        dataType: DataType.String,
        key: 'status',
        style: {width: 120},
        title: 'Status',
        isEditable: false,

      },
    ],
    selectedRows: [],
    data: props.filteredAppraiserData,
    rowKeyField: 'user_id',
    height: '75vh',
    sortingMode: SortingMode.Single,
    editingMode: EditingMode.Cell,
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: localStorage.getItem('itemsPerPageUser') ? localStorage.getItem('itemsPerPageUser') : 250,
      pageSizes: [250, 500, 1000],
    },
  }

  const [userListTable, setUserListTable] = useState(tablePropsInit)
  // const adminState = useSelector((state) => state.admin)
  const dispatch = useDispatch()
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [isClick, setClick] = useState(false)
  const [key, setKey] = useState(false)


  useEffect(() => {
    if (props.searchValue !== '') {
      const result = props.filteredAppraiserData.filter((item) => {

        // const isSearchAllowed = item.status !== 1 || 0 // Check if status is not equal to 1 (active)

        // return isSearchAllowed &&
        return (item?.first_name?.toLowerCase().includes(props.searchValue.toLowerCase()) ||
         item?.name?.toLowerCase().includes(props.searchValue.toLowerCase()))
      //  item?.username?.toLowerCase().includes(searchValue.toLowerCase()))
      })

      setUserListTable({...userListTable, data: result})
    } else {
      setUserListTable({...userListTable, data: props.filteredAppraiserData})
    }
  }, [props.filteredAppraiserData])


  useEffect(() => {
    return () => {
      dispatch(adminListActions.clearSelectedItems()) // clearing the global state of checkboxes for every component
    }
  }, [])

  const dispatchResearch = (action) => {
    setUserListTable((prevState) => kaReducer(prevState, action))
    localStorage.setItem('itemsPerPageUser', userListTable?.paging?.pageSize)
  }
  // const activeInactiveUser = (status) => () => {
  //   let userData = {
  //     by_mne_admin: 1,
  //     status,
  //     user_id: adminState.selectedItems.join('^'),
  //   }
  //   dispatch(postAssignAppraiserUserAction(userData))
  // }

  const activeInactiveUser = (status) => () => {
    if (userListTable?.selectedRows.length > 0) {
      let userData = {
        by_mne_admin: 1,
        status,
        user_id: userListTable?.selectedRows.join('^'),
      }
      dispatch(postAssignAppraiserUserAction(userData))
      setUserListTable({...userListTable, selectedRows: []})
    } else {
      setMessage('Please select at least one user.')
      setStatus('warning')
    }
  }

  const handleCancel = () => {
    setUserListTable({...userListTable, selectedRows: []})
  }

  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    dispatch(adminListActions.clearMessageData())
  }

  return (
    <MyDiv>
      <CustomTable
        tableProps={userListTable}
        dispatch={dispatchResearch}
        isClick={isClick}
        setClick={setClick}
        key={key}
        setKey={setKey}
      />
      <Box className="d-flex bottom_actions">
        <CustomButton title="Active" className="btn_theme" variant="contained" onClick={activeInactiveUser(1)} />
        <CustomButton title="InActive" className="btn_theme" variant="contained" onClick={activeInactiveUser(0)} />
        <CustomButton title="Cancel" className="btn_reset" variant="contained" onClick={handleCancel} />
      </Box>
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
