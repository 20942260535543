/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import {postMakeAdd} from '../../../services/adminService'
import {adminListActions} from '../admin'
import getMakeListAction from './getMakeListAction'

const postFlagMake = (payload) => {
  let flag = localStorage.getItem('isFlagged')
  return (dispatch) => {
    dispatch(adminListActions.postFlagMakeRequest())
    postMakeAdd(payload).then((res) => {
      if (res.status === 200) {
        dispatch(adminListActions.setMessageData({message: `Make ${+payload?.isFlagged === 0 ? 'un-flagged' : 'flagged'} successfully.`, status: 'success'}))// message content, status, eg - res.data
        dispatch(getMakeListAction(flag))
      }
      dispatch(adminListActions.postFlagMakeSuccess(res))
    }).catch((error) => {
      dispatch(adminListActions.postFlagMakeFailure(error))
    })
  }
}

export default postFlagMake
