/* eslint-disable  */

// ! eslint is causing too many problems so just disabled it
var filterOptions, refCount, current_ref_count

export const getRefDataCommon = (data, filterOptions) => {
 filterOptions = filterOptions;
 refCount = 0;
 current_ref_count = 0;
 /*
  * Formatting asset data as required for ui-grid
  */
 var asset_data = data.asset;
 var location_id, area, line, asset, j = 0, new_data = [], line_asset = [], line_index = 0, asset_list_data = [];
 refCount = data.ref_count;
 let assetLines = data.line;
 for (var i = 0; i < asset_data.length; i++) {
  asset = asset_data[i];
  if (location_id === "" || location_id !== asset.location_id) {
   area = "";
   location_id = asset.location_id;
   new_data[j] = {};
   new_data[j].location_label = asset.location_name;
   new_data[j].location_label_id = asset.location_id;
   if (asset.billing_address_street != '') {
    new_data[j].location_label = new_data[j].location_label + ', ' + asset.billing_address_street;
   }
   if (asset.billing_address_city != '') {
    new_data[j].location_label = new_data[j].location_label + ', ' + asset.billing_address_city;
   }
   if (asset.billing_address_state != '') {
    new_data[j].location_label = new_data[j].location_label + ', ' + asset.billing_address_state;
   }
   if (asset.billing_address_postalcode != '') {
    //new_data[j].location_label = new_data[j].location_label + '-' + asset.billing_address_postalcode;
   }
   if (asset.billing_address_country != '') {
    new_data[j].location_label = new_data[j].location_label + ', ' + asset.billing_address_country;
   }
   j++;
  }

  if (area === "" || area !== asset.area) {
   area = asset.area;
   new_data[j] = {};
   new_data[j].area_label = area;
   new_data[j].location_id = location_id;
   j++;
  }

  if (!filterOptions.showOnlyAsset && asset.parent_line != '0' && asset.parent_line !== '' && asset.parent_line !== null && line !== asset.parent_line) {
   line = asset.parent_line;
   line_index = "'" + line + "'";

   if (!(line_asset[line_index])) {
    line_asset[line_index] = [];
    line_asset[line_index]['index'] = j;
    line_asset[line_index]['asset'] = new Object();
    new_data[j] = {};
    if (data.line && data.line[line]) {
     new_data[j] = data.line[line][0];
     new_data[j].line_display_name = new_data[j].line_name;
     if (new_data[j]?.line_unique_name != '' && new_data[j]?.line_unique_name != null && new_data[j]?.line_name?.toLowerCase() != new_data[j]?.line_unique_name?.toLowerCase()) {
      new_data[j].line_display_name = new_data[j].line_name + ' (' + new_data[j].line_unique_name + ')';
     }
     new_data[j] = generateRefCommon(new_data[j], filterOptions);
     j++;
    }
   }
  }

  if (!filterOptions.showOnlyAsset && asset.parent_line != '0' && asset.parent_line !== '' && asset.parent_line !== null) {

   Array.prototype.push.call(line_asset[line_index]['asset'], asset);
   // line_asset[line_index]['asset'].push(...asset);
  } else {
   asset_list_data.push(asset.type_id);
   new_data[j] = asset;
   new_data[j] = generateRefCommon(new_data[j], filterOptions);
   if (new_data[j].area === null || new_data[j].area === '') {
    new_data[j].area = "Undefined Area";
   }
   j++;
  }
 }

 /**
  * Merging line asset with asset array
  */
 var new_index = 0;
 for (var key in line_asset) {
  if (key === 'length' || !line_asset.hasOwnProperty(key)) {
   continue;
  }
  var asset_value = line_asset[key]['asset'];
  /*
   * Order line assets as per sorting order
   */
  var orderDir = '-';
  if (filterOptions.sortOrder == 'asc') {
   orderDir = '+';
  }
  if (filterOptions.sortCol != 'area_order') {
   asset_value = [...asset_value].sort((a, b) => {
    if (orderDir === 'asc') {
     return a[filterOptions.sortCol] - b[filterOptions.sortCol];
    } else {
     return b[filterOptions.sortCol] - a[filterOptions.sortCol];
    }
   });
  }
  var index = new_index + parseInt(line_asset[key]['index']);
  for (var i = 0; i < asset_value.length; i++) {
   index = index + 1;
   new_index++;
   new_data.splice(index, 0, asset_value[i]);
  }
 }

 var ref_data_array = [];
 var values_total = {};
 var values_requested = [];
 var currency_symbol = '$';

 new_data.forEach((itemObj) => {
  if (typeof itemObj.type_id !== undefined && typeof itemObj.ref_no !== undefined) {
   const codes = itemObj?.value_code?.split('#');
   const short_names = itemObj?.value_short_name?.split('#');
   const values = itemObj?.value?.split('#');
   const vArr = {};

   const currency_symbol = formatCurrency('0', itemObj.reporting_currency_c);

   short_names ? short_names.forEach((item, indexS) => {
    itemObj[item] = values[indexS];

    if (!isNaN(values[indexS]) && values[indexS] !== null && values[indexS] !== '') {
     if (values_total[item + '_TOTAL'] === undefined) {
      values_total[item + '_TOTAL'] = parseInt(values[indexS]);
     } else {
      values_total[item + '_TOTAL'] += parseInt(values[indexS]);
     }
    }
   }): null;
   

   if(itemObj?.type_id && itemObj.ref_no && itemObj.type) {
       ref_data_array.push({ type_id: itemObj.type_id, ref_no: itemObj.ref_no, type: itemObj.type });
    }
  }
 });


 var allData = {};
 allData.refData = ref_data_array;
 allData.reportData = new_data;
 allData.values_total = values_total;
 allData.currency_symbol = currency_symbol;
 return allData;
};

const generateRefCommon = (obj, filterOptions) => {
 if ((obj.type === 'line' || obj.type === 'asset') && (obj.parent_line == '0' || obj.parent_line == '' || obj.parent_line == null) && filterOptions?.sortCol === 'area_order') {
  refCount++;
  current_ref_count++;
  obj.ref_no = refCount;
 }
 return obj;
};

const getCurrencySymbol = function (value, scope) {
 var val = formatCurrency(value, scope, 0);
 var symbol = val.substring(0, val.length - 4);
 if (symbol != '') {
  if (scope == 'CAD') {
   return 'C$';
  }
  else {
   return symbol;
  }
 }
 else {
  return '$';
 }
};

const formatCurrency = (value, locale = 'en-US', currency = 'USD') => {
    return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(value);
}