import React, {useState, useEffect} from 'react'
import {Box, Grid, Typography} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux'
import {decode as base64Decode} from 'base-64'
import {AlertMessage, CustomButton, CustomTextBox, Loader} from '../../../components'
import {
  getSerialLabelAction, getAppraiserTrueAction, postSerialLabelAction,
} from '../../../redux/admin/AdminActions'
import {adminListActions} from '../../../redux/admin/admin'
import {SerialLabelsTable} from './components'
import MyDiv from './serialLabels.style'

export default function SerialLabels(props) {

  const dispatch = useDispatch()
  const [addValue, setAddValue] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [serialLabelList, setSerialLabelList] = useState()
  const [filteredSerialLabelList, setFilteredSerialLabelList] = useState([])
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')

  const adminState = useSelector((state) => state.admin)
  let userId = localStorage.getItem('userId')

  let projectIDCrm = localStorage.getItem('project_id_crm')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string

  const appraiserTrue = {
    getAppraisersOnly: true,
    project_id_crm: decodedProjectId,
  }

  useEffect(() => {
    dispatch(getSerialLabelAction())
    dispatch(getAppraiserTrueAction(appraiserTrue))
  }, [dispatch])

  useEffect(() => {
    setSerialLabelList(adminState?.serialLabelList?.serialLabelList)
    setFilteredSerialLabelList(adminState?.serialLabelList?.serialLabelList)
  }, [adminState?.serialLabelList?.serialLabelList])

  useEffect(() => {
    if (adminState?.messageData.status === 'success') {
      setMessage(adminState?.messageData?.message)
      setStatus('success')
      setAddValue('')
    }
    if (adminState?.messageData.status === 'failed') {
      setMessage(adminState?.messageData?.message)
      setStatus('warning')
    }
  }, [adminState?.messageData])

  const handleSearchName = () => {
    if (searchValue !== '') {
      const result = serialLabelList.filter((item) => {
        return item?.name?.toLowerCase().includes(searchValue.toLowerCase())
      })
      setFilteredSerialLabelList(result)
    } else {
      setFilteredSerialLabelList(serialLabelList)
    }
  }

  const handleChange = (e) => {
    setSearchValue(e.target.value)
  }

  const handleReset = () => {
    window.location.reload()
    // setSearchValue('')
    // dispatch(getSerialLabelAction())
  }

  const handleChangeAdd = (e) => {
    setAddValue(e.target.value)
  }

  const handleMessageData = (value, type) => {
    setMessage(value)
    setStatus(type)
  }

  const handleAdd = () => {
    if (!addValue) {
      setMessage('Please Enter Serial Label')
      setStatus('warning')
      return
    }
    let addObj = {
      created_by: Number(userId),
      modified_by: Number(userId),
      name: addValue,
    }
    dispatch(postSerialLabelAction(addObj, 'add'))
  }

  const handleSnackBarClose = () => {
    setTimeout(() => {
      setMessage('')
      setStatus('')
      dispatch(adminListActions.clearMessageData())
    }, 2000)
  }
  return (
    <MyDiv>
      {(adminState?.serialLabelList?.loading || adminState?.deleteSerialLabel?.loading || adminState?.postSerialLabel?.loading) &&
      <div>
        <Loader />
      </div>
      }
      <Box className="page_heading">
        <Typography>Manage Serial Number Labels</Typography>
      </Box>
      <Box className="admin_card">
        <Grid container className="main_container">
          <Grid item md={4} className="d-flex">
            <Box className="input_box">
              <Typography>Serial Label</Typography>
              <CustomTextBox name="serial_add" value={addValue} onChange={handleChangeAdd} />
            </Box>
            <CustomButton title="Add" className="btn_theme" variant="contained" onClick={handleAdd} />
          </Grid>
          <Grid item md={4} />
          <Grid item md={4} className="d-flex">
            <Box className="input_box">
              <Typography>Search String</Typography>
              <CustomTextBox name="search_serial" value={searchValue} onChange={handleChange} />
            </Box>
            <Box className="d-flex">
              <CustomButton title="Search" className="btn_theme" variant="contained" onClick={handleSearchName} />
              <CustomButton title="Reset" className="btn_reset" variant="contained" onClick={handleReset} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {filteredSerialLabelList?.length > 0 ?
        <SerialLabelsTable handleMessageData={handleMessageData} serialLabelList={filteredSerialLabelList} searchValue={searchValue} /> :
        <Box className="no-data">
          <Typography>No Data Available</Typography>
        </Box>
      }
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
