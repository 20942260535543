const reorderReducer = (state = {}, action) => {
  const {data} = state

  const targetRow = data?.find((row) => row.type_id === action.targetRowKeyValue)
  const draggedRow = data?.find((row) => row.type_id === action.rowKeyValue)
  if (!targetRow || !draggedRow) {
    return state
  }

  const updatedRows = data?.map((row) => {
    if (row.className === 'droped-row') {
      return {...row, className: ''}
    }

    if (targetRow.area === draggedRow.area) {
      if (row.type_id === action.rowKeyValue) {
        return {
          ...row,
          area: targetRow.area,
          area_order: targetRow.area_order,
          area_asset_order: targetRow.area_asset_order,
          parent_line: targetRow.parent_line,
          ...(row.parent_line === 0 && {ref: targetRow.ref}),
          className: 'droped-row',
        }
      } else if (
        row.area === targetRow.area &&
        row.location_id === targetRow.location_id &&
        row.type_id !== action.rowKeyValue &&
        row.area_asset_order >= targetRow.area_asset_order &&
        row.area_asset_order <= draggedRow.area_asset_order
      ) {
        return {
          ...row,
          area_asset_order: row.area_asset_order + 1,
          ...(row.parent_line === 0 && {ref: row.ref + 1}),
        }
      }
    } else if (targetRow.area !== draggedRow.area) {
      if (row.type_id === action.rowKeyValue) {
        return {
          ...row,
          area: targetRow.area,
          area_order: targetRow.area_order,
          area_asset_order: targetRow.area_asset_order,
          parent_line: targetRow.parent_line,
          ...(row.parent_line === 0 && {ref: targetRow.ref}),
          className: 'droped-row',
        }
      } else if (
        row.area === targetRow.area &&
        row.location_id === targetRow.location_id &&
        row.type_id !== action.rowKeyValue &&
        row.area_asset_order >= targetRow.area_asset_order
      ) {
        return {
          ...row,
          area_asset_order: row.area_asset_order + 1,
          ...(row.parent_line === 0 && {ref: row.ref + 1}),
        }
      } else if (
        row.area === draggedRow.area &&
        row.location_id === draggedRow.location_id &&
        row.area_asset_order > draggedRow.area_asset_order
      ) {
        return {
          ...row,
          area_asset_order: row.area_asset_order - 1,
          ...(row.parent_line === 0 && {ref: row.ref - 1}),
        }
      }
    }

    return row
  })

  const updatedRowsSorted = updatedRows.sort((a, b) =>
    a.location_id - b.location_id ||
    a.area_order - b.area_order ||
    a.area_asset_order - b.area_asset_order ||
    a.ref - b.ref
  )

  const refRefAssets = updatedRowsSorted.map((asset, index) => {
    return {
      ...asset,
      ref: asset.parent_line === 0 ? index + 1 : asset.ref,
    }
  })

  return {
    ...state,
    data: [...refRefAssets],
  }
}

export default reorderReducer
