/* eslint-disable no-debugger */
import {postAssetTypeAdd} from '../../../services/adminService'
import {adminListActions} from '../admin'
import getAssetTypeAction from './getAssetTypeAction'

const postAssetTypeAction = (payload, mode, flag) => {
  return async(dispatch) => {
    dispatch(adminListActions.postAssetTypeRequest())
    // if (!payload.type) return
    // postAssetTypeAdd({action: 'duplicate', type: payload.type}).then((res) => {
    // if (res.status === 200) {
    // if (res.data.assetType[0].count === 1) {
    //   if (flag) {
    //     dispatch(adminListActions.setMessageData({message: 'Please Enter Asset Type Label.', status: 'failed'}))
    //     dispatch(adminListActions.postAssetTypeFailure())
    //     dispatch(getAssetTypeAction())// message content, status, eg - res.data
    //   } else {
    //     dispatch(adminListActions.setMessageData({message: 'Asset Type Already Exists.', status: 'failed'}))// message content, status, eg - res.data
    //     dispatch(adminListActions.postAssetTypeFailure())
    //     dispatch(getAssetTypeAction())
    //     return
    //   }
    // }
    if (mode === 'add') {
      postAssetTypeAdd({...payload, action: 'add'}).then((res) => {
        dispatch(getAssetTypeAction())
        dispatch(adminListActions.setMessageData({message: 'Asset Type Added Successfully.', status: 'success'}))// message content, status, eg - res.data
        dispatch(adminListActions.postAssetTypeSuccess(res))
      })
    }
    if (mode === 'edit') {
      postAssetTypeAdd({...payload, action: 'edit'}).then((res) => {
        dispatch(adminListActions.setMessageData({message: 'Asset Type Update Successfully.', status: 'success'}))// message content, status, eg - res.data
        dispatch(adminListActions.postAssetTypeSuccess(res))
        dispatch(getAssetTypeAction())
      })
    }
    // }
    // }).catch((error) => {
    //   dispatch(adminListActions.postAssetTypeFailure(error))
    // })
  }
}

export default postAssetTypeAction
