import apiInstance from '../config/api/axios'

// Post upload file
const uploadFile = async(data, payload) => {
  try {
    const response = await apiInstance.post(`import-assets?skipRows=${data.skipRows}&append=${data.append}`, payload)
    return response
  } catch (error) {
    console.log(error)
  }
}

// GET imported assets
const fetchImportedAssets = async(payload) => {
  try {
    const response = await apiInstance.get(`import-assets?action=${payload.action}&project_id_crm=${payload.project_id_crm}`, payload)
    return response.data

  } catch (error) {
    console.log(error)
  }
}

const importAssets = async(payload) => {
  try {
    const response = await apiInstance.post('import-assets', payload)
    return response
  } catch (error) {
    console.log(error)
  }
}


export {uploadFile, fetchImportedAssets, importAssets}

