/* eslint-disable*/
import React, {useState, useEffect} from 'react'
import {Box, Grid, Typography} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux'
import {decode as base64Decode} from 'base-64'
import {AlertMessage, CustomButton, CustomTextBox, Loader} from '../../../components'
import {
  getAssetTypeAction, getAppraiserTrueAction, postAssetTypeAction,
} from '../../../redux/admin/AdminActions'
import {adminListActions} from '../../../redux/admin/admin'
import {AssetTypesTable} from './components'
import MyDiv from './assetTypes.style'
import { validateFieldForRequest } from '../../../services/adminService'

export default function AssetTypes() {

  const dispatch = useDispatch()
  const [addValue, setAddValue] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [assetTypesList, setAssetTypesList] = useState()
  const [filteredAssetTypesList, setFilteredAssetTypesList] = useState([])
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(false)

  const adminState = useSelector((state) => state.admin)
  let userId = localStorage.getItem('userId')

  let projectIDCrm = localStorage.getItem('project_id_crm')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string

  const appraiserTrue = {
    getAppraisersOnly: true,
    project_id_crm: decodedProjectId,
  }

  useEffect(() => {
    dispatch(getAssetTypeAction())
    dispatch(getAppraiserTrueAction(appraiserTrue))
  }, [dispatch])

  useEffect(() => {
    setAssetTypesList(adminState?.assetTypeList?.assetTypeList)
    setFilteredAssetTypesList(adminState?.assetTypeList?.assetTypeList)
  }, [adminState?.assetTypeList?.assetTypeList])

  useEffect(() => {
    if (adminState?.messageData.status === 'success') {
      setMessage(adminState?.messageData?.message)
      setStatus('success')
      setAddValue('')
    }
    if (adminState?.messageData.status === 'failed') {
      setMessage(adminState?.messageData?.message)
      setStatus('warning')
    }
  }, [adminState?.messageData])

  const handleSearchName = () => {
    if (searchValue !== '') {
      const result = assetTypesList.filter((item) => {
        return item?.type?.toLowerCase().includes(searchValue.toLowerCase())
      })
      setFilteredAssetTypesList(result)
    } else {
      setFilteredAssetTypesList(assetTypesList)
    }
  }

  const handleChange = (e) => {
    setSearchValue(e.target.value)
  }

  const handleReset = () => {
    window.location.reload()
    // setSearchValue('')
    // dispatch(getAssetTypeAction())
  }

  const handleChangeAdd = (e) => {
    setAddValue(e.target.value)
  }

  const handleAdd = () => {
    if (!addValue) {
      setMessage('Please Enter Asset Type')
      setStatus('warning')
      return
    }
    setLoading(true)
    validateFieldForRequest({action: "duplicate", type: addValue}, 'asset-type').then((res) => {
      if(res?.data?.assetType[0]?.count > 0) {
        setMessage('Asset Type already exists.')
        setStatus('warning')
      } else {
        let addObj = {
          created_by: Number(userId),
          modified_by: Number(userId),
          type: addValue,
        }
        dispatch(postAssetTypeAction(addObj, 'add'))
      }
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
      console.log(err)
        setMessage('Something went wrong???')
        setStatus('warning')
    })
  }

    const handleMessageData = (value, type) => {
    setMessage(value)
    setStatus(type)
  }
  const handleSnackBarClose = () => {
    setTimeout(() => {
      setMessage('')
      setStatus('')
      dispatch(adminListActions.clearMessageData())
    }, 2000);
  }


  return (
    <MyDiv>
      {(adminState?.assetTypeList?.loading || adminState?.deleteAssetType?.loading || adminState?.postAssetType?.loading || loading) &&
      <div>
        <Loader />
      </div>
      }
      <Box className="page_heading">
        <Typography>Manage Asset Types</Typography>
      </Box>
      <Box className="admin_card">
        <Grid container className="main_container">
          <Grid item md={4} className="d-flex">
            <Box className="input_box">
              <Typography>Asset Type</Typography>
              <CustomTextBox name="type_add" value={addValue} onChange={handleChangeAdd} />
            </Box>
            <CustomButton title="Add" className="btn_theme" variant="contained" onClick={handleAdd} />
          </Grid>
          <Grid item md={4} />
          <Grid item md={4} className="d-flex">
            <Box className="input_box">
              <Typography>Search String</Typography>
              <CustomTextBox name="search_type" value={searchValue} onChange={handleChange} />
            </Box>
            <Box className="d-flex">
              <CustomButton title="Search" className="btn_theme" variant="contained" onClick={handleSearchName} />
              <CustomButton title="Reset" className="btn_reset" variant="contained" onClick={handleReset} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {filteredAssetTypesList?.length > 0 ?
        <AssetTypesTable handleMessageData={handleMessageData} assetTypesList={filteredAssetTypesList} searchValue={searchValue} /> :
        <Box className="no-data">
          <Typography>No Data Available</Typography>
        </Box>
      }
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
