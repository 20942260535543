import styled from 'styled-components'

const MyDiv = styled.div`
.authenticate_msg{
    text-align: center;
    padding: 30px 30px;
    font-size: 20px;
    color: #555;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "Arial";
}
.logout_users{
    width: 500px;
    margin: 100px auto;
    border: 1px solid #cecece;
    border-radius: 5px;
    box-shadow: 0px 5px 1px 0px rgba(0,0,0,0.09);
}
.auth_msg{
    text-align: center;
    padding: 10px;
    font-size: 20px;
    color: #555;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "Arial";
    background: #f6f6f6;
    border-bottom: 1px solid #ddd;
}
.logout_msg{
    text-align: center;
    font-size: 14px;
    color: red;
    font-weight: 400;
    font-family: "Arial";
    padding: 10px;
}
.login_msg{
    text-align: center;
    font-size: 15px;
    color: #000;
    font-weight: 600;
    padding-bottom: 10px;
    font-family: "Arial";
}
`
export default MyDiv
