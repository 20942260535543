/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, {useState, useEffect} from 'react'
import {Box, Typography} from '@mui/material'
import {kaReducer} from 'ka-table'
import {DataType} from 'ka-table/enums'
import {useDispatch, useSelector} from 'react-redux'
import {decode as base64Decode} from 'base-64'
import {getAppraiserTrueAction} from '../../../redux/admin/AdminActions'
import {AlertMessage, CustomButton, CustomTable, Loader} from '../../../components'
import {getReportsListAction} from '../../../redux/reports/ReportActions'
import {homeListActions} from '../../../redux/home/home'
import {fetchAssetListForValuation, getAssetList} from '../../../services/assetListService'
import {fetchProjectById} from '../../../services/homeService'
import {getRefDataCommon} from '../../../utils/Report.utils'
import {postReports} from '../../../services/reportServices'
import {generateKeyFromName} from '../../../utils/Utils'
import MyDiv from './valuationReports.style'

export default function ValuationReports() {

  const dispatch = useDispatch()
  const [reportsData, setReportsData] = useState([])
  // const [selectData, setSelectData] = useState([])
  const getSelectedRows = () => {
    const arr = []
    reportsData.map((i) => {
      arr.push(i.location_id)
    })
    return arr
  }
  const tablePropsInit = {
    columns: [
      {
        key: 'selection-cell',
        style: {width: '3.5%'},
      },
      {
        dataType: DataType.String,
        key: 'name',
        style: {width: 120},
        title: 'Location',
      },
      {
        dataType: DataType.String,
        key: 'street',
        style: {width: 120},
        title: 'Address',
      },
      {
        dataType: DataType.String,
        key: 'type_of_operation_c',
        style: {width: 120},
        title: 'Type of Operation',
      },
      {
        dataType: DataType.String,
        key: 'square_footage_c',
        style: {width: 120},
        title: 'Square Footage',
      },
    ],
    selectedRows: getSelectedRows(),
    data: reportsData,
    rowKeyField: 'location_id',
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: 50,
      pageSizes: [50, 100, 150, 200, 250],
    },
  }


  const [valuationReports, setValuationReports] = useState(tablePropsInit)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // const [allData, setAllData] = useState(null)
  // const [valuesRequestedArray, setValuesRequestedArray] = useState({})

  // const valuesRequestedArray = [{}]
  // Object.keys(data[0].values_requested_me_c);

  const adminState = useSelector((state) => state.report)

  let projectIDCrm = localStorage.getItem('project_id_crm')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string
  let projectID = localStorage.getItem('project_id')

  let reportListData = {
    pageReport: 'reports',
    project_id_crm: decodedProjectId,
  }
  const appraiserTrue = {
    getAppraisersOnly: true,
    project_id_crm: decodedProjectId,
  }

  useEffect(() => {
    dispatch(getAppraiserTrueAction(appraiserTrue))
    dispatch(getReportsListAction(reportListData))
  }, [dispatch])

  useEffect(() => {
    //! needs to be removed as part of redux cleanup prior to prod if possible
    setReportsData(adminState?.reportsList?.reportsList)
  }, [adminState?.reportsList?.reportsList])

  useEffect(() => {
    setValuationReports(tablePropsInit)
  }, [reportsData])

  const dispatchReports = (action) => {
    setValuationReports((prevState) => kaReducer(prevState, action))
  }
  // useEffect(() => {
  //   valuationReports?.data?.map((data) => {
  //     valuationReports?.selectedRows?.map((item) => {
  //       if (item === data?.location_id) {
  //         let obj = {
  //           columns: valuationReports?.columns,
  //           data: [data],
  //           paging: valuationReports?.paging,
  //           rowKeyField: 'location_id',
  //           selectedRows: valuationReports?.selectedRows,
  //         }
  //         setSelectData(obj)
  //       }
  //     })
  //   })

  // }, [adminState, reportsData])

  useEffect(() => {
    if (adminState?.reportsPdf?.reportsPdf?.status === 200) {
      window.open(adminState?.reportsPdf?.reportsPdf?.data?.path)
    }
  }, [adminState?.reportsPdf?.reportsPdf])


  const assetDescription = (asset) => {
    let {
      make,
      model,
      model_label,
      serial_number,
      serial_label,
      asset_type,
      year,
      // quantity,
      // description,
      // asset_type_id,
      capacity,
    } = asset

    let finaldescription = ''

    if (asset.type === 'asset') {
      if (make !== '' && make !== null && make !== undefined && make !== 'Undefined Make') {
        finaldescription = make
      }

      if (model_label !== '' && model !== '' && model !== undefined && model_label !== null && model !== null && finaldescription !== '') {
        finaldescription = `${finaldescription} ${model_label} ${model}`
      } else if (model_label !== '' && model !== '' && model !== undefined && model_label !== null && model !== null) {
        finaldescription = `${model_label} ${model}`
      }

      if (asset_type !== '' && asset_type !== null && capacity !== '' && capacity !== null && asset_type !== undefined && capacity !== undefined && finaldescription !== '') {
        finaldescription = `${finaldescription}, ${capacity} ${asset_type}`
      } else if (asset_type !== '' && asset_type !== null && capacity !== '' && capacity !== null && asset_type !== undefined && capacity !== undefined) {
        finaldescription = `${capacity} ${asset_type}`
      } else if (capacity !== '' && capacity !== null && capacity !== undefined && finaldescription !== '') {
        finaldescription = `${finaldescription}, ${capacity}`
      } else if (capacity !== '' && capacity !== null && capacity !== undefined) {
        finaldescription = capacity
      } else if (asset_type !== '' && asset_type !== null && asset_type !== undefined && finaldescription !== '') {
        finaldescription = `${finaldescription}, ${asset_type}`
      } else if (asset_type !== '' && asset_type !== null && asset_type !== undefined) {
        finaldescription = asset_type
      }

      if (serial_label !== '' && serial_number !== '' && model !== undefined && model_label !== undefined && serial_label !== null && serial_number !== null && finaldescription !== '') {
        const srlTxt = serial_label === 'VIN' ? 'VIN ' : 'S/N '
        finaldescription = `${finaldescription}, ${srlTxt}${serial_number}`
      } else if (serial_label !== '' && serial_number !== '' && model !== undefined && model_label !== undefined && serial_label !== null && serial_number !== null) {
        finaldescription = `${serial_label} ${serial_number}`
      }

      if (year !== '' && year !== null && year !== undefined && finaldescription !== '') {
        finaldescription = `${finaldescription} (New ${year})`
      } else if (year !== '' && year !== null && year !== undefined) {
        finaldescription = `(New ${year})`
      }
    } else {
      if (make !== '' && make !== null && make !== undefined && make !== 'Undefined Make') {
        finaldescription = make
      }

      if (asset.line_name !== '' && asset.line_name !== undefined && asset.line_name !== null && finaldescription !== '') {
        finaldescription = `${finaldescription} ${asset.line_name}`
      } else if (asset.line_name !== '' && asset.line_name !== undefined && asset.line_name !== null && finaldescription === '') {
        finaldescription = asset.line_name
      }

      if (asset.asset_type !== '' && asset.asset_type !== undefined && asset.asset_type !== null && finaldescription !== '') {
        finaldescription = `${finaldescription} ${asset.asset_type}`
      } else if (asset.asset_type !== '' && asset.asset_type !== undefined && asset.asset_type !== null && finaldescription === '') {
        finaldescription = asset.asset_type
      }

      if (year !== '' && year !== null && year !== undefined && finaldescription !== '') {
        finaldescription = `${finaldescription}, (New ${year})`
      } else if (year !== '' && year !== null && year !== undefined && finaldescription === '') {
        finaldescription = `(New ${year})`
      }
    }

    return finaldescription
  }


  const getStatsTabData = (data, valuesRequestedArray) => {
    let i = 1
    let finalStatsArray = []
    let totalStatsArray = []
    let typeToRef = []

    data.refData.forEach((value) => {
      typeToRef[value.type_id] = value.ref_no
    })

    data.reportData.forEach((value) => {
      if (value.parent_line?.toString() === '0') {
        // if (typeof value.value_code !== 'undefined' && typeof value.value !== 'undefined') {
        if (value !== null && typeof value === 'object' && value instanceof Object) {
          if (value.value !== null) {
            let code_arr = value.value_code.split('#')
            let value_arr = value.value.split('#')
            value.value = []
            for (let k = 0; k < code_arr.length; k++) {
              value[code_arr[k]] = value_arr[k]
            }
          }
        }

        finalStatsArray.push({
          ref_no: typeToRef[value.type_id],
        })

        if (value.quantity_type?.toString() === 'lot' || value.type?.toString() === 'line') {
          finalStatsArray[i - 1].qty = 'Lot'
        } else {
          finalStatsArray[i - 1].qty = value.quantity
        }

        let desc = assetDescription(value)
        finalStatsArray[i - 1].description = desc

        let l = 0
        Object.keys(valuesRequestedArray).forEach((valuesRequestedKey) => {
          let valuesRequestedValueName = valuesRequestedArray[valuesRequestedKey]
          const valuesRequestedValue = generateKeyFromName(valuesRequestedValueName)
          if (
            value[valuesRequestedValue]?.toLowerCase() === 'not valued' ||
            value[valuesRequestedValue]?.toLowerCase() === 'no value' ||
            value[valuesRequestedValue]?.toLowerCase() === 'leased' ||
            value[valuesRequestedValue] === ''
          ) {
            finalStatsArray[i - 1][valuesRequestedValueName] = value[valuesRequestedValue]
            finalStatsArray[i - 1].flag = false
            if (valuesRequestedKey === valuesRequestedArray.length - 1) {
              finalStatsArray[i - 1].flag = true
            }
          } else {
            if (typeof totalStatsArray[valuesRequestedValue] === 'undefined') {
              totalStatsArray[valuesRequestedValueName] = 0
            } else {
              totalStatsArray[valuesRequestedValueName] += +value[valuesRequestedValue]
            }
            finalStatsArray[i - 1][valuesRequestedValueName] = +value[valuesRequestedValue]
            finalStatsArray[i - 1].flag = false
          }
          l++
        })

        i++
      }
    })
    finalStatsArray = finalStatsArray.filter((item) => {
      return !item.flag
    })

    let rightestcolumn = `${valuesRequestedArray[valuesRequestedArray.length - 1]}`
    // sortData(finalStatsArray, rightestcolumn)
    let sortedData = finalStatsArray.filter((item) => {
      if (item[rightestcolumn] !== 'Not Valued' &&
            item[rightestcolumn] !== 'No Value' &&
            item[rightestcolumn] !== 'Leased' &&
            item[rightestcolumn] !== '') {
        return item
      }
    })
    sortedData = sortedData.sort((a, b) => b[rightestcolumn] - a[rightestcolumn])
    let statsTabFinal = sortedData.slice(0, 40)

    return statsTabFinal
  }


  // const selectedValues = selectData?.selectedRows?.map((item) => {return item})
  const generateValuationReport = () => {
    // const getProjectByCrmId = {
    //   project_id_crm: decodedProjectId,
    // }
    if (valuationReports?.selectedRows?.length > 0) {
      setIsLoading(true)
      // dispatch(getProjectByCrmIdAction(getProjectByCrmId))
      fetchProjectById({
        project_id_crm: decodedProjectId,
      }).then(({data}) => {
        let assetData = {
          sortCol: 'area_order',
          sortOrder: 'asc',
          action: 'getAssets',
          showOnlyAsset: false,
          appraiser: null,
          make: null,
          model: null,
          capacity: null,
          area: null,
          projectId: Number(projectID),
          // flag: 'valuationReports',
        }
        // setValuesRequestedArray(Object.keys(data[0].values_requested_me_c))
        // dispatch(getAssetListDataAction(assetData))
        let valuesRequestedArray = Object.keys(data[0].values_requested_me_c)
        fetchAssetListForValuation(assetData).then((res) => {
          // setAllData(res)
          const computedData = getRefDataCommon(res, assetData)
          computedData.reportData = computedData?.reportData.filter((elem) => {
            if (valuationReports?.selectedRows.length > 0) {
              return valuationReports?.selectedRows.includes(elem.location_id)
            }
          })
          const dataStatsTab = getStatsTabData(computedData, valuesRequestedArray)
          const valuationReportsArgument = {
            project_id: decodedProjectId,
          }
          if (valuationReports?.selectedRows.length > 0) {

            valuationReportsArgument.locationArray = valuationReports?.selectedRows?.join()
            valuationReportsArgument.reportType = 'valuationreports'
            valuationReportsArgument.reportFormat = 'excel'
            // valuationReportsArgument.reportData = refTypeData //! not sure if ever needed
            valuationReportsArgument.locations = valuationReports?.data
            valuationReportsArgument.dataStatsTab = dataStatsTab
            postReports(valuationReportsArgument).then((res) => {
              setIsLoading(false)
              window.location.href = res?.data?.path
            })
          }
        })
      })


    } else {
      setMessage('Please select at least one location.')
      setStatus('warning')
    }
  }

  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    dispatch(homeListActions.clearMessageData())
  }

  return (
    <MyDiv>
      {isLoading &&
      <div>
        <Loader />
      </div>
      }
      <Box className="page_heading">
        <Typography>VALUATION BY CLASS/CONDITION</Typography>
      </Box>
      <Box className="excel_download">
        <CustomButton
          title="Generate Excel Report"
          variant="contained"
          className="btn_theme"
          onClick={() => generateValuationReport()}
        />
      </Box>
      <CustomTable tableProps={valuationReports} dispatch={dispatchReports} />
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
