import {authenticateUser} from '../../../services/authService'
import {authListActions} from '../auth'

const authUserActions = (payload) => {
  return (dispatch) => {
    dispatch(authListActions.authUserRequest())
    authenticateUser(payload).then((res) => {
      dispatch(authListActions.authUserSuccess(res))
    }).catch((error) => {
      dispatch(authListActions.authUserFailure(error))
    })
  }
}

export default authUserActions
