import styled from 'styled-components'

const MyDiv = styled.div`
.wrapper_box{
    background-color: #fff;
    border: solid 1px #cecece;
    border-radius: 5px;
    padding: 20px;
}

.input_box{
    margin-bottom: 15px;
}
.input_box p{
    font-weight: 500;
    color: #555;
    font-size: 14px;
    font-family: "Arial";
}
.select_value{
    font-size: 14px;
}
#select_input{
    min-height: 1.5em;
    font-weight: 500;
    color: #555;
    font-size: 14px;
    font-family: "Arial";
}
.date_box{
    justify-content: space-between;
}
.checked_label .MuiFormControlLabel-label{
    font-weight: 500;
    color: #555;
    font-size: 14px;
    font-family: "Arial";
}
.d-flex{
    display: flex;
    column-gap: 20px;
    align-items: center;
}
.btn-flex{
    justify-content: end;
}
.mb-20{
    margin-bottom:20px;
}
.mt-10{
    margin-top:10px;
}

.autoComplete_custom.MuiAutocomplete-hasPopupIcon.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root{
  padding: 1px 39px 1px 4px!important;
}
.autoComplete_custom.MuiAutocomplete-hasPopupIcon.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root input{
  font-weight: 500;
  color: #555;
  font-size: 14px;
  font-family: 'Arial'!important;
}
.dropdown-menu{
    border: 1px solid #ddd;
    z-index: 11;
    border-bottom: 0;
    width: 100%!important;
    overflow-x: hidden!important;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(1.6em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #555;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control:focus-visible {
    border-color: #1976d2;
    border-width: 2px;
    outline: none!important;
}
a.dropdown-item{
    display: block;
    background: #fff;
    color: #000;
    font-size: 15px;
    font-weight: 400;
    text-decoration: none;
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
}
.rbt-input-multi.form-control {
    height: auto;
    min-height: calc(1.6em + 0.75rem + 2px);
}
.rbt-input-multi {
    cursor: text;
    overflow: hidden;
    position: relative;
}
.rbt-input-multi .rbt-input-wrapper {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -4px;
    margin-top: -1px;
    overflow: hidden;
}
.rbt-token {
    background-color: #e7f4ff;
    border: 0;
    border-radius: 0.25rem;
    color: #007bff;
    display: inline-flex;
    line-height: 1rem;
    margin: 1px 3px 2px 0;
}
.rbt-token-removeable {
    cursor: pointer;
}
.rbt-token .rbt-token-label {
    padding: 0.25rem 0.5rem;
}
.rbt-token .rbt-token-label:not(:last-child) {
    padding-right: 0.25rem;
}
.rbt-token .rbt-token-remove-button {
    background-image: none;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    box-shadow: none;
    color: inherit;
    display: flex;
    justify-content: center;
    font-size: inherit;
    font-weight: normal;
    opacity: 1;
    outline: none;
    padding: 0.25rem 0.5rem;
    padding-left: 0;
    text-shadow: none;
}
.rbt-token .rbt-token-remove-button .rbt-close-content {
    display: block;
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}
button.close{
    background-color: transparent;
    border: 0;
}
.rbt-input-wrapper input{
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #555;
    padding: 2px!important;
}
.rbt-aux {
    align-items: center;
    display: flex;
    bottom: 0;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 2rem;
}
.rbt-aux .rbt-close {
    // margin-top: -0.25rem;
    pointer-events: auto;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 1;
    cursor: pointer;
}
`
export default MyDiv
