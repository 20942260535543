import React from 'react'
import {Menu, MenuItem} from '@mui/material'
import Divider from '@mui/material/Divider'

export default function ParentMenu(props) {
  const CustomData = [
    {
      id: 1,
      name: 'Edit Line',
    },
    {
      id: 2,
      name: 'Copy Line',
    },
  ]
  const handleMenuItem = (key) => {
    props.handleMenuItem(key)
  }
  return (
    <Menu open={props.visible} onClose={props.handleClose} anchorOrigin={{
      vertical: props.posY,
      horizontal: props.posX,
    }}
    >
      {
        CustomData.map((key, index) => {
          return (
            <div key={index}><MenuItem onClick={() => handleMenuItem(key)}>{key.name}</MenuItem><Divider /></div>
          )
        })
      }
    </Menu>
  )
}
