import React, {useEffect, useState} from 'react'
import {Box, FormControl, Grid, Select, Typography, MenuItem} from '@mui/material'
import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import {AlertMessage, CustomButton, CustomTextBox, Loader} from '../../../components'
import {
  getMakeListAction, postFlagMake, postMakeListAction,
} from '../../../redux/admin/AdminActions'
import {adminListActions} from '../../../redux/admin/admin'
import {ManageMakeTable} from './components'
import MyDiv from './manageMake.style'
const selectValues = [
  {
    id: 0,
    label: 'Valid Make',
  },
  {
    id: 1,
    label: 'Flagged Make',
  },
  {
    id: 2,
    label: 'Both',
  },
]

const StyledMenuItem = styled(MenuItem)(() => ({
  '&.MuiMenuItem-root': {
    borderBottom: '1px solid #ccc',
    color: '#000000',
    fontWeight: '400',
    fontSize: '14px',
    fontFamily: 'Arial',
  },
  '&.MuiMenuItem-root:first-child': {
    marginTop: '-8px',
  },
  '&.MuiMenuItem-root:last-child': {
    marginBottom: '-8px',
  },
}))

export default function ManageMake() {

  const dispatch = useDispatch()
  const [isFlaggedId, setIsFlaggedId] = useState(0)
  const [addValue, setAddValue] = useState('')
  const [filteredMakeList, setFilteredMakeList] = useState([])
  const [makeList, setMakeList] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const adminState = useSelector((state) => state.admin)
  // const makeList = adminState?.makeList?.makeList
  let userId = localStorage.getItem('userId')

  useEffect(() => {
    localStorage.setItem('isFlagged', isFlaggedId)
    dispatch(getMakeListAction(isFlaggedId))
  }, [dispatch])

  useEffect(() => {
    // setFilteredMakeList(adminState?.makeList?.makeList)
    setMakeList(adminState?.makeList?.makeList)
  }, [adminState?.makeList?.makeList])

  useEffect(() => {
    if (adminState?.messageData.status === 'success') {
      setMessage(adminState?.messageData?.message)
      setStatus('success')
      setAddValue('')
    }
    if (adminState?.messageData.status === 'failed') {
      setMessage(adminState?.messageData?.message)
      setStatus('warning')
    }
  }, [adminState?.messageData])


  const handleCheck = (rowId, flagged) => () => {
    const isFlagged = flagged === 0 ? 1 : 0
    const data = {
      action: 'flagged',
      make_id: rowId,
      isFlagged,
    }
    dispatch(postFlagMake(data))
  }

  useEffect(() => {
    if (searchValue !== '') {
      const result = makeList.filter((item) => {
        return item?.name?.toLowerCase().includes(searchValue.toLowerCase())
      })
      setFilteredMakeList(result)
    } else {
      setFilteredMakeList(makeList)
    }
  }, [makeList])

  const handleSearchName = () => {
    if (searchValue !== '') {
      const result = makeList.filter((item) => {
        return item?.name?.toLowerCase().includes(searchValue.toLowerCase())
      })
      setFilteredMakeList(result)
    } else {
      setFilteredMakeList(makeList)
    }
  }

  const handleChange = (e) => {
    e.stopPropagation()
    setSearchValue(e.target.value)
  }

  const handleChangeAdd = (e) => {
    setAddValue(e.target.value)
  }

  const handleAdd = () => {
    if (!addValue) {
      setMessage('Please Enter Make')
      setStatus('warning')
      return
    }
    let obj = {
      created_by: Number(userId),
      modified_by: Number(userId),
      name: addValue,
    }
    dispatch(postMakeListAction(obj, 'add'))
  }

  const handleChangeMenu = (e) => {
    let value = e.target.value ?? 0
    localStorage.setItem('isFlagged', value)
    setIsFlaggedId(e.target.value)
    dispatch(getMakeListAction(e.target.value))
  }

  const handleSnackBarClose = () => {
    setTimeout(() => {
      setMessage('')
      setStatus('')
      dispatch(adminListActions.clearMessageData())
    }, 2000)
  }

  const handleReset = () => {
    window.location.reload()
    // setSearchValue('')
    // setIsFlaggedId(0)
    // setFilteredMakeList([])
    // dispatch(getMakeListAction())
  }

  const handleMessageData = (value, type) => {
    setMessage(value)
    setStatus(type)
  }

  return (
    <MyDiv>
      {(adminState?.makeList?.loading || adminState?.deleteMake?.loading || adminState?.postMake?.loading) &&
      <div>
        <Loader />
      </div>
      }
      <Box className="page_heading">
        <Typography>Manage Makes</Typography>
      </Box>
      <Box className="admin_card">
        <Grid container className="main_container" columnSpacing={{md: 8}} >
          <Grid item md={4} className="d-flex">
            <Box className="input_box">
              <Typography>Make</Typography>
              <CustomTextBox value={addValue} onChange={handleChangeAdd} name="serial" />
            </Box>
            <CustomButton onClick={handleAdd} title="Add" className="btn_theme" variant="contained" />
          </Grid>
          <Grid item md={4}>
            <Box className="input_box">
              <Typography>Search String</Typography>
              <CustomTextBox onChange={handleChange} name="serial" value={searchValue} />
            </Box>
          </Grid>
          <Grid item md={4} className="d-flex">
            <Box className="input_box">
              <FormControl size="small" className="select_area">
                <Select
                  id="select_input"
                  className="select_value"
                  value={isFlaggedId}
                  fullWidth
                  onChange={handleChangeMenu}
                >
                  {selectValues?.map((item, index) => {
                    return (
                      <StyledMenuItem key={index} value={item.id}>
                        {item.label}
                      </StyledMenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box className="d-flex">
              <CustomButton onClick={handleSearchName} title="Search" className="btn_theme" variant="contained" />
              <CustomButton title="Reset" className="btn_reset" variant="contained" onClick={handleReset} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {filteredMakeList.length > 0 ?
        <ManageMakeTable handleMessageData={handleMessageData} makeList={filteredMakeList} handleCheck={handleCheck} searchValue={searchValue} isFlaggedId={isFlaggedId} />
        :
        <Box className="no-data">
          <Typography>No Data Available</Typography>
        </Box>
      }
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
