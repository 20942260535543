import {copyAssetBelow} from '../../../services/assetListService'
import {assetsListActions} from '../assets'

const copyAssetBelowAction = (payload) => {
  return (dispatch) => {
    dispatch(assetsListActions.copyAssetBelowRequest())
    copyAssetBelow(payload).then((res) => {
      localStorage.removeItem('alreadyScrolled')
      localStorage.setItem('type_id', res?.data?.new_type_id)
      if (res.status === 200) {
        dispatch(assetsListActions.setMessageData({message: 'Asset Copy Successfully.', status: 'success'}))
      }
      dispatch(assetsListActions.copyAssetBelowSuccess(res))
      dispatch(assetsListActions.setDoFetchAssetList(true))
    }).catch((error) => {
      dispatch(assetsListActions.copyAssetBelowFailure(error))
    })

  }
}

export default copyAssetBelowAction
