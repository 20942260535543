import {createAsyncThunk} from '@reduxjs/toolkit'
import {postLineDetail} from '../../../services/lineServices'

export const copyLineAction = createAsyncThunk(
  'copyLineBelow',
  async(payload) => {
    const response = await postLineDetail(payload)
    return response
  }
)
