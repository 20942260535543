import styled from 'styled-components'

const MyDiv = styled.div`
.dialog_title{
    padding: 10px 0px;
    font-size: 20px;
    font-weight: 600;
    font-family: "Arial";
}
.dialog_row{
    display:flex;
    flex-direction:row;
    justify-content: space-around;
    align-items:center;
}
.dialog_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dialog_content{
    padding: 20px 0px 10px;
    margin:10px;
}
.dialog_box{
    padding: 0px 20px;
}
`
export default MyDiv
