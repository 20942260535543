import {editLineAsset} from '../../../services/lineServices'
import {lineListActions} from '../line'
import * as routesNames from '../../../constants/routes'

const editLineAssetAction = (payload, history, route) => {
  return (dispatch) => {
    dispatch(lineListActions.editLineAssetRequest())
    editLineAsset(payload).then((res) => {
      if (res === 1) {
        if (payload.action === 'deleteLineAndDeassocAsset') {
          if (route === 'asset-list') {
            dispatch(lineListActions.setMessageData({message: 'Record Update Successfully.', status: 'success'}))
            history.push(routesNames.ASSETLIST)
          } else {
            dispatch(lineListActions.setMessageData({message: 'Record Update Successfully.', status: 'success'}))
            history.push(routesNames.LINELIST)
          }
        }
        if (payload.action === 'deleteLineAndAsset') {
          if (route === 'asset-list') {
            dispatch(lineListActions.setMessageData({message: 'Record Update Successfully.', status: 'success'}))
            history.push(routesNames.ASSETLIST)
          } else {
            dispatch(lineListActions.setMessageData({message: 'Record Update Successfully.', status: 'success'}))
            history.push(routesNames.LINELIST)
          }
        }
        if (payload.action === 'deleteAllAsset') {
          if (route === 'asset-list') {
            dispatch(lineListActions.setMessageData({message: 'Record Update Successfully.', status: 'success'}))
            history.push(routesNames.ASSETLIST)
          } else {
            dispatch(lineListActions.setMessageData({message: 'Record Update Successfully.', status: 'success'}))
            // history.push(routesNames.LINELIST)
          }
        }
        if (payload.action === 'deassociateAll') {
          if (route === 'asset-list') {
            dispatch(lineListActions.setMessageData({message: 'Record Update Successfully.', status: 'success'}))
            // history.push(routesNames.ASSETLIST)
          } else {
            dispatch(lineListActions.setMessageData({message: 'Record Update Successfully.', status: 'success'}))
            // history.push(routesNames.LINELIST)
          }
        }
        dispatch(lineListActions.editLineAssetSuccess(res))
      }
    }).catch((error) => {
      dispatch(lineListActions.editLineAssetFailure(error))
    })

  }
}

export default editLineAssetAction
