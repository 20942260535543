/* eslint-disable no-unused-vars */
import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import {Close} from '@mui/icons-material'
import {Box, Divider, IconButton} from '@mui/material'
import {CustomButton} from '..'
import MyDiv from './confirmationAlert.style'

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ConfirmationAlert(props) {
  const popup = (popupbox) => (
    <MyDiv>
      <Box className="dialog_box">
        <Box className="dialog_header">
          <IconButton className="icon" onClick={props.handleConfirmation}><Close /></IconButton>
        </Box>
        <DialogContent sx={{padding: 0}}>
          <DialogContentText className="dialog_title">
            {props.title}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="d-flex btn-flex mb-20">
          <CustomButton title="Yes" variant="contained" className="btn_theme" onClick={props.handleDelete} />
          <CustomButton title="No" variant="contained" className="btn_theme" onClick={props.handleConfirmation} />
        </DialogActions>
      </Box>
    </MyDiv>
  )
  return (
    <Dialog
      popupbox="openDialog"
      open={props.openConfirmation}
      TransitionComponent={Transition}
      onClose={props.handleConfirmation}
      fullWidth
    >
      {popup('openDialog')}
    </Dialog>
  )
}
