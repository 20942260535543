import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  messageData: {
    message: null,
    status: null,
  },
  uploadFile: {
    loading: false,
    uploadFile: [],
    error: null,
  },
  getImportedAssets: {
    loading: false,
    getImportedAssets: [],
    error: null,
  },
  postImportAssets: {
    loading: false,
    postImportAssets: [],
    error: null,
  },
}

const importSlice = createSlice({
  name: 'import',
  initialState,
  reducers: {
    // defining the actions
    setMessageData(state, action) {
      state.messageData.message = action.payload.message
      state.messageData.status = action.payload.status
    },
    clearMessageData(state, action) {
      state.messageData.message = null
      state.messageData.status = null
    },
    uploadFileRequest(state, action) {
      state.uploadFile.loading = true
    },
    uploadFileSuccess(state, action) {
      state.uploadFile.uploadFile = action.payload
      state.uploadFile.loading = false
    },
    uploadFileFailure(state, action) {
      state.uploadFile.error = action.payload
      state.uploadFile.loading = false
    },
    getImportedAssetsRequest(state, action) {
      state.getImportedAssets.loading = true
    },
    getImportedAssetsSuccess(state, action) {
      state.getImportedAssets.getImportedAssets = action.payload
      state.getImportedAssets.loading = false
    },
    getImportedAssetsFailure(state, action) {
      state.getImportedAssets.error = action.payload
      state.getImportedAssets.loading = false
    },
    postImportAssetsRequest(state, action) {
      state.postImportAssets.loading = true
    },
    postImportAssetsSuccess(state, action) {
      state.postImportAssets.postImportAssets = action.payload
      state.postImportAssets.loading = false
    },
    postImportAssetsFailure(state, action) {
      state.postImportAssets.error = action.payload
      state.postImportAssets.loading = false
    },
  },
})

export const importListActions = importSlice.actions

export default importSlice.reducer
