import React from 'react'
import {Checkbox} from '@mui/material'
import {deselectAllFilteredRows, selectAllFilteredRows} from 'ka-table/actionCreators'
import {useDispatch} from 'react-redux'
// import {useLocation} from 'react-router-dom'

import {adminListActions} from '../../../../../redux/admin/admin'
import {reportListActions} from '../../../../../redux/reports/report'
import {assetsListActions} from '../../../../../redux/assets/assets'
import {researchListActions} from '../../../../../redux/research/research'
export default function SelectionHeader(props) {

  const {dispatch, areAllRowsSelected, id, allIds} = props
  // const location = useLocation()
  const selectionList = []
  if (allIds) {
    for (const id of allIds) {
      selectionList.push(id.type_id)
    }
  }
  const reduxDispatch = useDispatch()
  // useEffect(() => {
  //   let url = window.location.href
  //   if (url?.includes('valuation-reports')) {
  //     reduxDispatch(reportListActions.setAllItemsSelected(url))
  //     dispatch(selectAllFilteredRows())
  //   }
  //   // for now this is not working discuss
  //   if (url.includes('findreplace-imported-asset')) {
  //     reduxDispatch(assetsListActions.setAllItemsSelected(url))
  //     dispatch(selectAllFilteredRows())
  //   }
  // }, [])
  return (
    <Checkbox
      checked={areAllRowsSelected}
      onChange={(event) => {
        const {baseURI} = event.target
        if (event.currentTarget.checked && baseURI.includes(`asset-list-reports/${id}`)) {
          reduxDispatch(reportListActions.setSelectedReportRows(selectionList))
          dispatch(selectAllFilteredRows(selectionList))
        } else if (event.currentTarget.checked && baseURI.includes('asset-list-reports')) {
          reduxDispatch(reportListActions.setAllItemsSelected(baseURI))
          dispatch(selectAllFilteredRows())
        } else if (event.currentTarget.checked && baseURI.includes('manage-')) { // there are a lot of different url for admin, so we are using generic manage- instead of complete url.
          reduxDispatch(adminListActions.setAllItemsSelected(baseURI))
          dispatch(selectAllFilteredRows())
        } else if (event.currentTarget.checked && baseURI.includes('asset-list')) { // there are a lot of different url for admin, so we are using generic manage- instead of complete url.
          reduxDispatch(assetsListActions.setAllItemsSelected(baseURI))
          dispatch(selectAllFilteredRows())
        } else if (event.currentTarget.checked && baseURI.includes('valuation-reports')) {
          reduxDispatch(reportListActions.setAllItemsSelected(baseURI))
          dispatch(selectAllFilteredRows())
        } else if (event.currentTarget.checked && baseURI.includes('research')) {
          reduxDispatch(researchListActions.setAllItemsSelected(baseURI))
          dispatch(selectAllFilteredRows())
        } else if (event.currentTarget.checked && baseURI.includes('findreplace-imported-asset')) {
          reduxDispatch(assetsListActions.setAllItemsSelected(baseURI))
          dispatch(selectAllFilteredRows())
        } else if (event.currentTarget.checked && baseURI.includes('findreplace')) {
          reduxDispatch(assetsListActions.setAllItemsSelected(baseURI))
          dispatch(selectAllFilteredRows())
        } else {
          reduxDispatch(adminListActions.clearSelectedItems())
          reduxDispatch(reportListActions.clearSelectedItems())
          reduxDispatch(researchListActions.clearSelectedItems())
          dispatch(deselectAllFilteredRows())
        }
      }}
    />
  )
}
