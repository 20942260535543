/* eslint-disable max-len */
/* eslint-disable guard-for-in */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {Box, Grid, Typography} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux'
import {decode as base64Decode} from 'base-64'
import {AlertMessage, CustomButton, CustomTextBox, Loader} from '../../../components'
import {
  getAppraiserAction,
  getLocationAction,
  postAssignAppraiserAction,
  deleteAssignAppraiserAction,
  postAssignAppraiserAllAction,
  getResetAssignAppraiserAction,
  getAppraiserLocationsAction,
  getAppraiserTrueAction,
} from '../../../redux/admin/AdminActions'
import {adminListActions} from '../../../redux/admin/admin'
import {AppraiserDialog, AppraisersList, LocationsList} from './components'
import MyDiv from './assignAppraiser.style'

export default function AssignAppraiser() {

  const dispatch = useDispatch()
  const [openDialog, setOpenDialog] = useState(false)
  const [filteredFlag, setFilteredFlag] = useState(false)
  const [heading, setHeading] = useState()
  const [filteredAppraiserData, setFilteredAppraisersData] = useState([])
  const [filteredLocationData, setFilteredLocationData] = useState([])
  const [locationData, setLocationData] = useState()
  const [selectedIndex, setSelectedIndex] = useState([])
  const [locationId, setLocationId] = useState()
  const [userId, setUserId] = useState([])
  const [allAppraisers, setAllAppraiser] = useState([])
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [expanded, setExpanded] = useState(false)
  const [resetAppraisers, setResetAppraisers] = useState(false)
  const [searchAppraiserVal, setSearchAppraiserVal] = useState('')

  const adminState = useSelector((state) => state.admin)
  const appraiserData = adminState?.appraiserList?.appraiserList
  const allAppraiserLocations = adminState?.locationList?.locationList?.data?.locationAppraisers
  const appraisersLocations = adminState?.appraiserLocationsList?.appraiserLocationsList?.data?.assignedLocations

  let projectIDCrm = localStorage.getItem('project_id_crm')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string

  const appraiserOnAll = {
    type: 'AppraisersOnAllLocations',
    project_id_crm: decodedProjectId,
  }

  const uniqueUsernames = new Set()
  const uniqueAppraiserData = []


  const getAppraisersForLocations = () => {
    dispatch(getAppraiserTrueAction({getAppraisersOnly: true, project_id_crm: decodedProjectId}))
  }
  appraiserData?.forEach((entry) => {
    if (entry?.status === 1) {
      // if (!uniqueUsernames.has(entry.first_name + entry.last_name)) {
      //   uniqueUsernames.add(entry.first_name + entry.last_name)
      uniqueAppraiserData.push(entry)
      // }
    }
  })


  useEffect(() => {
    dispatch(getAppraiserAction())
    dispatch(getLocationAction({project_id_crm: decodedProjectId}))
    dispatch(getAppraiserLocationsAction(appraiserOnAll))
  }, [dispatch])

  // useEffect(() => {
  //   setFilteredAppraisersData(uniqueAppraiserData)
  // }, [adminState])

  useEffect(() => {
    setLocationData(adminState?.locationList?.locationList?.data)
  }, [adminState?.locationList?.locationList])

  useEffect(() => {
    if (adminState?.messageData.status === 'success') {
      setMessage(adminState?.messageData?.message)
      setStatus('success')
    }
    if (adminState?.messageData.status === 'failed') {
      setMessage(adminState?.messageData?.message)
      setStatus('warning')
    }
  }, [adminState?.messageData])

  const handlePopup = (id, type) => {
    const getAssignedAppraiser = {
      type: 'getAssignedLocations',
      user_id: id.toString(),
      project_id_crm: decodedProjectId,
    }
    const allKeys = Object.keys(allAppraiserLocations).reduce((commonKeys, item) => {
      const currentKeys = Object.keys(allAppraiserLocations[item])
      if (commonKeys.length === 0) {
        return currentKeys
      }
      return commonKeys.filter((key) => currentKeys.includes(key))
    }, [])

    const commonAppraisers = allKeys.map((key) => {
      return {[key]: allAppraiserLocations[Object.keys(allAppraiserLocations)[0]][key]}
    })

    const mergedObject = Object.assign({}, ...commonAppraisers)
    setOpenDialog(!openDialog)
    setAllAppraiser(mergedObject)
    switch (type) {
      case 'appraiserLocation':
        dispatch(getAppraiserLocationsAction(getAssignedAppraiser))
        setHeading('Assigned Locations')
        break
      case 'allLocationAppraisers':
        setHeading('Appraisers on all locations')
        break
      default:
        break
    }
  }

  const handleChange = (panel) => {
    setExpanded(panel)
  }

  const handleAccordionClick = (item) => {
    if (item === expanded) {
      setLocationId(null)
    } else {
      setLocationId(item.toString())
    }
  }

  const handleListItemClick = (index) => {
    let listData = appraiserData.filter((item) => item.user_id === index)
    let id = listData[0]?.user_id
    setUserId([...userId, id])

    let appraiserIndex = selectedIndex.find((item) => item === index)
    if (!appraiserIndex) {
      setSelectedIndex([...selectedIndex, index])
    } else if (appraiserIndex) {
      setSelectedIndex(selectedIndex?.filter((item) => item !== index))
      setUserId(selectedIndex?.filter((item) => item !== index))
    }
  }

  const handleMoveAppraiserList = () => {
    if (selectedIndex.length === 0) {
      setMessage('Please choose at least one appraiser.')
      setStatus('warning')
      return
    }
    let obj = {
      users: userId.toString(),
      location_id: locationId,
      project_id_crm: decodedProjectId,
    }
    let projectIDCrm = {
      project_id_crm: decodedProjectId,
    }
    if (locationId) {
      dispatch(postAssignAppraiserAction(obj, projectIDCrm))
    } else if (!locationId) {
      setMessage('Please choose location.')
      setStatus('warning')
    }
    setResetAppraisers(true)
    getAppraisersForLocations()
  }

  const handleMoveAppraiserAllList = () => {
    if (selectedIndex.length === 0) {
      setMessage('Please choose at least one appraiser.')
      setStatus('warning')
      return
    }
    let obj = {
      users: userId.toString(),
      location_id: 0,
      project_id_crm: decodedProjectId,
    }
    dispatch(postAssignAppraiserAllAction(obj, decodedProjectId))
    if (filteredLocationData?.length > 0) {
      setLocationId(filteredLocationData[0]?.location_id)
      setExpanded(filteredLocationData[0]?.location_id)
    } else {
      let firstIndexLocation = locationData?.locationsDataFull.find((item, index) => index === 0)
      setLocationId(firstIndexLocation?.location_id)
      setExpanded(firstIndexLocation?.location_id)
    }
    setResetAppraisers(true)
    getAppraisersForLocations()
  }

  useEffect(() => {
    if (searchAppraiserVal !== '') {
      const result = uniqueAppraiserData.filter((item) => {
        return (
          item?.first_name && item?.first_name?.toLowerCase().includes(searchAppraiserVal.toLowerCase()) ||
          item?.last_name && item?.last_name?.toLowerCase().includes(searchAppraiserVal.toLowerCase())
        )
      })
      setFilteredAppraisersData(result)
    } else if (searchAppraiserVal === '') {
      setFilteredAppraisersData(uniqueAppraiserData)
    }
  }, [searchAppraiserVal, resetAppraisers, appraiserData])
  // const handleSearchName = (e) => {
  //   const {value} = e.target
  //   setSearchAppraiserVal(value)

  //   // Regular expression to match only letters
  //   const lettersRegex = /^[a-zA-Z\s]*$/
  //   if (value !== '' && lettersRegex.test(value)) {
  //     const result = appraiserData.filter((item) => {
  //       return (
  //         item?.first_name?.toLowerCase().includes(value.toLowerCase()) ||
  //         item?.last_name?.toLowerCase().includes(value.toLowerCase())
  //       )
  //     })
  //     setFilteredAppraisersData(result)
  //   } else if (value === '') {
  //     setFilteredAppraisersData(appraiserData)
  //   }
  // }
  useEffect(() => {
    let firstIndexLocation = locationData?.locationsDataFull.find((item, index) => index === 0)
    setLocationId(firstIndexLocation?.location_id)
    setExpanded(firstIndexLocation?.location_id)
  }, [adminState?.appraiserList])

  const handleSearchLocation = (e) => {
    const {value} = e.target
    if (value !== '') {
      const result = locationData?.locationsDataFull.filter((item) => {
        return item?.name?.toLowerCase().includes(value.toLowerCase()) || item?.street?.toLowerCase().includes(value.toLowerCase()) || item?.state?.toLowerCase().includes(value.toLowerCase()) || item?.country?.toLowerCase().includes(value.toLowerCase())
      })
      let firstIndexLocation = result.find((item, index) => index === 0)
      setLocationId(firstIndexLocation?.location_id)
      setExpanded(firstIndexLocation?.location_id)
      setFilteredLocationData(result)
      setFilteredFlag(true)
    } else {
      let firstIndexLocation = locationData?.locationsDataFull.find((item, index) => index === 0)
      setLocationId(firstIndexLocation?.location_id)
      setExpanded(firstIndexLocation?.location_id)
      setFilteredLocationData(locationData?.locationsDataFull)
    }
  }

  const handleDelete = (userId, locationId) => {
    let obj = {
      userId: userId.toString(),
      locationId: locationId.toString(),
      project_id_crm: decodedProjectId,
    }
    let projectIDCrm = {
      project_id_crm: decodedProjectId,
    }
    dispatch(deleteAssignAppraiserAction(obj, projectIDCrm))
    getAppraisersForLocations()
  }

  const handleResetAppraisers = () => {
    window.location.reload()
  }

  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    dispatch(adminListActions.clearMessageData())
  }
  return (
    <MyDiv>
      {(adminState?.appraiserList?.loading || adminState?.locationList?.loading || adminState?.postAssignAppraiser?.loading || adminState?.postAssignAppraiserAll?.loading ||
        adminState?.deleteAssignAppraiser?.loading || adminState?.getResetAssignAppraiser?.loading || adminState?.appraiserLocationsList?.loading) &&
        <div>
          <Loader />
        </div>
      }
      <Box className="page_heading">
        <Typography>Assign Appraisers</Typography>
      </Box>
      <Box className="admin_card">
        <Grid container className="action_grid">
          <Grid item md={6}>
            <Grid container columnSpacing={{md: 4}}>
              <Grid item md={4}>
                <Box className="input_box">
                  <Typography>Appraiser</Typography>
                  <CustomTextBox
                    onChange={(e) => setSearchAppraiserVal(e.target.value)}
                    name="appraiser"
                    fieldLabel="Search Appraiser"
                  />
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box className="input_box">
                  <Typography>Location</Typography>
                  <CustomTextBox
                    onChange={handleSearchLocation}
                    name="location"
                    fieldLabel="Search Location"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Box className="d-flex">
              <CustomButton
                onClick={handleMoveAppraiserAllList}
                title="Assign to All Locations"
                variant="contained"
                className="btn_theme"
              />
              <CustomButton
                title="Appraisers on All Locations"
                variant="contained"
                className="btn_theme"
                onClick={(e) => handlePopup(e, 'allLocationAppraisers')}
                openDialog={openDialog}
              />
              <CustomButton
                onClick={handleResetAppraisers}
                title="Reset"
                variant="contained"
                className="btn_theme"
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container className="content_grid" columnSpacing={{md: 4}}>
          <Grid item md={6}>
            <AppraisersList
              selectedIndex={selectedIndex}
              handleListItemClick={handleListItemClick}
              handleMoveAppraiserList={handleMoveAppraiserList}
              filteredAppraiserData={filteredAppraiserData}
              handlePopup={handlePopup}
              openDialog={openDialog}
            />
          </Grid>
          <Grid item md={6}>
            <LocationsList
              handleDelete={handleDelete}
              handleAccordionClick={handleAccordionClick}
              locationData={locationData}
              filteredLocationData={filteredLocationData}
              filteredFlag={filteredFlag}
              expanded={expanded}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
      </Box>
      <AppraiserDialog
        heading={heading}
        appraisersLocations={appraisersLocations}
        locationData={locationData}
        handlePopup={handlePopup}
        openDialog={openDialog}
        allAppraisers={allAppraisers}
      />
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
