/* eslint-disable no-constant-condition */
import React, {useState, useEffect} from 'react'
import {Box, Grid, IconButton, Typography} from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import {useDispatch, useSelector} from 'react-redux'
import {decode as base64Decode} from 'base-64'
import {
  AlertMessage,
  CustomButton,
  CustomTextArea,
  CustomTextBox,
  Loader,
} from '../../../components'
import {
  getAssetClassesListAction, getAppraiserTrueAction, deleteAssetClassesAction, postAssetClassesAction, getAssetClassDetailAction, getAssetTypesClassesAction,
  deleteAssetTypeClassAction,
} from '../../../redux/admin/AdminActions'
import {adminListActions} from '../../../redux/admin/admin'
import {
  AssetClassesList,
  AssetClassTable,
  AssociatedAsset,
} from './components'
import MyDiv from './assetClasses.style'

export default function AssetClasses() {

  const dispatch = useDispatch()
  const [editList, setEditList] = useState(false)
  const [assetClassesList, setAssetClassesList] = useState([])
  const [filterAssetClassesList, setFilterAssetClassesList] = useState([])
  const [assetTypesList, setAssetTypesList] = useState([])
  const [filterAssetTypesList, setFilterAssetTypesList] = useState([])
  const [addAssetClass, setAddAssetClass] = useState({})
  const [ids, setIds] = useState()
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [selectedIndex, setSelectedIndex] = useState([])
  const [expanded, setExpanded] = useState(false)
  const [isFilter, setFilter] = useState(false)
  const adminState = useSelector((state) => state.admin)
  let userId = localStorage.getItem('userId')

  useEffect(() => {
    return () => {
      dispatch(adminListActions.clearAssetClassDetail())
    }
  }, [])

  let projectIDCrm = localStorage.getItem('project_id_crm')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string

  const appraiserTrue = {
    getAppraisersOnly: true,
    project_id_crm: decodedProjectId,
  }


  useEffect(() => {
    dispatch(getAssetClassesListAction())
    dispatch(getAppraiserTrueAction(appraiserTrue))
    dispatch(getAssetTypesClassesAction({action: 'FetchAssetType'}))
  }, [dispatch])

  useEffect(() => {
    setAssetClassesList(adminState?.assetClassesList?.assetClassesList)
  }, [adminState?.assetClassesList?.assetClassesList])

  useEffect(() => {
    setAssetTypesList(adminState?.assetTypesClasses?.assetTypesClasses)
  }, [adminState?.assetTypesClasses?.assetTypesClasses])

  useEffect(() => {
    setAddAssetClass(adminState?.assetClassDetail?.assetClassDetail)
  }, [adminState?.assetClassDetail?.assetClassDetail])

  useEffect(() => {
    if (adminState?.messageData.status === 'success') {
      setMessage(adminState?.messageData?.message)
      setStatus('success')
      if (editList) return
      setAddAssetClass({})
    }
    if (adminState?.messageData.status === 'failed') {
      setMessage(adminState?.messageData?.message)
      setStatus('warning')
    }
  }, [adminState?.messageData])


  const handleListItemClick = (index) => {
    let AssetClassIndex = selectedIndex.find((item) => item === index)

    if (!AssetClassIndex) {
      setSelectedIndex([...selectedIndex, index])
    } else if (AssetClassIndex) {
      setSelectedIndex(selectedIndex?.filter((item) => item !== index))
    }
    // setSelectedIndex([...selectedIndex, index])
  }

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const handleEdit = (id) => () => {
    setEditList(!editList)
    dispatch(getAssetClassDetailAction(id))
    setFilterAssetTypesList(assetTypesList.filter((data) => data.class_id === id))
    setFilterAssetClassesList(assetClassesList.filter((data) => data.asset_class_id !== id))
    setIds(id)
  }
  const handleClose = () => {
    window.location.reload()
    // setEditList(!editList)
    // setAddAssetClass({})
  }

  const handleDelete = (id) => () => {
    let obj = {
      action: 'delete',
      asset_class_id: id,
      modified_by: Number(userId),
    }
    dispatch(deleteAssetClassesAction(obj))
  }

  const handleDisassociate = (id) => {
    let obj = {
      action: 'disassociate',
      asset_type_id: id,
      modified_by: Number(userId),
      classListAction: 'FetchAssetType', // this is for another request that is initiated from within the below request
    }
    const updatedAssetTypeList = [...filterAssetTypesList]
    const list = updatedAssetTypeList.filter((data) => data.asset_type_id !== id)
    setFilterAssetTypesList(list)
    setExpanded(`panel${'0'}`)
    dispatch(deleteAssetTypeClassAction(obj))
    // console.log('first index', firstIndex)
  }

  const handleAssociate = () => {
    if (selectedIndex.length === 0) {
      setMessage('Please Select Asset Type First')
      setStatus('warning')
    } else {
      let obj = {
        action: 'associate',
        asset_type_id: selectedIndex?.join('^')?.toString(),
        class_id: ids?.toString(),
        modified_by: Number(userId),
        // classListAction: 'FetchAssetType', // this is for another request that is initiated from within the below request
      }
      let selectedItems = selectedIndex.map((id) => {
        return assetTypesList.find((asset) => asset.asset_type_id === id)
      })
      dispatch(deleteAssetTypeClassAction(obj))
      const updatedList = assetTypesList.filter((item) => !selectedItems.filter((selected) => selected?.asset_type_id === item?.asset_type_id))
      setAssetTypesList(updatedList)
      let list = [...filterAssetTypesList, ...selectedItems]
      setFilterAssetTypesList(list)
      setSelectedIndex([])
    }
  }

  const handleChange = (e) => {
    setAddAssetClass({...addAssetClass, [e.target.name]: e.target.value})
  }

  const checkDuplicacy = (type, value) => {
    return !!assetClassesList.find((elem) => elem[type] === value)
  }

  const handleSubmit = () => {
    if (!addAssetClass?.name) {
      setMessage('Please Enter Asset Class')
      setStatus('warning')
      return
    }
    if (!addAssetClass?.code) {
      setMessage('Please Enter Asset Class Code')
      setStatus('warning')
      return
    }
    if (addAssetClass?.code?.length > 2) {
      setMessage('Asset Class Code cannot be more than 2 characters.')
      setStatus('warning')
      return
    }
    if (addAssetClass?.name && checkDuplicacy('name', addAssetClass?.name) && !editList) {
      setMessage('Asset Class Already Exist.')
      setStatus('warning')
      return
    }
    if (addAssetClass?.code && checkDuplicacy('code', addAssetClass?.code) && !editList) {
      setMessage('Asset Class Code Already Exist.')
      setStatus('warning')
      return
    }
    if (addAssetClass?.name && addAssetClass?.code) {
      dispatch(postAssetClassesAction({...addAssetClass, action: editList ? 'edit' : 'add', modified_by: Number(userId), created_by: Number(userId)}))
    }
  }

  const handleCancel = () => {
    if (editList) {
      dispatch(getAssetClassDetailAction(ids))
    } else {
      setAddAssetClass({})

    }
    // dispatch(getAssetClassesListAction())
    // dispatch(getAppraiserTrueAction(appraiserTrue))
    // dispatch(getAssetTypesClassesAction({action: 'FetchAssetType'}))
    // setAddAssetClass({})
  }

  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    dispatch(adminListActions.clearMessageData())
  }
  return (
    <MyDiv>
      {(adminState?.assetClassesList?.loading || adminState?.deleteAssetClasses?.loading || adminState?.postAssetClasses?.loading || adminState?.assetClassDetail?.loading) &&
      <div>
        <Loader />
      </div>
      }
      <Box className="page_heading">
        <Grid container>
          <Grid item md={6}>
            <Typography>
              {editList ? 'Associated Asset Types' : 'Manage Asset Classes'}
            </Typography>
          </Grid>
          {editList && (
            <Grid item md={6}>
              <Typography>Other Asset Classes</Typography>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box className="admin_card">
        {editList &&
        <IconButton className="back_icon" onClick={handleClose}>
          <ArrowBackIosNewIcon />
        </IconButton>
        }
        <Grid container className="action_grid" columnSpacing={{md: 4}}>
          <Grid item md={4}>
            <Box className="input_box">
              <Typography>Asset Class Name</Typography>
              <CustomTextBox
                value={addAssetClass?.name ?? ''}
                name="name"
                fieldLabel="Asset Class Name"
                onChange={handleChange}
              />
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box className="input_box">
              <Typography>Asset Class Code</Typography>
              <CustomTextBox
                value={addAssetClass?.code ?? ''}
                name="code"
                fieldLabel="Asset Class Code"
                onChange={handleChange}
              />
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box className="input_box">
              <Typography>Description</Typography>
              <CustomTextBox
                value={addAssetClass?.description ?? ''}
                name="description"
                fieldLabel="Description"
                onChange={handleChange}
              />
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box className="input_box">
              <Typography>Examples</Typography>
              <CustomTextArea minRows={4} value={addAssetClass?.examples ?? ''} name="examples" fieldLabel="Examples" onChange={handleChange} />
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box className="input_box">
              <Typography>Comments</Typography>
              <CustomTextArea minRows={4} value={addAssetClass?.comments ?? ''} name="comments" fieldLabel="Comments" onChange={handleChange} />
            </Box>
          </Grid>
        </Grid>
        <Box className="d-flex btn_row">
          {!editList &&
          <CustomButton
            title="Toggle Search Bar"
            variant="contained"
            className="btn_theme"
            onClick={() => setFilter(!isFilter)}
          /> }
          <CustomButton
            title={editList ? 'Edit' : 'Save'}
            variant="contained"
            className="btn_theme"
            onClick={handleSubmit}
          />
          <CustomButton
            title="Cancel"
            variant="contained"
            className="btn_theme"
            onClick={handleCancel}
          />
        </Box>
      </Box>
      {editList ? (
        <Box className="admin_card" mt={3}>
          <Grid container className="content_grid" columnSpacing={{md: 4}}>
            <Grid item md={6}>
              <AssociatedAsset filterAssetTypesList={filterAssetTypesList} handleDisassociate={handleDisassociate} />
            </Grid>
            <Grid item md={6}>
              <AssetClassesList assetClassesList={assetClassesList} filterAssetClassesList={filterAssetClassesList} assetTypesList={assetTypesList} handleAssociate={handleAssociate}
                handleListItemClick={handleListItemClick} id={ids} selectedIndex={selectedIndex} handleChangeAccordion={handleChangeAccordion} expanded={expanded}
              />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box mt={3}>
          <AssetClassTable setFilter={setFilter} isFilter={isFilter} assetClassesList={assetClassesList} handleEdit={handleEdit} handleDelete={handleDelete} />
        </Box>
      )}
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
