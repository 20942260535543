/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable no-alert */
import React, {useEffect, useState} from 'react'
import {Box, IconButton, TextField, Typography} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import {kaReducer} from 'ka-table'
import {useSelector} from 'react-redux'
import {
  // DataType,
  // SortDirection,
  SortingMode,
  FilteringMode,
} from 'ka-table/enums'
import {CustomButton, AlertMessage, CustomTable, CustomTextBox, Loader} from '../../../../components'
import ResearchDialog from '../ResearchDialog'
import {showResearchData} from '../../../../services/researchServices'
import MyDiv from './researchTable.style'

export default function ResearchTable(props) {

  const [tableHead, setTableHead] = useState([])
  const [pageValue, setPageValue] = useState(0)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const researchState = useSelector((state) => state.research)
  // const ll = props?.searchedData?.assets?.map((item) => {return item})

  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
  }

  // const getSelectedRows = () => {
  //   const arr = []
  //   props?.searchedData?.assets?.map((i) => {
  //     arr.push(i.type_id)
  //   })
  //   return arr
  // }
  const tablePropsInit = {
    columns: [],
    data: [],
    rowKeyField: 'type_id',
    height: '75vh',
    sortingMode: SortingMode.Single,
    paging: {
      enabled: true,
      pageIndex: pageValue,
      pageSize: 50,
      pageSizes: [50, 100, 150, 200, 250],
    },
    filteringMode: FilteringMode.FilterRow,
  }

  const [researchTable, setResearchTable] = useState(tablePropsInit)
  const [columnSetting, setColumnSetting] = useState(false)
  const [showDataPopup, setShowDataPopup] = useState(false)
  const [currentRow, setCurrentRow] = useState([])
  const [currentRowData, setCurrentRowData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isIndex, setIndex] = useState(null)
  const [isDownload, setDownload] = useState(false)
  const [selectedReportData, setSelectedReportData] = useState(null)
  const Symbol = 'research'

  useEffect(() => {
    localStorage?.removeItem('itemsPerPage')
    let filterColumn = []
    filterColumn.unshift(
      {
        key: 'selection-cell',
        style: {width: 50},
      },
    )
    props?.isChecked?.map((item) => {
      let type = typeof item?.value === 'number' ? 'number' : 'string'
      let data = {
        dataType: type,
        key: item?.value,
        style: {width: 120},
        title: item?.name,
        type: item?.type,
        // width: '50%',

        // sortDirection: SortDirection.Descend,

      }
      filterColumn.push(data)
    })
    filterColumn?.sort((a, b) => {
      if (a.type === 'values' && b.type !== 'values') {
        return 1 // a comes after b
      } else if (a.type !== 'values' && b.type === 'values') {
        return -1 // a comes before b
      } else {
        return 0 // no change in order
      }
    })
    // const index = filterColumn.findIndex((item) => item.value === 'quantity')
    // if (index !== -1 && index !== 0) {
    //   const quantityObj = filterColumn.splice(index, 1)[0]
    //   filterColumn.unshift(quantityObj)
    // }
    setTableHead(filterColumn)
  }, [props.isChecked])

  useEffect(() => {
    const updatedAssets = props.searchedData?.assets?.map((item) => {
      const {value_code, value, ...rest} = item
      const valueNames = value_code.split('#')
      const valueCodes = value.split('#')

      const newObj = {}

      valueNames.forEach((key, index) => {
        newObj[key] = parseInt(valueCodes[index]) // Convert string to integer using parseInt
      })

      return {
        ...rest,
        ...newObj, // Spread newObj directly here
      }
    })
    setResearchTable({...researchTable, data: updatedAssets, columns: tableHead})
  }, [props.searchedData?.assets])


  useEffect(() => {
    let arr = []
    props.searchedData?.assets?.map((item, index) => {
      if (item.type_id === currentRow) {
        arr.push(item)
        setIndex(index)
      }
    })
    setCurrentRowData(arr[0])
  }, [currentRow])


  const handleSidebar = () => {
    setColumnSetting(!columnSetting)
  }

  const dispatchResearch = (action) => {
    setResearchTable((prevState) => kaReducer(prevState, action))
  }

  const handlePopup = (typeId) => {
    setCurrentRow(typeId)
    // const findAsset
    setShowDataPopup(!showDataPopup)
  }
  useEffect(() => {
    let arr = []
    props.searchedData?.assets?.map((item, index) => {
      if (index === isIndex) {
        setCurrentRow(item.type_id)
        arr.push(item)
      }
    })
    setCurrentRowData(arr[0])
  }, [isIndex])

  const handleNext = () => {
    setIndex(isIndex + 1)
  }

  const handlePrevious = () => {
    setIndex(isIndex - 1)
  }

  useEffect(() => {
    let selectedRows = []
    if (researchTable?.selectedRows?.length > 0) {
      researchTable?.selectedRows?.map((elem) => {
        props.searchedData.assets?.map((item) => {
          if (item?.type_id === elem) {
            const newItem = {
              ...item,
              value: {},
            }

            if (item.value_code.includes('#')) {
              const values = item.value.split('#')
              const valueCodes = item.value_code.split('#')
              for (let i = 0; i < valueCodes.length; i++) {
                // eslint-disable-next-line eqeqeq
                newItem.value[valueCodes[i]] = values[i] == 'LEASED' || values[i] == 'NOT VALUED' || values[i] == 'NO VALUE' ? '0' : '$' + values[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            } else {
              newItem.value[item.value_code] = '$' + item.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            selectedRows.push(newItem)
          }
        })
      })
      setSelectedReportData(selectedRows)
    } else {
      props.searchedData.assets?.map((item) => {
        const newItem = {
          ...item,
          value: {},
        }

        if (item.value_code.includes('#')) {
          const values = item.value.split('#')
          const valueCodes = item.value_code.split('#')
          for (let i = 0; i < valueCodes.length; i++) {
            // eslint-disable-next-line eqeqeq
            newItem.value[valueCodes[i]] = values[i] == 'LEASED' || values[i] == 'NOT VALUED' || values[i] == 'NO VALUE' || !values[i]
              ? '0' : '$' + values[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
        } else {
          newItem.value[item.value_code] = '$' + item.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        selectedRows.push(newItem)
      })
      setSelectedReportData(selectedRows)
    }

  }, [researchTable])

  const downloadFile = (type) => {
    // if (researchTable?.selectedRows?.length > 0) {
    let payload = {dataForPDF: props.searchedData.assets}

    if (type === 'pdf') {
      payload = {dataForPDF: selectedReportData, app_type: 'pdf'}
    }
    if (type === 'excel') {
      payload = {dataForPDF: selectedReportData, app_type: 'excel'}
    }
    if (type === 'selectedDataPdf') {
      payload = {dataForPDF: selectedReportData, app_type: 'pdf'}
    }
    if (type === 'selectedDataExcel') {
      payload = {dataForPDF: selectedReportData, app_type: 'excel'}
    }
    setIsLoading(true)
    showResearchData(payload).then((res) => {
      const response = res.data
      if (response?.path.includes('.pdf')) {
        window.open(response?.path, '_blank')
        setIsLoading(false)
      }
      if (response?.path.includes('.xlsx')) {
        window.open(response?.path, '_blank')
        setIsLoading(false)
      }
    }).catch((error) => {
      console.log(error) //need to show snackbar
      setIsLoading(false)
    })
    // } else {
    //   setMessage('Please Select the Asset First')
    //   setStatus('warning')
    // }
  }

  const handlePageChange = (e) => {
    if (e.keyCode === 13) {
      const currentPage = researchTable?.paging?.pageIndex // !to reset to the current active page if any of the below conditions are met
      let handlePage = ((researchTable?.data?.length / researchTable?.paging?.pageSize)).toFixed()
      if ((pageValue + 1) > handlePage) {
        setMessage('Page number provided does not exist..')
        setStatus('warning')
        return setResearchTable((prevState) => ({
          ...prevState,
          paging: {
            ...prevState.paging,
            pageIndex: currentPage,
          },
        }))
      } if ((pageValue) === researchTable?.paging?.pageIndex) {
        setMessage('you are already on this page you need.')
        setStatus('warning')
        return setResearchTable((prevState) => ({
          ...prevState,
          paging: {
            ...prevState.paging,
            pageIndex: currentPage,
          },
        }))
      } if (pageValue === -1) {
        setMessage('Page number provided does not exist..')
        setStatus('warning')
        return setResearchTable((prevState) => ({
          ...prevState,
          paging: {
            ...prevState.paging,
            pageIndex: currentPage,
          },
        }))
      } else {
        setResearchTable((prevState) => ({
          ...prevState,
          paging: {
            ...prevState.paging,
            pageIndex: pageValue,
          },
        }))
      }
    }
  }
  return (
    <MyDiv>
      {(isLoading) &&
        <div>
          <Loader />
        </div>
      }
      <Box className="count_box">
        <Typography variant="h5">Research</Typography>
        <Typography>Total Items: {props?.searchedData?.totalRecord}</Typography>
      </Box>
      <Box className="button_group">
        <TextField
          label="Go To Page"
          onChange={(e) => setPageValue(+e.target.value - 1)}
          onKeyDown={handlePageChange}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <CustomButton
          title="Modify Search"
          variant="contained"
          className="btn_theme"
          onClick={props.modifySearch}
        />
        {researchTable?.selectedRows?.length > 0 ?
          <>
            <CustomButton
              title="Export Selected Data to Pdf"
              variant="contained"
              className="btn_theme"
              onClick={() => downloadFile('selectedDataPdf')}
            />
            <CustomButton
              title="Export Selected Data to Excel"
              variant="contained"
              className="btn_theme"
              onClick={() => downloadFile('selectedDataExcel')}
            />
          </> : <>
            <CustomButton
              title="Export All Data to PDF"
              variant="contained"
              className="btn_theme"
              onClick={() => downloadFile('pdf')}
            />
            <CustomButton
              title="Export All Data to Excel"
              variant="contained"
              className="btn_theme"
              onClick={() => downloadFile('excel')}
            />
          </>}
        <CustomButton
          title="Back"
          variant="contained"
          className="btn_theme"
          onClick={props.handleShow}
        />
      </Box>
      <IconButton onClick={handleSidebar} className="column_setting">
        <MenuIcon />
      </IconButton>
      <CustomTable
        Symbol={Symbol}
        tableProps={researchTable}
        dispatch={dispatchResearch}
        columnSetting={columnSetting}
        handleSidebar={handleSidebar}
        handlePopup={handlePopup}
      />

      <ResearchDialog
        modalData={currentRowData}
        handlePopup={handlePopup}
        showDataPopup={showDataPopup}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        isIndex={isIndex}
      />

      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
