/* eslint-disable no-debugger */
import {updateModel} from '../../../services/adminService'
// import {getModelByMakeAction} from '../../assets/AssetsActions'
import {adminListActions} from '../admin'
// import getNewModelListAction from './getNewModelListAction'

const updateModelAction = (payload) => {
  return (dispatch) => {
    dispatch(adminListActions.updateModelRequest())
    updateModel(payload).then((res) => {
      if (res.status === 200) {
        dispatch(adminListActions.setMessageData({message: 'Model Updated Successfully.', status: 'success'}))// message content, status, eg - res.data
        // dispatch(getModelByMakeAction())
        // dispatch(getNewModelListAction())
        window.location.reload()

      }
      dispatch(adminListActions.updateModelSuccess(res))
    }).catch((error) => {
      dispatch(adminListActions.updateModelFailure(error))
    })
  }
}

export default updateModelAction
