import {postAssetTypeAdd} from '../../../services/adminService'
import {lineListActions} from '../line'
import getAssetDetailsAction from './getAssetDetailsAction'
import getAssetTypeListAction from './getAssetTypeListAction'
const addAssetTypeAction = (payload, type) => {

  return (dispatch) => {
    dispatch(lineListActions.assetTypeRequest())
    // if (!payload.type) return
    // postAssetTypeAdd({action: 'duplicate', type: payload.type}).then((res) => {
    //   if (res.status === 200) {
    //     if (res.data.assetType[0].count !== 0) {
    //       dispatch(lineListActions.setMessageData({message: 'Asset Type Already Exists.', status: 'failed'}))
    //       dispatch(lineListActions.assetTypeSuccess(res))
    //       // message content, status, eg - res.data
    //       return
    //     }
    postAssetTypeAdd({...payload, action: 'add'}).then((res) => {
      // dispatch(getMakeCapacityAction())
      dispatch(getAssetDetailsAction({location_id: payload.location_id, assetId: payload?.assetId, project_id_crm: payload.project_id_crm}))
      dispatch(lineListActions.setMessageData({message: 'Asset Type Added Successfully.', status: 'success'}))
      dispatch(lineListActions.assetTypeSuccess(res))
      dispatch(getAssetTypeListAction())
      // message content, status, eg - res.data
    })
    //   }
    // })
      .catch((error) => {
        dispatch(lineListActions.assetTypeFailure(error))
      })
  }
}

export default addAssetTypeAction
