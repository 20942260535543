import React, {useState, useEffect} from 'react'
import 'jspdf-autotable'
import {kaReducer} from 'ka-table'
import {
  DataType,
  SortingMode,
  FilteringMode,
} from 'ka-table/enums'
import {updateFilterRowValue} from 'ka-table/actionCreators'
import {CustomTable} from '../../../../../components'
import MyDiv from './assetClassTable.style'

export default function AssetClassTable(props) {

  const tablePropsInit = {
    columns: [
      {
        dataType: DataType.String,
        key: 'name',
        style: {width: 120},
        title: 'Asset Class',
      },
      {
        dataType: DataType.String,
        key: 'code',
        style: {width: 50},
        title: 'Code',
      },
      {
        dataType: DataType.Number,
        key: 'count',
        style: {width: 50},
        title: 'Associated Asset Type',
      },
      {
        key: 'action-cell',
        style: {width: '50px'},
        isSortable: false,
        title: 'Actions',
      },
    ],
    data: props.assetClassesList,
    rowKeyField: 'asset_class_id',
    height: '50vh',
    sortingMode: SortingMode.Single,
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: localStorage.getItem('itemsPerPageAssetClass') ? localStorage.getItem('itemsPerPageAssetClass') : 250,
      pageSizes: [250, 500, 1000],
    },
  }

  const [assetClassesTable, setAssetClassesTable] = useState(tablePropsInit)

  useEffect(() => {
    setAssetClassesTable(tablePropsInit)
  }, [props.assetClassesList])


  const dispatchResearch = (action) => {
    setAssetClassesTable((prevState) => kaReducer(prevState, action))
    localStorage.setItem('itemsPerPage', assetClassesTable?.paging?.pageSize)
    // localStorage.setItem('itemsPerPageAssetClass', action?.pageSize)
  }

  useEffect(() => {
    assetClassesTable?.columns.map((item) => {
      if (item.filterRowValue) {
        dispatchResearch(updateFilterRowValue(item.key, ''))
      }
    })
  }, [props.isFilter])
  return (
    <MyDiv>
      <CustomTable
        tableProps={assetClassesTable}
        dispatch={dispatchResearch}
        handleEdit={props.handleEdit}
        handleDelete={props.handleDelete}
        setFilter={props.setFilter}
        filteringMode={props.isFilter ? FilteringMode.FilterRow : ''}

      />
    </MyDiv>
  )
}
