import {DataType} from 'ka-table/enums'

const finalReportTablePropsInit = {
  columns: [
    {
      key: 'selection-cell',
      style: {width: '3.5%'},
    },
    {
      key: 'expand-cell',
      style: {width: '5%'},
    },
    {
      dataType: DataType.String,
      key: 'name',
      style: {width: 120},
      title: 'Location',
    },
    {
      dataType: DataType.String,
      key: 'street',
      style: {width: 120},
      title: 'Address',
    },
    {
      dataType: DataType.String,
      key: 'type_of_operation_c',
      style: {width: 120},
      title: 'Type of Operation',
    },
    {
      dataType: DataType.String,
      key: 'square_footage_c',
      style: {width: 120},
      title: 'Square Footage',
    },
    {
      key: 'area-ordering-cell',
      style: {width: 70},
      title: 'Area Ordering',
    },
    {
      key: 'location-asset-cell',
      style: {width: 80},
      title: 'Location Wise Assets',
    },
  ],
  rowReordering: true,
  data: null,
  selectedRows: [],
  rowKeyField: 'location_id',
  paging: {
    enabled: true,
    pageIndex: 0,
    pageSize: 50,
    pageSizes: [50, 100, 150, 200, 250],
  },
  // height: '55vh',
}

// const tablePropsInit = {
//   columns: [
//     {
//       dataType: DataType.Number,
//       key: 'asset_id',
//       style: {width: 120},
//       title: 'Asset Id',
//     },
//     {
//       dataType: DataType.String,
//       key: 'make',
//       style: {width: 120},
//       title: 'Make',
//     },
//     {
//       dataType: DataType.String,
//       key: 'line_name',
//       style: {width: 120},
//       title: 'Line Name',
//     },
//     {
//       dataType: DataType.String,
//       key: 'asset_type',
//       style: {width: 120},
//       title: 'Asset Type',
//     },
//     {
//       dataType: DataType.String,
//       key: 'line_unique_name',
//       style: {width: 120},
//       isSortable: false,
//       title: 'Unique Name',
//     },
//     {
//       dataType: DataType.String,
//       key: 'year',
//       style: {width: 120},
//       isSortable: false,
//       title: 'Year',
//     },
//     {
//       dataType: DataType.String,
//       key: 'asset_no',
//       style: {width: 120},
//       isSortable: false,
//       title: 'Asset#',
//     },
//   ],
//   data: linesData,
//   rowKeyField: 'asset_id',
//   sortingMode: SortingMode.Single,
//   paging: {
//     enabled: true,
//     pageIndex: 0,
//     pageSize: 50,
//     pageSizes: [50, 100, 150, 200, 250],
//   },
//   filteringMode: FilteringMode.FilterRow,
// }

// export {finalReportTablePropsInit, tablePropsInit}
export {finalReportTablePropsInit}
