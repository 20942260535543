import lzString from 'lz-string'
import {fetchModelByMake} from '../../../services/assetListService'
import {assetsListActions} from '../assets'
const getModelByMakeAction = () => {
  return (dispatch) => {
    dispatch(assetsListActions.getModelByMakeRequest())
    fetchModelByMake().then((res) => {
      dispatch(assetsListActions.getModelByMakeSuccess(res))
      const compressedData = lzString.compressToUTF16(JSON.stringify(res))
      localStorage.setItem('make_model', compressedData)
      // if (history) {
      //   history.push('/')
      // }
    }).catch((error) => {
      dispatch(assetsListActions.getModelByMakeFailure(error))
    })
  }
}


export default getModelByMakeAction
