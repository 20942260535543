import {fetchImportedAssets} from '../../../services/importServices'
import {importListActions} from '../import'

const getImportedAssets = (payload) => {
  return (dispatch) => {
    dispatch(importListActions.getImportedAssetsRequest())
    fetchImportedAssets(payload).then((res) => {
      dispatch(importListActions.getImportedAssetsSuccess(res))
      if (res?.redirect_url) window.location.href = res.redirect_url
    }).catch((error) => {
      dispatch(importListActions.getImportedAssetsFailure(error))
    })

  }
}

export default getImportedAssets
