/* eslint-disable camelcase */
/* eslint-disable no-duplicate-imports */
/* eslint-disable max-len */
import React, {useState, useEffect} from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import {useSelector, useDispatch} from 'react-redux'
import {decode as base64Decode} from 'base-64'
import {DataType} from 'ka-table/enums'
import {kaReducer} from 'ka-table'
import {AlertMessage, CustomButton, CustomTable, CustomTextBox, Loader} from '../../../components'
import {assetsListActions} from '../../../redux/assets/assets'
import {getFindReplaceAction, postFindReplaceAction} from '../../../redux/assets/AssetsActions'
import MyDiv from './findReplaceImport.style'

const TABLEPROPSINIT = {
  columns: [
    {
      key: 'selection-cell',
      style: {width: '3.5%'},
    },
    {
      dataType: DataType.String,
      key: 'asset_imported_id',
      style: {width: 70},
      title: 'Asset Imported Id',
    },
    {
      dataType: DataType.String,
      key: 'capacity',
      style: {width: 70},
      title: 'Original Capacity',
    },
    {
      dataType: DataType.String,
      key: 'replace_capacity',
      style: {width: 70},
      title: 'Replace Capacity',
    },
    {
      dataType: DataType.String,
      key: 'description',
      style: {width: 70},
      title: 'Original Description',
    },
    {
      dataType: DataType.String,
      key: 'replace_description',
      style: {width: 70},
      title: 'Replace Description',
    },
    {
      dataType: DataType.String,
      key: 'make',
      style: {width: 70},
      title: 'Original Make',
    },
    {
      dataType: DataType.String,
      key: 'replace_make',
      style: {width: 70},
      title: 'Replace Make',
    },
    {
      dataType: DataType.String,
      key: 'model',
      style: {width: 70},
      title: 'Original Model',
    },
    {
      dataType: DataType.String,
      key: 'replace_model',
      style: {width: 70},
      title: 'Replace Model',
    },
    {
      dataType: DataType.String,
      key: 'serial_number',
      style: {width: 70},
      title: 'Original Serial Number',
    },
    {
      dataType: DataType.String,
      key: 'replace_serial_number',
      style: {width: 70},
      title: 'Replace Serial Number',
    },
    {
      dataType: DataType.String,
      key: 'area',
      style: {width: 70},
      title: 'Original Area',
    },
    {
      dataType: DataType.String,
      key: 'replace_area',
      style: {width: 70},
      title: 'Replace Area',
    },
  ],
  data: [],
  // selectedRows: [],
  rowKeyField: 'type_id',
  // paging: {
  //   enabled: true,
  //   pageIndex: 0,
  //   pageSize: 50,
  //   pageSizes: [50, 100, 150, 200, 250],
  // },
}
export default function FindReplaceImport() {


  const dispatch = useDispatch()
  const [showList, setShowList] = useState(false)
  const [values, setValues] = useState({})
  const [checkValue, setCheckValue] = useState('matchcase')
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [isSubmit, setSubmit] = useState(false)
  const assetsState = useSelector((state) => state.assets)
  let projectIDCrm = localStorage.getItem('project_id_crm')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string


  const [findReplaceColumn, setfindReplaceColumn] = useState(TABLEPROPSINIT)
  // const getSelectedRows = () => {
  //   const arr = []
  //   findReplaceColumn?.data?.map((i) => {
  //     arr.push(i.type_id)
  //   })
  //   return arr
  // }
  const getSelectedRows = () => {
    let data = assetsState?.findReplace?.findReplace
    const arr = []
    data?.map(({asset_imported_id}) => {arr.push(asset_imported_id)})
    return arr
  }
  useEffect(() => {
    setfindReplaceColumn({...TABLEPROPSINIT, data: assetsState?.findReplace?.findReplace, selectedRows: getSelectedRows()})
  }, [assetsState?.findReplace?.findReplace])

  const dispatchReports = (action) => {
    setfindReplaceColumn((prevState) => kaReducer(prevState, action))
  }

  useEffect(() => {
    if (assetsState?.messageData.status === 'success') {
      setMessage(assetsState?.messageData?.message)
      setStatus('success')
      // setShowList(!showList)
      setValues({})
    }
    if (assetsState?.messageData.status === 'failed') {
      setMessage(assetsState?.messageData?.message)
      setStatus('warning')
    }
  }, [assetsState?.messageData])

  const handleChange = (e) => {
    setValues({...values, [e.target.name]: e.target.value})
  }

  const handleMatchCase = (e) => {
    setCheckValue(e.target.value)
  }


  const handleSubmit = () => {
    if (!values?.find) {
      setMessage('Please Enter Find')
      setStatus('warning')
      return
    }
    let obj = {
      find: values?.find ?? '',
      matchtype: checkValue,
      replace: values?.replace ?? '',
      type: 'filter',
      importedAsset: '1',
      project_id_crm: decodedProjectId,
    }
    dispatch(getFindReplaceAction(obj))
    setShowList(true)
    // setSubmit(true)

  }
  const commitChanges = () => {
    if (findReplaceColumn?.selectedRows?.length > 0) {
      let data = {
        find_text: values?.find ?? '',
        replace_text: values?.replace ?? '',
        matchtype: checkValue,
        items: findReplaceColumn?.selectedRows.join(','),
        importedAsset: 1,
      }
      dispatch(postFindReplaceAction(data))
      // setValues({})
      setSubmit(false)
      setShowList(false)
    } else {
      setMessage('Please select atleast one asset')
      setStatus('warning')
    }
  }

  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    dispatch(assetsListActions.clearMessageData())
  }
  // useEffect(() => {
  //   if (values?.find) {
  //     if (findReplaceColumn?.data?.length === 0) {
  //       setShowList(false)
  //       setMessage('Nothing to replace.')
  //       setStatus('warning')
  //     }
  //   }
  // }, [findReplaceColumn])

  useEffect(() => {
    let data = assetsState?.findReplace?.findReplace
    if (isSubmit) {
      if (data?.length > 0) {
        setShowList(true)
      } else {
        setMessage('Nothing to Change')
        setStatus('warning')
      }
    }
  }, [assetsState?.findReplace?.findReplace])

  return (
    <MyDiv>
      {(assetsState?.findReplace?.loading) &&
        <div>
          <Loader />
        </div>
      }
      <Box className="page_heading">
        <Typography>{!showList ? 'Find Replace Imported Asset' : 'Assets'}</Typography>
        {showList &&
          <CustomButton variant="contained" className="btn_theme" title="Commit" onClick={commitChanges} />
        }      </Box>
      <Box className="wrapper_box">
        <Grid container columnSpacing={{md: 4}}>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Find</Typography>
              <CustomTextBox value={values?.find ?? ''} onChange={handleChange} name="find" fieldLabel="Find" />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Replace</Typography>
              <CustomTextBox value={values?.replace ?? ''} onChange={handleChange} name="replace" fieldLabel="Replace" />
            </Box>
          </Grid>
          {/* {showList &&
            <Box mt={2}>
              <CustomButton
                title="Commit"
                variant="contained"
                className="btn_themes"
                onClick={commitChanges}
              />
            </Box>
          } */}
          <Grid item md={12} mt={2}>
            <FormControl>
              <RadioGroup row defaultValue="matchCase">
                <FormControlLabel
                  className="checked_label"
                  value="matchcase"
                  control={<Radio onChange={handleMatchCase} checked={checkValue === 'matchcase'} />}
                  label="Match Case"
                />
                <FormControlLabel
                  className="checked_label"
                  value="matchword"
                  control={<Radio onChange={handleMatchCase} checked={checkValue === 'matchword'} />}
                  label="Match Whole Word"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Box mt={2}>
          <CustomButton
            title="Submit"
            variant="contained"
            className="btn_theme"
            onClick={handleSubmit}
          />
        </Box>
      </Box>
      {showList &&
        <CustomTable tableProps={findReplaceColumn} dispatch={dispatchReports} />
        // <FindReplaceImportTable findReplaceList={findReplaceList} handleCheckBox={handleCheckBox} isChecked={isChecked} setIsChecked={setIsChecked} commitChanges={commitChanges} />

      }
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
