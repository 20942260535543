/* eslint-disable max-len */
import React, {useEffect, useState} from 'react'
import {Menu, MenuItem} from '@mui/material'
import Divider from '@mui/material/Divider'
import CustomData from './CustomData.json'

const filterDataJson = [
  {
    id: 1,
    name: 'Edit Asset',
  },
  {
    id: 9,
    name: 'Delete Asset',
  },
]
export default function CustomMenu(props) {
  const [isUpdateMenu, setUpdateMenuItem] = useState(false)
  const handleMenuItem = (key) => {
    props.handleMenuItem(key)
  }
  const handleMenuFlag = () => {
    let flag = props.rowData && (props.rowData.flagged === 1 ? 'UnFlag Asset' : 'Flag Asset')
    props.handleMenuItem(flag)
  }
  useEffect(() => {
    if (props.inputdata.appraiser || props.inputdata.area) {
      setUpdateMenuItem(true)
    } else {
      setUpdateMenuItem(false)
    }
  }, [props.inputdata])
  return (
    <Menu open={props.visible} onClose={props.handleClose} anchorOrigin={{
      vertical: props.posY,
      horizontal: props.posX,
    }}
    >
      { !isUpdateMenu ?
        CustomData.map((key, index) => {
          return (
            <div key={index}><MenuItem onClick={() => handleMenuItem(key)}>{key.name}</MenuItem><Divider /></div>
          )
        })
        : (
          filterDataJson.map((key, index) => {
            return (
              <div key={index}><MenuItem onClick={() => handleMenuItem(key)}>{key.name}</MenuItem><Divider /></div>
            )
          })
        )
      }
      <MenuItem onClick={handleMenuFlag}>{props.rowData && props.rowData.flagged === 1 ? 'UnFlag Asset' : 'Flag Asset'}</MenuItem>
    </Menu>
  )
}

