import React from 'react'
import {Box} from '@mui/material'
import MyDiv from './conditionDropdown.style'

export default function ConditionDropdown({selectedValueCondition, conditionData, handleConditionChange, toggleCondition, handleToggleCondition, rowIdCondition, currentRowIdCondition}) {

  return (
    <MyDiv>
      {toggleCondition && currentRowIdCondition?.type_id === rowIdCondition ?
        <select value={selectedValueCondition || ''} onChange={(e) => handleConditionChange(e.target.value)} name="assetClass">
          {conditionData?.map((item) => {
            return (
              <option value={item?.value} key={item?.id}>{item?.value}</option>
            )
          })}
        </select> : <Box onClick={() => handleToggleCondition(rowIdCondition)} style={{color: 'black'}}>{selectedValueCondition}</Box>
      }
    </MyDiv>
  )
}
