/* eslint-disable no-undef */
/* eslint-disable no-duplicate-case */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable max-len */
import React, {useState, useEffect, useRef} from 'react'
import {Grid} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux'
import {getAssetDetailsAction} from '../../redux/lines/LineActions'
import {getResearchTypeAction, showResearchAction} from '../../redux/research/ResearchActions'
import {Loader, AlertMessage} from '../../components'
import {ResearchColumns, ResearchForm, ResearchTable} from './components'
import MyDiv from './research.style'

const researchData = {
  displyColumns: [
    {
      id: '1',
      label: 'make',
      name: 'Make',
    },
    {
      id: '2',
      label: 'model',
      name: 'Model',
    },
    {
      id: '3',
      label: 'capacity',
      name: 'Capacity',
    },
    {
      id: '4',
      label: 'asset_type',
      name: 'Asset Type',
    },
    {
      id: '6',
      label: 'year',
      name: 'Year',
    },
    {
      id: '14',
      label: 'company',
      name: 'Company',
    },
    {
      id: '15',
      label: 'client',
      name: 'Client',
    },
    {
      id: '16',
      label: 'appraiser',
      name: 'Appraiser',
    },
    {
      id: '25',
      label: 'date_appraised',
      name: 'Date Appraiser',
    },
    {
      id: '26',
      label: 'cost',
      name: 'Cost',
    },
    {
      id: '5',
      label: 'quantity',
      name: 'Quantity',
    },
  ],
  values: [
    {
      id: '17',
      label: 'FLV',
      name: 'FLV',
      type: 'values',

    },
    {
      id: '18',
      label: 'OLV',
      name: 'OLV',
      type: 'values',

    },
    {
      id: '19',
      label: 'OLVIP',
      name: 'OLVIP',
      type: 'values',

    },
    {
      id: '20',
      label: 'FMV',
      name: 'FMV',
      type: 'values',

    },
    {
      id: '21',
      label: 'FMVIP',
      name: 'FMVIP',
      type: 'values',

    },
    {
      id: '22',
      label: 'FAS',
      name: 'FAS',
      type: 'values',

    },
    {
      id: '23',
      label: 'RCN',
      name: 'RCN',
      type: 'values',

    },
  ],
}

const modifyData = {
  modifyColumn: [
    {
      id: '7',
      label: 'asset_no',
      name: 'Asset No',
    },
    {
      id: '8',
      label: 'serial_number',
      name: 'Serial Number',
    },
    {
      id: '9',
      label: 'description',
      name: 'Description',
    },
    {
      id: '10',
      label: 'condition_col',
      name: 'Condition',
    },
    {
      id: '11',
      label: 'mileage',
      name: 'Hour/Mileage',
    },
    {
      id: '13',
      label: 'area',
      name: 'Area',
    },
    {
      id: '12',
      label: 'new_cost',
      name: 'New Cost',
    },
  ],
  modifyValues: [
    {
      id: '24',
      label: 'FV',
      name: 'FV',
      type: 'values',
    },
    {
      id: '26',
      label: 'CV',
      name: 'CV',
      type: 'values',
    },
  ],
}

export default function Research() {

  const dispatch = useDispatch()
  const [isChecked, setIsChecked] = useState([])
  const [showList, setShowList] = useState(false)
  const [assetData, setAssetData] = useState([])
  const [assetTypeData, setAssetTypeData] = useState()
  const [filterAssetData, setFilterAssetData] = useState([])
  const [appraiser, setAppraiser] = useState([])
  const [inputValues, setInputValues] = useState({})
  const [checkValue, setCheckValue] = useState('2')
  const [checkLine, setCheckLine] = useState(false)
  const [appraisalData, setAppraisalData] = useState([])
  const [importedData, setImportedData] = useState([])
  const [searchedData, setSearchedData] = useState([])
  const [sortColumn, setSortColumn] = useState(researchData)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [orderedItems, setOrderedItems] = useState([])


  const lineState = useSelector((state) => state.line)
  const researchState = useSelector((state) => state.research)
  const assetTypeRef = useRef()
  const makeRef = useRef()
  const clientRef = useRef()
  const companyRef = useRef()
  const appraiserRef = useRef()
  const saleRef = useRef()
  const lineRef = useRef()
  const includeLineRef = useRef()

  useEffect(() => {
    if (checkValue === '4') {
      // Combine modifyData with researchData and remove FAS and Appraiser
      const combinedData = {
        displyColumns: researchData.displyColumns
          .filter((item) => item.label !== 'appraiser' && item.label !== 'cost')
          .concat(modifyData.modifyColumn),
        values: researchData.values
          .filter((item) => item.label !== 'FAS' && item.label !== 'appraiser')
          .concat(modifyData.modifyValues),
      }
      setSortColumn(combinedData)
    } else {
      // Remove FV and CV, include FAS, and optionally exclude Appraiser
      const filteredResearchData = {
        displyColumns: researchData.displyColumns
          .filter((item) => item.label !== 'FV' && item.label !== 'CV'),
        values: researchData.values,
      }
      setSortColumn(filteredResearchData)
    }
  }, [checkValue])

  useEffect(() => {
    let payload = {
      is_research: 1,
    }
    dispatch(getAssetDetailsAction(payload))
    dispatch(getResearchTypeAction({app_type: 'appraisal'}))
    dispatch(getResearchTypeAction({app_type: 'imported'}))
  }, [dispatch])

  useEffect(() => {
    setAssetData(lineState?.assetDetails?.assetDetails)
  }, [lineState?.assetDetails?.assetDetails])

  useEffect(() => {
    setAppraisalData(researchState?.appraisalType?.appraisalType)
    setImportedData(researchState?.importedType?.importedType)
    setSearchedData(researchState?.showResearch?.showResearch)
  }, [researchState?.appraisalType?.appraisalType, researchState?.importedType?.importedType, researchState?.showResearch?.showResearch])

  useEffect(() => {
    const uniqueUsernames = new Set()
    const uniqueAppraiserData = []
    assetData?.data?.appraiserList.forEach((entry) => {
      if (!uniqueUsernames.has(entry.username)) {
        uniqueUsernames.add(entry.username)
        uniqueAppraiserData.push(entry)
      }
    })
    setFilterAssetData(uniqueAppraiserData)
  }, [assetData?.data])

  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
  }

  const makeListData = (checkValue === '2' || checkValue === '1') ? appraisalData?.makeList : importedData?.makeList
  const clientListData = checkValue === '2' ? appraisalData?.clients : importedData?.clients
  const companiesListData = checkValue === '2' ? appraisalData?.companies : importedData?.companies
  const salesData = checkValue === '2' ? appraisalData?.sales : importedData?.sales
  const filteredAssetTypes = assetData?.data?.assetTypeList?.length > 0 && assetData?.data?.assetTypeList[0]?.asset_type === 'Select or search asset type' ? assetData?.data?.assetTypeList?.slice(1) : assetData?.data?.assetTypeList

  useEffect(() => {
    if (inputValues?.selectedAppraiser) {
      const joinAppraisers = inputValues?.selectedAppraiser?.map((item) => {return (item.id)?.toString()})
      setAppraiser(joinAppraisers)
    }
    if (checkValue === '2' || checkValue === '1') {
      if (inputValues?.selectedAssetType) {
        const joinAssetTypes = inputValues?.selectedAssetType?.map((item) => {return (item.asset_type_id)?.toString()})
        setAssetTypeData(joinAssetTypes)
      }
    }
    if (checkValue === '4') {
      if (inputValues?.selectedAssetType) {
        const joinAssetTypes = inputValues?.selectedAssetType?.map((item) => {return (item.asset_type)})
        setAssetTypeData(joinAssetTypes)
      }
    }

  }, [inputValues?.selectedAppraiser, inputValues?.selectedAssetType])


  const handleChangeInput = (e) => {
    setInputValues({...inputValues, [e.target.name]: e.target.value})
  }

  const handleSelected = (selected, type) => {
    let label
    // we set lable in case we are adding a custom value and not one that is already in the list
    if (selected[0]?.label) label = true
    switch (type) {
      // if we have a label, we set the values accordingly based on a ternary check
      case 'appraiser':
        setInputValues({...inputValues, selectedAppraiser: selected, appraiser: label ? selected[0]?.label : selected?.map((item) => {return item.id})})
        break
      case 'make':
        setInputValues({...inputValues, selectedMake: selected, make: label ? selected[0]?.label : selected[0]?.make, make_id: label ? null : selected[0]?.make_id})
        break
      case 'client':
        setInputValues({...inputValues, selectedClient: selected, client_name: label ? selected[0]?.label : selected[0]?.client_name})
        break
      case 'company':
        setInputValues({...inputValues, selectedCompany: selected, company_name: label ? selected[0]?.label : selected[0]?.company_name})
        break
      case 'sale_name':
        setInputValues({...inputValues, selectedSaleName: selected, sale_name: label ? selected[0]?.label : selected[0]?.sale_name})
        break
      case 'line':
        setInputValues({...inputValues, selectedLine: selected, line_name: label ? selected[0]?.label : selected[0]?.line_name})
        break
      case 'assetType':
        setInputValues({...inputValues, selectedAssetType: selected, asset_type: label ? selected[0]?.label : selected?.map((item) => {return item.asset_type}), asset_type_id: label ? null : selected?.map((item) => {return item.asset_type_id})})
        break
      default:
        break
    }
  }

  const handleCheckbox = (e) => {
    setCheckValue(e.target.value)
    setInputValues({})
    setAssetTypeData({})
    assetTypeRef?.current?.clear()
    makeRef?.current?.clear()
    companyRef?.current?.clear()
    saleRef?.current?.clear()
    appraiserRef?.current?.clear()
    clientRef?.current?.clear()
    lineRef?.current?.clear()
    includeLineRef?.current?.clear()
    setCheckLine(false)
  }

  const handleCheckLine = (e) => {
    setCheckLine(e.target.checked)
  }

  const modifySearch = () => {
    setShowList(false)
  }

  // const generateKey = (value) => {
  //   if (value.includes(' ')) {
  //     return value.split(' ').join('_').toLowerCase()
  //   } else {
  //     return value.toLowerCase()
  //   }
  // }

  const handleChange = (event, item) => {
    let updatedCheck = [...isChecked]
    let obj = {
      id: item?.id,
      value: item.label,
      name: item.name,
      type: item?.type,
    }
    if (event?.target?.checked) {
      updatedCheck = [...isChecked, obj]
    } else {
      updatedCheck = updatedCheck.filter((item) => item.id !== obj.id)
    }
    setIsChecked(updatedCheck)
    setOrderedItems(updatedCheck)
  }

  const onDragEnd = (result) => {
    if (!result.destination) return

    const items = Array.from(orderedItems)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    setOrderedItems(items)
    setIsChecked(items)
  }

  const handleShow = () => {
    let arr = []
    isChecked?.map((item) => {
      let obj = {
        name: item?.name,
        order: '',
      }
      arr.push(obj)
    })
    if (isChecked?.length > 0) {
      let obj = {
        app_type: checkValue === '2' ? 'appraisal' : checkValue === '1' ? 'all' : 'imported',
        filterOptions: {
          app_type: 'all',
          appraisers: appraiser ? appraiser.join(',') : null,
          asset_no: inputValues?.asset_no ?? null,
          asset_type: assetTypeData?.length > 0 ? assetTypeData?.join(',') : null,
          // imported_asset_type: assetTypeData ? assetTypeData?.join(',') : null,
          asset_type_ind: null,
          capacity: inputValues?.capacity ?? null,
          client_name: inputValues?.client_name ?? null,
          company_name: inputValues?.company_name ?? null,
          cost: inputValues?.cost ?? null,
          description: inputValues?.description ?? null,
          date_appraised_from: inputValues?.date_appraised_from ?? null,
          date_appraised_to: inputValues?.date_appraised_to ?? null,
          includeLine: '',
          make: inputValues?.make ?? null,
          make_id: inputValues.make_id ?? null,
          model: inputValues?.model ?? null,
          line_name: inputValues?.line_name ?? null,
          pageNumber: 1,
          pageSize: 60,
          sale_name: null,
          serial_number: inputValues?.serial_number ?? null,
          sortCol: 'a.created_on',
          sortOrder: 'asc',
          year_app_from: inputValues?.year_app_from ?? null,
          year_app_to: inputValues?.year_app_to ?? null,
          year_from: inputValues?.year_from ?? null,
          year_to: inputValues?.year_to ?? null,
        },
        sortableData: arr,
      }
      setShowList(!showList)
      dispatch(showResearchAction(obj))
    } else {
      alert('Please choose at least one column to show')
    }
  }

  const handleReset = () => {
    window.location.reload()
    // setShowList(false)
    // setInputValues({})
    // setAssetTypeData({})
    // assetTypeRef?.current?.clear()
    // makeRef?.current?.clear()
    // companyRef?.current?.clear()
    // saleRef?.current?.clear()
    // appraiserRef?.current?.clear()
    // clientRef?.current?.clear()
    // lineRef?.current?.clear()
    // includeLineRef?.current?.clear()
    // setCheckLine(false)
    // // setSortColumn(researchData)
    // // setOrderedItems(researchData)
    // // setIsChecked([])
  }

  useEffect(() => {
    let defaultColumn = []
    let valueColumn = []
    let columnOrder = sortColumn?.displyColumns.sort((a, b) => a?.id - b?.id)
    columnOrder?.map((item) => {
      if (checkValue === '1' || checkValue === '2') {
        if (item.id === '1' || item.id === '2' || item.id === '3' || item.id === '4' || item.id === '5' || item.id === '6' || item.id === '17' || item.id === '18') {
          let obj = {
            id: item.id,
            value: item.label,
            name: item.name,
            type: item?.type,
          }
          defaultColumn.push(obj)
        }
      } if (checkValue === '4') {
        if (item.id === '1' || item.id === '2' || item.id === '3' || item.id === '4' || item.id === '5' || item?.id === '6' || item.id === '17' || item.id === '18') {
          let obj = {
            id: item.id,
            value: item.label,
            name: item.name,
            type: item?.type,
          }
          defaultColumn.push(obj)
        }
      }
    })
    sortColumn?.values?.map((item) => {
      if (checkValue === '1' || checkValue === '2' || checkValue === '4') {
        if (item.id === '17' || item.id === '18') {
          let obj = {
            id: item.id,
            value: item.label,
            name: item.name,
            type: item?.type,
          }
          valueColumn.push(obj)
        }
      }
    })
    const finalObj = [...defaultColumn, ...valueColumn]
    setIsChecked(finalObj)
    const index = finalObj.findIndex((item) => item.value === 'quantity')
    if (index !== -1 && index !== 0) {
      const quantityObj = finalObj.splice(index, 1)[0]
      finalObj.unshift(quantityObj)
    }
    setOrderedItems(finalObj)
  }, [sortColumn, checkValue])
  return (
    <MyDiv>
      {(lineState?.assetDetails?.loading || researchState?.appraisalType?.loading || researchState?.showResearch?.loading) &&
        <div>
          <Loader />
        </div>
      }
      {!showList ?
        <Grid container columnSpacing={{sm: 4, md: 4}}>
          <Grid item md={8}>
            <ResearchForm
              handleShow={handleShow}
              checkValue={checkValue}
              inputValues={inputValues}
              handleCheckbox={handleCheckbox}
              handleChangeInput={handleChangeInput}
              handleReset={handleReset}
              isChecked={isChecked}
              checkLine={checkLine}
              handleCheckLine={handleCheckLine}
              filterAssetData={filterAssetData}
              makeListData={makeListData}
              clientListData={clientListData}
              companiesListData={companiesListData}
              salesData={salesData}
              linesData={appraisalData?.lines}
              assetTypesData={filteredAssetTypes}
              importAssetTypesData={assetData?.data?.importedAssetTypeList}
              handleSelected={handleSelected}
              assetTypeRef={assetTypeRef}
              makeRef={makeRef}
              companyRef={companyRef}
              clientRef={clientRef}
              saleRef={saleRef}
              lineRef={lineRef}
              appraiserRef={appraiserRef}
              includeLineRef={includeLineRef}
            />
          </Grid>
          <Grid item md={4}>
            <ResearchColumns handleChange={handleChange} orderedItems={orderedItems} researchData={sortColumn} isChecked={isChecked} onDragEnd={onDragEnd} />
          </Grid>
        </Grid> :
        <ResearchTable modifySearch={modifySearch} handleShow={handleReset} searchedData={searchedData} isChecked={isChecked} orderedItems={orderedItems} />
      }
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
