import {DataType, SortDirection} from 'ka-table/enums'

const tableConfig = {
  dataKeys: {
    attributes: {
      location_id: {visibility: true, model: ['asset'], toggable: false},
      area: {visibility: true, model: ['asset'], toggable: false},
      // area_asset_order: {
      //   visibility: true,
      //   model: ['asset'],
      //   toggable: true,
      // },
      asset_class_code: {
        visibility: true,
        model: ['asset', 'line'],
        toggable: true,
      },
      icon: {visibility: true, model: ['asset'], toggable: true},
      select: {visibility: true, model: ['asset'], toggable: true},
      // asset_id: {
      //   visibility: true,
      //   model: ['asset', 'line'],
      //   toggable: true,
      // },
      // asset_no: {
      //   visibility: true,
      //   model: ['asset', 'line'],
      //   toggable: true,
      // },
      asset_type: {
        visibility: true,
        model: ['asset', 'line'],
        toggable: true,
      },
      capacity: {
        visibility: true,
        model: ['asset', 'line'],
        toggable: true,
        secondaryKey: 'line_name',
      },
      flagged: {visibility: true, model: ['asset', 'line'], toggable: true},
      // is_not_found: {
      //   visibility: true,
      //   model: ['asset', 'line'],
      //   toggable: true,
      // },
      make: {visibility: true, model: ['asset', 'line'], toggable: true},
      model: {visibility: true, model: ['asset', 'line'], toggable: true},
      // quantity: {
      //   visibility: true,
      //   model: ['asset', 'line'],
      //   toggable: true,
      // },
      ref: {visibility: true, model: ['asset'], toggable: false},
      serial_number: {
        visibility: true,
        model: ['asset', 'line'],
        toggable: true,
      },
      // year: {visibility: true, model: ['asset', 'line'], toggable: true},
      parent_line: {
        visibility: false,
        model: ['asset', 'line'],
        toggable: false,
      },
      // FLV: {
      //   visibility: true,
      //   model: ['asset', 'line'],
      //   toggable: false,
      // },
      // OLV: {
      //   visibility: true,
      //   model: ['asset', 'line'],
      //   toggable: false,
      // },
    },
    orders: [
      'icon',
      'select',
      'ref',
      'area',
      // 'area_asset_order',
      'location_id',
      'flagged',
      // 'is_not_found',
      // 'asset_id',
      // 'quantity',
      // 'asset_no',
      'make',
      'model',
      // 'year',
      'capacity',
      'asset_type',
      'asset_class_code',

      'serial_number',
      'parent_line',
      // 'FLV',
      // 'OLV',
    ],
  },
}
const columns = {
  location_id: {
    key: 'location_address',
    title: 'Location',
    dataType: DataType.String,
    isEditable: false,
    width: 0,
    sortIndex: 0,
  },
  parent_line: {
    key: 'parent_line',
    isEditable: false,
    title: 'Parent Line',
    dataType: DataType.String,
    width: 0,
  },
  area: {
    key: 'area',
    isEditable: false,
    title: 'Area',
    dataType: DataType.String,
    with: 0,
  },
  area_order: {
    key: 'area_order',
    title: 'Area Order',
    isEditable: false,
    dataType: DataType.String,
    width: 0,
    sortIndex: 1,
  },
  ref: {
    key: 'ref',
    isEditable: false,
    title: 'Ref #',
    // sortable: true,
    width: '6%',
    dataType: DataType.Number,
    sortDiretion: SortDirection.Ascend,
    visible: false,
  },
  flagged: {
    key: 'flagged',
    width: '6%',
    isEditable: false,
    title: 'Flag',
    dataType: DataType.Boolean,
    visible: true,
  },
  icon: {
    key: 'add-icon',
    isEditable: false,
    style: {width: '3%', border: '1px solid #ccc', background: '#EAEAEA'},
  },
  select: {
    key: 'selection-cell',
    isEditable: false,
    style: {width: '5%', borderRight: '1px solid #747d86', borderBottom: '1px solid #ccc', borderTop: '1px solid #ccc', borderLeft: '1px solid #ccc', background: '#EAEAEA'},
  },
  // is_not_found: {
  //   key: 'is_not_found',
  //   width: 100'5%',
  //   title: 'Flag',
  //   dataType: DataType.Boolean,
  // },
  // asset_id: {
  //   key: 'asset_id',
  //   type: 'number',
  //   width: 100'5%',
  //   title: 'Asset ID',
  //   dataType: DataType.Number,
  // },
  // area_asset_order: {
  //   key: 'area_asset_order',
  //   width: 100'8%',
  //   title: 'Asset Area Order',
  //   dataType: DataType.Number,
  //   sortIndex: 2,
  // },
  // quantity: {
  //   key: 'quantity',
  //   title: 'Qty',
  //   width: 100'6%',
  //   dataType: DataType.Number,
  // },
  // asset_no: {
  //   key: 'asset_no',
  //   title: 'Asset #',
  //   width: 100'8%',
  //   dataType: DataType.String,
  // },
  make: {
    key: 'make',
    width: 100,
    isEditable: false,
    title: 'Make',
    dataType: DataType.String,
  },
  model: {
    key: 'model',
    isEditable: false,
    width: 100,
    title: 'Model',
    dataType: DataType.String,
  },
  capacity: {
    key: 'capacity',
    isEditable: false,
    title: 'Capacity/Text1',
    width: 100,
    dataType: DataType.String,
  },
  asset_type: {
    isEditable: false,
    key: 'asset_type',
    title: 'Asset Type',
    width: 100,
    dataType: DataType.String,
  },
  asset_class_code: {
    isEditable: false,
    key: 'asset_class_code',
    name: 'asset_class',
    title: 'Class',
    width: 100,
    dataType: DataType.String,
  },
  serial_number: {
    key: 'serial_number',
    isEditable: false,
    // minwidth: 100'125',
    width: 100,
    title: 'Serial Number',
    dataType: DataType.String,
  },
  // year: {
  //   key: 'year',
  //   width: 100'5%',
  //   title: 'Year',
  //   dataType: DataType.String,
  // },
  // FLV: {
  //   key: 'FLV',
  //   width: 100'8%',
  //   title: 'FLV',
  //   dataType: DataType.String,
  // },
  // OLV: {
  //   key: 'OLV',
  //   width: 100'8%',
  //   title: 'OLV',
  //   dataType: DataType.String,
  // },
}

export {tableConfig, columns}
