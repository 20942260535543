import {fetchProjectById} from '../../../services/homeService'
import {homeListActions} from '../home'

const getProjectByCrmIdAction = (payload) => {
  return (dispatch) => {
    dispatch(homeListActions.projectByIdRequest())
    fetchProjectById(payload).then((res) => {
      dispatch(homeListActions.projectByIdSuccess(res))
    }).catch((error) => {
      dispatch(homeListActions.projectByIdFailure(error))
    })
  }
}

export default getProjectByCrmIdAction
