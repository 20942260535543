import React from 'react'
import {Checkbox} from '@mui/material'
import {deselectRow, selectRow, selectRowsRange} from 'ka-table/actionCreators'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useParams} from 'react-router-dom/cjs/react-router-dom.min'
import {adminListActions} from '../../../../../redux/admin/admin'
import {reportListActions} from '../../../../../redux/reports/report'
import {assetsListActions} from '../../../../../redux/assets/assets'
import {researchListActions} from '../../../../../redux/research/research'
// import {reportListActions} from '../../../../../redux/reports/report'

export default function SelectionCell(props) {

  const {id} = useParams()
  const location = useLocation()
  const currentUrl = location.pathname
  const reduxDispatch = useDispatch()

  const selectedReportRows = useSelector((state) => state.report.selectedItems)

  const {rowKeyValue, dispatch, isSelectedRow, selectedRows} = props
  // This is because of a custom invert select feature
  if (selectedReportRows && currentUrl.includes(`asset-list-reports/${id}`)) {
    selectedRows.splice(0, selectedRows.length)
    selectedRows.push([...selectedReportRows])
  }


  const handleChange = (event) => {
    if (event.nativeEvent.shiftKey) {
      dispatch(selectRowsRange(rowKeyValue, [...selectedRows].pop()))
    } else if (event.currentTarget.checked) {
      reduxDispatch(adminListActions.setSelectedItems({id: rowKeyValue, type: 'add'}))
      reduxDispatch(assetsListActions.setSelectedItems({id: rowKeyValue, type: 'add'}))
      reduxDispatch(reportListActions.setSelectedItems({id: rowKeyValue, type: 'add'}))
      reduxDispatch(researchListActions.setSelectedItems({id: rowKeyValue, type: 'add'}))
      dispatch(selectRow(rowKeyValue))
    } else {
      reduxDispatch(adminListActions.setSelectedItems({id: rowKeyValue, type: 'remove'}))
      reduxDispatch(reportListActions.setSelectedItems({id: rowKeyValue, type: 'remove'}))
      reduxDispatch(assetsListActions.setSelectedItems({id: rowKeyValue, type: 'remove'}))
      reduxDispatch(researchListActions.setSelectedItems({id: rowKeyValue, type: 'remove'}))
      dispatch(deselectRow(rowKeyValue))
    }
  }

  // /asset-list-reports/{id} page
  if (selectedReportRows && currentUrl.includes(`asset-list-reports/${id}`)) {
    return (
      <Checkbox
        checked={selectedRows[0].includes(rowKeyValue)}
        onChange={(event) => {
          handleChange(event)
        }}
      />
    )
  }

  // for everything else

  return (
    <Checkbox
      checked={isSelectedRow}
      onChange={(event) => {
        handleChange(event)
      }}
    />
  )
}
