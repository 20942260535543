import {DataType, SortDirection} from 'ka-table/enums'

const tableConfig = {
  dataKeys: {
    attributes: {
      location_id: {visibility: true, model: ['asset'], toggable: false},
      area: {visibility: true, model: ['asset'], toggable: false, locked: true, // Fix this column
      },
      area_asset_order: {
        visibility: false,
        model: ['asset'],
        toggable: true,
        locked: true, // Fix this column

      },
      asset_class_code: {
        visibility: true,
        model: ['asset', 'line'],
        toggable: false,
      },
      asset_id: {
        visibility: true,
        model: ['asset', 'line'],
        toggable: true,
      },
      asset_no: {
        visibility: true,
        model: ['asset', 'line'],
        toggable: true,
      },
      asset_type: {
        visibility: true,
        model: ['asset', 'line'],
        toggable: true,
      },
      capacity: {
        visibility: true,
        model: ['asset', 'line'],
        toggable: true,
        secondaryKey: 'line_name',
      },
      flagged: {visibility: true, model: ['asset', 'line'], toggable: true},
      is_not_found: {
        visibility: true,
        model: ['asset', 'line'],
        toggable: true,
      },
      make: {visibility: true, model: ['asset', 'line'], toggable: true},
      model: {visibility: true, model: ['asset', 'line'], toggable: true},
      quantity: {
        visibility: true,
        model: ['asset', 'line', 'quantity'],
        toggable: true,
        secondaryKey: 'quantity_type',
      },
      icon: {visibility: true, model: ['asset'], toggable: true},
      select: {visibility: true, model: ['asset'], toggable: true},
      ref: {visibility: true, model: ['asset'], toggable: false, locked: true, // Fix this column
      },
      serial_number: {
        visibility: true,
        model: ['asset', 'line'],
        toggable: true,
      },
      year: {visibility: true, model: ['asset', 'line'], toggable: true},
      parent_line: {
        visibility: false,
        model: ['asset', 'line'],
        toggable: false,
      },
      condition_value: {visibility: true, model: ['asset', 'line'], toggable: true},
      FLV: {
        visibility: true,
        model: ['asset', 'line'],
        toggable: false,
      },
      FMV: {
        visibility: true,
        model: ['asset', 'line'],
        toggable: false,
      },
      OLV: {
        visibility: true,
        model: ['asset', 'line'],
        toggable: false,
      },
      OLVIP: {
        visibility: true,
        model: ['asset', 'line'],
        toggable: false,
      },
      FMVIP: {
        visibility: true,
        model: ['asset', 'line'],
        toggable: false,
      },
      CV: {
        visibility: true,
        model: ['asset', 'line'],
        toggable: false,
      },
    },
    orders: [
      'icon',
      'select',
      'ref',
      'area',
      // 'area_asset_order',
      'location_id',
      // 'flagged',
      // 'is_not_found',
      // 'asset_id',
      // 'quantity',
      // 'asset_no',
      // 'make',
      // 'model',
      // 'capacity',
      // 'asset_type',
      // 'year',
      // 'serial_number',
      // 'asset_class_code',
      // 'parent_line',
      // 'condition',
      // 'FLV',
      // 'OLV',
      // 'FMV',
    ],
  },
}
const columns = {
  location_id: {
    key: 'location_address',
    title: '',
    dataType: DataType.String,
    width: 0,
    sortIndex: 0,
    isEditable: false,
  },
  parent_line: {
    key: 'parent_line',
    title: 'Parent Line',
    dataType: DataType.String,
    width: 0,
    isEditable: false,
  },
  area: {
    key: 'area',
    title: '',
    dataType: DataType.String,
    width: 0,
    isEditable: false,
  },
  area_order: {
    key: 'area_order',
    title: 'Area Order',
    dataType: DataType.String,
    width: 0,
    sortIndex: 1,
  },
  icon: {
    key: 'add-icon',
    isEditable: false,
    style: {width: '3%', border: '1px solid #ccc', background: '#EAEAEA'},
  },
  select: {
    key: 'selection-cell',
    isEditable: false,
    style: {width: '3%', borderRight: '1px solid #747d86', borderBottom: '1px solid #ccc', borderTop: '1px solid #ccc', borderLeft: '1px solid #ccc', background: '#EAEAEA'},
  },
  ref: {
    key: 'ref',
    title: 'Ref #',
    // sortable: true,
    width: '6%',
    dataType: DataType.Number,
    sortDiretion: SortDirection.Ascend,
    visible: false,
    isEditable: false,
  },
  flagged: {
    key: 'flagged',
    width: '6%',
    title: 'Flag',
    dataType: DataType.Number,
    visible: false,
    isEditable: false,
  },
  is_not_found: {
    key: 'is_not_found',
    width: '5%',
    title: 'Found',
    dataType: DataType.Number,
    isEditable: false,
    visible: false,

  },
  asset_id: {
    key: 'asset_id',
    type: 'number',
    width: '5%',
    title: 'Asset ID',
    dataType: DataType.Number,
    isEditable: false,
    visible: false,
  },
  area_asset_order: {
    key: 'area_asset_order',
    width: '8%',
    title: 'Asset Area Order',
    dataType: DataType.Number,
    sortIndex: 2,
    isEditable: false,
  },
  quantity: {
    key: 'quantity',
    title: 'Quantity',
    width: '6%',
    dataType: DataType.String,
    isEditable: false,
  },
  asset_no: {
    key: 'asset_no',
    title: 'Asset #',
    width: '8%',
    dataType: DataType.String,
    isEditable: false,
  },
  make: {
    key: 'make',
    width: '10%',
    title: 'Make',
    dataType: DataType.String,
    isEditable: false,
  },
  model: {
    key: 'model',
    width: '10%',
    title: 'Model',
    dataType: DataType.String,
    isEditable: false,
  },
  capacity: {
    key: 'capacity',
    title: 'Capacity/Text1',
    width: '15%',
    dataType: DataType.String,
    isEditable: false,
  },
  asset_type: {
    key: 'asset_type',
    title: 'Asset Type',
    width: '10%',
    dataType: DataType.String,
    isEditable: false,
  },
  asset_class_code: {
    key: 'asset_class_code',
    name: 'asset_class',
    title: 'Class',
    width: '5%',
    dataType: DataType.String,
    isEditable: false,
  },
  serial_number: {
    key: 'serial_number',
    // minwidth: '125',
    width: '6%',
    title: 'Serial Number',
    dataType: DataType.String,
    isEditable: false,
  },
  year: {
    key: 'year',
    width: '5%',
    title: 'Year',
    dataType: DataType.String,
    isEditable: true,
  },
  condition_value: {
    key: 'condition_value',
    width: '8%',
    title: 'Condition',
    dataType: DataType.String,
    isEditable: false,
  },
  FLV: {
    key: 'FLV',
    width: '8%',
    title: 'FLV($)',
    dataType: DataType.String,
    isEditable: true,
  },
  OLV: {
    key: 'OLV',
    width: '8%',
    title: 'OLV($)',
    dataType: DataType.String,
    isEditable: true,
  },
  FMV: {
    key: 'FMV',
    width: '8%',
    title: 'FMV($)',
    dataType: DataType.String,
    isEditable: true,
  },
  OLVIP: {
    key: 'OLVIP',
    width: '8%',
    title: 'OLVIP($)',
    dataType: DataType.String,
    isEditable: true,
  },
  FMVIP: {
    key: 'FMVIP',
    width: '8%',
    title: 'FMVIP($)',
    dataType: DataType.String,
    isEditable: true,
  },
  OLV1: {
    key: 'OLV1',
    width: '8%',
    title: 'OLV1',
    dataType: DataType.String,
    isEditable: true,
  },
  CV: {
    key: 'CV',
    width: '8%',
    title: 'CV',
    dataType: DataType.String,
    isEditable: true,
  },
}

export {tableConfig, columns}
