import React, {useEffect, useState} from 'react'
import 'jspdf-autotable'
import {kaReducer} from 'ka-table'
import {
  DataType,
  SortingMode,
  EditingMode,
  FilteringMode,
} from 'ka-table/enums'
import {CustomTable} from '../../../../../components'
import MyDiv from './manageValidateTable.style'


export default function ManageValidateTable(props) {

  const tablePropsInit = {
    columns: [
      {
        dataType: DataType.String,
        key: 'make',
        style: {width: 120},
        title: 'Make Name',
        isEditable: false,
      },
      {
        dataType: DataType.String,
        key: 'model',
        style: {width: 120},
        title: 'Model Name',
        isEditable: false,
      },
      {
        dataType: DataType.String,
        key: 'model_power_bi',
        style: {width: 120},
        title: 'Model Power Bi',
        isEditable: false,
      },
      {
        dataType: DataType.String,
        key: 'created_on',
        style: {width: 120},
        title: 'Created On',
        isEditable: false,
      },
      {
        key: 'edit-cell',
        style: {width: '50px'},
        isSortable: false,
        title: 'Actions',
        isEditable: false,
      },
    ],
    data: [],
    rowKeyField: 'make_id',
    sortingMode: SortingMode.Single,
    editingMode: EditingMode.Cell,
    filteringMode: FilteringMode.FilterRow,
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: 250,
      pageSizes: [250, 500, 1000],
    },
  }
  const [modelListTable, setModelListTable] = useState(tablePropsInit)

  useEffect(() => {
    setModelListTable({...tablePropsInit, data: props?.modelList})
  }, [props?.modelList])

  const dispatchResearch = (action) => {
    setModelListTable((prevState) => kaReducer(prevState, action))
  }

  return (
    <MyDiv>
      <CustomTable
        tableProps={modelListTable}
        dispatch={dispatchResearch}
        handleEdit={props.handleEdit}
      />
    </MyDiv>
  )
}
