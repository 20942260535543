/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, {useEffect, useState} from 'react'
import {AppBar, Box, Toolbar, Typography, Menu, MenuItem, Link} from '@mui/material'
// import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {NavLink, useHistory, useLocation} from 'react-router-dom'
import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import Logo from '../../../images/logo.png'
import {logoutUserActions} from '../../../redux/auth/AuthActions'
import Loader from '../../Loader'
import {Menus} from '../../../constants/constant'
import MyDiv from './header.style'

const StyledMenuItem = styled(MenuItem)(() => ({
  '&.MuiMenuItem-root': {
    borderBottom: '1px solid #ccc',
  },
  '&.MuiMenuItem-root a': {
    color: '#000000',
    fontWeight: '400',
    fontSize: '13px',
    fontFamily: 'Arial',
  },
  '&.MuiMenuItem-root:first-child': {
    marginTop: '-8px',
  },
  '&.MuiMenuItem-root:last-child': {
    marginBottom: '-8px',
  },
}))


function Header() {

  // const history = useHistory()
  const dispatch = useDispatch()
  // const location = useLocation()
  const [anchorEl, setAnchorEl] = useState(null)
  const [currentElem, setCurrentElem] = useState(null)
  const authState = useSelector((state) => state.auth)
  let userName = localStorage.getItem('userName')
  let authToken = localStorage.getItem('token')
  // const [updateMenus, setUpdateMenus] = useState([])
  // const [isresearch, setResearch] = useState(false)
  useEffect(() => {
    if (authState?.logoutUser?.logoutData?.status === 200) {
      localStorage.clear()
      window.location.reload()
    }
  }, [authState?.logoutUser?.logoutData])

  // useEffect(() => {
  //   if (location?.pathname === '/research') {
  //     setResearch(true)
  //     const filteredMenus = Menus?.map((item) => {
  //       const excludedSubmenus = ['/map-photos', '/findreplace', '/other-reports', '/manage-serial-labels', '/manage-asset-type', '/manage-make', '/validate-model', '/manage-area', '/manage-model', '/manage-user']
  //       const filteredSubmenu = item?.submenu?.filter((data) => !excludedSubmenus.includes(data.link))
  //       return {
  //         ...item,
  //         submenu: filteredSubmenu,
  //       }
  //     })
  //     setUpdateMenus(filteredMenus)
  //   } else {
  //     setResearch(false)
  //   }
  // }, [location, isresearch])

  const handleClick = (index) => (event) => {
    setAnchorEl(event.currentTarget)
    setCurrentElem(index)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setCurrentElem(null)
  }
  const locationWithoutAppraisers = useSelector((state) => state.admin?.getResetAssignAppraiser?.data?.locationWithoutAppraisers)
  const isSubMenuDisabled = (item) => {
    if ((item.menuName === 'Assets' || item.menuName === 'Lines') && locationWithoutAppraisers > 0) {
      return true
    }
    return false
  }
  const handleLogout = () => {
    let data = {
      token: authToken,
    }
    dispatch(logoutUserActions(data))
  }

  return (
    <MyDiv>
      {(authState?.logoutUser?.loading) &&
      <div>
        <Loader />
      </div>
      }
      <Box sx={{flexGrow: 1}}>
        <AppBar position="static" className="custom_header">
          <Toolbar className="custom_toolbar">
            <a href="/"><img src={Logo} alt="Hilco Logo" /></a>
            <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}} className="menu_box">
              {/* {!isresearch ?
                Menus.map((item, index) => {
                  return (
                    <MenuItem className="menuitem" key={index}>
                      {!item.submenu ?
                        <NavLink to={item.link} activeClassName="activeMenu" style={{textDecoration: 'none'}}>{item.menuName}
                        </NavLink> :
                        <Link style={{textDecoration: 'none'}}
                          id={`menu-item-${index}`}
                          onClick={handleClick(index)}
                        >{item.menuName}
                          <KeyboardArrowDownIcon />
                        </Link>
                      }
                      <Menu
                        anchorEl={anchorEl}
                        open={currentElem === index}
                        onClose={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            'overflow': 'visible',
                            'filter': 'drop-shadow(0px 2px 25px #00000029)',
                            'mt': 0,
                            '& .MuiAvatar-root': {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              left: 14,
                              width: 10,
                              height: 10,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0,
                            },
                          },
                        }}
                      >
                        {item?.submenu?.map((submenu, index) => {
                          return (
                            <StyledMenuItem onClick={handleClose} key={index}>
                              <NavLink to={submenu.link} style={{textDecoration: 'none'}}>{submenu.submenuName}
                              </NavLink>
                            </StyledMenuItem>
                          )
                        })}
                      </Menu>
                    </MenuItem>
                  )
                }) : updateMenus.map((item, index) => {
                  return (
                    <MenuItem className="menuitem" key={index}>
                      {!item.submenu ?
                        <NavLink to={item.link} activeClassName="activeMenu" style={{textDecoration: 'none'}}>{item.menuName}
                        </NavLink> :
                        <Link style={{textDecoration: 'none'}}
                          id={`menu-item-${index}`}
                          onClick={handleClick(index)}
                        >{item.menuName}
                          <KeyboardArrowDownIcon />
                        </Link>
                      }
                      <Menu
                        anchorEl={anchorEl}
                        open={currentElem === index}
                        onClose={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            'overflow': 'visible',
                            'filter': 'drop-shadow(0px 2px 25px #00000029)',
                            'mt': 0,
                            '& .MuiAvatar-root': {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              left: 14,
                              width: 10,
                              height: 10,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0,
                            },
                          },
                        }}
                      >
                        {item?.submenu?.map((submenu, index) => {
                          return (
                            <StyledMenuItem onClick={handleClose} key={index}>
                              <NavLink to={submenu.link} style={{textDecoration: 'none'}}>{submenu.submenuName}
                              </NavLink>
                            </StyledMenuItem>
                          )
                        })}
                      </Menu>
                    </MenuItem>
                  )
                })


              } */}
              {
                Menus.map((item, index) => {
                  return (
                    <MenuItem className="menuitem" key={index}>
                      {!item.submenu ?
                        <NavLink to={item.link} activeClassName="activeMenu" style={{textDecoration: 'none'}}>{item.menuName}
                        </NavLink> :
                        <Link style={{textDecoration: 'none'}}
                          id={`menu-item-${index}`}
                          onClick={handleClick(index)}
                        >{item.menuName}
                          <KeyboardArrowDownIcon />
                        </Link>
                      }
                      <Menu
                        anchorEl={anchorEl}
                        open={currentElem === index}
                        onClose={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            'overflow': 'visible',
                            'filter': 'drop-shadow(0px 2px 25px #00000029)',
                            'mt': 0,
                            '& .MuiAvatar-root': {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              left: 14,
                              width: 10,
                              height: 10,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0,
                            },
                          },
                        }}
                      >
                        {item?.submenu?.map((submenu, index) => {
                          return (
                            <StyledMenuItem
                              component={NavLink}
                              to={submenu.link}
                              key={index}
                              onClick={handleClose}
                              disabled={isSubMenuDisabled(item)}
                              style={{textDecoration: 'none'}}
                            >
                              {submenu.submenuName}
                            </StyledMenuItem>
                          )
                        })}
                      </Menu>
                    </MenuItem>
                  )
                })
              }
            </Box>
            <Box className="user_info">
              <Typography className="text_font">Welcome {userName}</Typography>
              <Typography className="text_font">|</Typography>
              <Typography className="logout_user text_font" onClick={handleLogout}>Logout</Typography>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </MyDiv>
  )
}
export default Header
