import styled from 'styled-components'

const MyDiv = styled.div`
margin-top:130px;
margin-bottom: 100px;
position: relative;
.page_heading{
    background: #f6f6f6;
    border-bottom: 1px solid #ddd;
    box-shadow: 0px 2px 2px 0px rgb(50 50 50 / 10%);
    top: 50px;
    position: fixed;
    left: 0;
    z-index: 111;
    width: 100%;
}
.page_heading p{
    margin: 0;
    text-align: left;
    padding: 10px 30px;
    font-size: 20px;
    color: #555;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Arial";
}
.wrapper_box{
    background-color: #fff;
    border: solid 1px #cecece;
    border-radius: 5px;
    padding: 20px;
}

.project_name h6{
    font-size: 15px;
    color: #000;
    font-weight: 500;
    font-family: "Arial";
}
.project_name p{
    font-size: 15px;
    color: #000;
    font-weight: 600;
    font-family: "Arial";
}

.input_box{
    margin-bottom: 15px;
}
.input_box p{
    font-weight: 500;
    color: #555;
    font-size: 14px;
    font-family: "Arial";
}
.select_value{
    font-size: 14px;
}
#select_input{
    min-height: 1.5em;
    font-weight: 500;
    color: #555;
    font-size: 14px;
    font-family: "Arial";
}

.d-flex{
    display: flex;
    column-gap: 10px;
    align-items: center;
}
.btn-flex{
    justify-content: end;
}
.mb-20{
    margin-bottom:20px;
}
.mt-10{
    margin-top:10px;
}
.add_btn{
    justify-content: space-between;
}
.add_btn button{
    padding: 0;
    color: #176de5;
}

  .autoComplete_custom.MuiAutocomplete-hasPopupIcon.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root{
    padding: 1px 39px 1px 4px!important;
  }
  .autoComplete_custom.MuiAutocomplete-hasPopupIcon.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root input{
    font-weight: 500;
    color: #555;
    font-size: 14px;
    font-family: 'Arial'!important;
  }
.text_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.note{
    font-size: 12px!important;
}
.dropdown-menu{
    border: 1px solid #ddd;
    z-index: 1;
    border-bottom: 0;
    width: 100%!important;
    overflow-x: hidden!important;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(1.6em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #555;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control:focus-visible {
    border-color: #1976d2;
    border-width: 2px;
    outline: none!important;
}
a.dropdown-item{
    display: block;
    background: #fff;
    color: #000;
    font-size: 15px;
    font-weight: 400;
    text-decoration: none;
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
}
.rbt-aux {
    align-items: center;
    display: flex;
    bottom: 0;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 2rem;
}
.rbt-aux .rbt-close {
    // margin-top: -0.25rem;
    pointer-events: auto;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 1;
    cursor: pointer;
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}
button.close{
    background-color: transparent;
    border: 0;
}

`
export default MyDiv
