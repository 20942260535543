/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
/* eslint-disable prefer-template */
/* eslint-disable no-fallthrough */
/* eslint-disable radix */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react'
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import styled from 'styled-components'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {decode as base64Decode} from 'base-64'
import {
  getLocationsDataAction, getLocationPriceValuesAction, getProjectByCrmIdAction,
} from '../../redux/home/HomeActions'
import {getAppraiserTrueAction} from '../../redux/admin/AdminActions'
import {CustomBarChart, CustomPieChart, Loader} from '../../components'
import * as routesNames from '../../constants/routes'
import {generateSelected} from '../../utils/Utils'
import MyDiv from './home.style'

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#ecebeb',
    color: '#555',
    fontSize: '15px',
    fontWeight: '600',
    textAlign: 'center',
    fontFamily: 'Arial',
    border: '1px solid #cecece',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#555',
    textAlign: 'center',
    fontFamily: 'Arial',
    borderBottom: '1px solid #cecece',
    borderRight: '1px solid #cecece',
    borderLeft: '1px solid #cecece',
  },
}))
const StyledTableCellBold = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#333',
    textAlign: 'center',
    fontFamily: 'Arial',
    borderBottom: '1px solid #cecece',
    borderRight: '1px solid #cecece',
    borderLeft: '1px solid #cecece',
  },
}))

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fff',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#f6f6f6',
  },
  // hide last border
  //   '&:last-child td, &:last-child th': {
  //     borderBottom: 0,
  //   },
}))

const StyledMenuItem = styled(MenuItem)(() => ({
  '&.MuiMenuItem-root': {
    borderBottom: '1px solid #ccc',
    color: '#000000',
    fontWeight: '400',
    fontSize: '14px',
    fontFamily: 'Arial',
  },
  '&.MuiMenuItem-root:first-child': {
    marginTop: '-8px',
  },
  '&.MuiMenuItem-root:last-child': {
    marginBottom: '-8px',
  },
}))

export default function Home() {

  const dispatch = useDispatch()
  const history = useHistory()
  const [values, setValues] = useState(null)
  const [locationData, setLocationData] = useState()
  const [locationValues, setLocationValues] = useState()

  const homeState = useSelector((state) => state.home)

  const locationAppraisers = locationData?.locationAppraisers // to show all the appraiser data in table
  const locationsDataFull = locationData?.locationsDataFull // to show all the locations data in table
  const locationPriceValues = locationValues?.locationPriceValues // to extract the data of particular location price values like flv, olv etc...
  const projectPriceValues = locationData?.projectPriceValues // to extract the values like flv, olv etc...
  const locationAssets = locationData?.locationsData // to extract the locations data
  const locationNames = locationValues?.locationNames // to extract the locations names

  // Calculate values of Flv and Olv
  let flvData = locationPriceValues?.FLV !== undefined ? Object.entries(locationPriceValues?.FLV) : null
  let olvData = locationPriceValues?.OLV !== undefined ? Object.entries(locationPriceValues?.OLV) : null

  let projectIDCrm = localStorage.getItem('project_id_crm')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string

  // Total Sum for Flvs and Olvs Data
  const totalAssets = locationsDataFull?.reduce((prev, {assets}) => {
    return prev + assets
  }, 0)

  const totalLineAssets = locationsDataFull?.reduce((prev, next) => {
    return prev + (next.assets - next.less_count)
  }, 0)

  const priceValuesData = {
    action: 'getPriceValDataForHome',
    appraiser: null,
    make: null,
    model: null,
    capacity: null,
    area: null,
    project_id_crm: decodedProjectId,
    showOnlyAsset: false,
    sortCol: 'area_order',
    sortOrder: 'asc',
  }

  const locationsData = {
    homepage: 1,
    project_id_crm: decodedProjectId,
    type: 'homeData',
  }

  const appraiserTrue = {
    getAppraisersOnly: true,
    project_id_crm: decodedProjectId,
  }

  const getProjectByCrmId = {
    project_id_crm: decodedProjectId,
  }

  useEffect(() => {
    dispatch(getLocationsDataAction(locationsData))
    dispatch(getProjectByCrmIdAction(getProjectByCrmId))
    dispatch(getLocationPriceValuesAction(priceValuesData))
    dispatch(getAppraiserTrueAction(appraiserTrue))
  }, [dispatch])

  useEffect(() => {
    setLocationData(homeState?.locationsList?.locationsList?.data)
  }, [homeState?.locationsList?.locationsList])

  useEffect(() => {
    setLocationValues(homeState?.locationsPriceValues?.locationsPriceValues?.data)
  }, [homeState?.locationsPriceValues?.locationsPriceValues])

  useEffect(() => {
    if (locationPriceValues && !values) {
      const selected = Object.keys(locationPriceValues)[0]
      setValues(selected)
    }
  }, [locationPriceValues])


  const handleChange = (event) => {
    setValues(event.target.value)
  }

  useEffect(() => {
    localStorage.removeItem('location_id_home')
  }, [])

  const handleAssetList = (locationId) => {
    localStorage.setItem('location_id_home', locationId)
    localStorage.removeItem('lastAssetAddLocation')
    localStorage.removeItem('selectedAppraiser')
    localStorage.removeItem('selectedArea')
    // history.push(routesNames.ASSETLIST)
    history.push({
      pathname: routesNames.ASSETLIST,
      // search: `?location=${locationId}`,
    })

  }

  // for line chart
  const chartData = [['Location', 'Assets', {role: 'style'}]]
  locationAssets?.map((item) => {
    chartData.push([item.location_id.toString(), Number(item.assets), '#3366cc'])
  })

  const optionsBar = {
    title: '',
    width: 500,
    height: 300,
    vAxis: {title: 'Assets', minValue: 50},
    hAxis: {title: 'Location Id', slantedText: false, slantedTextAngle: 15},
    legend: {textStyle: {fontSize: 10}},
    chartArea: {width: '50%', height: '70%'},
  }

  // for pie charts
  const dataPie = [['Major', 'Degrees']]
  locationPriceValues?.[values] && Object.keys(locationPriceValues?.[values])?.map((item) => {
    dataPie.push([locationNames[item] + item, Number(locationPriceValues?.[values][item])])
  })
  const optionsPie = {
    title: '',
    width: 500,
    height: 300,
    chartArea: {width: '100%', height: '70%'},
    tooltip: {
      isHtml: true,
    },
  }
  let data = locationPriceValues ? Object.values(locationPriceValues) : null
  let data1 = locationPriceValues ? Object.keys(locationPriceValues) : null
  let data3 = locationValues?.locationTotalValues ? Object.values(locationValues?.locationTotalValues) : null


  const calculateGoogleMapUri = (payload) => {
    let myUri = 'https://www.google.com/maps/place/'
    if (payload) {
      if (payload.street) myUri += encodeURIComponent(payload.street) + ','
      if (payload.city) myUri += encodeURIComponent(payload.city) + ','
      if (payload.state) myUri += encodeURIComponent(payload.state) + ','
      if (payload.country) myUri += encodeURIComponent(payload.country) + ','
    }
    return myUri
  }
  // useEffect(() => {
  //   if (!locationAppraisers) return
  //   Object?.keys(locationAppraisers)?.forEach((key) => {
  //     console.log(key)
  //     locationAppraisers[key] === '17460' ? console.log('fuck') : console.log('nah')
  //   })
  // }, [locationAppraisers])
  return (
    <MyDiv>
      {(homeState?.locationsList?.loading || homeState?.locationsPriceValues?.loading) &&
        <div>
          <Loader />
        </div>
      }
      <Box className="page_heading">
        <Typography>PROJECT: {locationsDataFull?.[0].project_name}</Typography>
      </Box>
      <Grid container columnSpacing={{sm: 8, md: 8}}>
        <Grid item md={6}>
          <Box className="chart_box">
            <Typography>Location wise Assets</Typography>
            <CustomBarChart data={chartData} options={optionsBar} />
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box className="chart_box">
            <Typography>Location wise Values</Typography>
            <FormControl size="small" className="select_form">
              <Select
                id="select_input"
                className="select_value"
                value={values}
                onChange={handleChange}
              >
                {projectPriceValues?.map((item, index) => {
                  return (
                    <StyledMenuItem key={index} value={item}>
                      {item}
                    </StyledMenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <CustomPieChart data={dataPie} options={optionsPie} />
          </Box>
        </Grid>
      </Grid>
      <TableContainer className="custom_table">
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Location [Location Id]</StyledTableCell>
              <StyledTableCell>No. of Assets (Individual)</StyledTableCell>
              <StyledTableCell>No. of Assets (Line)</StyledTableCell>
              <StyledTableCell>Appraisers</StyledTableCell>
              {data1?.map((item1, index) => {
                return (
                  <StyledTableCell>{item1}($)</StyledTableCell>
                )
              })
              }
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {locationsDataFull?.map((item, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    <Box className="location_cell">
                      <Box>
                        <a target="_BLANK" href={calculateGoogleMapUri(item)}>
                          <LocationOnIcon />
                        </a>
                      </Box>
                      <Box>

                        <Box>{item.name} <b>[{item.location_id}]</b></Box>
                        <Box className="location_add">{item.street} {item.city} {item.state} {item.country} </Box>
                      </Box>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>{item.assets?.toLocaleString('en-US')}</StyledTableCell>
                  <StyledTableCell>{(item.assets - item.less_count)?.toLocaleString('en-US')}</StyledTableCell>
                  <StyledTableCell sx={{width: '25%'}} key={item.location_id}>
                    {locationAppraisers && locationAppraisers[item?.location_id] ? Object?.entries(locationAppraisers?.[item?.location_id])?.map(([key, value], index, array) => {
                      if (value !== ' ') {
                        return (
                          <span style={{padding: '10px 2px'}} key={key}>{value}
                            {index === array.length - 1 ? '' : ', '}
                          </span>
                        )
                      }
                    }) : null}
                  </StyledTableCell>
                  {data?.map((item1, index) => {
                    return (
                      <StyledTableCell>{item1[item.location_id]?.toLocaleString('en-US')} </StyledTableCell>
                    )
                  })
                  }
                  <StyledTableCell>
                    <IconButton className="list_icon" onClick={() => handleAssetList(item.location_id)}>
                      <FormatListBulletedIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              )
            }
            )}
            <StyledTableRow>
              <StyledTableCellBold>Total for all locations</StyledTableCellBold>
              <StyledTableCellBold>{totalAssets?.toLocaleString('en-US')}</StyledTableCellBold>
              <StyledTableCellBold>{totalLineAssets?.toLocaleString('en-US')}</StyledTableCellBold>
              <StyledTableCellBold />
              {data3?.map((item1, index) => {
                return (
                  <StyledTableCellBold>${item1?.toLocaleString('en-US')}</StyledTableCellBold>
                )
              })
              }
              <StyledTableCellBold />
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </MyDiv>
  )
}
