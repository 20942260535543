import styled from 'styled-components'

const MyDiv = styled.div`
margin-top:110px;
margin-bottom: 100px;
position: relative;
.page_heading{
    background: #f6f6f6;
    border-bottom: 1px solid #ddd;
    box-shadow: 0px 2px 2px 0px rgb(50 50 50 / 10%);
    top: 50px;
    position: fixed;
    left: 0;
    z-index: 111;
    width: 100%;
    display:flex;
    align-items:center;
    font-size:14px;
    justify-content: space-between;
}
.imageBox{
    margin-left:27px;
    // width: 30% !important;
}
.submenu{
    padding:0px 10px;
}
.image_row{
    margin-left:0px;
    // flex-basis: 25% !important;
    // -webkit-box-flex: 0 !important;
    // flex-grow: 0 !important;
    // max-width: 8.33% !important;
}
.page_heading p{
    margin: 0;
    text-align: left;
    padding: 10px 10px;
    font-size: 20px;
    color: #555;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Arial";
}
.wrapper_box{
    background-color: #fff;
    border: solid 1px #cecece;
    border-radius: 5px;
    padding: 10px 10px;
}

.project_name h6{
    font-size: 15px;
    color: #000;
    font-weight: 500;
    font-family: "Arial";
}
.project_name p{
    font-size: 15px;
    color: #000;
    font-weight: 600;
    font-family: "Arial";
}

.input_box{
    margin-bottom: 10px;
}
.input_box p{
    font-weight: 500;
    color: #555;
    font-size: 14px;
    font-family: "Arial";
}
.form_control{
    width: 50%;
}
.mileage_control{
    width: 250px;
}
.select_value{
    font-size: 14px;
}
#select_input{
    min-height: 1.5em;
    font-weight: 500;
    color: #555;
    font-size: 14px;
    font-family: "Arial";
    padding: 4px 10px!important;
}
.d-flex{
    display: flex;
    column-gap: 10px;
    align-items: center;
}
.checked_label .MuiFormControlLabel-label{
    font-weight: 500;
    color: #555;
    font-size: 14px;
    font-family: "Arial";
}
.checked_label {
    margin-right: 15px;
}
.checked_label:last-child {
    margin-right: 0px;
}
.btn-flex{
    justify-content: end;
}
.mb-20{
    margin-bottom:20px;
}
.mt-10{
    margin-top:10px;
}
.add_btn{
    justify-content: space-between;
}
.add_btn button{
    padding: 0;
    color: #176de5;
}
.icon{
    color: #000;
   }

.box_actions{
    justify-content: space-between;
    margin-bottom: 10px;
    width: 51%;
   }
.notes_special{
    margin-top: 18px;
}

  .autoComplete_custom .MuiTextField-root .MuiOutlinedInput-root{
    padding: 1px 39px 1px 4px!important;
  }

  .autoComplete_custom .MuiTextField-root .MuiOutlinedInput-root input{
    font-weight: 500;
    color: #555;
    font-size: 14px;
    font-family: 'Arial'!important;
    padding: 7.5px 4px 7.5px 6px;
  }
  .text_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.note{
    font-size: 12px!important;
}
.dropdown-menu{
    border: 1px solid #ddd;
    z-index: 1;
    border-bottom: 0;
    width: 100%!important;
    overflow-x: hidden!important;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(1.1em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #555;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control:focus-visible {
    border-color: #1976d2;
    border-width: 2px;
    outline: none!important;
}
a.dropdown-item{
    display: block;
    background: #fff;
    color: #000;
    font-size: 15px;
    font-weight: 400;
    text-decoration: none;
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
}
.rbt-aux {
    align-items: center;
    display: flex;
    bottom: 0;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 2rem;
}
.rbt-aux .rbt-close {
    // margin-top: -0.25rem;
    pointer-events: auto;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 1;
    cursor: pointer;
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}
button.close{
    background-color: transparent;
    border: 0;
}
.list_btn{
    color: #000;
}
.d-flex-link{
    display: flex;
    justify-content: end;
    margin-top: 20px;
}
.btn_link {
    background: #176de5;
    font-size: 14px;
    text-transform: capitalize;
    font-family: "Arial";
    padding: 8px;
    border-radius: 4px;
    color: #fff;
    text-decoration: none;
    line-height: 1.75;
}
.w-50{
    width: 50%;
}
.project_title{
    font-size: 14px;
    font-weight: 400;
    font-family: "Arial";
}
.f-600{
    font-weight: 600;
}
.project_head{
    padding: 0px 10px;
}
.add_btn_icon svg{
    width: 0.8em;
    height: 0.8em;
}
.placeholder_text input{
    height: 0.9em;
}
.checked_label span svg {
    width: 0.8em;
    height: 0.8em;
}
.wrapper{
    height: 60vh;
    overflow-y: scroll;
}
.wrapper .accordion_box {
    margin-right: 20px;
}
.accordion_box{
    border: 1px solid #cecece;
    box-shadow: none;
    margin-bottom: 15px !important;
    border-radius: 4px !important;
    background-color: #f5f5f5;
}
.accordion_box::before{
    background-color: transparent;
}
.accordion_details{
    background: #fff;
    border-top: 1px solid #cecece;
    padding: 15px 16px 16px;
}
// .img-wrapper {
//     overflow: hidden;
//     position: relative;
// }
.fullSizeImage{
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 35vw;
    align-items: center;
}

.img-wrapper img:{
    padding:20px;
    position: fixed;
    top: 0;
    left: 0;
    // width: 60vw;
    // height: 60vh;
    object-fit: contain; /* Adjust to your preference: contain, cover, etc. */
    z-index: 9999; /* Ensure it's on top of other elements */
    cursor: pointer; /* Change cursor on hover */
}
.list_item{
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 3px 5px 0 #eee;
    padding: 5px;
    margin-bottom: 15px;
    position: relative;
}
.list_item:last-child{
    margin-bottom: 18px;
}
.list_item .label_name{
    margin: 0;
}
.list_item .label_name span{
    font-weight: 500;
    color: #333;
    // font-size: 11px;
    font-family: "Arial";
}
.list_item button{
    color: #fff;
    position: absolute;
    right: -12px;
    top: -10px;
    background: #000;
    padding: 4px;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px;
}
.list_item button svg {
    width: 20px;
    height: 20px;
}
.list{
    padding: 0;
    display:block;
    text-align:center;
}
.list_item img{
    width: 100%;
    height: auto;
    backgound-size:contain;
    // object-fit:cover;
}
.label_title{
    font-weight: 600;
    color: #333;
    font-size: 16px;
    font-family: "Arial";
}
.accordion_item .MuiAccordionSummary-content{
    display: block;
    margin: 5px 0px;
}
.checked_label .MuiFormControlLabel-label{
    font-weight: 500;
    color: #555;
    font-size: 14px;
    font-family: "Arial";
}
.accordion_box .MuiAccordionSummary-root.Mui-expanded{
    min-height:48px;
}


`
export default MyDiv
