import axios from 'axios'
const url = process.env.REACT_APP_API_URL
const apiInstance = axios.create({
  baseURL: url,
  crossDomain: false,
})
apiInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token')
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})

export default apiInstance
