/* eslint-disable no-unused-vars */
/* eslint-disable no-duplicate-imports */
import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import {Autocomplete, Box, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, MenuItem, Radio, Select, TextField, Typography} from '@mui/material'
import {Close} from '@mui/icons-material'
import {Typeahead} from 'react-bootstrap-typeahead'
import styled from 'styled-components'
import {CustomTextBox, CustomButton} from '../../../../../components'
import MyDiv from './modelEditDialog.style.js'

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

const StyledMenuItem = styled(MenuItem)(() => ({
  '&.MuiMenuItem-root': {
    borderBottom: '1px solid #ccc',
    color: '#000000',
    fontWeight: '400',
    fontSize: '14px',
    fontFamily: 'Arial',
  },
  '&.MuiMenuItem-root:first-child': {
    marginTop: '-8px',
  },
  '&.MuiMenuItem-root:last-child': {
    marginBottom: '-8px',
  },
}))

const ITEM_HEIGHT = 60
const Menu = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
    },
  },
}

export default function ModelEditDialog(props) {
  const popup = (popupbox) => (
    <MyDiv>
      <Box className="dialog_box">
        <Box className="dialog_header">
          <DialogTitle className="dialog_title">Resolve</DialogTitle>
          <IconButton className="icon" onClick={props.handleClosePopup}><Close /></IconButton>
        </Box>
        <Divider />
        <DialogContent className="dialog_content">
          <Typography className="heading">Model Power bi: {props?.filterModelList?.model_power_bi}</Typography>
          <Box className="input_box">
            <Typeahead
              id="basic-typeahead"
              clearButton
              // ref={makeRef}
              labelKey={(option) => option?.make?.toString() || ''}
              options={props.makeList}
              placeholder="Search Make"
              selected={props?.inputValues?.selectedMake} // we are expecting it in the format [{key:key_value, value: value}]
              size="large"
              onChange={(selected) => props.handleSelected(selected, 'make')}
            />
          </Box>
          <Box className="radio_item" mb={2}>
            <FormControlLabel className="checked_label"
              control={<Radio name="radio-buttons" value="1" onChange={(e) => props.handleCheckbox(e)} checked={props.checkValue === '1'} />} label="Choose Existing Model"
            />
            <Typeahead
              id="basic-typeahead"
              clearButton
              // ref={makeRef}
              labelKey={(option) => option?.model?.toString() || ''}
              options={props?.filterMakeModelList}
              placeholder="Search Make"
              selected={props?.inputValues?.selectedModel} // we are expecting it in the format [{key:key_value, value: value}]
              size="large"
              disabled={props.checkValue !== '1'}
              onChange={(selected) => props.handleSelected(selected, 'model')}
            />
          </Box>
          <Box className="radio_item" mb={2}>
            <FormControlLabel className="checked_label"
              control={<Radio name="radio-buttons" value="2" onChange={(e) => props.handleCheckbox(e)} checked={props.checkValue === '2'} />} label="Add New Model"
            />
            <CustomTextBox value={props?.values?.isAdd} disabled={props.checkValue !== '2'} name="isAdd" onChange={props.handleInputChange} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box className="d-flex btn-flex mb-20 mt-10">
            <CustomButton
              title="Save"
              variant="contained"
              className="btn_theme"
              onClick={props.handleSubmit}
            />
            <CustomButton
              title="Cancel"
              variant="contained"
              className="btn_theme"
              onClick={props.handleClosePopup}
            />
          </Box>
        </DialogActions>
      </Box>
    </MyDiv>
  )
  return (
    <Dialog
      popupbox="openDialog"
      open={props.editPopup}
      TransitionComponent={Transition}
      onClose={props.handleClosePopup}
      fullWidth
    >
      {popup('openDialog')}
    </Dialog>
  )
}
