import React from 'react'
import AddBoxIcon from '@mui/icons-material/AddBox'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
export default function SwitchIcon(props) {

  const handleSelect = () => {
    props.handleSelect()
  }
  return (
    <>
      {props.showIcon ? <IndeterminateCheckBoxIcon onClick={handleSelect} /> : <AddBoxIcon onClick={handleSelect} />
      }
    </>
  )
}

