/* eslint-disable camelcase */
import React, {useState, useEffect} from 'react'
import 'jspdf-autotable'
import {kaReducer} from 'ka-table'
import {
  DataType,
  SortingMode,
  EditingMode,
} from 'ka-table/enums'
import {useDispatch} from 'react-redux'
import {Box} from '@mui/material'
import {AlertMessage, CustomButton, CustomTable} from '../../../../../components'
import {deleteSerialLabelAction, postSerialLabelAction} from '../../../../../redux/admin/AdminActions'
import {adminListActions} from '../../../../../redux/admin/admin'
import MyDiv from './serialLabelsTable.style'

export default function SerialLabelsTable(props) {

  const tablePropsInit = {
    columns: [
      {
        key: 'selection-cell',
        style: {width: '2%'},
      },
      {
        dataType: DataType.String,
        key: 'name',
        style: {width: 120},
        title: 'Name',
        isEditable: true,
      },
      {
        dataType: DataType.String,
        key: 'created_on',
        style: {width: 120},
        title: 'Created On',
        isEditable: false,
      },
    ],
    selectedRows: [],
    data: props.serialLabelList,
    rowKeyField: 'serial_label_id',
    height: '75vh',
    sortingMode: SortingMode.Single,
    editingMode: EditingMode.Cell,
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: 250,
      pageSizes: [250, 500, 1000],
    },
  }

  const [serialLabelTable, setSerialLabelTable] = useState(tablePropsInit)
  const dispatch = useDispatch()
  // const adminState = useSelector((state) => state.admin)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')

  useEffect(() => {
    if (props.searchValue !== '') {
      const result = props.serialLabelList.filter((item) => {
        return item?.name?.toLowerCase().includes(props.searchValue.toLowerCase())
      })
      setSerialLabelTable({...tablePropsInit, data: result})
    } else {
      setSerialLabelTable({...tablePropsInit, data: props.serialLabelList})
    }
  }, [props.serialLabelList])

  // useEffect(() => {
  //   setSerialLabelTable(tablePropsInit)
  // }, [props.serialLabelList])

  useEffect(() => {
    return () => {
      dispatch(adminListActions.clearSelectedItems()) // clearing the global state of checkboxes for every component
    }
  }, [])

  let userId = localStorage.getItem('userId')

  const CheckValue = (action, prevState) => {
    const previousValue = prevState.data.find(({serial_label_id}) => +serial_label_id === action.rowKeyValue)
    let inputValues = action.value
    let flag
    if (previousValue[action.columnKey].toLowerCase() === inputValues.toLowerCase()) {
      flag = true
    } else {
      flag = false
    }

    if (flag) {
      return previousValue[action.columnKey]

    } else {
      return inputValues
    }
  }

  const dispatchResearch = (action) => {
    setSerialLabelTable((prevState) => {
      if (action.value !== '') {
        if (action.type === 'UpdateCellValue') {
          let obj = {
            modified_by: Number(userId),
            name: CheckValue(action, prevState),
            serial_label_id: action.rowKeyValue,
          }
          dispatch(postSerialLabelAction(obj, 'edit'))
        }
      } else {
        // setMessage('Please Enter Serial Label')
        // setStatus('warning')
        props.handleMessageData('Please Enter Serial Label', 'warning')
        return prevState
      }
      return kaReducer(prevState, action)
    })
  }

  const handleDelete = () => {
    if (serialLabelTable?.selectedRows?.length > 0) {
      let obj = {
        action: 'deleteList',
        serial_label_id: serialLabelTable.selectedRows.join('^'),
      }
      dispatch(deleteSerialLabelAction(obj))
      dispatch(adminListActions.clearSelectedItems())
    } else {
      setMessage('Please select at least one Serial Label.')
      setStatus('warning')
    }
  }

  const handleCancel = () => {
    setSerialLabelTable({...tablePropsInit, paging: serialLabelTable?.paging, selectedRows: []})
  }

  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    dispatch(adminListActions.clearMessageData())
  }

  return (
    <MyDiv>
      <CustomTable
        tableProps={serialLabelTable}
        dispatch={dispatchResearch}
      />
      <Box className="d-flex bottom_actions">
        <CustomButton title="Delete" className="btn_theme" variant="contained" onClick={handleDelete} />
        <CustomButton title="Cancel" className="btn_reset" variant="contained" onClick={handleCancel} />
      </Box>
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
