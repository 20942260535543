import styled from 'styled-components'

const MyDiv = styled.div`
margin-top:50px;
margin-bottom: 100px;
position: relative;
.page_heading{
    background: #f6f6f6;
    border-bottom: 1px solid #ddd;
    box-shadow: 0px 2px 2px 0px rgb(50 50 50 / 10%);
    top: 50px;
    position: static;
    left: 0;
    z-index: 111;
    width: 100%;
    display:flex;
    flex-direction:row;
    padding-top: 20px;
    padding-right: 10px;
    justify-content:space-between;
}
.page_heading p{
    margin: 0;
    text-align: left;
    padding: 10px 30px;
    font-size: 20px;
    color: #555;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Arial";
}
.column_setting{
    color: #000;
    position: absolute;
    right: 0;
    z-index: 111;
    margin-right:0.3em;
}
.ka-cell{
    padding: 5px 10px!important;
}
`
export default MyDiv
