/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react'
import {
  Box,
  FormControl,
  Grid,
  Select,
  Typography,
  MenuItem,
} from '@mui/material'
import styled from 'styled-components'
import {decode as base64Decode} from 'base-64'
import {useDispatch, useSelector} from 'react-redux'
import {
  getLocationAction, getAppraiserTrueAction, getAreaListAction, deleteAreaAction,
} from '../../../redux/admin/AdminActions'
import {adminListActions} from '../../../redux/admin/admin'
import {AlertMessage, CustomButton, Loader} from '../../../components'
import {getAssetListDataAction} from '../../../redux/assets/AssetsActions'
import {ManageTable} from './components'
import MyDiv from './manageArea.style'

const StyledMenuItem = styled(MenuItem)(() => ({
  '&.MuiMenuItem-root': {
    borderBottom: '1px solid #ccc',
    color: '#000000',
    fontWeight: '400',
    fontSize: '14px',
    fontFamily: 'Arial',
  },
  '&.MuiMenuItem-root:first-child': {
    marginTop: '-8px',
  },
  '&.MuiMenuItem-root:last-child': {
    marginBottom: '-8px',
  },
}))

export default function ManageArea() {

  const dispatch = useDispatch()
  const [locationValue, setLocationValue] = useState('0')
  const [locationData, setLocationData] = useState()
  // const [areaList, setAreaList] = useState([])
  const [locationId, setLocationId] = useState()
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const adminState = useSelector((state) => state.admin)
  const areaList = adminState?.areaList?.areaList

  const locationsList = locationData?.locationsDataFull

  let projectIDCrm = localStorage.getItem('project_id_crm')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string

  const appraiserTrue = {
    getAppraisersOnly: true,
    project_id_crm: decodedProjectId,
  }


  useEffect(() => {
    // remove arealist data
    dispatch(adminListActions.clearAreaList())
  }, [])
  useEffect(() => {
    dispatch(getLocationAction({project_id_crm: decodedProjectId}))
    dispatch(getAppraiserTrueAction(appraiserTrue))
  }, [dispatch])

  useEffect(() => {
    setLocationData(adminState?.locationList?.locationList?.data)
  }, [adminState?.locationList?.locationList])

  useEffect(() => {
    //
    if (adminState?.deleteArea?.loading === false && locationId) {
      let obj = {
        location_id: locationId.toString(),
      }
      let projectID = localStorage.getItem('project_id')

      let areaData = {
        action: 'getArea',
        project_id: Number(projectID),
      }
      dispatch(getAreaListAction(obj))
      dispatch(getAssetListDataAction(areaData))
    }
  }, [adminState?.deleteArea?.loading])
  // useEffect(() => {
  // setAreaList(adminState?.areaList?.areaList)
  // }, [adminState?.areaList?.areaList])

  useEffect(() => {
    if (adminState?.messageData.status === 'success') {
      setMessage(adminState?.messageData?.message)
      setStatus('success')
    }
    if (adminState?.messageData.status === 'failed') {
      setMessage(adminState?.messageData?.message)
      setStatus('warning')
    }
  }, [adminState?.messageData])

  const handleChange = (event) => {
    setLocationValue(event.target.value)
    if (event.target.value === '0') {
      dispatch(adminListActions.clearAreaList())
    }
  }

  const handleClick = (id) => {
    setLocationId(id)
    let obj = {
      location_id: id.toString(),
    }
    dispatch(getAreaListAction(obj))
  }

  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    dispatch(adminListActions.clearMessageData())
  }


  return (
    <MyDiv>
      {(adminState?.locationList?.loading || adminState?.areaList?.loading || adminState?.deleteArea?.loading) &&
      <div>
        <Loader />
      </div>
      }
      <Box className="page_heading">
        <Typography>Manage Area</Typography>
      </Box>
      <Box className="admin_card">
        <Grid container className="main_container">
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Select Areas By Location</Typography>
              <FormControl size="small" className="select_area">
                <Select
                  id="select_input"
                  className="select_value"
                  value={locationValue}
                  fullWidth
                  onChange={handleChange}
                >
                  <StyledMenuItem value="0">Please Select</StyledMenuItem>
                  {locationsList?.map((item, index) => {
                    return (
                      <StyledMenuItem key={index} value={item.location_id} onClick={() => handleClick(item.location_id)}>
                        {item.name} {item.location_id}
                      </StyledMenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {areaList?.length > 0 ?
        <ManageTable areaList={areaList} locationId={locationId} /> :
        <Box className="no-data">
          <Typography>No Data Available</Typography>
        </Box>
      }
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
