import {getModelList} from '../../../services/adminService'
import {adminListActions} from '../admin'

const getNewModelListAction = () => {
  return (dispatch) => {
    dispatch(adminListActions.newModelListRequest())
    getModelList().then((res) => {
      dispatch(adminListActions.newModelListSuccess(res))
    }).catch((error) => {
      dispatch(adminListActions.newModelListFailure(error))
    })
  }
}

export default getNewModelListAction
