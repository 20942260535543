/* eslint-disable */
import React from 'react';
import { Checkbox } from '@mui/material';
// import {deselectAllFilteredRows, selectAllFilteredRows} from 'ka-table/actionCreators'
import { useDispatch } from 'react-redux';
import {
  deselectRow,
  selectRow,
  selectRowsRange,
} from 'ka-table/actionCreators';
// import { assetsListActions } from '../../../../../redux/assets/assets';
import { assetsListActions } from '../../../../../redux/assets/assets';
import { adminListActions } from '../../../../../redux/admin/admin';
import { reportListActions } from '../../../../../redux/reports/report';
import { researchListActions } from '../../../../../redux/research/research';
// import {useLocation} from 'react-router-dom'

// ! just a hotfix and will remove this in phase 2. We are 2 days from moving to prod, don't want to break things that are already working right now
export default function TempSelectionLine(props) {
  console.log("🚀 ~ assetsListActions:", assetsListActions)
  const reduxDispatch = useDispatch();

  const selectionList = [props.rowData.type_id];

  const handleChange = (event) => {
        props.rows.map((item) => {
      if (item.parent_line === props.rowData.type_id) {
        selectionList.push(item.type_id)
      }
    })
    if (event.nativeEvent.shiftKey) {
      dispatch(selectRowsRange(rowKeyValue, [...selectedRows].pop()));
    } else if (event.currentTarget.checked) {

      reduxDispatch(
        reportListActions.tempSetSelectedItems({ items: selectionList, type: 'add' })
      );

      // dispatch(selectRow(rowKeyValue))
      selectionList.forEach((element) => {
        //! https://stackblitz.com/edit/table-selection-ts-1dy6az?file=Demo.tsx
        props.dispatch(selectRow(element)); // !since ka tables selectSelectedRows method is not working, sticking to one at a time
      });
    } else {
      reduxDispatch(
        reportListActions.tempSetSelectedItems({ items: selectionList, type: 'remove' })
      );

      // dispatch(deselectRow(rowKeyValue))
      selectionList.forEach((element) => {
        props.dispatch(deselectRow(element)); //! same in case of deselecting
      });
    }
  };
  return (
    <Checkbox checked={props.isSelectedRow} onChange={handleChange} />
  );
}
