import apiInstance from '../config/api/axios'
//get line list assets
const fetchLineAssetList = async(id) => {
  try {
    const response = await apiInstance.get(`line-assets/${id}`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}
//all line actions assets
const editLineAsset = async(payload) => {
  let id = payload.id
  try {
    const response = await apiInstance.post(`line-assets?id=${id}`, payload)
    return response.data
  } catch (error) {
    console.log(error)
  }
}
// GET Lines List
const fetchLinesList = async(payload) => {
  try {
    const response = await apiInstance.get(`line-detail?project_id=${payload.project_id}`)
    return response.data.lineDetails

  } catch (error) {
    console.log(error)
  }
}
// GET assets List
const fetchAssetDetails = async(payload) => {
  try {
    if (payload?.flag === 'lineEdit') {
      const response = await apiInstance.get(`asset-detail?id=${payload.assetId}&location_id=${payload?.location_id}&project_id_crm=${payload?.project_id_crm}&type=${payload.type}`, payload)
      return response
    }
    if (payload?.flag === 'addAsset') {
      const response = await apiInstance.get(`asset-detail?location_id=${payload?.location_id}&project_id_crm=${payload?.project_id_crm}`, payload)
      return response
    }
    if (payload?.is_research === 1) {
      const response = await apiInstance.get('asset-detail?is_research=1', payload)
      return response
    }
    if (payload) {
      if (payload?.type) {
        const response = await apiInstance.get(`asset-detail?location_id=${payload?.location_id}&project_id_crm=${payload?.project_id_crm}&type=${payload.type}`, payload)
        return response
      } else if (!payload?.type) {
        const response = await apiInstance.get(`asset-detail?id=${payload.assetId}&location_id=${payload?.location_id}&project_id_crm=${payload?.project_id_crm}`, payload)
        return response
      }
    } else {
      const response = await apiInstance.get('asset-detail')
      return response?.data
    }

  } catch (error) {
    console.log(error)
  }
}

const getLineById = async(payload) => {
  try {
    const res = await apiInstance.get(`asset-detail?id=${payload.id}&location_id=${payload.location_id}&project_id_crm=${payload.project_id}&type=line`)
    return res.data
  } catch (error) {
    throw error
  }
}
// GET Asset Type List
const getAssetTypeList = async() => {
  try {
    const response = await apiInstance.get('common?type=assetTypeOnly')
    return response.data.assetsList
  } catch (error) {
    console.log(error)
  }
}

// GET make and capacity List
const fetchMakeCapacityList = async(payload) => {
  try {
    const response = await apiInstance.get(`common?project_id_crm=${payload.project_id_crm}&type=${payload.type}`, payload)
    return response

  } catch (error) {
    console.log(error)
  }
}

// GET Line By Area List
const fetchAreaByLineList = async(payload) => {
  try {
    const response = await apiInstance.get(`line-by-area?area=${encodeURIComponent(payload?.area)}&location_id=${payload?.location_id}`, payload)
    return response?.data?.lineList

  } catch (error) {
    console.log(error)
  }
}

// Post Line Detail
const postLineDetail = async(payload) => {
  try {
    const response = await apiInstance.post('line-detail', payload)
    return response
  } catch (error) {
    console.log(error)
  }
}


export {fetchLinesList, editLineAsset, fetchLineAssetList, fetchAssetDetails, getAssetTypeList, fetchMakeCapacityList, postLineDetail, fetchAreaByLineList, getLineById,
}
