/* eslint-disable guard-for-in */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable no-alert */
import React, {useState, useEffect} from 'react'
import {
  Box,
  Grid,
  Typography,
  MenuItem,
  FormControl,
  Select,
  IconButton,
  ListItem,
  Checkbox,
  ListItemText,
  FormControlLabel,
} from '@mui/material'
import {Add, Close} from '@mui/icons-material'
import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import {useParams, useHistory} from 'react-router-dom'
import {Typeahead} from 'react-bootstrap-typeahead'
import {decode as base64Decode} from 'base-64'
import {
  AlertMessage,
  ConfirmationAlert,
  CustomButton,
  CustomTextArea,
  CustomTextBox,
  Loader,
} from '../../../components'
import AddAssetDialog from '../../AddAssetDialog'
import {getLocationsDataAction} from '../../../redux/home/HomeActions'
import {getAssetDetailsAction, addAssetTypeAction, getMakeCapacityAction, postLineDetailAction, editLineAssetAction} from '../../../redux/lines/LineActions'
import {lineListActions} from '../../../redux/lines/line'
import {fetchAssetDetails, fetchLineAssetList, getLineById} from '../../../services/lineServices'
import {deletePhotosToAssets, fetchPhotosByAssetId, postPhotosToAssets} from '../../../services/assetListService'
import FullSizeImagePopUp from '../../Assets/AddAssets/fullSizeImage'
import {condition} from '../../../constants/constant'
import {copyLineAction} from '../../../redux/lines/LineActions/copyLineAction'
import MyDiv from './editLine.style'
import EditLineTable from './EditLineTable/editLineTable'

const StyledMenuItem = styled(MenuItem)(() => ({
  '&.MuiMenuItem-root': {
    borderBottom: '1px solid #ccc',
    color: '#000000',
    fontWeight: '400',
    fontSize: '14px',
    fontFamily: 'Arial',
  },
  '&.MuiMenuItem-root:first-child': {
    marginTop: '-8px',
  },
  '&.MuiMenuItem-root:last-child': {
    marginBottom: '-8px',
  },
}))

const ITEM_HEIGHT = 60
const Menu = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
    },
  },
}

// const condition = [
//   {
//     id: 1,
//     name: 'New',
//   },
//   {
//     id: 2,
//     name: 'Excellent',
//   },
//   {
//     id: 3,
//     name: 'Very Good',
//   },
//   {
//     id: 4,
//     name: 'Good',
//   },
//   {
//     id: 5,
//     name: 'Fair',
//   },
//   {
//     id: 6,
//     name: 'Poor',
//   },
//   {
//     id: 7,
//     name: 'NA',
//   },
//   {
//     id: 8,
//     name: 'Scrap',
//   },
//   {
//     id: 9,
//     name: 'Salvage',
//   },
// ]

export default function EditLine(props) {

  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const [inputValues, setInputValues] = useState({})
  console.log('🚀 ~ EditLine ~ inputValues:', inputValues)
  const [lineValues, setLineValues] = useState({})
  const [openDialog, setOpenDialog] = useState(false)
  const [locationData, setLocationData] = useState()
  const [assetData, setAssetData] = useState([])
  const [locationId, setLocationId] = useState()
  const [addValue, setAddValue] = useState('')
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [type, setType] = useState(null)
  const [rowId, setRowId] = useState(null)
  const [assetPhotosCount, setAssetPhotosCount] = useState(0)
  const [assetPhotos, setAssetPhotos] = useState([])
  const [showFullImage, setShowFullImage] = useState(false)
  const [imagePath, setImagePath] = useState()
  const [value, setValue] = useState([])
  const [filteredAssetTypes, setFilteredAssetTypes] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [isLoading2, setLoading2] = useState(false) // ? just till we get rid of the redux hell

  // const [isAction, setAction] = useState(true)
  const [lineAssetList, setLineAssetList] = useState()
  const homeState = useSelector((state) => state.home)
  const lineState = useSelector((state) => state.line)
  const url = process.env.REACT_APP_IMAGE_BASE_URL

  const makeList = lineState?.makeCapacityList?.makeCapacityList?.data?.makeList
  const assetTypes = assetData?.assetTypeList

  const locationsDataFull = locationData?.locationsDataFull
  const appraiserList = assetData?.appraiserList
  let userId = localStorage.getItem('userId')
  let projectIDCrm = localStorage.getItem('project_id_crm')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string
  const data = props?.location?.state?.data
  let makeCapacityData = {
    type: 'getMakeCapacityList',
    project_id_crm: decodedProjectId,
  }

  let locationsData = {
    type: 'locationData',
    project_id_crm: decodedProjectId,
  }

  const getLineAssetList = () => {
    if (params.id !== undefined) {
      localStorage.setItem('type_id', data?.parentRowData?.type_id)
      fetchLineAssetList(params.id).then((res) => {
        setLineAssetList(res)
      })
    }
  }

  useEffect(() => {
    dispatch(getLocationsDataAction(locationsData))
    dispatch(getMakeCapacityAction(makeCapacityData))
  }, [dispatch])

  useEffect(() => {
    setLocationData(homeState?.locationsList?.locationsList?.data)
  }, [homeState?.locationsList?.locationsList])
  useEffect(() => {
    if (lineAssetList) return //! disable api call if we already have the data
    getLineAssetList()
  }, [lineAssetList])

  useEffect(() => {
    if (data) {
      setLoading(true)
      let assetData = {
        location_id: data ? data?.parentRowData?.location_id : locationsDataFull?.[0]?.location_id?.toString(),
        type: 'line',
        project_id_crm: decodedProjectId,
        assetId: data?.parentRowData?.type_id,
        flag: 'lineEdit',
      }
      if (locationsDataFull?.[0]?.location_id !== undefined) {
        setLocationId(locationsDataFull?.[0]?.location_id)
      }
      fetchAssetDetails(assetData).then((response) => {
        setAssetData(response?.data)
        getLineById({id: data?.parentRowData?.type_id, location_id: data?.parentRowData?.location_id, project_id: decodedProjectId}).then((res) => {
          const dataToEdit = res?.assetDetails
          const updatedData = {...dataToEdit}
          const valueCode = res.assetDetails.value_code?.split('#')?.map((item) => {return item})
          const values = res.assetDetails.value?.split('#')?.map((item, index) => {return {[valueCode[index]]: item}})
          const finalValues = values?.reduce((acc, item) => ({...acc, ...item}), {})
          const localAssetTypes = response?.data?.assetTypeList
          const localFilteredAssetTypes = localAssetTypes?.length > 0 && localAssetTypes[0]?.asset_type === 'Select or search asset type' ? localAssetTypes?.slice(1) : localAssetTypes
          setFilteredAssetTypes(localFilteredAssetTypes)
          Object.keys(res?.assetDetails).forEach((i) => {
            if (i == 'make') updatedData.selectedMake = [{make: dataToEdit?.make, make_id: dataToEdit?.make_id}]
            if (i == 'asset_type_id' && dataToEdit[i]) {
              const foundAsset = localFilteredAssetTypes?.find((i) => +i.asset_type_id === +dataToEdit.asset_type_id)
              updatedData.selectedAssetType = [{asset_type_id: dataToEdit.asset_type_id, asset_type: foundAsset?.asset_type}]
            }
          })
          setInputValues({...updatedData, ...finalValues})
          setLoading(false)
        })
      })
      fetchPhotosByAssetId('line', data?.parentRowData?.type_id).then((res) => {
        if (res?.asset_photos !== undefined) {
          if (res?.asset_photos[data?.parentRowData?.type_id] !== undefined) {
            setAssetPhotos(res?.asset_photos[data?.parentRowData?.type_id])
            if (res?.asset_photos != undefined && res?.asset_photos[data?.parentRowData?.type_id] != undefined) {
              setAssetPhotosCount(Object.keys(res?.asset_photos[data?.parentRowData?.type_id]).length)
              setLoading2(false)
            } else {
              setAssetPhotosCount(0)
              setLoading2(false)
            }
          }
        }
        setLoading2(false)
      })
    }
  }, [data, locationsDataFull?.[0]?.location_id])

  useEffect(() => {
    const clearMessage = () => {
      dispatch(lineListActions.clearMessageData())
    }
    const showMessage = () => {
      if (lineState?.messageData.status === 'success') {
        setMessage(lineState?.messageData?.message)
        setStatus('success')
        setOpenConfirmation(false)
        clearMessage()
      }
      if (lineState?.messageData.status === 'failed') {
        setMessage(lineState?.messageData?.message)
        setStatus('warning')
        clearMessage()
      }
    }

    const timer = setTimeout(() => {
      showMessage()
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  }, [lineState?.messageData])

  // useEffect(() => {
  //   return () => {
  //     dispatch(lineListActions.clearMessageData())
  //   }
  // }, [])

  const handleSelected = (selected, type) => {
    let label
    if (selected.length === 0) return
    // we set lable in case we are adding a custom value and not one that is already in the list
    if (selected[0]?.label) label = true
    switch (type) {
      // if we have a label, we set the values accordingly based on a ternary check
      case 'make':
        setInputValues({...inputValues, selectedMake: selected, make: label ? selected[0]?.label : selected[0]?.make, make_id: label ? null : selected[0]?.make_id})
        break
      case 'assetType':
        setInputValues({...inputValues, selectedAssetType: selected, asset_type: label ? selected[0]?.label : selected[0]?.asset_type, asset_type_id: label ? null : selected[0]?.asset_type_id, asset_class_id: +selected[0]?.class_id ? +selected[0]?.class_id : 0})
        // setInputValues({...inputValues, asset_class_id: +selected[0]?.class_id})
        break
      default:
        break
    }
  }

  const handleChange = (e) => {
    let value = e.target.value
    if (e.target.name === 'line_text2') {
      if (value?.length <= 100) {
        setInputValues({...inputValues, line_text2: e.target.value})
      }
    } else if (e.target.name === 'line_name') {
      if (value.length <= 500) {
        setInputValues({...inputValues, line_name: e.target.value})
      }
    } else {
      setInputValues({...inputValues, [e.target.name]: e.target.value})
    }
  }

  const handleChangeValues = (e, type) => {
    if (type === 'location') {
      setLocationId(e.target.value)
      let assetData = {
        location_id: e.target.value.toString(),
        type: 'line',
        project_id_crm: decodedProjectId,
      }
      dispatch(getAssetDetailsAction(assetData))
    }
    setLineValues({...lineValues, [e.target.name]: e.target.value})
    setInputValues({...inputValues, [e.target.name]: e.target.value})
  }

  // useEffect(() => {
  //   setInputValues({...inputValues, ...data?.parentRowData})
  // }, [data])

  const handleChangeAdd = (e) => {
    let value = e.target.value
    if (value?.length <= 40) {
      setAddValue(e.target.value)
    }
  }
  const handleDialog = () => {
    setOpenDialog(!openDialog)
    setAddValue('')
  }
  const handleAdd = () => {
    if (!addValue) {
      setMessage('Please Enter Asset Type')
      setStatus('warning')
      return
    }
    let addObj = {
      created_by: Number(userId),
      modified_by: Number(userId),
      type: addValue,
      location_id: locationId,
      project_id_crm: decodedProjectId,
      assetId: params?.id,
    }
    let assetData = {
      location_id: data ? data?.parentRowData?.location_id : locationsDataFull?.[0]?.location_id?.toString(),
      type: 'line',
      project_id_crm: decodedProjectId,
      assetId: data?.parentRowData?.type_id,
      flag: 'lineEdit',
    }
    const isAlreadyExists = assetTypes?.find(({asset_type}) => asset_type === addValue)
    if (isAlreadyExists) {
      setMessage('Asset Type Already Exists')
      setStatus('warning')
    } else {
      dispatch(addAssetTypeAction(addObj))
      handleDialog()
      fetchAssetDetails(assetData).then((res) => {
        const localAssetTypes = res?.data?.assetTypeList
        const localFilteredAssetTypes = localAssetTypes?.length > 0 && localAssetTypes[0]?.asset_type === 'Select or search asset type' ? localAssetTypes?.slice(1) : localAssetTypes
        setFilteredAssetTypes(localFilteredAssetTypes)
      })
    }
  }
  const validateCost = (item) => {
    // ! A total of 6 cases are being handled as per the requirements
    switch (item) {
      case 'FLV':
        if (+inputValues.FLV > +inputValues.OLV || +inputValues.FLV > +inputValues.FMV) return true
        break
      case 'OLV':
        if (+inputValues.OLV > +inputValues.FMV || +inputValues.OLV > +inputValues.OLVIP) return true
        break
      case 'FMV':
        if (+inputValues.FMV > +inputValues.FMVIP) return true
        break
      case 'OLVIP':
        if (+inputValues.OLVIP > +inputValues.FMVIP) return true
        break
      default:
        break
    }
  }

  const generatePriceKeys = () => {
    const valueCode = []
    assetData?.locationPriceValues?.forEach(({value_short_name}) => valueCode.push(value_short_name))
    // const computedPriceKeys = Object.keys(inputValues).reduce((acc, i) => {
    //   if (valueCode?.includes(i)) acc?.push(i)
    //   return acc
    // }, [])
    const computedPriceKeys = []
    for (let i = 0; i < valueCode.length; i++) {
      // data.assetValues[data.locationPriceValues[i]['value_short_name']] = $scope.formData.priceVal[data.locationPriceValues[i]['value_code']];
      // computedPriceKeys[valueCode[i]['value_short_name']] = $scope.formData.priceVal[data.locationPriceValues[i]['value_code']];
      computedPriceKeys.push(valueCode[i])
    }
    return computedPriceKeys
  }

  const handleFocus = () => {
    let updatedInputValues = {...inputValues}
    let computedPriceValues = generatePriceKeys()
    const allowedValues = ['no value', 'leased', 'not valued']

    computedPriceValues.forEach((val) => {
      if (allowedValues.includes(updatedInputValues[val]?.toLowerCase()?.trim()) || +updatedInputValues[val] > 0) {
        // Valid value, do nothing
      } else {
        updatedInputValues[val] = '' // Reset invalid value
      }
    })

    setInputValues(updatedInputValues)
  }
  const CheckType = (value) => {
    let flag
    let digitRegex = /^[0-9]+$/
    let valid = digitRegex.test(value)
    if (valid) {
      flag = true
    } else {
      flag = false
    }
    return flag
  }
  const getPriceValues = () => {
    let computedPriceValues = generatePriceKeys()
    return computedPriceValues?.map((item, index) => {
      let percentageValue = ((inputValues[item]) / inputValues?.cost) * 100
      let type = CheckType(inputValues[item])
      return (
        <Grid item md={3} key={index}>
          <Box className="input_box">
            <Typography>{item} ($):</Typography>
            {type ? (inputValues[item] && inputValues?.cost > 0 ? <Typography>{percentageValue.toFixed(2)}% of Cost</Typography> : '') : ''}
            <CustomTextBox
              className={validateCost(item) ? 'coral_background' : null}
              onChange={(e) => handleChangeValues(e, 'priceValue')}
              name={item}
              value={inputValues[item]}
              onBlur={handleFocus}
            />
          </Box>
        </Grid>
      )
    })
  }

  const handleConfirmation = (id) => {
    setRowId(id)
    setOpenConfirmation(!openConfirmation)
  }
  useEffect(() => {
    let priceVal = []
    const finalValue = assetData?.locationPriceValues
    finalValue?.forEach((item) => {
      for (const key in inputValues) {
        if (item.value_short_name === key) {
          let obj = {
            name: item.value_code,
            value: inputValues[key],
          }
          priceVal.push(obj)
        }
      }
    })
    setValue(priceVal)
  }, [inputValues])

  const transformedPriceVal = value?.reduce((result, item) => {
    result[item.name] = item.value
    return result
  }, {})
  const handleSubmit = (type) => {
    localStorage.setItem('lastAssetAddLocation', inputValues.location_id) // add the last location in all cases
    // useless since we are not checking for any values during edit in hilcoweb
    // if (inputValues && inputValues?.value?.length > 0) {
    let commonPayload = {
      appraiser: inputValues.appraiser ?? null,
      appraiser_note: inputValues.appraiser_note ?? '',
      area: inputValues.area ?? null,
      area_asset_order: inputValues?.area_asset_order,
      area_order: inputValues?.area_order,
      asset_class_id: inputValues.asset_class_id ?? null,
      asset_id: inputValues.asset_id,
      asset_no: inputValues.asset_no ?? inputValues.asset_no,
      asset_type_id: inputValues.asset_type_id ?? null,
      capacity: inputValues.capacity ?? null,
      condition: inputValues.condition ?? inputValues.condition,
      cost: inputValues.cost ?? inputValues.cost,
      created_by: inputValues.created_by ?? null,
      created_on: inputValues.created_on ?? null,
      description: inputValues.description ?? null,
      flagged: inputValues.flagged ?? null,
      formAction: 'saveUpdate',
      is_not_found: inputValues.is_not_found ?? null,
      line_name: inputValues.line_name ?? null,
      line_text2: inputValues.line_text2 ?? null,
      line_unique_name: inputValues.line_unique_name ?? null,
      location_id: inputValues.location_id,
      make: inputValues.make ?? null,
      make_id: inputValues.make_id ?? null,
      mileage: null,
      mileage_type: inputValues.mileage_type ?? null,
      model: inputValues.model ?? null,
      model_label: inputValues.model_label ?? null,
      model_label_id: inputValues.model_label_id ?? null,
      modified_by: Number(userId),
      modified_on: '',
      parent_line: inputValues.parent_line ?? null,
      priceVal: transformedPriceVal,
      project_id: inputValues?.project_id,
      quantity: inputValues.quantity ?? null,
      quantity_type: inputValues.quantity_type ?? null,
      serial_label: null,
      serial_label_id: inputValues?.serial_label_id ?? null,
      serial_number: inputValues?.serial_number ?? null,
      special_notes: inputValues?.special_notes ?? null,
      type: 'line',
      type_id: inputValues.type_id ?? null,
      value: inputValues.value ?? null,
      value_code: inputValues.value_code ?? null,
      year: inputValues.year ?? inputValues.year, //
    }
    if (type === 'Delete line and Contained assets') {
      setType(type)
      handleConfirmation()
    } else if (type === 'Delete line and unassign assets') {
      setType(type)
      handleConfirmation()
    } else if (type === 'Delete all contained assets') {
      setType(type)
      handleConfirmation()
    } else if (type === 'Unassign all assets') {
      setType(type)
      handleConfirmation()
    } else if (type === 'Copy Line') {
      let obj = {
        formAction: 'copyLine',
        line_name: inputValues.line_name,
        location_id: locationId,
        type_id: inputValues.type_id,
        user_id: Number(userId),
      }
      dispatch(copyLineAction(obj)).then(() => {
        dispatch(lineListActions.setMessageData({message: 'Copy Line successfully.', status: 'success'}))
        history.push('/asset-list')
      })
    } else if (type === 'SaveAndBack') {
      if (commonPayload?.line_name || commonPayload?.asset_type_id || commonPayload?.make_id) {
        dispatch(postLineDetailAction(commonPayload))
        setTimeout(() => {
          history.push('/asset-list')
        }, 2000)
      } else if (!commonPayload?.make_id && commonPayload?.line_name) {
        dispatch(postLineDetailAction(commonPayload))
        // history.push('/asset-list')
        setTimeout(() => {
          history.push('/asset-list')
        }, 2000)
      } else {
        setMessage('Please enter at least one field from Make, Text1 and Asset Type.')
        setStatus('warning')
      }
    } else if (type === 'Cancel') {
      history.push('/asset-list')
    } else {
      // dispatch(postLineDetailAction(commonPayload))
    }
    // }
  }

  const handleImageEnter = (obj) => {
    setShowFullImage(true)
    setImagePath(obj)
  }

  const handleImageLeave = () => {
    setShowFullImage(false)

  }

  const handleNominatePhoto = (obj) => {
    setShowFullImage(false)
    let payload = {
      type: 'line',
      op: 'nominate_status_update',
      type_id: data?.parentRowData?.type_id,
      photo_location: obj?.path,
      photo_nominated: obj?.photo_nominated === 1 ? '0' : '1',
      asset_media_type: 'photo',
    }
    try {
      postPhotosToAssets(payload).then((res) => {
        setMessage('Images Update Successfully')
        setStatus('success')
        const photos = [...assetPhotos]
        const updatedNominatePhotos = photos.map((item) => {
          if (item.path === obj?.path) {
            // Toggle the value of photo_nominated property
            item.photo_nominated = item?.photo_nominated === 1 ? 0 : 1
          }
          return item // Return the updated item
        })

        setAssetPhotos(updatedNominatePhotos)
      })
    } catch (error) {
      console.log('error', error)
    }
  }
  const handleImageDelete = (imagePath) => {
    let obj = {
      photo_location: imagePath,
      type: 'line',
      type_id: data.parentRowData.type_id.toString(),
    }
    try {
      deletePhotosToAssets(obj, {user_id: inputValues?.user_id?.toString() ?? ''}).then((res) => {
        setMessage('Images Delete Successfully')
        setStatus('success')
        const photos = [...assetPhotos]
        const updatedPhotos = photos.filter(({path}) => path !== imagePath)
        setAssetPhotosCount(updatedPhotos?.length)
        setAssetPhotos(updatedPhotos)
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    return () => {
      if (data) {
        // ! for scroll functionality
        localStorage.removeItem('alreadyScrolled')
      }
    }
  }, [])
  const handleUnassignAsset = () => {
    if (type === 'Unassign all assets') {
      let obj = {
        action: 'deassociateAll',
        id: inputValues.type_id,
      }
      dispatch(editLineAssetAction(obj, history, data?.route))
      const updatedAssetList = {...lineAssetList}
      updatedAssetList.lineAssets = []
      setLineAssetList(updatedAssetList)
      // getLineAssetList()
      setOpenConfirmation(false)
      // setAction(true)
    }
    if (type === 'Delete all contained assets') {
      let obj = {
        action: 'deleteAllAsset',
        id: inputValues?.type_id,
      }
      dispatch(editLineAssetAction(obj))
      const updatedAssetList = {...lineAssetList}
      updatedAssetList.lineAssets = []
      setLineAssetList(updatedAssetList)
      // getLineAssetList()
      setOpenConfirmation(false)
      // setAction(true)

    }
    if (type === 'Delete line and unassign assets') {
      let obj = {
        action: 'deleteLineAndDeassocAsset',
        id: inputValues.type_id,
      }
      dispatch(editLineAssetAction(obj, history, data?.route))
      const updatedAssetList = {...lineAssetList}
      updatedAssetList.lineAssets = updatedAssetList?.lineAssets?.filter(({type_id}) => type_id !== rowId)
      setLineAssetList(updatedAssetList)
      // getLineAssetList()
      // setAction(true)

    }
    if (type === 'Delete line and Contained assets') {
      let obj = {
        action: 'deleteLineAndAsset',
        id: inputValues.type_id,
      }
      dispatch(editLineAssetAction(obj, history, data?.route))
      // getLineAssetList()
      // setAction(true)

    }
  }


  const handleDelete = () => {
    const obj = {
      action: 'deassociate',
      id: rowId,
    }
    dispatch(editLineAssetAction(obj))
    // getLineAssetList()
    const updatedAssetList = {...lineAssetList}
    updatedAssetList.lineAssets = updatedAssetList.lineAssets.filter(({type_id}) => type_id !== rowId)
    setLineAssetList(updatedAssetList)
    setOpenConfirmation(false)
    // setAction(true)
  }

  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    // dispatch(lineListActions.clearMessageData())
  }
  const handleMapPhotos = () => {
    let url = window?.location?.href
    let parentRowData = data?.parentRowData
    if (Object.keys(inputValues).length !== 0 && (inputValues?.user_id !== null || inputValues?.appraiser !== null)) {
      history.push({
        pathname: `/map-photos/${inputValues?.appraiser}`,
        state: {data: {parentRowData, inputValues, url}},
      })
    } else {
      setMessage('Choose Appraiser First.')
      setStatus('warning')
    }
  }

  const handleSelectedForLineAndAssetType = (selected) => {
    let label
    if (selected[0]?.label) label = true
    setInputValues({
      ...inputValues,
      selectedAssetType: selected,
      asset_type: label ? selected[0]?.label : selected[0]?.asset_type,
      asset_type_id: label ? null : selected[0]?.asset_type_id,
      asset_class_id: +selected[0]?.class_id ? +selected[0]?.class_id : 0,
    })
  }
  const handleKeyDownForTypeAhead = (e) => {
    const {placeholder, value} = e.target
    if (!value) return
    if (e.keyCode === 9) {
      if (placeholder === 'Search Make') {
        const makeId = makeList?.find(({make}) => make === value)?.make_id
        setInputValues({...inputValues, selectedMake: [{make: value}], make: value, make_id: makeId})
      }
      if (placeholder === 'Search Asset Types') {
        const assetTypeValue = filteredAssetTypes?.find(({asset_type}) => asset_type === value)
        setInputValues({...inputValues, selectedAssetType: [{asset_type: value}], asset_type: value, asset_type_id: assetTypeValue?.asset_type_id, asset_class_id: assetTypeValue?.class_id})
      }
    }
  }
  const handleCustomTypeAheadForMake = (makeValue) => {
    if (Array.isArray(makeValue)) return
    setInputValues({...inputValues, selectedMake: [{make: makeValue}], make: makeValue})
  }

  return (
    <MyDiv>
      {(isLoading || isLoading2 || homeState?.locationsList?.loading || lineState?.assetDetails?.loading || lineState?.postLineDetail?.loading || lineState?.assetType?.loading || lineState?.editLineAsset?.loading) &&
        <div>
          <Loader />
        </div>
      }

      <Box className="page_heading">
        <Typography>{data?.key?.name}</Typography>
        <span className="submenu">Asset Id : <b>{data && data?.parentRowData.asset_id}</b></span>
      </Box>
      {data?.key?.name === 'Edit Line' ?
        <Box className="wrapper_box">
          <Grid container columnSpacing={{sm: 4, md: 4}}>
            <Grid item md={3} className="project_name d-flex">
              <Typography variant="h6">Project :-</Typography>
              <Typography>{locationsDataFull?.[0]?.project_name}</Typography>
            </Grid>
            <Grid item md={3}>
              <Box className="input_box">
                <Typography>Select Location</Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    id="select_input"
                    className="select_value"
                    // value={lineValues.location || locationId !== undefined ? locationId : null ?? inputValues?.location_id}
                    value={+(inputValues?.location_id) ? +(inputValues?.location_id) : (lineValues.location || locationId !== undefined ? locationId : null)}
                    onChange={(e) => handleChangeValues(e, 'location')}
                    name="location"
                    MenuProps={{...Menu}}
                    disabled={inputValues?.location_id}
                  >
                    {locationsDataFull?.map((item, index) => {
                      return (
                        <StyledMenuItem key={index} value={item.location_id}>
                          {item.name} [{item.street} {item.city} {item.country}]
                        </StyledMenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className="input_box">
                <Typography>Appraiser</Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    id="select_input"
                    className="select_value"
                    value={inputValues?.appraiser ?? +inputValues?.appraiser}
                    onChange={handleChange}
                    name="appraiser"
                    MenuProps={{...Menu}}
                  >
                    {appraiserList?.map((item, index) => {
                      return (
                        <StyledMenuItem key={index} value={item.user_id}>
                          {item.last_name}{' '}{item.name}
                        </StyledMenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className="input_box">
                <Typography>Asset #</Typography>
                <CustomTextBox
                  autoFocus
                  value={inputValues?.asset_no}
                  onChange={handleChange}
                  name="asset_no"
                />
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className="input_box">
                <Typography>Make</Typography>
                <Typeahead
                  id="basic-typeahead"
                  allowNew
                  // clearButton
                  labelKey={(option) => option?.make?.toString() || ''}
                  options={makeList}
                  placeholder="Search Make"
                  selected={inputValues?.selectedMake} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  onChange={(selected) => handleSelected(selected, 'make')}
                  onInputChange={handleCustomTypeAheadForMake}
                  onKeyDown={handleKeyDownForTypeAhead}
                />
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className="input_box">
                <Box className="text_flex">
                  <Typography>Text 1</Typography>
                  <Typography className="note">Max 500 characters</Typography>
                </Box>
                <CustomTextBox
                  maxLength={500}
                  value={inputValues?.line_name}
                  onChange={handleChange}
                  name="line_name"
                />
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className="input_box">
                <Box className="text_flex">
                  <Typography>Text 2</Typography>
                  <Typography className="note">Max 100 characters</Typography>
                </Box>
                <CustomTextBox
                  maxLength={100}
                  value={inputValues?.line_text2}
                  onChange={handleChange}
                  name="line_text2"
                />
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className="input_box">
                <Typography>Year</Typography>
                <CustomTextBox
                  maxLength={4}
                  value={inputValues?.year}
                  onChange={handleChange}
                  name="year"
                />
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className="input_box">
                <Box className="d-flex add_btn">
                  <Typography>Asset Type</Typography>
                  <IconButton tabIndex={-1} onClick={handleDialog}><Add /></IconButton>
                </Box>
                <Typeahead
                  id="basic-typeahead"
                  // allowNew
                  // clearButton
                  labelKey={(option) => option?.asset_type || ''}
                  options={filteredAssetTypes}
                  placeholder="Search Asset Types"
                  selected={inputValues?.selectedAssetType} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  onChange={(selected) => handleSelectedForLineAndAssetType(selected)}
                  onKeyDown={handleKeyDownForTypeAhead}
                />
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className="input_box">
                <Typography>Asset Class</Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    id="select_input"
                    className="select_value"
                    value={inputValues.asset_class_id ?? ''}
                    onChange={handleChange}
                    name="asset_class_id"
                    MenuProps={{...Menu}}
                  >
                    {assetData?.assetClassList?.map((item, index) => {
                      return (
                        <StyledMenuItem key={index} value={item.asset_class_id}>
                          {item.name}
                        </StyledMenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className="input_box">
                <Typography>Condition</Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    id="select_input"
                    className="select_value"
                    value={inputValues.condition ? inputValues.condition : ''}
                    onChange={handleChange}
                    name="condition"
                    MenuProps={{...Menu}}
                  >
                    {condition?.map((item, index) => {
                      return (
                        <StyledMenuItem key={index} value={item.name}>
                          {item.name}
                        </StyledMenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className="input_box">
                <Typography>Cost</Typography>
                <CustomTextBox
                  value={inputValues?.cost}
                  onChange={handleChange}
                  name="cost"
                />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Appraiser Notes</Typography>
                <CustomTextArea minRows={3} value={inputValues.appraiser_note} name="appraiser_note" onChange={handleChange} />
              </Box>
            </Grid>
          </Grid>
          <Grid container columnSpacing={{sm: 4, md: 4}}>
            {getPriceValues()}
          </Grid>
          <CustomButton
            title="Map and Nominate Photos"
            variant="contained"
            className="btn_theme"
            style={{float: 'right'}}
            onClick={handleMapPhotos}
          />

          {assetPhotosCount > 0 ?
            <Grid className="imageBox">
              Images Mapped
              <hr />
              <Grid container columnSpacing={{md: 2}}>
                {assetPhotos?.map((obj, index) => {
                  return (
                    <Grid container xs={2} key={index} className="image_row">
                      <Box className="list_item">
                        <ListItem key={index} className="list" >
                          <img src={url + obj?.path} alt={obj?.path.split('/').pop()} onClick={() => handleImageEnter(obj)} />
                          <ListItemText
                            primary={obj?.path.split('/').pop()}
                            className="label_name"
                          />
                          <FormControlLabel
                            className="checked_label"
                            control={<Checkbox checked={obj?.photo_nominated === 1 ?? false} onChange={() => handleNominatePhoto(obj)} />}
                            label="Nominate"
                          />

                        </ListItem>
                        <IconButton onClick={() => handleImageDelete(obj?.path)}>
                          <Close />
                        </IconButton>
                      </Box>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
            : null
          }
          <Box className="d-flex btn-flex mb-42 mt-10">


            {/* <CustomButton
              title="Map and Nominate Photos"
              variant="contained"
              className="btn_theme"
              onClick={handleMapPhotos}
            /> */}

            <CustomButton
              title="Unassign all assets"
              variant="contained"
              className="btn_theme"
              onClick={() => handleSubmit('Unassign all assets')}
            />
            <CustomButton
              title="Delete all contained assets"
              variant="contained"
              className="btn_theme"
              onClick={() => handleSubmit('Delete all contained assets')}
            />
            <CustomButton
              title="Delete line and unassign assets"
              variant="contained"
              className="btn_theme"
              onClick={() => handleSubmit('Delete line and unassign assets')}
            />
            <CustomButton
              title="Delete line and Contained assets"
              variant="contained"
              className="btn_theme"
              onClick={() => handleSubmit('Delete line and Contained assets')}

            />
            <CustomButton
              title="Save & Back"
              variant="contained"
              className="btn_theme"
              onClick={() => handleSubmit('SaveAndBack')}
            />
            <CustomButton
              title="Cancel"
              variant="contained"
              className="btn_theme"
              onClick={() => handleSubmit('Cancel')}

            />
          </Box>
        </Box> :
        <Box className="wrapper_box">
          <Grid container columnSpacing={{sm: 4, md: 4}}>
            <Grid item md={3}>
              <Box className="input_box">
                <Box className="text_flex">
                  <Typography>Text 1</Typography>
                  <Typography className="note">Max 500 characters</Typography>
                </Box>
                <CustomTextBox
                  value={inputValues?.line_name}
                  onChange={handleChange}
                  name="line_name"
                />
              </Box>
            </Grid>
            <Box className="d-flex btn-flex copy_line">
              <CustomButton
                title="Copy Line"
                variant="contained"
                className="btn_theme"
                onClick={() => handleSubmit('Copy Line')}
                disabled={!inputValues?.line_name}
              />
              <CustomButton
                title="Cancel"
                variant="contained"
                className="btn_theme"
                onClick={() => handleSubmit('Cancel')}
              />
            </Box>
          </Grid>
        </Box>}
      <FullSizeImagePopUp url={url} imagePath={imagePath} openDialog={showFullImage} onClose={handleImageLeave} />
      <AddAssetDialog handleDialog={handleDialog} openDialog={openDialog} addValue={addValue} handleChangeAdd={handleChangeAdd} handleAdd={handleAdd} />
      {lineAssetList?.lineAssets?.length > 0 ? <EditLineTable lineAssetList={lineAssetList} handleDelete={handleConfirmation} data={data} /> : ''}
      <ConfirmationAlert handleConfirmation={handleConfirmation} openConfirmation={openConfirmation} handleDelete={type ? () => handleUnassignAsset() : () => handleDelete()}
        title={type ? `Are you sure you want to ${type} ?` : 'Are you sure you want to Unassign asset ?'}
      />
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
