import {fetchAssetClass} from '../../../services/assetListService'
import {assetsListActions} from '../assets'

const getAssetClassAction = () => {
  return (dispatch) => {
    dispatch(assetsListActions.assetClassRequest())
    fetchAssetClass().then((res) => {
      dispatch(assetsListActions.assetClassSuccess(res))
    }).catch((error) => {
      dispatch(assetsListActions.assetClassFailure(error))
    })
  }
}

export default getAssetClassAction
