/* eslint-disable no-empty-function */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {Box, Typography, MenuItem} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux'
import {decode as base64Decode} from 'base-64'
import {getNewModelListAction, updateModelAction} from '../../../redux/admin/AdminActions'
import {AlertMessage, Loader} from '../../../components'
import {getMakeCapacityAction} from '../../../redux/lines/LineActions'
import {adminListActions} from '../../../redux/admin/admin'
import {clearMakeModelAction} from '../../../redux/assets/AssetsActions/'

import {ManageValidateTable, ModelEditDialog} from './components'
import MyDiv from './validateModel.style'

export default function ValidateModel() {

  const dispatch = useDispatch()
  const adminState = useSelector((state) => state.admin)
  const modelState = useSelector((state) => state.assets?.getModelByMake?.getModelByMake)
  const makeList = useSelector((state) => state.line?.makeCapacityList?.makeCapacityList?.data?.makeList)
  const modelList = adminState?.newModelList?.newModelList

  const [editPopup, setEditPopup] = useState(false)
  const [filterModelList, setFilterModelList] = useState(null)
  const [filterMakeModelList, setFilterMakeModelList] = useState(null)
  const [inputValues, setInputValues] = useState({})
  const [values, setValues] = useState({})
  const [checkValue, setCheckValue] = useState('2')
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')

  let userId = localStorage.getItem('userId')
  let projectIDCrm = localStorage.getItem('project_id_crm')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string

  let makeCapacityData = {
    type: 'getMakeCapacityList',
    project_id_crm: decodedProjectId,
  }

  useEffect(() => {
    dispatch(getNewModelListAction())
    dispatch(getMakeCapacityAction(makeCapacityData))
  }, [dispatch])

  useEffect(() => {
    if (adminState?.messageData?.status === 'success') {
      setMessage(adminState?.messageData?.message)
      setStatus('success')
      setValues({})
      setEditPopup(false)
      setCheckValue(null)
    }
  }, [adminState?.messageData])

  const handleClosePopup = () => {
    setEditPopup(false)
  }

  const handleSelected = (selected, type) => {
    // let filteredModel = modelState?.filter((item) => item.make_id === selected[0]?.make_id)
    switch (type) {
      case 'make':
        setInputValues({...inputValues, selectedMake: selected, make: selected[0]?.make, make_id: selected[0]?.make_id})
        break
      case 'model':
        setInputValues({...inputValues, selectedModel: selected, model: selected[0]?.model})
        break
      default:
        break
    }
    // setFilterMakeModelList(filteredModel)
  }
  const handleEdit = (rowItem) => {
    setCheckValue('2')
    let filteredModel = modelState?.filter((item) => item.make_id === rowItem?.rowKeyValue)
    setFilterMakeModelList(filteredModel)
    const data = modelList?.find((item) => item.make_id === rowItem?.rowKeyValue)
    const updatedData = {...data}
    Object.keys(data).forEach((i) => {
      if (i === 'make') {
        updatedData.selectedMake = [{make: data?.make, make_id: data?.make_id}]
      }
    })
    setFilterModelList(data)
    setValues({...values, isAdd: data?.model})
    setInputValues({...updatedData})
    setEditPopup(true)
  }
  const handleCheckbox = (e) => {
    setCheckValue(e.target.value)
  }

  const handleInputChange = (e) => {
    setValues({...values, [e?.target?.name]: e?.target?.value})
  }
  const handleSubmit = () => {
    let data = {
      // make_id: filterModelList?.make_id ?? inputValues?.make_id, // why are we accepting two parameters and if we are then what is the point of giving the user the option to change it?
      make_id: inputValues?.make_id ?? null,
      model: filterModelList?.model,
      new_model: values?.isAdd,
      model_power_bi: filterModelList?.model_power_bi,
      modified_by: Number(userId),
      is_changed: inputValues?.make_id || values?.isAdd ? 1 : 0,
    }
    if (checkValue === '1') {
      data.model = inputValues?.selectedModel?.[0]?.model
      data.new_model = inputValues?.selectedModel?.[0]?.model
      // data.model_power_bi = inputValues?.selectedModel?.[0]?.model.split(' ').join('') //! still needs testing
    }
    if (checkValue === '2' && !values.isAdd) {
      setMessage('Model name cannot be empty')
      setStatus('warning')
      return
    }
    if (checkValue === '1' && !inputValues?.selectedModel) {
      setMessage('Model name cannot be empty')
      setStatus('warning')
      return
    }
    localStorage.removeItem('make_model')
    dispatch(clearMakeModelAction())
    dispatch(updateModelAction(data))
  }

  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    dispatch(adminListActions.clearMessageData())
  }

  return (
    <MyDiv>
      {(adminState?.newModelList?.loading || adminState?.updateModel?.loading) &&
      <div>
        <Loader />
      </div>
      }
      <Box className="page_heading">
        <Typography>Manage Makes and Models</Typography>
      </Box>
      <ManageValidateTable modelList={modelList} handleEdit={handleEdit} />
      <ModelEditDialog
        handleEdit={handleEdit}
        handleCheckbox={handleCheckbox}
        editPopup={editPopup}
        checkValue={checkValue}
        values={values}
        handleInputChange={handleInputChange}
        filterModelList={filterModelList}
        filterMakeModelList={filterMakeModelList}
        handleSubmit={handleSubmit}
        handleClosePopup={handleClosePopup}
        makeList={makeList}
        handleSelected={handleSelected}
        inputValues={inputValues}
      />
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
