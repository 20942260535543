/* eslint-disable camelcase */
import React, {useState, useEffect} from 'react'
import 'jspdf-autotable'
import {kaReducer} from 'ka-table'
import {
  DataType,
  SortingMode,
  EditingMode,
} from 'ka-table/enums'
import {useDispatch} from 'react-redux'
import {Box} from '@mui/material'
import {AlertMessage, CustomButton, CustomTable} from '../../../../../components'
import {deleteAssetTypeAction, postAssetTypeAction} from '../../../../../redux/admin/AdminActions'
import {adminListActions} from '../../../../../redux/admin/admin'
import {validateFieldForRequest} from '../../../../../services/adminService'
import MyDiv from './assetTypesTable.style'

export default function AssetTypesTable(props) {

  const tablePropsInit = {
    columns: [
      {
        key: 'selection-cell',
        style: {width: '2%'},
      },
      {
        dataType: DataType.String,
        key: 'type',
        style: {width: 120},
        title: 'Singular Type',
      },
      {
        dataType: DataType.String,
        key: 'type_plural',
        style: {width: 120},
        title: 'Plural Type',
      },
      {
        dataType: DataType.String,
        key: 'created_on',
        style: {width: 120},
        title: 'Created On',
        isEditable: false,
      },
    ],
    selectedRows: [],
    data: props.assetTypesList,
    rowKeyField: 'asset_type_id',
    height: '75vh',
    sortingMode: SortingMode.Single,
    editingMode: EditingMode.Cell,
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: 250,
      pageSizes: [250, 500, 1000],
    },
  }

  const [assetTypesTable, setAssetTypesTable] = useState(tablePropsInit)
  const dispatch = useDispatch()
  // const adminState = useSelector((state) => state.admin)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')

  useEffect(() => {
    if (props.searchValue !== '') {
      const result = props.assetTypesList.filter((item) => {
        return item?.type?.toLowerCase().includes(props.searchValue.toLowerCase())
      })
      setAssetTypesTable({...assetTypesTable, data: result})
    } else {
      setAssetTypesTable({...assetTypesTable, data: props.assetTypesList})
    }
  }, [props.assetTypesList])

  useEffect(() => {
    return () => {
      dispatch(adminListActions.clearSelectedItems()) // clearing the global state of checkboxes for every component
    }
  }, [])

  let userId = localStorage.getItem('userId')
  // const CheckValue = (action, prevState) => {
  //   const previousValue = prevState.data.find(({asset_type_id}) => +asset_type_id === action.rowKeyValue)
  //   let inputValues = action.value
  //   let flag
  //   if (previousValue[action.columnKey].toLowerCase() === inputValues.toLowerCase()) {
  //     flag = true
  //   } else {
  //     flag = false
  //   }

  //   if (flag) {
  //     return previousValue[action.columnKey]

  //   } else {
  //     return inputValues
  //   }
  // }
  const dispatchResearch = (action) => {
    let finalObj = action.columnKey === 'type' ? 'type' : 'type_plural'
    if (action?.type === 'UpdateCellValue') {
      if (action.value !== '') {
        if (finalObj === 'type') {
          let obj = {
            modified_by: Number(userId),
            asset_type_id: action.rowKeyValue,
            type: action.value,
          }
          validateFieldForRequest({action: 'duplicate', type: action.value}, 'asset-type').then((res) => {
            if (res?.data?.assetType[0]?.count > 0) {
              setMessage('Asset Type already exists.')
              setStatus('warning')
            } else {
              dispatch(postAssetTypeAction(obj, 'edit'))
            }
          }).catch((err) => {
            console.log(err)
            setMessage('Something went wrong???')
            setStatus('warning')
          })
        } else {
          let obj = {
            modified_by: Number(userId),
            asset_type_id: action.rowKeyValue,
            type_plural: action.value,
          }
          dispatch(postAssetTypeAction(obj, 'edit'))
        }
      } else {
        props.handleMessageData('Please Enter Asset type.', 'warning')
      }
    } else {
      setAssetTypesTable((prevState) => kaReducer(prevState, action))
    }
  }

  const handleDelete = () => {
    if (assetTypesTable?.selectedRows.length > 0) {
      let obj = {
        action: 'deleteList',
        asset_type_id: assetTypesTable?.selectedRows.join('^'),
      }
      dispatch(deleteAssetTypeAction(obj))
      dispatch(adminListActions.clearSelectedItems())
    } else {
      setMessage('Please select at least one Asset Type.')
      setStatus('warning')
    }
  }

  // console.log(adminState.selectedItems, 'adminState.selectedItems')

  const handleCancel = () => {
    setAssetTypesTable({...tablePropsInit, paging: assetTypesTable.paging, selectedRows: []})
  }

  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    dispatch(adminListActions.clearMessageData())
  }

  return (
    <MyDiv>
      <CustomTable
        tableProps={assetTypesTable}
        dispatch={dispatchResearch}
      />
      <Box className="d-flex bottom_actions">
        <CustomButton title="Delete" className="btn_theme" variant="contained" onClick={handleDelete} />
        <CustomButton title="Cancel" className="btn_reset" variant="contained" onClick={handleCancel} />
      </Box>
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
