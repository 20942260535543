/* eslint-disable prefer-template */
import React, {useEffect, useState} from 'react'
import {
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import {
  Box,
  IconButton,
  ListItemText,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  ListItem,
} from '@mui/material'
import MyDiv from './locationsList.style'

export default function LocationsList(props) {

  const [finalData, setFinalData] = useState()

  const locationAppraisers = props.locationData?.locationAppraisers
  const locationsDataFull = props.locationData?.locationsDataFull

  useEffect(() => {
    if (props.filteredFlag === false) {
      setFinalData(locationsDataFull)
    } else if (props.filteredFlag === true) {
      setFinalData(props.filteredLocationData)
    }
  }, [locationsDataFull, props.filteredLocationData])
  return (
    <MyDiv>
      <Box className="wrapper">
        {finalData?.map((item, index) => {
          return (
            <Accordion
              key={index}
              expanded={props.expanded === item.location_id}
              onChange={(_, isExpanded) => props.handleChange(isExpanded ? item.location_id : false)}
              className="accordion_box"
            >
              <AccordionSummary
                onClick={() => props.handleAccordionClick(item?.location_id)}
                className="accordion_item"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className="label_title">{item.name} [{item.location_id}]</Typography>
                <Typography className="label_data">{item.street} {item?.city}  {item.state} {item.country}</Typography>
              </AccordionSummary>
              <AccordionDetails className="accordion_details">
                {locationAppraisers[item?.location_id] &&
  Object.entries(locationAppraisers[item?.location_id])
    .sort(([, a], [, b]) => a.localeCompare(b))
    .map(([key, value], index) => (
      <Box className="list_item" key={index}>
        <ListItem key={key} className="list">
          <ListItemText
            primary={value}
            className="label_name"
          />
        </ListItem>
        <IconButton onClick={() => props.handleDelete(key, item?.location_id)}>
          <DeleteIcon />
        </IconButton>
      </Box>
    ))
                }

              </AccordionDetails>
            </Accordion>
          )
        })}
      </Box>
    </MyDiv>
  )
}
