/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */
import React from 'react'
import {
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Radio,
} from '@mui/material'
import {Typeahead} from 'react-bootstrap-typeahead'
import {CustomButton, CustomTextBox} from '../../../../components'
import MyDiv from './researchForm.style'

export default function ResearchForm(props) {

  return (
    <MyDiv>
      <Box className="wrapper_box">
        <Grid container columnSpacing={{sm: 4, md: 4}} mb={3}>
          <Grid item md={3}>
            <FormControlLabel className="checked_label"
              control={<Radio name="radio-buttons" value="1" onChange={(e) => props.handleCheckbox(e)} checked={props.checkValue === '1'} />} label="All System"
            />
          </Grid>
          <Grid item md={3}>
            <FormControlLabel className="checked_label"
              control={<Radio name="radio-buttons" value="2" onChange={(e) => props.handleCheckbox(e)} checked={props.checkValue === '2'} />} label="Appraisal Only"
            />
          </Grid>
          <Grid item md={3}>
            <FormControlLabel className="checked_label"
              control={<Radio name="radio-buttons" value="4" onChange={(e) => props.handleCheckbox(e)} checked={props.checkValue === '4'} />} label="Imported Only"
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={{sm: 4, md: 4}}>
          {(props.checkValue === '1') &&
          <>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Appraiser</Typography>
                <Typeahead
                  id="basic-typeahead"
                  // allowNew
                  // clearButton
                  ref={props.appraiserRef}
                  labelKey={(option) => `${option?.last_name} ${option?.name} (${option?.user_id})` || ''}
                  options={props.filterAssetData}
                  placeholder="Search Appraiser"
                  selected={props.inputValues?.selectedAppraiser} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  multiple
                  onChange={(selected) => props.handleSelected(selected, 'appraiser')}
                />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Make</Typography>
                <Typeahead
                  id="basic-typeahead"
                  // allowNew
                  clearButton
                  ref={props.makeRef}
                  labelKey={(option) => option?.make?.toString() || ''}
                  options={props.makeListData}
                  placeholder="Search Make"
                  selected={props?.inputValues?.selectedMake} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  onChange={(selected) => props.handleSelected(selected, 'make')}
                />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Model</Typography>
                <CustomTextBox value={props.inputValues?.model ?? ''} onChange={props.handleChangeInput} name="model" />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Capacity</Typography>
                <CustomTextBox value={props.inputValues?.capacity ?? ''} onChange={props.handleChangeInput} name="capacity" />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Asset Type</Typography>
                <Typeahead
                  id="basic-typeahead"
                  // allowNew
                  // clearButton
                  ref={props.assetTypeRef}
                  labelKey={(option) => option?.asset_type?.toString() || ''}
                  options={(props.checkValue === '1' || props.checkValue === '2') ? props.assetTypesData : props.importAssetTypesData}
                  placeholder="Search Asset Types"
                  selected={props?.inputValues?.selectedAssetType} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  multiple
                  onChange={(selected) => props.handleSelected(selected, 'assetType')}
                />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Year</Typography>
                <Box className="date_box d-flex">
                  <CustomTextBox value={props.inputValues?.year_from ?? ''} onChange={props.handleChangeInput} name="year_from" fieldLabel="From" />
                  <CustomTextBox value={props.inputValues?.year_to ?? ''} onChange={props.handleChangeInput} name="year_to" fieldLabel="To" />
                </Box>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Description</Typography>
                <CustomTextBox value={props.inputValues?.description ?? ''} onChange={props.handleChangeInput} name="description" />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Client</Typography>
                <Typeahead
                  id="basic-typeahead"
                  // allowNew
                  clearButton
                  ref={props.clientRef}
                  labelKey={(option) => option?.client_name?.toString() || ''}
                  options={props.clientListData}
                  placeholder="Search Client"
                  selected={props?.inputValues?.selectedClient} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  onChange={(selected) => props.handleSelected(selected, 'client')}
                />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Company</Typography>
                <Typeahead
                  id="basic-typeahead"
                  // allowNew
                  clearButton
                  ref={props.companyRef}
                  labelKey={(option) => option?.company_name?.toString() || ''}
                  options={props.companiesListData}
                  placeholder="Search Company"
                  selected={props?.inputValues?.selectedCompany} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  onChange={(selected) => props.handleSelected(selected, 'company')}
                />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Asset Type Industrial</Typography>
                <CustomTextBox value={props.inputValues?.asset_type_industrial ?? ''} onChange={props.handleChangeInput} name="asset_type_industrial" fieldLabel="Asset type Industrial" />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Sale Name</Typography>
                <Typeahead
                  id="basic-typeahead"
                  // allowNew
                  clearButton
                  ref={props.saleRef}
                  labelKey={(option) => option?.sale_name?.toString() || ''}
                  options={props.salesData}
                  placeholder="Search Sale Name"
                  selected={props?.inputValues?.selectedSaleName} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  onChange={(selected) => props.handleSelected(selected, 'sale_name')}
                />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box mt={2}>
                <FormControlLabel className="checked_label" control={<Checkbox onChange={props.handleCheckLine} checked={props.checkLine} />} label="Include Line" />
              </Box>
            </Grid>
          </>
          }
          {(props.checkValue === '2') &&
          <>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Appraiser</Typography>
                <Typeahead
                  id="basic-typeahead"
                  // allowNew
                  // clearButton
                  ref={props.appraiserRef}
                  labelKey={(option) => `${option?.last_name} ${option?.name} (${option?.user_id})` || ''}
                  options={props.filterAssetData}
                  placeholder="Search Appraiser"
                  selected={props.inputValues?.selectedAppraiser} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  multiple
                  onChange={(selected) => props.handleSelected(selected, 'appraiser')}
                />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Date Appraised</Typography>
                <Box className="date_box d-flex">
                  <CustomTextBox value={props.inputValues?.date_appraised_from ?? ''} onChange={props.handleChangeInput} name="date_appraised_from" fieldLabel="From" />
                  <CustomTextBox value={props.inputValues?.date_appraised_to ?? ''} onChange={props.handleChangeInput} name="date_appraised_to" fieldLabel="To" />
                </Box>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Line</Typography>
                <Typeahead
                  id="basic-typeahead"
                  // allowNew
                  clearButton
                  ref={props.lineRef}
                  labelKey={(option) => option?.line_name?.toString() || ''}
                  options={props.linesData}
                  placeholder="Search Line"
                  selected={props?.inputValues?.selectedLine} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  onChange={(selected) => props.handleSelected(selected, 'line')}
                />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Make</Typography>
                <Typeahead
                  id="basic-typeahead"
                  // allowNew
                  clearButton
                  ref={props.makeRef}
                  labelKey={(option) => option?.make?.toString() || ''}
                  options={props.makeListData}
                  placeholder="Search Make"
                  selected={props?.inputValues?.selectedMake} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  onChange={(selected) => props.handleSelected(selected, 'make')}
                />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Model</Typography>
                <CustomTextBox value={props.inputValues?.model ?? ''} onChange={props.handleChangeInput} name="model" />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Capacity</Typography>
                <CustomTextBox value={props.inputValues?.capacity ?? ''} onChange={props.handleChangeInput} name="capacity" />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Asset Type</Typography>
                <Typeahead
                  id="basic-typeahead"
                  // allowNew
                  // clearButton
                  ref={props.assetTypeRef}
                  labelKey={(option) => option?.asset_type?.toString() || ''}
                  options={(props.checkValue === '1' || props.checkValue === '2') ? props.assetTypesData : props.importAssetTypesData}
                  placeholder="Search Asset Types"
                  selected={props?.inputValues?.selectedAssetType} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  multiple
                  onChange={(selected) => props.handleSelected(selected, 'assetType')}
                />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Year</Typography>
                <Box className="date_box d-flex">
                  <CustomTextBox value={props.inputValues?.year_from ?? ''} onChange={props.handleChangeInput} name="year_from" fieldLabel="From" />
                  <CustomTextBox value={props.inputValues?.year_to ?? ''} onChange={props.handleChangeInput} name="year_to" fieldLabel="To" />
                </Box>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Description</Typography>
                <CustomTextBox value={props.inputValues?.description ?? ''} onChange={props.handleChangeInput} name="description" />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Client</Typography>
                <Typeahead
                  id="basic-typeahead"
                  // allowNew
                  clearButton
                  ref={props.clientRef}
                  labelKey={(option) => option?.client_name?.toString() || ''}
                  options={props.clientListData}
                  placeholder="Search Client"
                  selected={props?.inputValues?.selectedClient} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  onChange={(selected) => props.handleSelected(selected, 'client')}
                />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Company</Typography>
                <Typeahead
                  id="basic-typeahead"
                  // allowNew
                  clearButton
                  ref={props.companyRef}
                  labelKey={(option) => option?.company_name?.toString() || ''}
                  options={props.companiesListData}
                  placeholder="Search Company"
                  selected={props?.inputValues?.selectedCompany} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  onChange={(selected) => props.handleSelected(selected, 'company')}
                />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box mt={2}>
                <FormControlLabel className="checked_label" control={<Checkbox onChange={props.handleCheckLine} checked={props.checkLine} />} label="Include Line" />
              </Box>
            </Grid>
          </>
          }
          {(props?.checkValue === '4') &&
          <>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Make</Typography>
                <Typeahead
                  id="basic-typeahead"
                  // allowNew
                  clearButton
                  ref={props.makeRef}
                  labelKey={(option) => option?.make?.toString() || ''}
                  options={props.makeListData}
                  placeholder="Search Make"
                  selected={props?.inputValues?.selectedMake} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  onChange={(selected) => props.handleSelected(selected, 'make')}
                />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Model</Typography>
                <CustomTextBox value={props.inputValues?.model ?? ''} onChange={props.handleChangeInput} name="model" />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Capacity</Typography>
                <CustomTextBox value={props.inputValues?.capacity ?? ''} onChange={props.handleChangeInput} name="capacity" />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Year</Typography>
                <Box className="date_box d-flex">
                  <CustomTextBox value={props.inputValues?.year_from ?? ''} onChange={props.handleChangeInput} name="year_from" fieldLabel="From" />
                  <CustomTextBox value={props.inputValues?.year_to ?? ''} onChange={props.handleChangeInput} name="year_to" fieldLabel="To" />
                </Box>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Cost</Typography>
                <CustomTextBox value={props.inputValues?.cost ?? ''} onChange={props.handleChangeInput} name="cost" fieldLabel="Cost" />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Description</Typography>
                <CustomTextBox value={props.inputValues?.description ?? ''} onChange={props.handleChangeInput} name="description" />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Client</Typography>
                <Typeahead
                  id="basic-typeahead"
                  // allowNew
                  clearButton
                  ref={props.clientRef}
                  labelKey={(option) => option?.client_name?.toString() || ''}
                  options={props.clientListData}
                  placeholder="Search Client"
                  selected={props?.inputValues?.selectedClient} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  onChange={(selected) => props.handleSelected(selected, 'client')}
                />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Company</Typography>
                <Typeahead
                  id="basic-typeahead"
                  // allowNew
                  clearButton
                  ref={props.companyRef}
                  labelKey={(option) => option?.company_name?.toString() || ''}
                  options={props.companiesListData}
                  placeholder="Search Company"
                  selected={props?.inputValues?.selectedCompany} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  onChange={(selected) => props.handleSelected(selected, 'company')}
                />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Asset No</Typography>
                <CustomTextBox value={props.inputValues?.asset_no ?? ''} onChange={props.handleChangeInput} name="asset_no" fieldLabel="Asset No" />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Asset Type</Typography>
                <Typeahead
                  id="basic-typeahead"
                  // allowNew
                  // clearButton
                  ref={props.assetTypeRef}
                  labelKey={(option) => option?.asset_type?.toString() || ''}
                  options={(props.checkValue === '1' || props.checkValue === '2') ? props.assetTypesData : props.importAssetTypesData}
                  placeholder="Search Asset Types"
                  selected={props?.inputValues?.selectedAssetType} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  multiple
                  onChange={(selected) => props.handleSelected(selected, 'assetType')}
                />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Serial Number</Typography>
                <CustomTextBox value={props.inputValues?.serial_number ?? ''} onChange={props.handleChangeInput} name="serial_number" />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="input_box">
                <Typography>Date Appraised</Typography>
                <Box className="date_box d-flex">
                  <CustomTextBox value={props.inputValues?.date_appraised_from ?? ''} onChange={props.handleChangeInput} name="date_appraised_from" fieldLabel="From" />
                  <CustomTextBox value={props.inputValues?.date_appraised_to ?? ''} onChange={props.handleChangeInput} name="date_appraised_to" fieldLabel="To" />
                </Box>
              </Box>
            </Grid>
          </>
          }
        </Grid>
        <Box className="d-flex btn-flex mb-20 mt-10">
          <CustomButton title="Search" variant="contained" className="btn_theme" onClick={props.handleShow} />
          <CustomButton title="Reset" variant="contained" className="btn_reset" onClick={props.handleReset} />
        </Box>
      </Box>
    </MyDiv>
  )
}
