import {deleteInnerAssets} from '../../../services/assetListService'
import {assetsListActions} from '../assets'

const deleteInnerAssetAction = (payload) => {
  return (dispatch) => {
    dispatch(assetsListActions.deleteInnerAssetRequest())
    deleteInnerAssets(payload).then((res) => {
      if (res.status === 200) {
        dispatch(assetsListActions.setMessageData({message: 'Asset Delete Successfully.', status: 'success'}))
      }
      dispatch(assetsListActions.deleteInnerAssetSuccess(res))
    }).catch((error) => {
      dispatch(assetsListActions.deleteInnerAssetFailure(error))
    })

  }
}

export default deleteInnerAssetAction
