
// http://hilcoapi.mindstask.com/api/asset-list/multiAssetDrop
import {assetDrop} from '../../../services/assetListService'
import {assetsListActions} from '../assets'

const assetDropAction = (payload, type) => {
  return (dispatch) => {
    dispatch(assetsListActions.dropAssetsRequest())
    assetDrop(payload).then((res) => {
      if (res?.status === 200) {
        if (type !== 'line') {
          dispatch(assetsListActions.setMessageData({message: 'Assets Move Successfully.', status: 'success'}))
        }
      }
      dispatch(assetsListActions.dropAssetsSuccess(res))
      dispatch(assetsListActions.setDoFetchAssetList(true))
    }).catch((error) => {
      dispatch(assetsListActions.dropAssetsFailure(error))
    })

  }
}

export default assetDropAction
