/* eslint-disable prefer-template */
import React from 'react'
import {Close, ExpandMore as ExpandMoreIcon} from '@mui/icons-material'
import {
  Box,
  IconButton,
  ListItemText,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  ListItem,
  Grid,
  FormControlLabel,
  Checkbox,
  Tooltip,
  // Tooltip,
} from '@mui/material'
import MyDiv from './assetIdList.style'

export default function AssetIdList(props) {

  const getAssetCount = (item) => {
    return Object.entries(props?.assetsData?.asset_photos).filter((elem) => elem[0] === item?.toString())?.[0]?.[1]?.length ?? 0
  }
  const objLength = props?.photosAssetData ? Object.keys(props?.photosAssetData)?.length : ''
  return (
    <MyDiv>
      <Box className="wrapper">
        {objLength && objLength > 0 ?
          <>
            {props.photosAssetData && Object.values(props.photosAssetData)?.map((item, index) => {
              return (
                +props?.inputValues?.asset_id === +(item.asset_id) ?
                  <Accordion
                    key={index}
                    expanded={props.expanded === 'panel' + item.type_id}
                    onChange={props.handleAccordionChange('panel' + item.type_id)}
                    className="accordion_box"
                  >
                    <AccordionSummary
                      onClick={() => props.handleAccordionClick(item)}
                      className="accordion_item"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className="label_title">Asset Id #{item.asset_id} {item.make}  {item.serial_number} {item.year} {item.asset_no}
                        ({props?.assetsData?.asset_photos && getAssetCount(item.type_id)}) <b>{item.type === 'line' ? item.type : ''}</b>{item.type === 'asset' ? '' : ''}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordion_details">
                      <Grid container columnSpacing={{md: 3}}>
                        {props?.assetsData?.asset_photos && Object.keys(props?.assetsData?.asset_photos)?.map((elem, index) => {
                          return (
                            +(elem) === +(item.type_id) ?
                              <>
                                {props?.assetsData?.asset_photos[elem].map((obj) => {
                                  return (
                                    <Grid item md={3} key={index}>

                                      <Box className="list_item">
                                        <Tooltip title={obj.path.split('/').pop()} placement="bottom-end">
                                          <ListItem key={index} className="list">
                                            <img src={props.url + obj.path} alt={obj.path.split('/').pop()} />
                                            <ListItemText
                                              primary={obj.path.split('/').pop()}
                                              className="label_name"
                                            />
                                            <FormControlLabel
                                              className="checked_label"
                                              control={<Checkbox checked={obj.photo_nominated === 1 ?? false} onChange={() => props.handleNominatePhoto(elem, obj, item)} />}
                                              label="Nominate"
                                            />
                                          </ListItem>
                                        </Tooltip>
                                        <IconButton onClick={() => props.handleDelete(elem, obj, item)}>
                                          <Close />
                                        </IconButton>
                                      </Box>
                                    </Grid>
                                  )
                                })}
                              </>
                              : null
                          )
                        })}
                      </Grid>
                    </AccordionDetails>
                  </Accordion> :
                  <Accordion
                    key={index}
                    expanded={props.expanded === 'panel' + item.type_id}
                    onChange={props.handleAccordionChange('panel' + item.type_id)}
                    className="accordion_box"
                  >
                    <AccordionSummary
                      onClick={() => props.handleAccordionClick(item)}
                      className="accordion_item"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className="label_title">Asset Id #{item.asset_id} {item.make} {item.serial_number} {item.year} {item.asset_no}
                        ({props?.assetsData?.asset_photos && getAssetCount(item.type_id)}) <b>{item.type === 'line' ? item.type : ''}</b>{item.type === 'asset' ? '' : ''}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordion_details">
                      <Grid container columnSpacing={{md: 3}}>
                        {props?.assetsData?.asset_photos && Object.keys(props?.assetsData?.asset_photos)?.map((elem, index) => {
                          return (
                            +(elem) === +(item.type_id) ?
                              <>
                                {props?.assetsData?.asset_photos[elem].map((obj) => {
                                  return (
                                    <Grid item md={3} key={index}>
                                      <Box className="list_item">
                                        <Tooltip title={obj.path.split('/').pop()} placement="bottom-end">
                                          <ListItem key={index} className="list">
                                            <img src={props.url + obj.path} alt={obj.path.split('/').pop()} />
                                            <ListItemText
                                              primary={obj.path.split('/').pop()}
                                              className="label_name"
                                            />
                                            <FormControlLabel
                                              className="checked_label"
                                              control={<Checkbox checked={obj.photo_nominated === 1 ?? false} onChange={() => props.handleNominatePhoto(elem, obj, item)} />}
                                              label="Nominate"
                                            />

                                          </ListItem>
                                        </Tooltip>
                                        <IconButton onClick={() => props.handleDelete(elem, obj, item)}>
                                          <Close />
                                        </IconButton>
                                      </Box>
                                    </Grid>
                                  )
                                })}
                              </>
                              : null
                          )
                        })}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
              )
            })}
          </> : <Typography className="label_titles" style={{fontWeight: 'bold'}}>No assets found</Typography>
        }
      </Box>
    </MyDiv>
  )
}
