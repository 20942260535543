import apiInstance from '../config/api/axios'

const authenticateUser = async(payload) => {
  try {
    const response = await apiInstance.get(`common?type=${payload.type}&username=${payload.username}&role=${payload.role}&project_id_crm=${payload.project_id_crm}`)
    const responseData = response?.data?.userdetail
    //! setting cookies because the import module uses these cookies
    document.cookie = `userdetails=${encodeURIComponent(JSON.stringify(response?.data?.userdetail))}`
    localStorage.setItem('userId', response.data.userdetail.user_id)
    localStorage.setItem('userName', response.data.userdetail.username)
    // const authData = {client_id: 'testclient', client_secret: 'testpass', grant_type: 'client_credentials', role: responseData?.role}
    const authData = {client_id: responseData?.username, client_secret: responseData?.username, grant_type: 'client_credentials', role: responseData?.role}
    const authResponse = await apiInstance.post('oauth?type=verifyuser', authData)
    //setting token for the import app in this app. Needs testing first and then we will migrate our token logic as well.
    const item = {
      token: `Bearer ${authResponse.data.access_token}`,
      expiry: Date.now() + authResponse.data.expires_in,
    }
    document.cookie = `access_token=${encodeURIComponent(JSON.stringify(authResponse.data.access_token))}`
    document.cookie = `project_id_crm=${encodeURIComponent(JSON.stringify(payload.project_id_crm))}`
    localStorage.setItem('authToken', JSON.stringify(item))
    localStorage.setItem('token', authResponse.data.access_token)
    return response
  } catch (error) {
    console.log(error)
  }
}

const logoutUser = async(payload) => {
  try {
    const response = await apiInstance.get(`logout?token=${payload.token}`)
    return response

  } catch (error) {
    console.log(error)
  }
}

export {authenticateUser, logoutUser}
