/* eslint-disable*/
/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  Select,
  Typography,
  MenuItem,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  ListItem,
  ListItemText,
  CircularProgress,
  Tooltip,
} from '@mui/material';
// import Link from 'react-router-dom'
import styled from 'styled-components';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Add,
  Close,
} from '@mui/icons-material';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { decode as base64Decode } from 'base-64';
import {
  AlertMessage,
  ConfirmationAlert,
  CustomButton,
  CustomTextArea,
  CustomTextBox,
  Loader,
} from '../../../components';
import * as routesNames from '../../../constants/routes';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Typeahead } from 'react-bootstrap-typeahead';
import AddAssetDialog from '../../AddAssetDialog';
import {
  deletePhotosToAssets,
  fetchAssetList,
  fetchPhotosByAssetId,
  getAssetDetails,
  getAssetInEdit,
  postPhotosToAssets,
} from '../../../services/assetListService';
import { getLocationsDataAction } from '../../../redux/home/HomeActions';
import {
  addAssetTypeAction,
  getAssetDetailsAction,
  getMakeCapacityAction,
  postLineDetailAction,
  getLineByAreaListAction,
} from '../../../redux/lines/LineActions';
import { assetsListActions } from '../../../redux/assets/assets';
import { getAreaListAction } from '../../../redux/admin/AdminActions';
import {
  // insertAssetAboveAction,
  deleteInnerAssetAction,
  getModelByMakeAction,
  // saveAndCreateNewAction,
  // insertAssetBelowAction,
  postAddAssetAction,
  // postUpdateAssetAction,
  // saveAndCopyNewAction,
  // saveAndCopyBelowAction,
} from '../../../redux/assets/AssetsActions';
import { lineListActions } from '../../../redux/lines/line';
import InsertNewLineDialog from '../../../components/Tables/CustomTable/components/InsertNewLineDialog';
import MyDiv from './addAssets.style';
import FullSizeImagePopUp from './fullSizeImage';
import {
  condition,
  mileage,
  quantity,
  specialNote,
  specialNoteTypes,
} from '../../../constants/constant';
import { myDebounce } from '../../../utils/Utils';

const MODEL_DATA = localStorage.getItem('make_model');

const StyledMenuItem = styled(MenuItem)(() => ({
  '&.MuiMenuItem-root': {
    borderBottom: '1px solid #ccc',
    color: '#000000',
    fontWeight: '400',
    fontSize: '14px',
    fontFamily: 'Arial',
  },
  '&.MuiMenuItem-root:first-child': {
    marginTop: '-8px',
    height: '30px',
  },
  '&.MuiMenuItem-root:last-child': {
    marginBottom: '-8px',
  },
}));

const ITEM_HEIGHT = 60;
const Menu = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
    },
  },
};

// ! this is why - we are complicating this because of the conditional way these buttons already work in the angular app so please don't fuck around with it
export default function AddAssets(props) {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const homeState = useSelector((state) => state.home);
  const lineState = useSelector((state) => state.line);
  const adminState = useSelector((state) => state.admin);
  const assetState = useSelector((state) => state.assets);
  const {
    postAddAsset,
    saveAndCreateNew,
    saveAndCopyNew,
    saveAndCopyBelow,
    insertAssetAbove,
    insertAssetBelow,
    postUpdateAsset,
    saveAndCopyNewSuccess,
  } = useSelector((state) => state.assets); // exteme stupidity with redux

  const areaRef = useRef()
  const lineRef = useRef()
  const makeRef = useRef()
  const modelRef = useRef()
  const capacityRef = useRef()
  const assetTypeRef = useRef()
  // const assetNoRef = useRef()
  const assetHashRef = useRef()

  useEffect(() => {
    assetHashRef.current.focus()
    dispatch(assetsListActions.postAddAssetSuccess(null))
  }, [])

  const [loadingData, setLoadingData] = useState(false)
  const [inputValues, setInputValues] = useState({})
  const [inputLineValues, setInputLineValues] = useState({})
  const [lineValues, setLineValues] = useState({})
  const [openDialog, setOpenDialog] = useState(false)
  const [openDialogLine, setOpenDialogLine] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [locationData, setLocationData] = useState()
  const [assetData, setAssetData] = useState([])
  const [locationId, setLocationId] = useState()
  const [modelList, setModelList] = useState([])
  const [addValue, setAddValue] = useState('')
  const [justAddedAssetTypeAndRemoveThisWithRedux, setJustAddedAssetTypeAndRemoveThisWithRedux] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [checkValue, setCheckValue] = useState('')
  const [flagged, setFlagged] = useState(false)
  const [removeTypeOnSave, setRemoveTypeOnSave] = useState(false)
  const [doWeUseTypeId, setDoWeReallyUseTypeId] = useState(false)
  const [item, setItem] = useState()
  const [valueForAsset, setValueForAsset] = useState([])
  const [valueForLine, setValueForLine] = useState([])
  const [isMapNominateImageShow, setMapNominateImageShow] = useState(false)
  // ! replaced appraiserList with uniqueAppraiserData wherever we assign an appraiser when loading the page
  //! or changing the location to get the correct appraiser at all times
  const [uniqueAppraiserData, setUniqueAppraiserData] = useState([])

  const [isaction, setAction] = useState(false)
  const [handleTitle, setHandleTitle] = useState(false)
  const [assetPhotosCount, setAssetPhotosCount] = useState(0)
  const [assetPhotos, setAssetPhotos] = useState([])
  const [isChange, setChange] = useState(false)
  // const [isfieldEnbled, setIsFieldEnabled] = useState(false)
  const [showFullImage, setShowFullImage] = useState(false)
  // const [locationEditDisabled, setLocationEditDisabled] = useState(false)
  const [imagePath, setImagePath] = useState()
  const [isHeaderDataShow, setHeaderDataShow] = useState(false)
  const [isPreviousValueSave, setPreviousValueSave] = useState(false)
  const [isDataSave, setDataSaved] = useState(false) // true on after every action perform so that we save data on previous icon
  const locationsDataFull = locationData?.locationsDataFull
  const appraiserList = assetData?.appraiserList // ! not correct when we are editing an asset, we only get that particular locations appraisers
  const projectPriceValues =
    homeState?.locationsList?.locationsList?.data?.projectPriceValues
  const url = process.env.REACT_APP_IMAGE_BASE_URL

  let userId = localStorage.getItem('userId')
  let projectIDCrm = localStorage.getItem('project_id_crm')
  let locationIDHome = localStorage.getItem('location_id_home')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string
  const data = props?.location?.state?.data
  const typeId = assetState?.postAddAsset?.postAddAsset?.data?.id
  const saveAndCopyNewTypeId =
    assetState?.saveAndCopyNew?.saveAndCopyNew?.data?.id
  const saveAndCopyBelowTypeId =
    assetState?.saveAndCopyBelow?.saveAndCopyBelow?.data?.id
  // useEffect(() => {
  //   localStorage.removeItem('actionPerformed')
  // }, [])
  // for updating all data in next and previous click

  const filterAppraiserId = localStorage.getItem('selectedAppraiser')
  const filterArea = localStorage.getItem('selectedArea')
  // const filterLocation = localStorage.getItem('')
  const refPayload = {
    pageNumber: 3, // not needed but sending just till we update the api post prod
    pageSize: 60, // not needed but sending just till we update the api post prod
    sortCol: 'area_order',
    sortOrder: 'asc',
    action: 'getAssetInEdit',
    isAudio: false, // not needed but sending just till we update the api post prod
    NForDeleted: false, // not needed but sending just till we update the api post prod
    showOnlyAsset: false, // not needed but sending just till we update the api post prod
    hideCategorized: false, // not needed but sending just till we update the api post prod
    appraiser: filterAppraiserId,
    make: null, // not needed but sending just till we update the api post prod
    model: null, // not needed but sending just till we update the api post prod
    capacity: null, // not needed but sending just till we update the api post prod
    area: filterArea, // not needed but sending just till we update the api post prod
    global_search: null, // not needed but sending just till we update the api post prod
    location: null, // not needed but sending just till we update the api post prod
    currentPageNumber: 3, // not needed but sending just till we update the api post prod
    projectId: locationsDataFull?.[0]?.project_id,
    project_id_crm: decodedProjectId,
    // type_id: nextObj.rowData.type_id
  };

  // ! not sure why this was implemented and took forever to find but this caused issues with make, asset type, model and capacity typeaheads to get reset
  // useEffect(() => { // ! we force reset the type-ahead component. Now need to optimize the rest of this file accordingly
  //   if (!inputValues?.asset_type_id) assetTypeRef?.current?.clear()
  //   if (!inputValues?.make) makeRef?.current?.clear()
  //   if (!inputValues?.capacity) capacityRef?.current?.clear()
  //   if (!inputValues?.model) modelRef?.current?.clear()
  // }, [inputValues])



  useEffect(() => {
    if (data === undefined) {
      setAction(true) // for change heading of add asset or edit asset when we do save and copy below , or other action
      setItem('Add Asset')
      localStorage.removeItem('selectedAppraiser')
      localStorage.removeItem('selectedArea')
      if(localStorage.getItem('manually_updated_location')) {
        localStorage.removeItem('location_id_home')
      }
      // locationIDHome ? setLocationEditDisabled(true) : null
    } else {
      localStorage.removeItem('actionPerformed') // remove this because when we add asset after insert above below and again come back and then cancel and  add another asset  then it add in last and it handle item.name 
      // setLocationEditDisabled(true)
      setDoWeReallyUseTypeId(true) // setting to true for when we first edit an asset.
      if (localStorage.getItem('resetType')) {
        localStorage.removeItem('resetType')
        return setItem('Add Asset')
      }
      setItem(data?.key);
    }
  }, []);

  useEffect(() => {
    //? add new asset always on last location add , edit asset
    const homeLocation = localStorage.getItem('location_id_home')
    let lastActiveLocation = localStorage.getItem('lastAssetAddLocation')
    let activeLocation = 0
    if (!data) {
      if (lastActiveLocation && lastActiveLocation != 'undefined') {
        activeLocation = lastActiveLocation
      } else if (homeLocation) {
        activeLocation = homeLocation
      } else {
        activeLocation = locationsDataFull?.[0]?.location_id?.toString()
      }
      setInputValues({ location_id: activeLocation })
      setLocationId(activeLocation)
    }
  }, [locationsDataFull])

  useEffect(() => {
    setLocationData(homeState?.locationsList?.locationsList?.data);
  }, [homeState?.locationsList?.locationsList]);

  useEffect(() => {
    return () => {
      // ! for scroll functionality
      localStorage.removeItem('alreadyScrolled');
      dispatch(assetsListActions.postAddAssetSuccess(null))
    }
  }, [])
  useEffect(() => {
    // ! need to validate why we are doing this
    let lastAssetAddLocation = localStorage.getItem('lastAssetAddLocation')
    let location =
      locationIDHome
        ? +locationIDHome
        : lastAssetAddLocation ? lastAssetAddLocation : locationsDataFull?.[0]?.location_id?.toString()


    let assetData = {
      location_id: +location,
      project_id_crm: decodedProjectId,
      flag: 'addAsset',
    };

    let assetDataEdit = {
      location_id: data ? data?.rowData?.location_id : locationId,
      project_id_crm: decodedProjectId,
      assetId: +params?.id ? params?.id : data?.rowData?.type_id,
    };

    let areaData = {
      location_id: data
        ? data?.rowData?.location_id?.toString()
        : +location
          ? +location
          : locationsDataFull?.[0]?.location_id?.toString(),
    };

    let obj = {
      location_id: data ? data?.rowData?.location_id : +location,
      getLastAsset: true,
      project_id_crm: decodedProjectId,
      flag: 'addAsset',

    };
    // if(!obj.location_id) return
    if (data) {
      dispatch(getAssetDetailsAction(assetDataEdit));
    }
    if (!data) {
      dispatch(getAssetDetailsAction(assetData));
    }
    dispatch(getLocationsDataAction(obj));
    dispatch(getAreaListAction(areaData));

  }, [locationsDataFull?.[0]?.location_id, data]);

  useEffect(() => {
    let makeCapacityData = {
      type: 'getMakeCapacityList',
      project_id_crm: decodedProjectId,
    };

    dispatch(getMakeCapacityAction(makeCapacityData));
    if (
      !assetState?.getModelByMake.loading &&
      assetState?.getModelByMake?.getModelByMake?.length === 0 &&
      !MODEL_DATA
    ) {
      dispatch(getModelByMakeAction())
    }
  }, [dispatch, data]);

  useEffect(() => {
    setAssetData(lineState?.assetDetails?.assetDetails?.data);
  }, [lineState?.assetDetails?.assetDetails]);

  useEffect(() => {
    if (assetState?.getModelByMake?.getModelByMake?.length === 0)
      setModelList([]);
    let filterModelList = assetState?.getModelByMake?.getModelByMake?.filter(
      (item) => item.make_id === inputValues?.make_id
    );
    setModelList(filterModelList);
  }, [assetState?.getModelByMake?.getModelByMake, inputValues?.make_id]);

  useEffect(() => {
    let lastAssetAddLocation = localStorage.getItem('lastAssetAddLocation')
    let location;
    if (locationId) {
      location = locationId
    } else if (data && data?.rowData?.location_id) {
      location = data?.rowData?.location_id
    } else if (lastAssetAddLocation) {
      location = lastAssetAddLocation
    } else if (locationIDHome) {
      location = locationIDHome
    }
    // locationIDHome !== 0 && locationIDHome !== null
    //   ? +locationIDHome
    //  : lastAssetAddLocation ? lastAssetAddLocation : locationsDataFull?.[0]?.location_id?.toString()
    let obj = {
      // location_id: data ? data?.rowData?.location_id : location,
      location_id: location,
      area:
        inputValues?.area === undefined ? 'Undefined Area' : inputValues?.area,
    };
    if (obj?.area && !lineState.lineByAreaList?.loading) {
      // lineRef.current.clear() //? validate if needed
      if(!obj.location_id) return
      dispatch(getLineByAreaListAction(obj));
    }
  }, [inputValues?.area, inputValues?.line_name, locationId]);

  useEffect(() => {
    if (lineState?.messageData.status === 'success') {
      setMessage(lineState?.messageData?.message);
      setStatus('success');
      setAddValue('');
      setOpenDialog(false);
      setOpenDialogLine(false);
      setInputLineValues({});
      setLineValues({});
      let obj = {
        location_id: data ? data?.rowData?.location_id : locationId,
        area: inputValues?.area ?? 'Undefined Area',
      };
      if(!obj.location_id) return
      dispatch(getLineByAreaListAction(obj));
    }
    if (lineState?.messageData.status === 'failed') {
      setMessage(lineState?.messageData?.message);
      setStatus('warning');
    }
  }, [lineState?.messageData]);

  useEffect(() => {
    if (assetState?.messageData.status === 'success') {
      setMessage(assetState?.messageData?.message);
      setStatus('success');
    }
  }, [assetState?.messageData]);

  const makeList =
    lineState?.makeCapacityList?.makeCapacityList?.data?.makeList;
  const capacityList =
    lineState?.makeCapacityList?.makeCapacityList?.data?.capacityList;
  const areaList = adminState?.areaList?.areaList;
  const assetTypes = assetData?.assetTypeList;
  const lineList = lineState?.lineByAreaList?.lineByAreaList;
  const filteredAssetTypes =
    assetTypes?.length > 0 &&
      assetTypes[0]?.asset_type === 'Select or search asset type'
      ? assetTypes?.slice(1)
      : assetTypes;



  useEffect(() => { 
  //! Assign line name to selectedLine when we do not have line_name property in the current asset
  //! only works when we have "data, lineList, selectedLine and selectedArea" and selectedLine does not have line_name
    if(!data || !lineList?.length) return 
    if(inputValues?.selectedLine?.length && +inputValues?.selectedLine[0]?.type_id > 0 && !inputValues?.selectedLine[0]?.line_name) {
      const currentLineName = lineList.find(({type_id}) => {
        return +type_id === +inputValues?.selectedLine[0]?.type_id
    })
    if(currentLineName) {
      setInputValues({...inputValues, selectedLine: [{ line_name: currentLineName?.line_name, type_id: inputValues?.selectedLine[0]?.type_id }]})
    }
    }
  }, [lineList, data, inputValues, inputValues?.selectedArea])
  useEffect(() => {
    if (data?.sleekMode || justAddedAssetTypeAndRemoveThisWithRedux) return // error
    if (lineState?.assetDetails?.assetDetails?.data) {
      assetHashRef.current.focus() // when change location
      areaRef.current.clear() // clear on change location
      const dataToEdit =
        lineState?.assetDetails?.assetDetails?.data?.assetDetails;
      const valueCode =
        lineState?.assetDetails?.assetDetails?.data?.assetDetails?.value_code
          ?.split('#')
          ?.map((item) => {
            return item;
          });
      const values =
        lineState?.assetDetails?.assetDetails?.data?.assetDetails?.value
          ?.split('#')
          ?.map((item, index) => {
            return { [valueCode[index]]: item };
          });
      const finalValues = values?.reduce(
        (acc, item) => ({ ...acc, ...item }),
        {}
      );
      const updatedData = { ...dataToEdit };
      // updatedData.location_id = inputValues?.location_id ?? locationIDHome
      updatedData.quantity = updatedData.quantity ?? 1
      updatedData.serial_label_id = updatedData.serial_label_id ?? 1,
        updatedData.model_label_id = updatedData.model_label_id ?? 1,
        updatedData.mileage_type = updatedData.mileage_type ?? 'mileage',
        updatedData.quantity_type = updatedData.quantity_type ?? 'number'
      updatedData.condition = updatedData.condition ?? 'Good'
      updatedData.asset_class_id= updatedData?.asset_class_id ? updatedData?.asset_class_id  : 0 //! not sure why this was being set to 0 and might be a bug that had been resolved so it's a risk but here we go
      // updatedData.appraiser = lineState?.assetDetails?.assetDetails?.data?.assetDetails?.appraiser ? lineState?.assetDetails?.assetDetails?.data?.assetDetails?.appraiser : lineState?.assetDetails?.assetDetails?.data?.appraiserList.length && lineState?.assetDetails?.assetDetails?.data?.appraiserList[0]?.user_id
      updatedData.appraiser = lineState?.assetDetails?.assetDetails?.data?.assetDetails?.appraiser ? lineState?.assetDetails?.assetDetails?.data?.assetDetails?.appraiser : uniqueAppraiserData.length && uniqueAppraiserData[0]?.user_id

      dataToEdit &&
        Object.keys(dataToEdit).forEach((i) => {
          if (i == 'make' && dataToEdit[i]) {

            updatedData.selectedMake = [
              { make: dataToEdit.make, make_id: dataToEdit.make_id },
            ];
          } else {
            makeRef.current.clear()
          }
          if (i == 'model' && dataToEdit[i]) {

            updatedData.selectedModel = [{ model: dataToEdit.model }];
          } else {
            modelRef.current.clear()
          }
          if (i == 'capacity' && dataToEdit[i]) {

            updatedData.selectedCapacity = [{ capacity: dataToEdit.capacity }];
          } else {
            capacityRef.current.clear()
          }
          if (i == 'area' && dataToEdit[i]) {

            updatedData.selectedArea = [{ area: dataToEdit.area }];
          } else {
            areaRef.current.clear()
          }
          if (i === 'parent_line') {
            updatedData.selectedLine = dataToEdit.parent_line
              ? [
                {
                  line_name: lineList?.filter(
                    ({ type_id }) => +type_id == +dataToEdit.parent_line
                  )[0]?.line_name,
                  type_id: +dataToEdit?.parent_line
                },
              ]
              : [];
            }
          if (i == 'asset_type_id' && dataToEdit[i]) {
            const foundAsset = filteredAssetTypes?.find(
              (i) => +i.asset_type_id === +dataToEdit.asset_type_id
            );
            updatedData.selectedAssetType = [
              {
                asset_type_id: dataToEdit.asset_type_id,
                asset_type: foundAsset?.asset_type,
              },
            ];
          }
        });
      setInputValues({ ...updatedData, ...finalValues });
    }
  }, [lineState?.assetDetails?.assetDetails]);




  // console.log('input values', inputValues)
  //! wtf, we even need this?
  // useEffect(() => {
  //   if (data?.sleekMode) return
  //   if (lineList.length) {
  //     setInputValues({ ...inputValues, selectedLine: [{ line_name: lineList?.[0]?.line_name }] })
  //   }
  // }, [lineList, data])

  //code-break in this useeffect
  //dont need of this
  // useEffect(() => {
  //   if (data?.sleekMode) return
  //   if (appraiserList && !inputValues?.appraiser) {
  //     setInputValues({ ...inputValues, appraiser: appraiserList]?.user_id, condition: 'Good', quantity_type: 'number', quantity: '1', mileage_type: 'Mileage' })
  //   }
  //   if (!inputLineValues?.condition) {
  //     setInputLineValues({ ...inputLineValues, condition: 'Good' })
  //   }
  // }, [appraiserList, inputValues?.appraiser])

  useEffect(() => {
    if (!data?.sleekMode) {
      fetchPhotosByAssetId('asset', data?.rowData?.type_id).then((res) => {
        if (res?.asset_photos !== undefined) {
          if (res?.asset_photos[data?.rowData?.type_id] !== undefined) {
            setAssetPhotos(res?.asset_photos[data?.rowData?.type_id]);
            if (
              res?.asset_photos != undefined &&
              res?.asset_photos[data?.rowData?.type_id] != undefined
            ) {
              setAssetPhotosCount(
                Object.keys(res?.asset_photos[data?.rowData?.type_id]).length
              );
            } else {
              setAssetPhotosCount(0);
            }
          }
        }
      });
    }
  }, [data]);
  useEffect(() => {
    if (data?.sleekMode) {
      setDataSaved(false)

      // setIsFieldEnabled(true);
      setChange(false);
      setAction(true);
      lineRef.current.clear();
      makeRef.current.clear();
      modelRef.current.clear();
      capacityRef.current.clear();
      assetTypeRef.current.clear();
      setDoWeReallyUseTypeId(false);
      // setInputValues({ appraiser: 0, condition: 'Good', quantity_type: 'number', quantity: '1', mileage_type: 'Mileage', serial_label_id: 1, model_label_id: 1 })
    } else {
      setHeaderDataShow(true)
      // setDataSaved(true)
      setChange(true);
      setMapNominateImageShow(true)
      // setIsFieldEnabled(true)
      const dataToEdit = { ...data?.rowData, ...assetData?.assetDetails }

      const valueCode = dataToEdit?.value_short_name
        ? dataToEdit?.value_short_name?.split('#')?.map((item) => {
          return item
        })
        : ''
      const values = valueCode
        ? dataToEdit?.value?.split('#')?.map((item, index) => {
          return { [valueCode[index]]: item }
        })
        : ''
      const finalValues = values
        ? values?.reduce((acc, item) => ({ ...acc, ...item }), {})
        : ''
      const updatedData = { ...dataToEdit }
      updatedData.flagged = data?.rowData?.flagged
      updatedData.is_not_found = data?.rowData?.is_not_found
      // updatedData.appraiser = +data?.rowData?.user_id ?? appraiserList[0]?.user_id // aspper discussion with manish sir
      updatedData.appraiser = +data?.rowData?.user_id ?? uniqueAppraiserData?.[0]?.user_id
      updatedData.mileage_type = dataToEdit.mileage_type
      updatedData.quantity_type = dataToEdit.quantity_type
      // updatedData.line_name = lineList?.filter(({ type_id }) => +type_id == +dataToEdit?.parent_line)[0]?.line_name
      dataToEdit &&
        Object.keys(dataToEdit).forEach((i) => {
          if (i == 'make')
            updatedData.selectedMake = [
              { make: dataToEdit?.make, make_id: dataToEdit?.make_id },
            ]
          if (i == 'model')
            updatedData.selectedModel = [{ model: dataToEdit?.model }]
          if (i == 'capacity')
            updatedData.selectedCapacity = [{ capacity: dataToEdit?.capacity }]
          if (i == 'area')
            updatedData.selectedArea = [{ area: dataToEdit?.area }]
          if (i === 'parent_line')
            updatedData.selectedLine = dataToEdit.parent_line
              ? [
                {
                  line_name: lineList?.filter(({ type_id }) => +type_id == +dataToEdit.parent_line)[0]?.line_name,
                  type_id: +dataToEdit?.parent_line
                },
              ]
              : []
          if (i == 'asset_type_id') {
            const foundAsset = filteredAssetTypes?.find(
              (i) => +i.asset_type_id === +dataToEdit?.asset_type_id
            )
            updatedData.selectedAssetType = [
              {
                asset_type_id: dataToEdit?.asset_type_id,
                asset_type: foundAsset?.asset_type,
              },
            ]
          }
        })
      setFlagged(data?.rowData?.flagged)
      setCheckValue(data?.rowData?.is_not_found?.toString())
      setInputValues({ ...updatedData, ...finalValues })
    }
  }, [data]);

  useEffect(() => {
    if (data?.sleekMode) { // change this because when we save with new data then this useefect replace updated data by this
      let validatedLocationId;// ? need to confirm if this works or not once Ritik tests this out
      if (locationId) {
        if (+locationId !== +data?.rowData?.location_id) {
          validatedLocationId = +locationId
        }
      } else {
        validatedLocationId = +data?.rowData?.location_id
      }
      setInputValues({
        area: data?.rowData?.area,
        location_id: validatedLocationId,
        selectedArea: [{ area: data?.rowData?.area }],
        selectedLine: [{ line_name: data?.rowData?.line_name, type_id: data?.rowData?.parent_line }],
        // appraiser: appraiserList?.[0]?.user_id,
        appraiser: uniqueAppraiserData?.[0]?.user_id,
        condition: 'Good',
        quantity_type: 'number',
        quantity: '1',
        mileage_type: 'mileage',
        serial_label_id: 1,
        model_label_id: 1,
        area_asset_order: assetData?.assetDetails?.area_asset_order,
      });
      // return;
    }
  }, [data?.sleekMode, appraiserList]);
  useEffect(() => {
    if (!data) {
      setInputValues({
        ...inputValues,
        location_id: +locationId,
        // appraiser: appraiserList?.[0]?.user_id,
        appraiser: uniqueAppraiserData?.[0]?.user_id,
        condition: 'Good',
        quantity_type: 'number',
        quantity: '1',
        mileage_type: 'mileage',
      });
    }
  }, [appraiserList, handleTitle]);

  useEffect(() => {
    let priceVal = [];
    const finalValue = assetData?.locationPriceValues;
    finalValue?.forEach((item) => {
      for (const key in inputLineValues) {
        if (item.value_short_name === key) {
          let obj = {
            name: item.value_code,
            value: inputLineValues[key],
          };
          priceVal.push(obj);
        }
      }
    })
    setValueForLine(priceVal)
  }, [inputLineValues])

  useEffect(() => {
    let priceVal = [];
    const finalValue = assetData?.locationPriceValues;
    finalValue?.forEach((item) => {
      for (const key in inputValues) {
        if (item.value_short_name === key) {
          let obj = {
            name: item.value_code,
            value: inputValues[key],
          };
          priceVal.push(obj);
        }
      }
    });
    setValueForAsset(priceVal);
  }, [inputValues]);

  const transformedPriceValForLines = valueForLine?.reduce((result, item) => {
    result[item.name] = item.value;
    return result;
  }, {});

  const transformedPriceValForAsset = valueForAsset?.reduce((result, item) => {
    result[item.name] = item.value;
    return result;
  }, {});

  const handleChange = (e) => {
    setChange(true);
    if(e.target.name === 'special_notes_type') {
      if (e.target.value == 'Create a New Note') {
        setInputValues({...inputValues, [e.target.name]: e.target.value, special_notes : null})
      } else {
        setInputValues({...inputValues, [e.target.name]: e.target.value, special_notes : e.target.value})
      }
    } else if (e.target.name === 'area' && e.target.value.length <= 250) {
      setInputValues({ ...inputValues, area: e.target.value })
    } else if (e.target.name === 'quantity' && e.target.value.length <= 6) {
      setInputValues({ ...inputValues, quantity: e.target.value })
    } else if (e.target.name === 'quantity_type' && e.target.value === 'lot') {
      setInputValues({
        ...inputValues,
        quantity: '',
        quantity_type: e.target.value,
      });
    } else if (
      e.target.name === 'quantity_type' &&
      e.target.value === 'number'
    ) {
      setInputValues({
        ...inputValues,
        quantity: 1,
        quantity_type: e.target.value,
      });
    } else {
      setInputValues({ ...inputValues, [e.target.name]: e.target.value });
    }
  };

  const handleChangeLine = (e) => {
    let value = e.target.value;
    if (e.target.name === 'line_text2') {
      if (value.length < 100) {
        setInputLineValues({ ...inputLineValues, line_text2: e.target.value });
      }
    } else if (e.target.name === 'line_name') {
      if (value.length < 500) {
        setInputLineValues({ ...inputLineValues, line_name: e.target.value });
      }
    } else {
      setInputLineValues({
        ...inputLineValues,
        [e.target.name]: e.target.value,
      });
    }
    // setInputLineValues({ ...inputLineValues, [e.target.name]: e.target.value })
  };

  const handleKeyDownForTypeAhead = (e) => {
    const {placeholder, value} = e.target
    if(!value) return
    if(e.keyCode === 9) {
      switch (placeholder) {
        case 'Search Area':
          setInputValues({...inputValues, selectedLine: [], selectedArea: [{area: value}], area: value})
          lineRef.current.clear()
          break;
        case 'Search Make':
          const makeId = makeList?.find(({make}) => make === value)?.make_id
          setInputValues({...inputValues, selectedMake: [{make: value}], make: value, make_id: makeId})
        break;
        case 'Search Model':
          setInputValues({...inputValues, selectedModel: [{model: value}], model: value})
        break;
        case 'Search Capacity':
          setInputValues({...inputValues, selectedCapacity: [{capacity: value}], capacity: value})
        break;
        case 'Search Line':
          const lineId = lineList.find(({line_name}) => line_name === value)?.type_id
                        setInputValues({
          ...inputValues,
          selectedLine: [{line_name: value, type_id: lineId}],
          line_name: value,
        });
        break;
        case 'Search Asset Types':
          const assetTypeValue = filteredAssetTypes?.find(({asset_type}) => asset_type === value)
          setInputValues({...inputValues, selectedAssetType: [{asset_type: value}], asset_type: value, asset_type_id: assetTypeValue?.asset_type_id, asset_class_id: assetTypeValue?.class_id})
        //   setInputValues({...inputValues, selectedAssetType: [{asset_type: value}],
        //   asset_type_id: label ? null : selected[0]?.asset_type_id,
        //   asset_class_id: +selected[0]?.class_id ? +selected[0]?.class_id : 0,
        // });
        break;
        default:
          break;
      }
      // if (placeholder == 'Search Area') {
      // setInputValues({...inputValues, selectedLine: [], selectedArea: [{area: value}], area: value})
      // lineRef.current.clear()
      // }
    }
    // console.log(e)
  }
  const handleCustomTypeAheadForArea = (areaVal) => {
    if(Array.isArray(areaVal)) return 
        setInputValues({...inputValues, selectedLine: [], selectedArea: [{area: areaVal}], area: areaVal})
      lineRef.current.clear()
  }
  const handleCustomTypeAheadForMake = (makeValue) => {
    if(Array.isArray(makeValue)) return 
        setInputValues({...inputValues, selectedMake: [{make: makeValue}], make: makeValue})
  }
  const handleCustomTypeAheadForModel = (modelValue) => {
    if(Array.isArray(modelValue)) return 
        setInputValues({...inputValues, selectedModel: [{model: modelValue}], model: modelValue})
  }
  const handleCustomTypeAheadForCapacity = (capacityValue) => {
    if(Array.isArray(capacityValue)) return 
        setInputValues({...inputValues, selectedCapacity: [{capacity: capacityValue}], capacity: capacityValue})
  }
  const handleCustomTypeAheadForAssetType = (assetTypeValue) => {
    if(Array.isArray(assetTypeValue)) return 
        setInputValues({...inputValues, selectedAssetType: [{asset_type: assetTypeValue}], asset_type: assetTypeValue})
  }
  console.log(inputValues)
  const handleSelectedForLineAndAssetType = (selected, type) => {
    setChange(true);
    let label;
    if (selected[0]?.label) label = true;
    if(type === 'assetType') {
              setInputValues({
          ...inputValues,
          selectedAssetType: selected,
          asset_type: label ? selected[0]?.label : selected[0]?.asset_type,
          asset_type_id: label ? null : selected[0]?.asset_type_id,
          asset_class_id: +selected[0]?.class_id ? +selected[0]?.class_id : 0,
        });
    } else if (type === 'line') {
              setInputValues({
          ...inputValues,
          selectedLine: selected,
          line_name: label ? selected[0]?.label : selected[0]?.line_name,
        });
    }
  }
  const handleSelected = (selected, type) => {
    setChange(true);
    let label;
    if(selected.length === 0) return
    // we set lable in case we are adding a custom value and not one that is already in the list
    if (selected[0]?.label) label = true;
    switch (type) {
      // if we have a label, we set the values accordingly based on a ternary check
      case 'area':
        setInputValues({
          ...inputValues,
          selectedLine:[],
          // parent_line: null,
          selectedArea: selected,
          area: label ? selected[0]?.label : selected[0]?.area,
        });
        lineRef.current.clear()
        break;
      case 'line':
        setInputValues({
          ...inputValues,
          selectedLine: selected,
          line_name: label ? selected[0]?.label : selected[0]?.line_name,
        });
        break;
      case 'make':
        setInputValues({
          ...inputValues,
          selectedMake: selected,
          make: label ? selected[0]?.label : selected[0]?.make,
          make_id: label ? null : selected[0]?.make_id,
        });
        break;
      case 'model':
        setInputValues({
          ...inputValues,
          selectedModel: selected,
          model: label ? selected[0]?.label : selected[0]?.model,
        });
        break;
      case 'capacity':
        setInputValues({
          ...inputValues,
          selectedCapacity: selected,
          capacity: label ? selected[0]?.label : selected[0]?.capacity,
        });
        break;
      case 'assetType':
        setInputValues({
          ...inputValues,
          selectedAssetType: selected,
          asset_type: label ? selected[0]?.label : selected[0]?.asset_type,
          asset_type_id: label ? null : selected[0]?.asset_type_id,
          asset_class_id: +selected[0]?.class_id ? +selected[0]?.class_id : 0,
        });
        break;
      default:
        break;
    }
  };

  const handleSelectedForLines = (selected, type) => {
    let label;
    // we set lable in case we are adding a custom value and not one that is already in the list
    if (selected[0]?.label) label = true;
    switch (type) {
      // if we have a label, we set the values accordingly based on a ternary check
      case 'make':
        setInputLineValues({
          ...inputLineValues,
          selectedMake: selected,
          make: label ? selected[0]?.label : selected[0]?.make,
          make_id: label ? null : selected[0]?.make_id,
        });
        break;
      case 'assetType':
        setInputLineValues({
          ...inputLineValues,
          selectedAssetType: selected,
          asset_type: label ? selected[0]?.label : selected[0]?.asset_type,
          asset_type_id: label ? null : selected[0]?.asset_type_id,
          asset_class_id: +selected[0]?.class_id ? +selected[0]?.class_id : 0,
        })
        break;
      default:
        break;
    }
  }
  const handleChangeValues = (e, type) => {
    setChange(true);
    if (type === 'location') {
      assetHashRef.current.focus() // check faraz 
      areaRef.current.clear() // check faraz
      // if we click on save button then location is change only with same data in add edit above below and no api call  
      if (!isPreviousValueSave && item?.name !== 'Edit Asset') {
        setCheckValue(null)
        setFlagged(false)
        let assetData = {
          location_id: e.target.value.toString(),
          project_id_crm: decodedProjectId,
          flag: 'addAsset',
        };
        setLocationId(e.target.value)
        dispatch(getAssetDetailsAction(assetData));
      } else if (!isPreviousValueSave && item?.name === 'Edit Asset') {

        let assetData = {
          location_id: e.target.value.toString(),
          project_id_crm: decodedProjectId,
          assetId: typeId ? typeId : params.id, // !will also remove this in phase 2
        };
        // setLocationEditDisabled(true)
        dispatch(getAssetDetailsAction(assetData));
      } else {
        setLocationId(e.target.value)
        setInputValues({ ...inputValues, location_id: e.target.value });
      }
      let areaData = {
        location_id: e.target.value.toString(),
      };
      dispatch(getAreaListAction(areaData));
      setLineValues({ ...lineValues, [e.target.name]: e.target.value });
    }
  };
  const handleAssetFocus = () => {
    let updatedInputValues = { ...inputValues }
    const allowedValues = ['no value', 'leased', 'not valued'];
    projectPriceValues.forEach((val) => {
      if (allowedValues.includes(updatedInputValues[val]?.toLowerCase()?.trim()) || +updatedInputValues[val] > 0) {
        // Valid value, do nothing
      } else {
        updatedInputValues[val] = '' // Reset invalid value
      }
    })

    setInputValues(updatedInputValues)
  };


  const handleLineFocus = () => {
    let updatedInputValues = { ...inputLineValues }
    const allowedValues = ['no value', 'leased', 'not valued'];
    projectPriceValues.forEach((val) => {
      if (
        allowedValues.includes(
          updatedInputValues[val]?.toLowerCase()?.trim()
        ) ||
        +updatedInputValues[val] > 0
      ) {
        // Valid value, do nothing
      } else {
        updatedInputValues[val] = '' // Reset invalid value
      }
    })

    setInputLineValues(updatedInputValues)
  }

  const handleChangeLineValues = (e) => {
    const { name, value } = e.target;
    setInputLineValues({ ...inputLineValues, [name]: value });
  };

  const handleChangeAdd = (e) => {
    let value = e.target.value;
    if (value?.length <= 40) {
      setAddValue(e.target.value);
    }
  };
  const handleDialog = () => {
    setOpenDialog(!openDialog);
    setAddValue('');
  };
  const handleAdd = () => {
    if (!addValue) {
      setMessage('Please Enter Asset Type');
      setStatus('warning');
      return;
    }
    let addObj = {
      created_by: Number(userId),
      modified_by: Number(userId),
      type: addValue,
      location_id: locationId,
      project_id_crm: decodedProjectId,
      assetId: params?.id,
    }
    const isAlreadyExists = assetTypes?.find(
      ({ asset_type }) => asset_type === addValue
    )
    if (isAlreadyExists) {
      setMessage('Asset Type Already Exists');
      setStatus('warning');
    } else {
      setJustAddedAssetTypeAndRemoveThisWithRedux(true)
      dispatch(addAssetTypeAction(addObj));
      handleDialog();
    }
  };

  const handleDialogLine = () => {
    setOpenDialogLine(!openDialogLine);
    setInputLineValues({
      condition: 'Good',
      asset_class_id: 1,
      line_text2: ', Consisting of:',
    });
    setAddValue('');
  };

  const handleConfirmation = () => {
    setOpenConfirmation(!openConfirmation);
  };

  const handleCheckbox = (e) => {
    setChange(true); // do this for validation when we fill any one of the field and then save
    setCheckValue(e.target.value);
    setInputValues({ ...inputValues, is_not_found: e.target.value });
  };

  const handleFlagged = (e) => {
    setChange(true); // do this for validation when we fill any one of the field and then save
    setFlagged(e.target.checked);
    setInputValues({ ...inputValues, flagged: e.target.checked });
  };
  const CheckType = (value) => {
    let flag;
    let digitRegex = /^[0-9]+$/;
    let valid = digitRegex.test(value);
    if (valid) {
      flag = true;
    } else {
      flag = false;
    }
    return flag;
  };

  const validateCost = (item) => {
    // ! A total of 6 cases are being handled as per the requirements
    switch (item) {
      case 'FLV':
        if (+inputValues.FLV > +inputValues.OLV || +inputValues.FLV > +inputValues.FMV) return true
        break
      case 'OLV':
        if (+inputValues.OLV > +inputValues.FMV || +inputValues.OLV > +inputValues.OLVIP) return true
        break
      case 'FMV':
        if (+inputValues.FMV > +inputValues.FMVIP) return true
        break
      case 'OLVIP':
        if (+inputValues.OLVIP > +inputValues.FMVIP) return true
        break
      default:
        break
    }
  }
  const generatePriceKeys = () => {
    const valueCode = []; // ! updated logic to now use locationPriceValues instead of assetValues
    lineState?.assetDetails?.assetDetails?.data?.locationPriceValues.forEach(
      ({ value_short_name }) => valueCode.push(value_short_name)
    );
    const currentAssetValues =
      lineState?.assetDetails?.assetDetails?.data?.assetValues; // ! updated logic to now use assetValues instead of the state inputValues and now much faster too
    if (!currentAssetValues) return;
    // if(valueCode.length != currentAssetValues) {
    //   return 
    // }
    // const computedPriceKeys = Object.keys(currentAssetValues).reduce(
    //   (acc, i) => {
    //     if (valueCode.includes(i)) acc.push(i);
    //     return acc;
    //   },
    //   []
    // );
    // const computedPriceKeys = 
    const computedPriceKeys = []
    for (var i = 0; i < valueCode.length; i++) {
// data.assetValues[data.locationPriceValues[i]['value_short_name']] = $scope.formData.priceVal[data.locationPriceValues[i]['value_code']];
      // computedPriceKeys[valueCode[i]['value_short_name']] = $scope.formData.priceVal[data.locationPriceValues[i]['value_code']];
      computedPriceKeys.push(valueCode[i])
    }

    return computedPriceKeys;
  };
  const getPriceValues = (type) => {
    let computedPriceValues;

    if (!data || (data && data?.sleekMode)) {
      computedPriceValues = projectPriceValues;
    } else {
      computedPriceValues = generatePriceKeys();
    }
    if (type === 'asset') {
      return computedPriceValues?.map((item, index) => {
        let percentageValue = (inputValues[item] / inputValues?.cost) * 100;
        let type = CheckType(inputValues[item]);
        return (
          <Grid item md={2} key={index}>
            <Box className="input_box">
              <Typography>{item} ($):</Typography>
              {type ? (
                inputValues[item] && inputValues?.cost > 0 ? (
                  <Typography>{percentageValue.toFixed(2)}% of Cost</Typography>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              <CustomTextBox
                className={validateCost(item) ? 'coral_background' : null}
                onChange={(e) => handleChange(e, 'priceValue')}
                name={item}
                value={inputValues[item] ?? ''}
                onBlur={handleAssetFocus}
              />
            </Box>
          </Grid>
        );
      });
    } else {
      return computedPriceValues?.map((item, index) => {
        let percentageValue =
          (inputLineValues[item] / inputLineValues?.cost) * 100;
        let type = CheckType(inputLineValues[item]);
        return (
          <Grid item md={3} key={index}>
            <Box className="input_box">
              <Typography>{item} ($):</Typography>
              {type ? (
                inputLineValues[item] && inputLineValues?.cost > 0 ? (
                  <Typography>{percentageValue.toFixed(2)}% of Cost</Typography>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              <CustomTextBox
                className={validateCost(item) ? 'coral_background' : null}
                onChange={(e) => handleChangeLineValues(e)}
                name={item}
                value={inputLineValues[item]}
                onBlur={handleLineFocus}
              />
            </Box>
          </Grid>
        );
      });
    }
  };

  const handleDelete = () => {
    // computing for deleting current row and active previous row highlight
    const currentRowIndex = data?.rows?.findIndex(
      (item) => item?.type_id === Number(params?.id)
    );
    const currentRowOrder = currentRowIndex - 1;
    const previousRowData = data?.rows?.find(
      (item) => data?.rows?.indexOf(item) === currentRowOrder
    );
    dispatch(deleteInnerAssetAction(typeId ? typeId : params.id));
    localStorage.setItem('type_id', previousRowData?.type_id);
    history.push(routesNames.ASSETLIST);
  };

  const updateInputPriceValues = () => {
    const valueCode =
      lineState?.assetDetails?.assetDetails?.data?.assetDetails?.value_code?.split(
        '#'
      );
    if (!valueCode) return;

    const dynamicValues = valueCode.reduce((acc, key) => {
      // if (inputValues[key] !== undefined) {
      acc[key] = '';
      // acc[key] = inputValues[key];
      // }
      return acc;
    }, {});
    dynamicValues.area = inputValues?.area;
    dynamicValues.appraiser = inputValues?.appraiser;
    dynamicValues.quantity = inputValues?.quantity;
    dynamicValues.quantity_type = inputValues?.quantity_type;
    dynamicValues.line_name = inputValues?.line_name;
    setInputValues(dynamicValues);
  };

  const handleBack = () => {
    // const expectedKeys = [
    //   'quantity_type',
    //   'serial_label_id',
    //   'model_label_id',
    //   'location_id',
    //   'appraiser',
    //   'quantity',
    //   'condition',
    //   'mileage_type',
    // ];
    // const extraKeys = Object.keys(inputValues).filter(
    //   (key) => !expectedKeys.includes(key)
    // );
    const formInputValues = [
      'area',
      'line_name',
      'asset_no',
      'make',
      'model',
      'capacity',
      'asset_class_id',
      'asset_type_id',
      'asset_class_code',
      'serial_number',
      'description',
      'appraiser_note',
      'year',
      'cost',
      'mileage',
      'flagged',
      'is_not_found',
      'special_notes',
      'FLV',
      'OLV',
      'OLVIP',
      'FMVIP',
      'FMV',
      'FV',
      'CV',
    ];
    let flag = false;
    for (const key of formInputValues) {
      if (inputValues[key] && inputValues[key] !== '') {
        flag = true;
        break;
      }
    }
    if (isChange && flag) {
      handleSubmit('Save');
      setTimeout(() => { // ! most valid and best hotfix at the moment because the function is just too complex and will take a good 
        // ! 2-3 hours to handle outside of the handleSubmit function
        history.push(routesNames.ASSETLIST);
      }, 2000);
      // history.push('/asset-list');
    } else {
      history.push('/asset-list'); // save on this
    }
  };
  useEffect(() => {
    let lineListData = lineList;
    let flag = lineListData?.filter(
      ({ type_id }) => +inputValues?.selectedLine?.[0]?.type_id === +type_id
    );
    // console.log('flag', flag)
    if (flag?.length === 0) {
      setInputValues({ ...inputValues, line_name: '', parent_line: 0 })
    } else {
      setInputValues({ ...inputValues, line_name: '', parent_line: flag?.[0]?.type_id })
    }
  }, [inputValues?.area, inputValues?.line_name, data]);

  const handleSubmit = (type, dropTo = null) => {
    // const expectedKeys = [
    //   'quantity_type',
    //   'serial_label_id',
    //   'model_label_id',
    //   'location_id',
    //   'appraiser',
    //   'quantity',
    //   'condition',
    //   'mileage_type',
    // ];
    const formInputValues = [
      'area',
      'line_name',
      'asset_no',
      'make',
      'model',
      'capacity',
      'asset_class_id',
      'asset_type_id',
      'asset_class_code',
      'serial_number',
      'description',
      'appraiser_note',
      'year',
      'cost',
      'mileage',
      'flagged',
      'is_not_found',
      'special_notes',
      'FLV',
      'OLV',
      'OLVIP',
      'FMVIP',
      'FMV',
      'FV',
      'CV',
    ];
    const formInputValuesForInsertAssetAboveAndBelow = [
      'asset_no',
      'make',
      'model',
      'capacity',
      'asset_class_id',
      'asset_type_id',
      'asset_class_code',
      'serial_number',
      'description',
      'appraiser_note',
      'year',
      'cost',
      'mileage',
      'flagged',
      'is_not_found',
      'special_notes',
      'FLV',
      'OLV',
      'OLVIP',
      'FMVIP',
      'FMV',
      'FV',
      'CV',
    ];
    let condition = item === 'Insert Asset Above' || 'Insert Asset Below';
    let flag = false;
    if (condition) {
      if (inputValues?.selectedLine?.length) flag = true //because of this a new bug is create handle it- ticket- 2205 2nd point ! just for line, might have to remove as part of redux cleanup and optimize

      for (const key of [...formInputValuesForInsertAssetAboveAndBelow, 'area']) { // ! hotfix - again need to find the cause when removing redux
        if (inputValues[key] && inputValues[key] !== '') {
          flag = true;
          break;
        }
      }
    } else {
      if (inputValues?.selectedLine?.length) flag = true // because of this a new bug is create handle it- ticket- 2205 2nd point, ! just for line, might have to remove as part of redux cleanup and optimize
      for (const key of formInputValues) {
        if (inputValues[key] && (inputValues[key] !== '' && inputValues[key] !== undefined && inputValues[key] !== null)) {
          flag = true;
          break;
        }
      }
    }
    if (isChange && flag) {
      // do this for validation when we fill any one of the field and then save
      // setLocationEditDisabled(false)
      let commonPayload = {
        created_by: Number(userId),
        modified_by: Number(userId),
        priceVal: transformedPriceValForAsset,
        project_id: locationsDataFull?.[0]?.project_id,
        type: 'asset',
        location_id: +locationId,
      };
      if (inputValues?.selectedModel?.[0]?.customOption === true) {
        commonPayload.is_new_model = 1;
      }
      if (inputValues?.selectedModel?.[0]?.customOption === false) {
        commonPayload.is_new_model = 0;
      }
      flagged ? (commonPayload.flagged = flagged ? 1 : 0) : null;
      checkValue === '1' ? (commonPayload.is_not_found = checkValue) : null;
      // ! honest to the lord, the entire project looks like a hotfix at this point
      // todo - handle line list case as well if needed
      if (+data?.rowData?.location_id === inputValues?.location_id) {
        if(inputValues?.selectedLine?.length && inputValues?.selectedLine?.[0]?.type_id) {
          commonPayload.parent_line = inputValues.selectedLine[0]?.type_id // ! not sure why we keep removing this - if you find a bug please add it as a comment before removing this
        } else if (inputValues?.parent_line) {
          commonPayload.parent_line = inputValues?.parent_line
        }
      }
      if(!data?.rowData) {
        if(inputValues?.selectedLine?.length && inputValues?.selectedLine?.[0]?.type_id) {
          commonPayload.parent_line = inputValues.selectedLine[0]?.type_id // ! not sure why we keep removing this - if you find a bug please add it as a comment before removing this
        } else if (inputValues?.parent_line) {
          commonPayload.parent_line = inputValues?.parent_line
        }
      }
      Object.keys(inputValues).forEach((key) => {
        if (
          homeState.locationsList?.locationsList?.data?.projectPriceValues.includes(
            key
          ) ||
          key === 'selectedMake' ||
          key === 'selectedModel' ||
          key === 'selectedCapacity' ||
          key === 'selectedArea' ||
          key === 'selectedAssetType' ||
          key === 'selectedLine' ||
          key === 'location_name' ||
          key === 'billing_address_street' ||
          key === 'billing_address_city' ||
          key === 'billing_address_state' ||
          key === 'billing_address_country' ||
          key === 'billing_address_postalcode' ||
          key === 'reporting_currency_c' ||
          key === 'reporting_currency_sign' ||
          key === 'asset_class' ||
          key === 'asset_class_code' ||
          key === 'asset_type_plural' ||
          key === 'values_requested' ||
          key === 'value_short_name' ||
          key === 'user_id' ||
          key === 'condition_value' ||
          key === 'location_address' ||
          key === 'ref' ||
          key === 'asset_type' ||
          key === 'NFLV' ||
          key === 'NOLV' ||
          key === 'CV' ||
          key === 'FLV' ||
          key === 'OLV' ||
          key === 'OLVIP' ||
          key === 'FMVR' ||
          key === 'RCN' ||
          key === 'FLVIP' ||
          key === 'special_notes_type' ||
          key === 'parent_line'
        )
          return;
        // ! don't see the point and started breaking previous/next and back 
        //! not sure why this has been commented out so many times
        //   if (key === 'selectedLine') {
        //     delete commonPayload.selectedLine
        //   return (commonPayload.parent_line = inputValues?.line_name? (inputValues?.selectedLine[0]?.type_id ?? inputValues?.parent_line
        //     ?? 0):0);
        // }
        // if (key === 'selectedLine') return
        // {
        //   return (commonPayload.parent_line = inputValues?.line_name
        //     ? inputValues?.selectedLine[0]?.type_id
        //     : 0);
        // delete commonPayload.selectedLine
        // }
        commonPayload[key] = inputValues[key] ? inputValues[key] : '';
        if (key === 'type_id') commonPayload[key] = '';
        if (key === 'quantity') commonPayload[key] = +inputValues[key];
        if (key === 'mileage_type')
          commonPayload[key] = inputValues[key]?.toLowerCase();
      });
      // let insertAsset = {
      //   created_by: Number(userId),
      //   modified_by: Number(userId),
      //   priceVal: transformedPriceVal,
      //   project_id: locationsDataFull?.[0]?.project_id,
      //   location_id: +locationId,
      //   type: 'asset',
      // }
      // flagged ? insertAsset.flagged = flagged ? 1 : 0 : null
      // checkValue === '1' ? insertAsset.is_not_found = checkValue : null
      // Object.keys(inputValues).forEach((key) => {
      //   if (homeState.locationsList?.locationsList?.data?.projectPriceValues.includes(key) || key === 'selectedMake' || key === 'selectedModel' || key === 'selectedCapacity' || key === 'selectedArea' || key === 'selectedAssetType' || key === 'location_name' || key === 'billing_address_street' || key === 'billing_address_city' || key === 'billing_address_state' || key === 'billing_address_country' || key === 'billing_address_postalcode' || key === 'reporting_currency_c' || key === 'reporting_currency_sign' || key === 'asset_class' || key === 'asset_class_code' || key === 'asset_type_plural' || key === 'values_requested' || key === 'value_short_name' || key === 'user_id' || key === 'condition_value' || key === 'location_address' || key === 'ref') return
      //   if (key === 'selectedLine') {
      //     return insertAsset.parent_line = inputValues?.selectedLine[0]?.type_id
      //     // delete insertAsset.selectedLine
      //   }
      //   insertAsset[key] = inputValues[key]
      //   if (key === 'quantity') insertAsset[key] = +inputValues[key]
      //   if (key === 'mileage_type') insertAsset[key] = inputValues[key]?.toLowerCase()
      // })
      // ! might break without any warnings now
      // const currentTypeId = postAddAsset?.postAddAsset?.data?.id ||
      //   saveAndCreateNew?.saveAndCreateNew?.data?.id ||
      //   saveAndCopyNew?.saveAndCopyNew?.data?.id ||
      //   saveAndCopyBelow?.saveAndCopyBelow?.data?.id ||
      //   insertAssetAbove?.insertAssetAbove?.data?.id ||
      //   insertAssetBelow?.insertAssetBelow?.data?.id ||
      //   postUpdateAsset?.postUpdateAsset?.data?.id ||
      //   saveAndCopyNewSuccess?.saveAndCopyNewSuccess?.data?.id;
      localStorage.setItem('lastAssetAddLocation', locationId ? locationId : inputValues?.location_id) // add the last location in all cases
      if (item === 'Add Asset') {
        assetHashRef.current.focus()
        if (type === 'Save') {
          let payloadWithId = {
            ...commonPayload,
            // copy_type_id: saveAndCopyNewTypeId ?? saveAndCopyBelowTypeId,
            copy_type_id: typeId,
          };
          let type_id = typeId;
          // let type_id = typeId || saveAndCreateNew?.saveAndCreateNew?.data?.id || postUpdateAsset?.postUpdateAsset?.data?.id
          let payload = {
            ...commonPayload,
          };
          doWeUseTypeId ? (payload.type_id = type_id) : null;
          // const isValid = validateFields() validation remove for now
          // isValid ? dispatch(postAddAssetAction(payload)) : null
          dispatch(
            postAddAssetAction(
              payloadWithId?.copy_type_id && doWeUseTypeId && removeTypeOnSave
                ? payloadWithId
                : payload, 'add', 'previousIdActive' // highlight previous row when save and copy below and then back

            )
          );
          setDoWeReallyUseTypeId(true);
          setRemoveTypeOnSave(false);
          // setHandleTitle(true)
          setChange(true);
          setAction(false);
          setPreviousValueSave(true)
          setDataSaved(true)
          // localStorage.removeItem('updatedLocationID');
          // setInputValues({...inputValues})
        }
        if (type === 'SaveAndCreateNew') {
          let payloadWithId = {
            ...commonPayload,
            copy_type_id: typeId,

            // copy_type_id: saveAndCopyNewTypeId ?? saveAndCopyBelowTypeId,
          };
          let type_id = typeId;
          // let type_id = typeId || postUpdateAsset?.postUpdateAsset?.data?.id
          let payload = {
            ...commonPayload,
          };
          doWeUseTypeId ? (payload.type_id = type_id) : null;
          dispatch(
            postAddAssetAction(
              payloadWithId?.copy_type_id && doWeUseTypeId && removeTypeOnSave
                ? payloadWithId
                : payload
            )
          );
          updateInputPriceValues();
          setInputValues({
            // appraiser: appraiserList[0]?.user_id,
            // condition: 'Good',
            // quantity_type: 'number',
            // quantity: '1',
            // mileage_type: 'mileage',
            // asset_no: '',
            // model_label_id: 1,
            // serial_label_id: 1,
            // appraiser: inputValues?.appraiser ? inputValues?.appraiser : appraiserList[0]?.user_id,
            appraiser: inputValues?.appraiser ? inputValues?.appraiser : uniqueAppraiserData[0]?.user_id,
            // selectedLine: [{...inputValues.selectedLine}],
            area: inputValues?.area,
            // selectedArea: [{area: inputValues?.area}],
            condition: 'Good',
            quantity_type: 'number',
            quantity: '1',
            mileage_type: 'mileage',
            asset_no: '',
            model_label_id: 1,
            serial_label_id: 1,
            location_id: inputValues?.location_id ?? +locationId,
            selectedArea: inputValues?.selectedArea,
            selectedLine: inputValues?.selectedLine
          });
          // areaRef.current.clear();
          // lineRef.current.clear();
          makeRef.current.clear();
          modelRef.current.clear();
          capacityRef.current.clear();
          assetTypeRef.current.clear();
          // assetNoRef.current.clear()
          setDoWeReallyUseTypeId(false);
          setAction(true);
          setFlagged(false);
          setCheckValue(null);
          setChange(false);
          setRemoveTypeOnSave(true);
          setMapNominateImageShow(false)
          setPreviousValueSave(false)
          setDataSaved(true)

          // localStorage.removeItem('updatedLocationID');
        }
        if (type === 'SaveAndCopyNew') {
          let payloadWithId = {
            ...commonPayload,
            copy_type_id: typeId,
            // copy_type_id: saveAndCopyBelowTypeId ?? saveAndCopyNewTypeId
          };
          let type_id = typeId;
          // let type_id = typeId || postUpdateAsset?.postUpdateAsset?.data?.id
          let payload = {
            ...commonPayload,
          };
          doWeUseTypeId ? (payload.type_id = type_id) : null;

          // const isValid = validateFields()

          dispatch(
            postAddAssetAction(
              payloadWithId?.copy_type_id && doWeUseTypeId && removeTypeOnSave
                ? payloadWithId
                : payload
            )
          );

          setRemoveTypeOnSave(true);
          setDoWeReallyUseTypeId(false); // change true to false
          setAction(true);
          setPreviousValueSave(false)
          // localStorage.removeItem('updatedLocationID');
          setChange(true);
          setDataSaved(true)

        }
        if (type === 'SaveAndCopyBelow') {
          let payloadWithId = {
            ...commonPayload,
            copy_type_id: typeId,

            // copy_type_id: saveAndCopyNewTypeId ?? saveAndCopyBelowTypeId,
          };
          let type_id = typeId;
          // let type_id = postUpdateAsset?.postUpdateAsset?.data?.id || typeId
          let payload = {
            ...commonPayload,
          };
          doWeUseTypeId ? (payload.type_id = type_id) : null;

          // const isValid = validateFields()
          dispatch(
            postAddAssetAction(
              payloadWithId?.copy_type_id && doWeUseTypeId && removeTypeOnSave
                ? payloadWithId
                : payload, 'add', 'previousIdActive'
            )
          );
          setDoWeReallyUseTypeId(true);
          setRemoveTypeOnSave(true);
          setAction(true);
          setChange(true);
          setPreviousValueSave(false)
          setDataSaved(true)

          // localStorage.removeItem('updatedLocationID');
        }
      }
      if (item?.name === 'Insert Asset Above') {
        assetHashRef.current.focus()
        if (type === 'Save') {
          const myDropTo = localStorage.getItem('assetDropTo')
          let payloadWithId = {
            ...commonPayload,
            copy_type_id: typeId,
            dropTo: +myDropTo,
          };
          let type_id = typeId;
          let payload = {
            ...commonPayload,
            dropTo: +myDropTo,
          };
          doWeUseTypeId ? (payload.type_id = type_id) : null;

          dispatch(
            postAddAssetAction(
              payloadWithId?.copy_type_id && doWeUseTypeId && removeTypeOnSave
                ? payloadWithId
                : payload, typeId ? ('add', 'previousIdActive') : ''
              // highlight previous row when save and copy below and then back

            )
          );
          setDoWeReallyUseTypeId(true);
          setRemoveTypeOnSave(false);
          // setHandleTitle(true);
          setAction(false);
          // setIsFieldEnabled(false);
          setPreviousValueSave(true)
          localStorage.setItem('actionPerformed', true);
        }
        if (type === 'SaveAndCreateNew') {
          const myDropTo = localStorage.getItem('assetDropTo')
          let payloadWithId = {
            ...commonPayload,
            copy_type_id: typeId,
            dropTo:+myDropTo,
          };
          let type_id = typeId;
          let payload = {
            ...commonPayload,
            dropTo: +myDropTo
          };
          doWeUseTypeId ? (payload.type_id = type_id) : null;
          dispatch(
            postAddAssetAction(
              payloadWithId?.copy_type_id && doWeUseTypeId && removeTypeOnSave
                ? payloadWithId
                : payload
            )
          );
          setDoWeReallyUseTypeId(false);
          setRemoveTypeOnSave(true);
          setAction(true);
          setChange(false);
          updateInputPriceValues();
          setFlagged(false);
          setCheckValue(null);
          // setIsFieldEnabled(false);
          localStorage.setItem('actionPerformed', true);
          setInputValues({
            // appraiser: appraiserList[0]?.user_id,
            // condition: 'Good',
            // quantity_type: 'number',
            // quantity: '1',
            // mileage_type: 'mileage',
            // asset_no: '',
            // model_label_id: 1,
            // serial_label_id: 1,
            // appraiser: inputValues?.appraiser ? inputValues?.appraiser : appraiserList[0]?.user_id,
            appraiser: inputValues?.appraiser ? inputValues?.appraiser : uniqueAppraiserData[0]?.user_id,
            selectedLine: [{...inputValues.selectedLine}],
            area: inputValues?.area,
            selectedArea: [{ area: inputValues?.area }],
            condition: 'Good',
            quantity_type: 'number',
            quantity: '1',
            mileage_type: 'mileage',
            asset_no: '',
            model_label_id: 1,
            serial_label_id: 1,
            location_id: inputValues?.location_id
          });
          makeRef.current.clear();
          modelRef.current.clear();
          capacityRef.current.clear();
          assetTypeRef.current.clear();
          setPreviousValueSave(false)
          localStorage.setItem('assetDropTo', +myDropTo + 1)

        }
        if (type === 'SaveAndCopyNew') {
          const myDropTo = localStorage.getItem('assetDropTo')
          let payloadWithId = {
            ...commonPayload,
            copy_type_id: typeId,
            dropTo:+myDropTo,
          };
          let type_id = typeId;
          let payload = {
            ...commonPayload,
            dropTo: +myDropTo,
          };
          doWeUseTypeId ? (payload.type_id = type_id) : null;
          // const isValid = validateFields()

          dispatch(
            postAddAssetAction(
              payloadWithId?.copy_type_id && doWeUseTypeId && removeTypeOnSave
                ? payloadWithId
                : payload
            )
          );
          setRemoveTypeOnSave(true);
          setDoWeReallyUseTypeId(false);
          setAction(true);
          // setIsFieldEnabled(false);
          setPreviousValueSave(false)

          localStorage.setItem('actionPerformed', true);
        }
        if (type === 'SaveAndCopyBelow') {
          const myDropTo = localStorage.getItem('assetDropTo')

          let payloadWithId = {
            ...commonPayload,
            copy_type_id: typeId,
            dropTo:+myDropTo,
          };
          let type_id = typeId;
          let payload = {
            ...commonPayload,
            dropTo: +myDropTo,
          };
          doWeUseTypeId ? (payload.type_id = type_id) : null;

          // const isValid = validateFields()
          dispatch(
            postAddAssetAction(
              payloadWithId?.copy_type_id && doWeUseTypeId && removeTypeOnSave
                ? payloadWithId
                : payload, 'add', "previousIdActive"
            )
          );
          setDoWeReallyUseTypeId(true);
          setRemoveTypeOnSave(true);
          setAction(true);
          // setIsFieldEnabled(false);
          setPreviousValueSave(false)

          localStorage.setItem('actionPerformed', true);
        }
      }
      if (item?.name === 'Insert Asset Below') {
        assetHashRef.current.focus()
        if (type === 'Save') {
          const myDropTo = localStorage.getItem('assetDropTo')
          let payloadWithId = {
            ...commonPayload,
            copy_type_id: typeId,
            dropTo: +myDropTo +1
            // dropTo: dropTo ? dropTo : (inputValues?.area_asset_order)+1 ?? (assetData?.assetDetails?.area_asset_order)+1
            // dropTo: localStorage.getItem('actionPerformed') ? assetData?.assetDetails?.area_asset_order+1 : assetData?.assetDetails?.area_asset_order,

          };
          let type_id = typeId;
          let payload = {
            ...commonPayload,
            // dropTo: dropTo ? dropTo : inputValues?.area_asset_order + 1 ?? (assetData?.assetDetails?.area_asset_order) + 1
            dropTo: +myDropTo +1
          };
          doWeUseTypeId ? (payload.type_id = type_id) : null;

          dispatch(
            postAddAssetAction(
              payloadWithId?.copy_type_id && doWeUseTypeId && removeTypeOnSave
                ? payloadWithId
                : payload, typeId ? ('add', 'previousIdActive') : ''
              // highlight previous row when save and copy below and then back

            )
          );
          setDoWeReallyUseTypeId(true);
          setRemoveTypeOnSave(false);
          // setHandleTitle(true);
          setAction(false);
          // setIsFieldEnabled(false);
          setPreviousValueSave(true)

          localStorage.setItem('actionPerformed', true);
        }
        if (type === 'SaveAndCreateNew') {
          const myDropTo = localStorage.getItem('assetDropTo')

          let payloadWithId = {
            ...commonPayload,
            copy_type_id: typeId,
            dropTo: +myDropTo +1
          };
          let type_id = typeId;
          let payload = {
            ...commonPayload,
            dropTo: +myDropTo +1

          };
          doWeUseTypeId ? (payload.type_id = type_id) : null;
          dispatch(
            postAddAssetAction(
              payloadWithId?.copy_type_id && doWeUseTypeId && removeTypeOnSave
                ? payloadWithId
                : payload
            )
          );
          setDoWeReallyUseTypeId(false);
          setRemoveTypeOnSave(true); // const isValid = validateFields()
          // dispatch(saveAndCreateNewAction({ ...insertAsset, type_id: doWeUseTypeId && type_id ? type_id : "" }))
          // const isValid = validateFields()
          // isValid ? dispatch(saveAndCreateNewAction(payload)) : null
          // setDoWeReallyUseTypeId(false)
          // setRemoveTypeOnSave(true)
          // dispatch(saveAndCreateNewAction(payload))
          setAction(true);
          setChange(false);
          setFlagged(false);
          setCheckValue(null);
          updateInputPriceValues();
          setInputValues({
            // appraiser: appraiserList[0]?.user_id,
            // condition: 'Good',
            // quantity_type: 'number',
            // quantity: '1',
            // mileage_type: 'mileage',
            // asset_no: '',
            // model_label_id: 1,
            // serial_label_id: 1,
            // appraiser: inputValues?.appraiser ? inputValues?.appraiser : appraiserList[0]?.user_id,
            appraiser: inputValues?.appraiser ? inputValues?.appraiser : uniqueAppraiserData[0]?.user_id,
            selectedLine: [{...inputValues.selectedLine}],
            area: inputValues?.area,
            selectedArea: [{ area: inputValues?.area }],
            condition: 'Good',
            quantity_type: 'number',
            quantity: '1',
            mileage_type: 'mileage',
            asset_no: '',
            model_label_id: 1,
            serial_label_id: 1,
            location_id: inputValues?.location_id
          });
          makeRef.current.clear();
          modelRef.current.clear();
          capacityRef.current.clear();
          assetTypeRef.current.clear();
          // setIsFieldEnabled(false);
          setPreviousValueSave(false)

          localStorage.setItem('actionPerformed', true);
          localStorage.setItem('assetDropTo', +myDropTo +1)
        }
        if (type === 'SaveAndCopyNew') {
          const myDropTo = localStorage.getItem('assetDropTo')
          let payloadWithId = {
            ...commonPayload,
            copy_type_id: typeId,
            dropTo: +myDropTo +1

          };
          let type_id = typeId;
          let payload = {
            ...commonPayload,
            dropTo: +myDropTo +1
          };
          doWeUseTypeId ? (payload.type_id = type_id) : null;
          // const isValid = validateFields()

          dispatch(
            postAddAssetAction(
              payloadWithId?.copy_type_id && doWeUseTypeId && removeTypeOnSave
                ? payloadWithId
                : payload
            )
          );
          setRemoveTypeOnSave(true);
          setDoWeReallyUseTypeId(false);
          // const isValid = validateFields()
          // isValid ? dispatch(saveAndCopyNewAction(payload)) : null
          // setDoWeReallyUseTypeId(false)
          // dispatch(saveAndCopyNewAction(payload))
          setAction(true);
          // setIsFieldEnabled(false);
          setPreviousValueSave(false)

          localStorage.setItem('actionPerformed', true);
        }
        if (type === 'SaveAndCopyBelow') {
          const myDropTo = localStorage.getItem('assetDropTo')
          let payloadWithId = {
            ...commonPayload,
            copy_type_id: typeId,
            dropTo: +myDropTo +1
          };
          let type_id = typeId;
          let payload = {
            ...commonPayload,
            dropTo: +myDropTo + 1

          };
          doWeUseTypeId ? (payload.type_id = type_id) : null;
          // const isValid = validateFields()
          dispatch(
            postAddAssetAction(
              payloadWithId?.copy_type_id && doWeUseTypeId && removeTypeOnSave
                ? payloadWithId
                : payload, 'add', "previousIdActive"
            )
          );
          setDoWeReallyUseTypeId(true);
          setRemoveTypeOnSave(true);
          setAction(true);
          // setIsFieldEnabled(false);
          setPreviousValueSave(false)

          localStorage.setItem('actionPerformed', true);
        }
      }
      if (item?.name === 'Edit Asset') {
        assetHashRef.current.focus()
        if (type === 'Save') {
          let payloadWithId = {
            ...commonPayload,
            copy_type_id: typeId,
          };
          // todo - please check if data?.rowData ever holds type_id now and if not just remove it from the equation
          // let type_id = typeId ? typeId : data?.rowData?.type_id ? data?.rowData?.type_id : inputValues?.type_id;
          let type_id = inputValues?.type_id ? inputValues?.type_id : typeId

          let payload = {
            ...commonPayload,
          };
          doWeUseTypeId ? (payload.type_id = type_id) : null;
          // const isValid = validateFields() validation remove for now
          // isValid ? dispatch(postAddAssetAction(payload)) : null
          dispatch(
            postAddAssetAction(
              payloadWithId?.copy_type_id && doWeUseTypeId && removeTypeOnSave
                ? payloadWithId
                : payload,
              'edit', 'previousIdActive' // highlight previous row when save and copy below and then back
            )
          );
          setDoWeReallyUseTypeId(true);
          setRemoveTypeOnSave(false);
          // dispatch(postUpdateAssetAction(payloadWithId?.copy_type_id && doWeUseTypeId ? payloadWithId :payload))
          //   dispatch(postUpdateAssetAction(payloadWithId?.copy_type_id && doWeUseTypeId) ? payloadWithId : payload)

          // setRemoveTypeOnSave(false)
          // setDoWeReallyUseTypeId(true)
          // localStorage.removeItem('updatedLocationID');
          setAction(false);
          // localStorage.removeItem('location_id_home')
          // }

          // if (type === 'Save') {
          //   let payloadWithId = {
          //     ...commonPayload,
          //     copy_type_id: saveAndCopyNewTypeId ?? saveAndCopyBelowTypeId,
          //   }
          //   let type_id = typeId || saveAndCreateNew?.saveAndCreateNew?.data?.id || data?.rowData?.type_id
          //   let payload = {
          //     ...commonPayload,
          //     type_id: doWeUseTypeId && type_id ? type_id : "",
          //   }
          //   dispatch(postUpdateAssetAction(payloadWithId?.copy_type_id && doWeUseTypeId) ? payloadWithId : payload)
          //   setDoWeReallyUseTypeId(true)
          //   setRemoveTypeOnSave(false)
          //   localStorage.removeItem('updatedLocationID')
          // setIsFieldEnabled(false)
          setPreviousValueSave(true)
        }
        if (type === 'SaveAndCreateNew') {
          let payloadWithId = {
            ...commonPayload,
            copy_type_id: typeId,
          };
          let type_id = inputValues?.type_id ? inputValues?.type_id : typeId
          let payload = {
            ...commonPayload,
          };
          doWeUseTypeId ? (payload.type_id = type_id) : null;
          dispatch(
            postAddAssetAction(
              payloadWithId?.copy_type_id && doWeUseTypeId && removeTypeOnSave
                ? payloadWithId
                : payload,
              'edit'
            )
          );

          setDoWeReallyUseTypeId(false);
          setRemoveTypeOnSave(true);
          // dispatch(saveAndCreateNewAction(payload))
          setMapNominateImageShow(false)
          areaRef.current.clear()
          // lineRef.current.clear()
          makeRef.current.clear();
          modelRef.current.clear();
          capacityRef.current.clear();
          assetTypeRef.current.clear();
          // setInputValues({...inputValues, asset_no:''})
          // assetNoRef.current.clear()
          setAction(true);
          setChange(false);
          setFlagged(false);
          setCheckValue(null);
          updateInputPriceValues();
          setHeaderDataShow(false)
          setInputValues({
            // appraiser: inputValues?.appraiser ? inputValues?.appraiser : appraiserList[0]?.user_id,
            appraiser: inputValues?.appraiser ? inputValues?.appraiser : uniqueAppraiserData[0]?.user_id,
            area: inputValues?.area,
            // selectedArea: [{area: inputValues?.area}],
            selectedLine: [{...inputValues.selectedLine}],
            condition: 'Good',
            quantity_type: 'number',
            quantity: '1',
            mileage_type: 'mileage',
            asset_no: '',
            model_label_id: 1,
            serial_label_id: 1,
            location_id: inputValues?.location_id,
            selectedArea: inputValues?.selectedArea,
            selectedLine: inputValues?.selectedLine
          });
          setPreviousValueSave(false)
          setHeaderDataShow(false)

          // localStorage.removeItem('updatedLocationID');
          // localStorage.removeItem('location_id_home')
          // setIsFieldEnabled(false);
        }
        if (type === 'SaveAndCopyNew') {
          let payloadWithId = {
            ...commonPayload,
            copy_type_id: typeId,
          };
          let type_id = inputValues?.type_id ? inputValues?.type_id : typeId
          let payload = {
            ...commonPayload,
          };
          doWeUseTypeId ? (payload.type_id = type_id) : null;

          // const isValid = validateFields()

          dispatch(
            postAddAssetAction(
              payloadWithId?.copy_type_id && doWeUseTypeId && removeTypeOnSave
                ? payloadWithId
                : payload,
              'edit'
            )
          );

          setRemoveTypeOnSave(true)
          setDoWeReallyUseTypeId(false)
          setAction(true)
          // localStorage.removeItem('updatedLocationID')
          // setIsFieldEnabled(false)
          setPreviousValueSave(false)
          setHeaderDataShow(false)

          // localStorage.removeItem('location_id_home')
        }
        if (type === 'SaveAndCopyBelow') {
          //  let type_id = (saveAndCopyNewTypeId || saveAndCopyBelowTypeId || typeId) ?? inputValues?.type_id

          let payloadWithId = {
            ...commonPayload,
            copy_type_id: typeId,
          };
          let type_id = inputValues?.type_id ? inputValues?.type_id : typeId
          let payload = {
            ...commonPayload,
          };
          doWeUseTypeId ? (payload.type_id = type_id) : null;

          // const isValid = validateFields()
          dispatch(
            postAddAssetAction(
              payloadWithId?.copy_type_id && doWeUseTypeId && removeTypeOnSave
                ? payloadWithId
                : payload,
              'edit', 'previousIdActive'
            )
          );

          setDoWeReallyUseTypeId(true);
          setRemoveTypeOnSave(true);
          setAction(true);
          // localStorage.removeItem('updatedLocationID');
          setPreviousValueSave(false)
          setHeaderDataShow(false)


          // setIsFieldEnabled(false)
        }
      }
      if (type === 'Delete') {
        handleConfirmation();
      }
      // if (type === 'Cancel') {
      //   window.location.reload()
      //   // const { location_id, type_id, appraiser, quantity_type, condition } = inputValues
      //   // setInputValues({ location_id, type_id, appraiser, quantity_type, condition })
      // }
      // makeRef.current.clear()
      // modelRef.current.clear()
      // capacityRef.current.clear()
      // assetTypeRef.current.clear()
      // areaRef.current.clear()
      // lineRef.current.clear()
      // // setFlagged(false)
      // // setCheckValue(false)
    } else {
      setMessage('please Enter Something');
      setStatus('warning');
    }
  };

  //no difference in save new and copy below payload
  const handleList = () => {
    let dropTo =
      item?.name === 'Insert Asset Above' || item?.name === 'Insert Asset Below'
        ? assetData?.assetDetails?.area_asset_order
        : '';
    if (isChange) {
      let type_id =
        (saveAndCopyNewTypeId ||
          saveAndCopyBelowTypeId ||
          typeId ||
          insertAssetBelow?.insertAssetBelow?.data?.id ||
          insertAssetAbove?.insertAssetAbove?.data?.id
        ) ??
        inputValues?.type_id ??
        data?.rowData?.type_id;
      let chooseItem = type_id ? 'Edit Asset' : 'Add Asset';
      let obj = {
        name: chooseItem,
      };
      setItem(obj);
      if (saveAndCreateNew?.saveAndCreateNew?.data?.id && removeTypeOnSave) {
        history.push(routesNames.ASSETLIST);
      } else {
        handleSubmit('Save', dropTo);
        setTimeout(() => { // ! most valid and best hotfix at the moment because the function is just too complex and will take a good 
          // ! 2-3 hours to handle outside of the handleSubmit function
          history.push(routesNames.ASSETLIST);
        }, 2000);
      }
    } else {
      history.push(routesNames.ASSETLIST);
    }
  };

  const toCheckDataIsEnter = () => {
    const expectedKeys = [
      'quantity_type',
      'serial_label_id',
      'model_label_id',
      'location_id',
      'appraiser',
      'quantity',
      'condition',
      'mileage_type',
    ];
    const formInputValues = [
      'area',
      'line_name',
      'asset_no',
      'make',
      'model',
      'capacity',
      'asset_class_id',
      'asset_type_id',
      'asset_class_code',
      'serial_number',
      'description',
      'appraiser_note',
      'year',
      'cost',
      'mileage',
      'flagged',
      'is_not_found',
      'special_notes',
      'FLV',
      'OLV',
      'OLVIP',
      'FMVIP',
      'FMV',
      'FV',
      'CV',
    ];
    const formInputValuesForInsertAssetAboveAndBelow = [
      'asset_no',
      'make',
      'model',
      'capacity',
      'asset_class_id',
      'asset_type_id',
      'asset_class_code',
      'serial_number',
      'description',
      'appraiser_note',
      'year',
      'cost',
      'mileage',
      'flagged',
      'is_not_found',
      'special_notes',
      'FLV',
      'OLV',
      'OLVIP',
      'FMVIP',
      'FMV',
      'FV',
      'CV',
    ];
    let condition = item === 'Insert Asset Above' || 'Insert Asset Below';
    let flag = false;
    if (condition) {
      for (const key of formInputValuesForInsertAssetAboveAndBelow) {
        if (inputValues[key] && inputValues[key] !== '') {
          flag = true;
          break;
        }
      }
    } else {
      for (const key of formInputValues) {
        if (inputValues[key] && inputValues[key] !== '') {
          flag = true;
          break;
        }
      }
    }
    return flag;
  };
  const handleEditAsset = () => {
    let dropTo =
      item?.name === 'Insert Asset Above' || item?.name === 'Insert Asset Below'
        ? assetData?.assetDetails?.area_asset_order + 1
        : '';
    let actionPerformed = localStorage.getItem('actionPerformed')
    // check with faraz
    let isEnterData = toCheckDataIsEnter()
    setAction(false)
    if (isEnterData && ((isDataSave) || actionPerformed)) {
      let key = {
        name: 'Edit Asset',
      };
      // setHandleTitle(true)
      // if(!data?.sleekMode){
      handleSubmit('Save', dropTo);
      // }      
      let last_type_id =
        homeState?.locationsList?.locationsList?.data?.lastAsset?.type_id
      let location_id = homeState?.locationsList?.locationsList?.data?.lastAsset?.location_id
      getAssetInEdit({
        ...refPayload,
        type_id: last_type_id,
      }).then(({ asset_data, current_ref_no }) => {
        const sortedAssetData = asset_data.sort(
          (a, b) =>
            a.location_id - b.location_id ||
            a.area_order - b.area_order ||
            // a.asset_id - b.asset_id || // for correct sequence when we create assets
            a.asset_area_order - b.asset_area_order
        );
        setLoadingData(false);
        let nextObj = {}
        // res.assetDetails.location_id
        //   ? setLocationEditDisabled(true)
        //   : setLocationEditDisabled(false);
        // nextObj.rows = data?.rows // reording of asset is wrong  if we fix this then line also show in edit asset on next previous , we fix later
        nextObj.rows = sortedAssetData // ! fixed the sorting issue
        history.push('/asset-detail/' + last_type_id, {
          data: {
            ...nextObj,
            rowData: { ...nextObj.rowData, location_id: location_id, ref: current_ref_no },
            key: key
          },
        });
      });
    } else {
      let key = {
        name: 'Edit Asset',
      };
      let last_type_id =
        homeState?.locationsList?.locationsList?.data?.lastAsset?.type_id
      let location_id = homeState?.locationsList?.locationsList?.data?.lastAsset?.location_id
      getAssetInEdit({
        ...refPayload,
        type_id: last_type_id,
      }).then(({ asset_data, current_ref_no }) => {
        const sortedAssetData = asset_data.sort(
          (a, b) =>
            a.location_id - b.location_id ||
            a.area_order - b.area_order ||
            // a.asset_id - b.asset_id || // for correct sequence when we create assets
            a.asset_area_order - b.asset_area_order
        );
        setLoadingData(false);
        let nextObj = {}
        // res.assetDetails.location_id
        //   ? setLocationEditDisabled(true)
        //   : setLocationEditDisabled(false);
        // nextObj.rows = data?.rows // reording of asset is wrong  if we fix this then line also show in edit asset on next previous , we fix later
        nextObj.rows = sortedAssetData // ! fixed the sorting issue
        history.push('/asset-detail/' + last_type_id, {
          data: {
            ...nextObj,
            rowData: { ...nextObj.rowData, location_id: location_id, ref: current_ref_no },
            key: key
          },
        });
      });
    }
  }

  const handleNominatePhoto = (obj) => {
    setShowFullImage(false);
    let payload = {
      type: 'asset',
      op: 'nominate_status_update',
      type_id: data?.rowData?.type_id,
      photo_location: obj?.path,
      photo_nominated: obj?.photo_nominated === 1 ? '0' : '1',
      asset_media_type: 'photo',
    };
    try {
      postPhotosToAssets(payload).then((res) => {
        setMessage('Images Update Successfully');
        setStatus('success');
        const photos = [...assetPhotos];
        const updatedNominatePhotos = photos.map((item) => {
          if (item.path === obj?.path) {
            // Toggle the value of photo_nominated property
            item.photo_nominated = item?.photo_nominated === 1 ? 0 : 1;
          }
          return item; // Return the updated item
        });

        setAssetPhotos(updatedNominatePhotos);
      });
    } catch (error) {
      throw error
      // console.log('error', error);
    }
  };
  const handleImageDelete = (imagePath) => {
    let obj = {
      photo_location: imagePath,
      type: 'asset',
      type_id: data?.rowData.type_id.toString(),
    };
    try {
      deletePhotosToAssets(obj, {
        user_id: inputValues?.user_id?.toString() ?? '',
      }).then((res) => {
        setMessage('Images Delete Successfully');
        setStatus('success');
        const photos = [...assetPhotos];
        const updatedPhotos = photos.filter(({ path }) => path !== imagePath);
        setAssetPhotosCount(updatedPhotos?.length);
        setAssetPhotos(updatedPhotos);
      });
    } catch (error) {
      throw error
      // console.log('error', error);
    }
  };


  // console.log(' data?.rows',  data?.rows)
  const handleNext = () => {
    assetHashRef.current.focus()
    let isEnterData = toCheckDataIsEnter()
    setAction(false)
    setAction(false) // for showing ref creted on data header of add asset page
    setAssetPhotos([])
    setAssetPhotosCount(0)

    // let type_id = (saveAndCopyNewTypeId || saveAndCopyBelowTypeId ||saveAndCreateNew?.saveAndCreateNew?.data?.id|| typeId || insertAssetBelow?.insertAssetBelow?.data?.id || insertAssetAbove?.insertAssetAbove?.data?.id) ?? inputValues?.type_id
    // let chooseItem= type_id  ? 'Edit Asset' : 'Add Asset'
    let obj = {
      name: 'Edit Asset',
    }
    setItem(obj)
    if (!isEnterData) {
      // to check data is newly enter or not before next and previous
      // if (typeId && removeTypeOnSave && doWeUseTypeId) {
      data?.rows?.map((item, index) => {
        if (index === data?.rows?.findIndex(({ type_id }) => +type_id === (+data?.rowData?.type_id ? +data?.rowData?.type_id : +params?.id))) {
          let nextIndex = index + 1;
          let nextObj = {
            rowData:
              nextIndex < (data?.rows?.length || 0)
                ? data?.rows[nextIndex]
                : null,
            rows: data?.rows,
            index: nextIndex,
          };
          const payload = {
            id: +nextObj.rowData.type_id,
            location_id: +nextObj?.rowData?.location_id,
            project_id_crm: decodedProjectId,
          };
          setLoadingData(true);
          // const ref =
          // history.push('/asset-detail/' + data?.rows[nextIndex].type_id, { data: nextObj });
          getAssetDetails(payload).then((res) => {
            getAssetInEdit({
              ...refPayload,
              type_id: res.assetDetails.type_id,
            }).then(({ asset_data, current_ref_no }) => {
              const sortedAssetData = asset_data.sort(
                (a, b) =>
                  a.location_id - b.location_id ||
                  a.area_order - b.area_order ||
                  // a.asset_id - b.asset_id || // for correct sequence when we create assets
                  a.asset_area_order - b.asset_area_order
              );
              setLoadingData(false);
              // res.assetDetails.location_id
              //   ? setLocationEditDisabled(true)
              //   : setLocationEditDisabled(false);
              // nextObj.rows = data?.rows // reording of asset is wrong  if we fix this then line also show in edit asset on next previous , we fix later
              nextObj.rows = sortedAssetData // ! fixed the sorting issue
              nextObj.rowData = { ...nextObj.rowData, ...res.assetDetails };
              localStorage.setItem('type_id', data?.rows[nextIndex].type_id)
              history.push('/asset-detail/' + data?.rows[nextIndex].type_id, {
                data: {
                  ...nextObj,
                  rowData: { ...nextObj.rowData, ref: current_ref_no },
                },
              });
            });
          });
        }
      });
    } else {
      handleSubmit('Save');
      data?.rows?.map((item, index) => {
        if (index === data?.rows?.findIndex(({ type_id }) => +type_id === (+data?.rowData?.type_id ? +data?.rowData?.type_id : +params?.id))) {
          let nextIndex = index + 1;
          let nextObj = {
            rowData:
              nextIndex < (data?.rows?.length || 0)
                ? data?.rows[nextIndex]
                : null,
            rows: data?.rows,
            index: nextIndex,
          };
          const payload = {
            id: +nextObj.rowData.type_id,
            location_id: +nextObj?.rowData?.location_id,
            project_id_crm: decodedProjectId,
          };
          setLoadingData(true);
          // history.push('/asset-detail/' + data?.rows[nextIndex].type_id, { data: nextObj });

          // const ref =
          getAssetDetails(payload).then((res) => {
            getAssetInEdit({
              ...refPayload,
              type_id: res.assetDetails.type_id,
            }).then(({ asset_data, current_ref_no }) => {
              const sortedAssetData = asset_data.sort(
                (a, b) =>
                  a.location_id - b.location_id ||
                  a.area_order - b.area_order ||
                  // a.asset_id - b.asset_id || // for correct sequence when we create assets
                  a.asset_area_order - b.asset_area_order
              );
              setLoadingData(false);
              // res.assetDetails.location_id
              //   ? setLocationEditDisabled(true)
              //   : setLocationEditDisabled(false);
              // nextObj.rows = data?.rows // reording of asset is wrong  if we fix this then line also show in edit asset on next previous , we fix later
              nextObj.rows = sortedAssetData // ! fixed the sorting issue
              nextObj.rowData = { ...nextObj.rowData, ...res.assetDetails };
              localStorage.setItem('type_id', data?.rows[nextIndex].type_id)
              history.push('/asset-detail/' + data?.rows[nextIndex].type_id, {
                data: {
                  ...nextObj,
                  rowData: { ...nextObj.rowData, ref: current_ref_no },
                },
              });
            });
          });
        }
      });
    }
  };

  const handlePrevious = () => {
    assetHashRef.current.focus()
    // console.log('id', params?.id)
    // if(item == 'add asset') {
    //   return
    // }
    let isEnterData = toCheckDataIsEnter()
    // console.log(isEnterData, 'ent')
    setAction(false)
    setAssetPhotos([])
    setAssetPhotosCount(0)

    // let type_id = (saveAndCopyNewTypeId || saveAndCopyBelowTypeId ||saveAndCreateNew?.saveAndCreateNew?.data?.id|| typeId || insertAssetBelow?.insertAssetBelow?.data?.id || insertAssetAbove?.insertAssetAbove?.data?.id) ?? inputValues?.type_id
    // let chooseItem= type_id  ? 'Edit Asset' : 'Add Asset'
    let obj = {
      name: 'Edit Asset',
    };
    setItem(obj)
    if (!isEnterData && (item != 'Add Asset' || !params?.id)) {
      // to check data is newly enter or not before next and previous
      // if (typeId && removeTypeOnSave && doWeUseTypeId) {
      data?.rows?.map((item, index) => {
        if (index === data?.rows?.findIndex(({ type_id }) => +type_id === (+data?.rowData?.type_id ? +data?.rowData?.type_id : +params?.id))) {
          let nextIndex = index - 1;
          let nextObj = {
            rowData:
              nextIndex < (data?.rows?.length || 0)
                ? data?.rows[nextIndex]
                : null,
            rows: data?.rows,
            index: nextIndex,
          };
          const payload = {
            id: (isaction && +typeId) ? +typeId : +nextObj.rowData.type_id,
            location_id: +nextObj?.rowData?.location_id,
            project_id_crm: decodedProjectId,
          };
          setLoadingData(true);

          getAssetDetails(payload).then((res) => {
            getAssetInEdit({
              ...refPayload,
              type_id: (isaction && +typeId) ? typeId : +res.assetDetails.type_id,
            }).then(({ asset_data, current_ref_no }) => {
              const sortedAssetData = asset_data.sort(
                (a, b) =>
                  a.location_id - b.location_id ||
                  a.area_order - b.area_order ||
                  // a.asset_id - b.asset_id || // for correct sequence when we create assets
                  a.asset_area_order - b.asset_area_order
              );
              setLoadingData(false);
              // res.assetDetails.location_id
              //   ? setLocationEditDisabled(true)
              //   : setLocationEditDisabled(false);
              // nextObj.rows = data?.rows // reording of asset is wrong  if we fix this then line also show in edit asset on next previous , we fix later
              nextObj.rows = sortedAssetData // ! fixed the sorting issue

              nextObj.rowData = { ...nextObj.rowData, ...res.assetDetails };
              let urlId = (isaction && +typeId) ? typeId : data?.rows[nextIndex].type_id
              localStorage.setItem('type_id', urlId)
              history.push('/asset-detail/' + urlId, {
                data: {
                  ...nextObj,
                  rowData: { ...nextObj.rowData, ref: current_ref_no },
                },
              });
            });
          });
        }
      });
    } else {
      handleSubmit('Save');
      data?.rows?.map((item, index) => {
        if (index === data?.rows?.findIndex(({ type_id }) => +type_id === (+data?.rowData?.type_id ? +data?.rowData?.type_id : +params?.id))) {
          let nextIndex = index - 1;
          let nextObj = {
            rowData:
              nextIndex < (data?.rows?.length || 0)
                ? data?.rows[nextIndex]
                : null,
            rows: data?.rows,
            index: nextIndex,
          };
          const payload = {
            id: (isaction && +typeId) ? typeId : +nextObj.rowData.type_id,
            location_id: +nextObj?.rowData?.location_id,
            project_id_crm: decodedProjectId,
          };
          setLoadingData(true);
          getAssetDetails(payload).then((res) => {
            getAssetInEdit({
              ...refPayload,
              type_id: (isaction && +typeId) ? typeId : res.assetDetails.type_id,
            }).then(({ asset_data, current_ref_no }) => {
              const sortedAssetData = asset_data.sort(
                (a, b) =>
                  a.location_id - b.location_id ||
                  a.area_order - b.area_order ||
                  // a.asset_id - b.asset_id || // for correct sequence when we create assets
                  a.asset_area_order - b.asset_area_order
              );
              setLoadingData(false);
              // res.assetDetails.location_id
              //   ? setLocationEditDisabled(true)
              //   : setLocationEditDisabled(false);
              // nextObj.rows = data?.rows // reording of asset is wrong  if we fix this then line also show in edit asset on next previous , we fix later
              nextObj.rows = sortedAssetData // ! fixed the sorting issue
              nextObj.rowData = { ...nextObj.rowData, ...res.assetDetails };
              let urlId = (isaction && +typeId) ? typeId : data?.rows[nextIndex].type_id
              localStorage.setItem('type_id', urlId)
              history.push('/asset-detail/' + urlId, {
                data: {
                  ...nextObj,
                  rowData: { ...nextObj.rowData, ref: current_ref_no },
                },
              });
            });
          });
        }
      });
    }
  };

  const handleInsertNewLine = () => {
    if (
      inputLineValues?.line_name ||
      inputLineValues?.make ||
      inputLineValues?.asset_type_id
    ) {
      let obj = {
        appraiser: inputValues?.appraiser,
        appraiser_note: inputLineValues?.appraiser_note,
        asset_class_id: inputLineValues?.asset_class_id,
        asset_no: inputLineValues?.asset_no,
        asset_type_id: inputLineValues?.asset_type_id,
        condition: inputLineValues?.condition,
        cost: inputLineValues?.cost,
        created_by: Number(userId),
        formAction: 'saveUpdate',
        line_name: inputLineValues?.line_name,
        line_text2: inputLineValues?.line_text2,
        location_id: inputValues?.location_id
          ? inputValues?.location_id
          : locationData?.locationsDataFull[0].location_id, // needs to be made dynamic most likely
        make: inputLineValues?.make,
        make_id: inputLineValues?.make_id,
        modified_by: Number(userId),
        priceVal: transformedPriceValForLines,
        project_id: locationsDataFull?.[0]?.project_id,
        project_id_crm: decodedProjectId,
        type: 'line',
        year: inputLineValues?.year,
      };
      dispatch(postLineDetailAction(obj, 'addLine'));
    } else {
      setMessage(
        'Please enter atleast one field from Make, Text1 or Asset Type.'
      );
      setStatus('warning');
    }
  };

  const handleSnackBarClose = () => {
    setMessage('');
    setStatus('');
    dispatch(assetsListActions.clearMessageData());
    dispatch(lineListActions.clearMessageData());
  };

  const handleImageLeave = () => {
    setShowFullImage(false);
  };
  const handleImageEnter = (obj) => {
    setShowFullImage(true);
    setImagePath(obj);
  };

  // useEffect(()=>{

  // },[data])

  const handleMapPhotos = () => {
    let url = window.location.href
    let response = assetState?.postAddAsset?.postAddAsset?.data
    if (
      Object.keys(inputValues).length !== 0 &&
      (inputValues?.user_id !== null || inputValues?.appraiser !== null)
    ) {
      history.push({
        pathname: `/map-photos/${inputValues?.appraiser}`,
        state: { data: { data, inputValues, response, url } },
      });
    } else {
      setMessage('Choose Appraiser First.');
      setStatus('warning');
    }
  };

  useEffect(() => {
    if(appraiserList?.length && (inputValues?.location_id || locationId)) {
      let currentLocationAppraisers
      currentLocationAppraisers = appraiserList?.filter((appraiserData) => {
        return +appraiserData.location_id == (+inputValues?.location_id || +locationId)
      })
      if(appraiserList.length) { // ! set to the appraiser list we get in the asset details api
        currentLocationAppraisers = assetData.appraiserList
      }
      setUniqueAppraiserData(currentLocationAppraisers)
      // currentLocationAppraisers.find(({user_id})) => +user_id != inputValues?.appraiser)
      if(!inputValues?.appraiser || !currentLocationAppraisers?.find(({user_id}) => +user_id === +inputValues?.appraiser)) {
        setInputValues({...inputValues, appraiser: currentLocationAppraisers[0]?.user_id})
      }
    }
  }, [appraiserList, inputValues, locationId, assetData])

  const handleCancel = () => {
    let actionPerformed = localStorage.getItem('actionPerformed')
    if (actionPerformed) {
      localStorage.setItem('resetType', true) //reset only when we perform any action after insert asset above and below otherwise not
    } // comment this because asset is not create properly.
    window.location.reload();
  };


  const specialNotesSelectValue = () => {
    if(inputValues?.special_notes_type) {
      return inputValues?.special_notes_type
    } else if (inputValues?.special_notes && !specialNoteTypes.includes(inputValues?.special_notes)) {
      return 'Create a New Note'
    } else if (inputValues?.special_notes) {
      return inputValues?.special_notes
    } else {
      return null
    }
  }
  const handleSelectedForLineAndAssetTypeForLine = (selected) => {
    let label
    if (selected[0]?.label) label = true
    setInputLineValues({
      ...inputLineValues,
      selectedAssetType: selected,
      asset_type: label ? selected[0]?.label : selected[0]?.asset_type,
      asset_type_id: label ? null : selected[0]?.asset_type_id,
      asset_class_id: +selected[0]?.class_id ? +selected[0]?.class_id : 0,
    })
  }
  const handleKeyDownForTypeAheadForLine = (e) => {
    const {placeholder, value} = e.target
    if (!value) return
    if (e.keyCode === 9) {
      if (placeholder === 'Search Make') {
        const makeId = makeList?.find(({make}) => make === value)?.make_id
        setInputLineValues({...inputLineValues, selectedMake: [{make: value}], make: value, make_id: makeId})
      }
      if (placeholder === 'Search Asset Types') {
        const assetTypeValue = filteredAssetTypes?.find(({asset_type}) => asset_type === value)
        setInputLineValues({...inputLineValues, selectedAssetType: [{asset_type: value}], asset_type: value, asset_type_id: assetTypeValue?.asset_type_id, asset_class_id: assetTypeValue?.class_id})
      }
    }
  }
  const handleCustomTypeAheadForMakeForLine = (makeValue) => {
    if (Array.isArray(makeValue)) return
    setInputLineValues({...inputLineValues, selectedMake: [{make: makeValue}], make: makeValue})
  }
  return (
    <MyDiv>
      {(loadingData ||
        homeState?.locationsList?.loading ||
        lineState?.assetDetails?.loading ||
        assetState?.postAddAsset?.loading ||
        lineState?.assetType?.loading ||
        assetState?.postUpdateAsset?.loading ||
        assetState?.insertAssetAbove?.loading ||
        assetState?.insertAssetBelow?.loading ||
        assetState?.postAddAsset?.loading ||
        assetState?.saveAndCreateNew?.loading ||
        assetState?.saveAndCopyNew?.loading ||
        assetState?.saveAndCopyBelow?.loading ||
        lineState?.makeCapacityList?.loading ||
        lineState?.postLineDetail?.loading) && (
          <div>
            <Loader />
          </div>
        )}
      <Box className="page_heading">
        <Box className="d-flex">
          {/* {handleTitle ? <Typography>Edit Asset</Typography> : */}
          <Typography>{!isaction ? 'Edit Asset' : 'Add Asset'}</Typography>
          <Box>
            <Box className="d-flex project_head">
              <Typography className="project_title" variant="h6">
                Project :-
              </Typography>
              <Typography className="project_title f-600" variant="h6">
                {locationsDataFull?.[0]?.project_name}
              </Typography>
            </Box>
            {data && !data?.sleekMode ? (
              isHeaderDataShow ? (
                <div>
                  <span className="submenu">Created By: {Number(userId)}</span>
                  <span className="submenu">
                    Created on:{' '}
                    <b>
                      {inputValues?.created_on
                        ? inputValues?.created_on
                        : data?.rowData?.created_on}
                    </b>
                  </span>
                  <span className="submenu">
                    Modified By:{' '}
                    <b>
                      {inputValues?.modified_by
                        ? inputValues?.modified_by
                        : data?.rowData?.modified_by}
                    </b>
                  </span>
                  <span className="submenu">
                    Modified on:{' '}
                    <b>
                      {inputValues?.modified_on
                        ? inputValues?.modified_on
                        : data?.rowData?.modified_on}
                    </b>
                  </span>
                  <span className="submenu">
                    Ref# : <b>{data?.rowData?.ref}</b>
                  </span>
                  <span className="submenu">
                    Asset Id : <b>{inputValues?.asset_id}</b>
                  </span>
                </div>
              ) : (
                ''
              )
            ) : null}
          </Box>
        </Box>
        <Box className="d-flex">
          {!data?.sleekMode && data ? (
            <Box>
              {data?.index !== 0 ? (
                <IconButton className="icon">
                  <KeyboardArrowLeftIcon onClick={handlePrevious} />
                </IconButton>
              ) : (
                ''
              )}
              <IconButton onClick={handleList} className="list_btn">
                <FormatListBulletedIcon />
              </IconButton>
              {data?.index !== data?.rows?.length - 1 ?
                <IconButton className="icon">
                  <KeyboardArrowRightIcon onClick={handleNext} />
                </IconButton> : ''}
            </Box>
          ) : (
            <Box>
              {' '}
              <IconButton className="icon">
                <KeyboardArrowLeftIcon onClick={handleEditAsset} />
              </IconButton>
              <IconButton onClick={handleList} className="list_btn">
                <FormatListBulletedIcon />
              </IconButton>
            </Box>
          )}
          {/* {!isaction ?
          <IconButton onClick={handleList} className='list_btn'>
            <FormatListBulletedIcon />
          </IconButton>:""} */}
        </Box>
      </Box>
      <Box className="wrapper_box">
        <Grid container columnSpacing={{ sm: 2, md: 2 }}>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Select Location</Typography>
              <FormControl size="small" fullWidth>
                <Select
                  id="select_input"
                  className="select_value"
                  value={
                    +inputValues?.location_id
                      ? +inputValues?.location_id
                      : lineValues.location || locationId !== undefined
                        ? locationId
                        : null
                  }
                  // value={lineValues.location || locationId !== undefined ? locationId : null ?? +inputValues?.location_id}
                  onChange={(e) => handleChangeValues(e, 'location')}
                  name="location_id"
                  MenuProps={{ ...Menu }}
                // disabled={isfieldEnbled}
                >
                  {locationsDataFull?.map((item, index) => {
                    return (
                      <StyledMenuItem key={index} value={item.location_id}>
                        {item.name} [{item.street} {item.city} {item.country}]
                      </StyledMenuItem>
                    );
                  })}
                </Select>{' '}
              </FormControl>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Appraiser</Typography>
              <FormControl size="small" fullWidth>
                <Select
                  id="select_input"
                  className="select_value"
                  value={inputValues?.appraiser ?? +inputValues?.appraiser}
                  onChange={handleChange}
                  name="appraiser"
                  MenuProps={{ ...Menu }}
                >
                  {uniqueAppraiserData?.map((item, index) => {
                    return (
                      <StyledMenuItem key={index} value={item.user_id}>
                        {item.last_name} {item.name}
                      </StyledMenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Box className="text_flex">
                <Typography>Area</Typography>
                <Typography className="note">Max 250 characters</Typography>
              </Box>
              <Typeahead
                id="basic-typeahead"
                className='typeahead-fix'
                allowNew
                // clearButton
                ref={areaRef}
                labelKey={(option) => `${option.area}`}
                options={areaList}
                placeholder="Search Area"
                aria-label="area"
                // disabled={isfieldEnbled}
                selected={inputValues?.selectedArea} // we are expecting it in the format [{key:key_value, value: value}]
                size="large"
                onChange={(selected) => handleSelected(selected, 'area')}
                onInputChange={handleCustomTypeAheadForArea}
                onKeyDown={handleKeyDownForTypeAhead}
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Box className="d-flex add_btn">
                <Box className="text_flex">
                  <Typography>Line{lineState.lineByAreaList?.loading && (
                    <CircularProgress color="info" size={20} />
                  )}</Typography>

                  <Box className="d-flex">
                    <Typography className="note">Max 500 characters</Typography>
                    <Tooltip tabIndex={-1} title="Add New Line">
                      <IconButton
                        className="add_btn_icon"
                        area-label="Add New Line"
                        onClick={handleDialogLine}
                      >
                        <Add />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
              <Typeahead
              className='typeahead-fix'
                id="basic-typeahead"
                // allowNew
                // clearButton
                ref={lineRef}
                labelKey={(option) =>
                  `${option.line_name || ''}`
                }
                options={lineList}
                placeholder="Search Line"
                // disabled={isfieldEnbled}
                selected={inputValues?.selectedLine} // we are expecting it in the format [{key:key_value, value: value}]
                size="large"
                onChange={(selected) => handleSelectedForLineAndAssetType(selected, 'line')}
                onKeyDown={handleKeyDownForTypeAhead}
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Quantity</Typography>
              <Box className="d-flex">
                <FormControl size="small" fullWidth className="form_control">
                  <Select
                    id="select_input"
                    className="select_value"
                    value={
                      inputValues?.quantity_type
                        ? inputValues?.quantity_type
                        : ''
                    }
                    onChange={handleChange}
                    name="quantity_type"
                    MenuProps={{ ...Menu }}
                  >
                    {quantity?.map((item, index) => {
                      return (
                        <StyledMenuItem key={index} value={item.type}>
                          {item.name}
                        </StyledMenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <CustomTextBox
                  type="number"
                  value={
                    inputValues?.quantity_type === 'number'
                      ? inputValues?.quantity
                      : ''
                  }
                  onChange={handleChange}
                  name="quantity"
                  disabled={inputValues?.quantity_type === 'lot'}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="d-flex">
              <Box className="input_box w-50">
                <Typography>Asset #</Typography>
                <input type="text" ref={assetHashRef} value={inputValues?.asset_no ? inputValues?.asset_no : ''}
                  onChange={handleChange}
                  name="asset_no"
                  style={{
                    height: 29,
                    width: '100%'
                  }}
                />
                {/* <CustomTextBox
                  autoFocus
                  value={inputValues?.asset_no ? inputValues?.asset_no : ''}
                  onChange={handleChange}
                  name="asset_no"
                /> */}
              </Box>
              <Box className="input_box w-50">
                <Typography>Condition</Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    id="select_input"
                    className="select_value"
                    value={inputValues?.condition ? inputValues?.condition : ''}
                    // value={inputValues?.condition}
                    onChange={handleChange}
                    name="condition"
                    MenuProps={{ ...Menu }}
                  >
                    {condition?.map((item, index) => {
                      return (
                        <StyledMenuItem key={index} value={item.name}>
                          {item.name}
                        </StyledMenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Make</Typography>
              <Typeahead
              className='typeahead-fix'
                id="basic-typeahead"
                allowNew
                // clearButton
                ref={makeRef}
                labelKey={(option) => option?.make?.toString() || ''}
                options={makeList}
                placeholder="Search Make"
                selected={inputValues?.selectedMake} // we are expecting it in the format [{key:key_value, value: value}]
                size="large"
                onChange={(selected) => handleSelected(selected, 'make')}
                onInputChange={handleCustomTypeAheadForMake}
                onKeyDown={handleKeyDownForTypeAhead}
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Model</Typography>
              <Box className="d-flex">
                <FormControl size="small" fullWidth className="form_control">
                  <Select
                    id="select_input"
                    className="select_value"
                    value={
                      inputValues?.model_label_id ??
                      +inputValues?.model_label_id
                    }
                    onChange={handleChange}
                    name="model_label_id"
                    MenuProps={{ ...Menu }}
                  >
                    {assetData?.modelLabelList?.map((item, index) => {
                      return (
                        <StyledMenuItem key={index} value={item.model_label_id}>
                          {item.name}
                        </StyledMenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Box sx={{ width: '100%' }}>
                  {((assetState?.getModelByMake?.getModelByMake?.length === 0 &&
                    !MODEL_DATA) ||
                    assetState?.getModelByMake.loading) && (
                      <CircularProgress color="info" size={20} />
                    )}
                  <Typeahead
                    id="basic-typeahead"
                    allowNew
                    // clearButton
                    className='typeahead-fix'
                    ref={modelRef}
                    labelKey={(option) => option?.model?.toString() || ''}
                    options={modelList}
                    placeholder="Search Model"
                    selected={inputValues?.selectedModel} // we are expecting it in the format [{key:key_value,` value: value}]
                    size="large"
                    onChange={(selected) => handleSelected(selected, 'model')}
                    onInputChange={handleCustomTypeAheadForModel}
                    onKeyDown={handleKeyDownForTypeAhead}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Capacity</Typography>
              <Typeahead
              className='typeahead-fix'
                id="basic-typeahead"
                allowNew
                // clearButton
                ref={capacityRef}
                labelKey={(option) => `${option?.capacity}`}
                options={capacityList}
                placeholder="Search Capacity"
                selected={inputValues?.selectedCapacity} // we are expecting it in the format [{key:key_value, value: value}]
                size="large"
                onChange={(selected) => handleSelected(selected, 'capacity')}
                onInputChange={handleCustomTypeAheadForCapacity}
                onKeyDown={handleKeyDownForTypeAhead}
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Box className="d-flex add_btn">
                <Typography>Asset Type</Typography>
                <Tooltip  tabIndex={-1} title="Add New Asset Type">
                  <IconButton onClick={handleDialog}>
                    <Add />
                  </IconButton>
                </Tooltip>
              </Box>
              <Typeahead
              className='typeahead-fix'
                id="basic-typeahead"
                // allowNew
                // clearButton
                ref={assetTypeRef}
                labelKey={(option) => option?.asset_type?.toString() || ''}
                options={filteredAssetTypes} // remove 1st option select or search asset type
                placeholder="Search Asset Types"
                selected={inputValues?.selectedAssetType} // we are expecting it in the format [{key:key_value, value: value}]
                size="large"
                onChange={(selected) => handleSelectedForLineAndAssetType(selected, 'assetType')}
                onKeyDown={handleKeyDownForTypeAhead}
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Asset Class</Typography>
              <FormControl size="small" fullWidth>
                <Select
                  id="select_input"
                  className="select_value"
                  value={
                    inputValues?.asset_class_id ?? +inputValues?.asset_class_id
                  }
                  onChange={handleChange}
                  name="asset_class_id"
                  MenuProps={{ ...Menu }}
                >
                  <StyledMenuItem key={-1} value={0}>
                    Please Select
                  </StyledMenuItem>
                  {assetData?.assetClassList?.map((item, index) => {
                    return (
                      <StyledMenuItem key={index} value={item.asset_class_id}>
                        {item.name}
                      </StyledMenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Serial Number</Typography>
              <Box className="d-flex">
                <FormControl size="small" fullWidth className="form_control">
                  <Select
                    id="select_input"
                    className="select_value"
                    value={
                      inputValues?.serial_label_id ??
                      +inputValues?.serial_label_id
                    }
                    onChange={handleChange}
                    name="serial_label_id"
                    MenuProps={{ ...Menu }}
                  >
                    {assetData?.serialLabelList?.map((item, index) => {
                      return (
                        <StyledMenuItem
                          key={index}
                          value={item.serial_label_id}
                        >
                          {item.name}
                        </StyledMenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <CustomTextBox
                  value={inputValues?.serial_number ?? ''}
                  onChange={handleChange}
                  name="serial_number"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Description</Typography>
              <CustomTextArea
                minRows={2}
                value={inputValues?.description ?? ''}
                name="description"
                onChange={handleChange}
              />
            </Box>
          </Grid>
          {/* <Grid item md={2} /> */}
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Appraiser Notes</Typography>
              <CustomTextArea
                minRows={2}
                value={inputValues?.appraiser_note ?? ''}
                name="appraiser_note"
                onChange={handleChange}
              />
            </Box>
          </Grid>
          <Grid item md={1.5}>
            <Box className="input_box">
              <Typography>Year</Typography>
              <CustomTextBox
                maxLength={4}
                value={inputValues?.year ?? ''}
                onChange={handleChange}
                name="year"
              />
            </Box>
          </Grid>
          <Grid item md={1.5}>
            <Box className="input_box">
              <Typography>Cost ($)</Typography>
              <CustomTextBox
                type="number"
                value={inputValues?.cost ?? ''}
                onChange={handleChange}
                name="cost"
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Mileage</Typography>
              <Box className="d-flex">
                <FormControl size="small" fullWidth className="form_control">
                  <Select
                    id="select_input"
                    className="select_value"
                    value={inputValues?.mileage_type ?? ''}
                    onChange={handleChange}
                    name="mileage_type"
                    MenuProps={{ ...Menu }}
                  >
                    {mileage?.map((item, index) => {
                      return (
                        <StyledMenuItem key={index} value={item.name}>
                          {item.name}
                        </StyledMenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <CustomTextBox
                  type="number"
                  value={inputValues?.mileage ? inputValues?.mileage : ''}
                  onChange={handleChange}
                  name="mileage"
                />
              </Box>
            </Box>
            <Box sx={{zIndex: 0}}>
              <FormControlLabel
                className="checked_label"
                control={
                  <Checkbox
                    onChange={(e) => handleFlagged(e)}
                    checked={flagged}
                  />
                }
                label="Flagged"
              />
              <FormControl>
                <RadioGroup row>
                  <FormControlLabel
                    className="checked_label"
                    value="1"
                    control={
                      <Radio
                        onChange={(e) => handleCheckbox(e)}
                        checked={checkValue === '1'}
                      />
                    }
                    label="Found"
                  />
                  <FormControlLabel
                    className="checked_label"
                    value="2"
                    control={
                      <Radio
                        onChange={(e) => handleCheckbox(e)}
                        checked={checkValue === '2'}
                      />
                    }
                    label="Not Found"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
          <Grid item md={12} className="project_name d-flex">
            <Typography variant="h6">Price values allowed-</Typography>
            <Typography>
              "No Value", "Not Valued", "Leased" or Numeric.
            </Typography>
          </Grid>
        </Grid>
        <Grid container columnSpacing={{ sm: 2, md: 2 }}>
          <Grid item md={6}>
            <Grid container columnSpacing={{ sm: 2, md: 2 }}>
              {getPriceValues('asset')}
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container columnSpacing={{ sm: 2, md: 2 }}>
              <Grid item md={4}>
                <Box className="input_box">
                  <Typography>Special Note</Typography>
                  <FormControl size="small" fullWidth>
                    <Select
                      id="select_input"
                      className="select_value"
                      value={specialNotesSelectValue()}
                      onChange={handleChange}
                      name="special_notes_type"
                      MenuProps={{ ...Menu }}
                    >
                      {specialNote?.map((item, index) => {
                        return (
                          <StyledMenuItem key={index} value={item.name}>
                            {item.name}
                          </StyledMenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item md={4}>
                {(inputValues?.special_notes_type === 'Create a New Note' || (inputValues?.special_notes && !specialNoteTypes.includes(inputValues?.special_notes))) && (
                  <Box className="input_box">
                    <Typography>Notes</Typography>
                    <CustomTextBox
                      value={inputValues?.special_notes ?? ''}
                      onChange={handleChange}
                      name="special_notes"
                    />
                  </Box>
                )}
              </Grid>

              {!isaction ? (
                <Grid item md={4}>
                  <Box className="d-flex-link">
                    <CustomButton
                      title="Map and Nominate Photos"
                      variant="contained"
                      className="btn_theme"
                      onClick={handleMapPhotos}
                    />
                  </Box>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
          {assetPhotosCount > 0 && isMapNominateImageShow ? (
            <Grid className="imageBox">
              Images Mapped
              <hr />
              <Grid container columnSpacing={{md:2}}>
                {assetPhotos?.map((obj, index) => {
                  return (
                    <Grid container xs={2} item key={index} className="image_row">
                      <Box className="list_item">
                        <ListItem key={index} className="list">
                          <img
                            src={url + obj?.path}
                            alt={obj?.path.split('/').pop()}
                            onClick={() => handleImageEnter(obj)}
                          />
                          {/* <ListItemText
                            primary={obj?.path.split('/').pop()}
                            className="label_name"
                          /> */}
                          <FormControlLabel
                            className="checked_label"
                            control={
                              <Checkbox
                                checked={obj?.photo_nominated === 1 ?? false}
                                onChange={() => handleNominatePhoto(obj)}
                              />
                            }
                            label="Nominate"
                          />
                        <IconButton
                        sx={{color: 'white', position: 'absolute', right: '3px',top: '1px', 
                          background: 'rgb(193 193 193 / 80%)', padding: '4px'}}
                          onClick={() => handleImageDelete(obj?.path)}
                        >
                          <Close />
                        </IconButton>
                        </ListItem>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          ) : null}
        </Grid>

        <Box className="d-flex btn-flex mt-10">
          <CustomButton
            title="Save"
            variant="contained"
            className="btn_theme"
            onClick={() => handleSubmit('Save')}
          />
          <CustomButton
            title="Save & Create New"
            variant="contained"
            className="btn_theme"
            onClick={() => handleSubmit('SaveAndCreateNew')}
          />
          <CustomButton
            title="Save & Copy New"
            variant="contained"
            className="btn_theme"
            onClick={() => handleSubmit('SaveAndCopyNew')}
          />
          <CustomButton
            title="Save & Copy Below"
            variant="contained"
            className="btn_theme"
            onClick={() => handleSubmit('SaveAndCopyBelow')}
          />
          <CustomButton
            title="Cancel"
            variant="contained"
            className="btn_theme"
            onClick={handleCancel}
          />
          {!isaction ? (
            <CustomButton
              title="Delete"
              variant="contained"
              className="btn_theme"
              onClick={() => handleSubmit('Delete')}
            />
          ) : (
            ''
          )}
          <CustomButton
            title="Back"
            variant="contained"
            className="btn_theme"
            onClick={handleBack}
          />
        </Box>
      </Box>
      <FullSizeImagePopUp
        url={url}
        imagePath={imagePath}
        openDialog={showFullImage}
        onClose={handleImageLeave}
      />
      <AddAssetDialog
        handleDialog={handleDialog}
        openDialog={openDialog}
        addValue={addValue}
        handleChangeAdd={handleChangeAdd}
        handleAdd={handleAdd}
      />
      <InsertNewLineDialog
        handleDialog={handleDialogLine}
        openDialog={openDialogLine}
        makeList={makeList}
        handleSelected={handleSelectedForLines}
        inputValues={inputLineValues}
        condition={condition}
        handleChange={handleChangeLine}
        assetTypes={filteredAssetTypes}
        handleInsertNewLine={handleInsertNewLine}
        handleCustomTypeAheadForMakeForLine={handleCustomTypeAheadForMakeForLine}
        handleKeyDownForTypeAheadForLine={handleKeyDownForTypeAheadForLine}
        handleSelectedForLineAndAssetTypeForLine={handleSelectedForLineAndAssetTypeForLine}
        assetData={assetData}
        getPriceValues={getPriceValues}
      />
      <ConfirmationAlert
        openConfirmation={openConfirmation}
        handleConfirmation={handleConfirmation}
        title="Are you sure to delete this asset"
        handleDelete={handleDelete}
      />
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  );
}
