import apiInstance from '../config/api/axios'

// GET Location List
const fetchLocations = async(payload) => {
  try {
    if (payload?.type === 'homeData') {
      const response = await apiInstance.get(`location?homepage=${payload?.homepage}&project_id_crm=${payload?.project_id_crm}`)
      return response
    } else if (payload?.type === 'locationData') {
      const response = await apiInstance.get(`location?project_id_crm=${payload?.project_id_crm}`)
      return response
    } else if (payload?.type === 'locationDataWithAppraiser') { // might remove
      const response = await apiInstance.get(`location?getAppraisersOnly=true&project_id_crm=${payload?.project_id_crm}`)
      return response
    } else if (payload?.flag === 'addAsset') {
      const response = await apiInstance.get(`location?getLastAsset=${payload?.getLastAsset}&location_id=${payload?.location_id}&project_id_crm=${payload.project_id_crm}`)
      return response
    }
  } catch (error) {
    console.log('error', error)
  }
}

// POST assign appraiser
const fetchLocationValues = async(payload) => {
  try {
    const response = await apiInstance.post('asset-list', payload)
    return response

  } catch (error) {
    console.log(error)
  }
}

// POST assign appraiser
const fetchProjectById = async(payload) => {
  try {
    const response = await apiInstance.get(`project/${payload.project_id_crm}`)
    localStorage.setItem('project_id', response.data[0].project_id)
    return response

  } catch (error) {
    console.log(error)
  }
}

export {fetchLocations, fetchLocationValues, fetchProjectById,
}

