/* eslint-disable */
/* eslint-disable no-alert */
import React, {useState, useEffect} from 'react'
import 'jspdf-autotable'
import {kaReducer} from 'ka-table'
import {
  DataType,
  SortingMode,
  EditingMode,
} from 'ka-table/enums'
import {useDispatch} from 'react-redux'
import {Box} from '@mui/material'
import {AlertMessage, CustomButton, CustomTable} from '../../../../../components'
import {deleteModelAction, postModelAction} from '../../../../../redux/admin/AdminActions'
import {adminListActions} from '../../../../../redux/admin/admin'
import MyDiv from './manageModelTable.style'
import { validateFieldForRequest } from '../../../../../services/adminService'

export default function ManageModelTable(props) {

  const tablePropsInit = {
    columns: [
      {
        key: 'selection-cell',
        style: {width: '2%'},
      },
      {
        dataType: DataType.String,
        key: 'name',
        style: {width: 120},
        title: 'Name',
        isEditable: true,

      },
      {
        dataType: DataType.String,
        key: 'created_on',
        style: {width: 120},
        title: 'Created On',
        isEditable: false,
      },
    ],
    selectedRows: [],
    data: props.filteredModelList,
    rowKeyField: 'model_label_id',
    height: '75vh',
    sortingMode: SortingMode.Single,
    editingMode: EditingMode.Cell,
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: localStorage.getItem('itemsPerPageModel') ? localStorage.getItem('itemsPerPageModel') : 250,
      pageSizes: [250, 500, 1000],
    },
  }

  const [modelTableList, setModelTableList] = useState(tablePropsInit)
  const dispatch = useDispatch()
  // const adminState = useSelector((state) => state.admin)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')

  useEffect(() => {
    if (props.searchValue !== '') {
      const result = props.filteredModelList.filter((item) => {
        return item?.name?.toLowerCase().includes(props.searchValue.toLowerCase())
      })
      setModelTableList({...modelTableList, data: result})
    } else {
      setModelTableList({...modelTableList, data: props.filteredModelList})
    }
  }, [props.filteredModelList])


  useEffect(() => {
    return () => {
      dispatch(adminListActions.clearSelectedItems()) // clearing the global state of checkboxes for every component
    }
  }, [])

  let userId = localStorage.getItem('userId')
  // const CheckValue = (action, prevState) => {
  //   const previousValue = prevState.data.find(({model_label_id}) => +model_label_id === action.rowKeyValue)
  //   let inputValues = action.value
  //   let flag
  //   if (previousValue[action.columnKey].toLowerCase() === inputValues.toLowerCase()) {
  //     flag = true
  //   } else {
  //     flag = false
  //   }

  //   if (flag) {
  //     return previousValue[action.columnKey]

  //   } else {
  //     return inputValues
  //   }
  // }
  const dispatchResearch = (action) => {
    // const hasValueKey = 'value' in action
    if (action.type === 'UpdateCellValue') {
      if (action.value) {
        let obj = {
          modified_by: Number(userId),
          // name: action.value,
          name: action.value,
          model_label_id: action.rowKeyValue,
        }
        validateFieldForRequest({
          name: action.value,
          action: 'duplicate',
        }, 'model-label').then((res) => {
          if (res?.data?.model[0]?.count > 0) {
            setMessage('Model already exists.')
            setStatus('warning')
          } else {
            dispatch(postModelAction(obj, 'edit'))
          }
        }).catch((err) => {
          console.log(err)
          setMessage('Something went wrong???')
          setStatus('warning')
        })
      } else {
        // setMessage('Please enter Make.')
        // setStatus('warning')
        props.messageData('Please enter Model.', 'warning')
      }
    } else {
      setModelTableList((prevState) => kaReducer(prevState, action))
    }
    // setModelTableList((prevState) => {
    //   if (action.value !== '') {
    //     if (action.type === 'UpdateCellValue') {
    //       let obj = {
    //         modified_by: Number(userId),
    //         name: CheckValue(action, prevState),
    //         model_label_id: action.rowKeyValue,
    //       }
    //       dispatch(postModelAction(obj, 'edit'))
    //     } else {
    //       setMessage('Please Enter Model label')
    //       setStatus('warning')
    //       return prevState
    //     }
    //   }
    //   localStorage.setItem('itemsPerPageModel', modelTableList?.paging.pageSize)
    //   return kaReducer(prevState, action)

    // })
  }

  const handleDelete = () => {
    if (modelTableList?.selectedRows.length > 0) {
      let obj = {
        action: 'deleteList',
        model_label_id: modelTableList?.selectedRows.join('^'),
      }
      dispatch(deleteModelAction(obj))
    } else {
      setMessage('Please select at least one Model Label.')
      setStatus('warning')
    }
  }

  const handleCancel = () => {
    setModelTableList({...modelTableList, selectedRows: []})
  }


  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    dispatch(adminListActions.clearMessageData())
  }

  return (
    <MyDiv>
      <CustomTable
        tableProps={modelTableList}
        dispatch={dispatchResearch}
        flag="model"
      />
      <Box className="d-flex bottom_actions">
        <CustomButton title="Delete" className="btn_theme" variant="contained" onClick={handleDelete} />
        <CustomButton title="Cancel" className="btn_reset" variant="contained" onClick={handleCancel} />
      </Box>
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
