/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {useState, useEffect} from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import {decode as base64Decode} from 'base-64'
import {DataType} from 'ka-table/enums'
import {kaReducer} from 'ka-table'
import {useDispatch, useSelector} from 'react-redux'
import {AlertMessage, CustomButton, CustomTable, CustomTextBox, Loader} from '../../../components'
// import {FindReplaceTable} from '../components'
import {getFindReplaceAction, postFindReplaceAction} from '../../../redux/assets/AssetsActions'
import {assetsListActions} from '../../../redux/assets/assets'
import MyDiv from './findReplace.style'


const TABLEPROPSINIT = {
  columns: [
    {
      key: 'selection-cell',
      style: {width: '3.5%'},
    },
    {
      dataType: DataType.String,
      key: 'asset_id',
      style: {width: 70},
      title: 'Asset',
    },
    {
      dataType: DataType.String,
      key: 'capacity',
      style: {width: 70},
      title: 'Original Capacity',
    },
    {
      dataType: DataType.String,
      key: 'replace_capacity',
      style: {width: 70},
      title: 'Replace Capacity',
    },
    {
      dataType: DataType.String,
      key: 'description',
      style: {width: 70},
      title: 'Original Description',
    },
    {
      dataType: DataType.String,
      key: 'replace_description',
      style: {width: 70},
      title: 'Replace Description',
    },
  ],
  data: [],
  selectedRows: [],
  rowKeyField: 'type_id',
  // paging: {
  //   enabled: true,
  //   pageIndex: 0,
  //   pageSize: 50,
  //   pageSizes: [50, 100, 150, 200, 250],
  // },
}

export default function FindReplace() {

  const dispatch = useDispatch()
  const [showList, setShowList] = useState(false)
  const [findReplaceList, setFindReplaceList] = useState(TABLEPROPSINIT)
  const [values, setValues] = useState({})
  const [checkValue, setCheckValue] = useState('matchcase')
  // const [isChecked, setIsChecked] = useState({})
  const [message, setMessage] = useState(null)
  const [status, setStatus] = useState(null)
  const [isSubmit, setSubmit] = useState(false)
  const [title, setTitle] = useState(null)
  const assetsState = useSelector((state) => state.assets)

  const getSelectedRows = () => {
    let data = assetsState?.findReplace?.findReplace
    const arr = []
    data?.map(({type_id}) => {arr.push(type_id)})
    return arr
  }
  let projectIDCrm = localStorage.getItem('project_id_crm')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string

  const dispatchReports = (action) => {
    setFindReplaceList((prevState) => kaReducer(prevState, action))
  }

  // useEffect(() => {
  //   setFindReplaceList(assetsState?.findReplace?.findReplace)
  // }, [assetsState?.findReplace?.findReplace])
  useEffect(() => {
    setFindReplaceList({...TABLEPROPSINIT, data: assetsState?.findReplace?.findReplace, selectedRows: getSelectedRows()})
  }, [assetsState?.findReplace?.findReplace])

  useEffect(() => {
    let data = assetsState?.findReplace?.findReplace
    if (isSubmit) {
      if (data?.length > 0) {
        setTitle('findreplace')
        setShowList(true)
      } else {
        setMessage('Nothing to Replace')
        setStatus('warning')
      }
    }
  }, [assetsState?.findReplace?.findReplace])

  useEffect(() => {
    if (assetsState?.messageData.status === 'success') {
      setMessage(assetsState?.messageData?.message)
      setStatus('success')
      // setShowList(!showList)
      setValues({})
    }
    if (assetsState?.messageData.status === 'failed') {
      setMessage(assetsState?.messageData?.message)
      setStatus('warning')
    }
  }, [assetsState?.messageData])

  const handleChange = (e) => {
    setValues({...values, [e.target.name]: e.target.value})
  }

  const handleMatchCase = (e) => {
    setCheckValue(e.target.value)
  }

  // const handleCheckBox = (e) => {
  //   setIsChecked({...isChecked, [e.target.value]: e.target.checked})
  // }

  const handleSubmit = () => {
    if (!values?.find) {
      setMessage('Please Enter Find')
      setStatus('warning')
      return
    }
    let obj = {
      find: values?.find ?? '',
      matchtype: checkValue,
      replace: values?.replace ?? '',
      type: 'filter',
      project_id_crm: decodedProjectId,
    }
    dispatch(getFindReplaceAction(obj))
    setShowList(true)
    setSubmit(true)
  }

  const commitChanges = () => {
    let data = {
      find_text: values?.find ?? '',
      replace_text: values?.replace ?? '',
      matchtype: checkValue,
      items: findReplaceList?.selectedRows.join(','),
      // importedAsset: 1,
    }
    // if (!values?.replace) {
    //   setMessage('Please Enter Replace')
    //   setStatus('warning')
    // }
    if (findReplaceList?.selectedRows?.length === 0) {
      setMessage('Please select atleast one asset')
      setStatus('warning')
    }
    if (findReplaceList?.selectedRows?.length !== 0) {
      dispatch(postFindReplaceAction(data))
      setSubmit(false)
      setShowList(false)
      // setValues({})
    }
  }
  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    dispatch(assetsListActions.clearMessageData())
  }
  return (
    <MyDiv>
      {(assetsState?.findReplace?.loading) &&
      <div>
        <Loader />
      </div>
      }
      <Box className="page_heading">
        <Typography>{!showList ? 'Find Replace' : 'Assets'}</Typography>
        {showList && findReplaceList?.data?.length > 0 &&
        <CustomButton variant="contained" className="btn_theme" title="Commit" onClick={commitChanges} />
        }
      </Box>
      <Box className="wrapper_box">
        <Grid container columnSpacing={{md: 4}}>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Find</Typography>
              <CustomTextBox value={values?.find ?? ''} onChange={handleChange} name="find" fieldLabel="Find" />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Replace</Typography>
              <CustomTextBox value={values?.replace ?? ''} onChange={handleChange} name="replace" fieldLabel="Replace" />
            </Box>
          </Grid>
          <Grid item md={12} mt={2}>
            <FormControl>
              <RadioGroup row>
                <FormControlLabel
                  className="checked_label"
                  value="matchcase"
                  control={<Radio onChange={handleMatchCase} checked={checkValue === 'matchcase'} />}
                  label="Match Case"
                />
                <FormControlLabel
                  className="checked_label"
                  value="matchword"
                  control={<Radio onChange={handleMatchCase} checked={checkValue === 'matchword'} />}
                  label="Match Whole Word"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Box mt={2}>
          <CustomButton
            title="Submit"
            variant="contained"
            className="btn_theme"
            onClick={handleSubmit}
          />
        </Box>
      </Box>
      {showList && findReplaceList?.data?.length > 0 ?
        <CustomTable title={title} tableProps={findReplaceList} dispatch={dispatchReports} /> :
        <Typography style={{textAlign: 'center', paddingTop: '10px'}}> {showList ? 'No Data Found' : '' }</Typography>}
      {/* // <FindReplaceTable findReplaceList={findReplaceList} handleCheckBox={handleCheckBox} isChecked={isChecked} /> : <Typography style={{textAlign: 'center', paddingTop: '10px'}}> {showList ? 'No Data Found' : '' }</Typography>} */}
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
