/* eslint-disable prefer-template */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import {Box, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography} from '@mui/material'
import {Close, KeyboardArrowLeft as KeyboardArrowLeftIcon, KeyboardArrowRight as KeyboardArrowRightIcon} from '@mui/icons-material'
import MyDiv from './researchDialogstyle'

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ResearchDialog({showDataPopup, isIndex, handlePopup, modalData, handleNext, handlePrevious}) {
  const valueCode = modalData?.value_code?.split('#')?.map((item) => {return item})
  const values = modalData?.value?.split('#')?.map((item) => {return item})

  const valueResults = valueCode?.reduce((obj, k, i) => {
    obj[k] = values[i] == 'LEASED' || values[i] == 'NOT VALUED' || values[i] == 'NO VALUE' || values[i] == 'Not Valued' || values[i] == 'No Value' || values[i] == 'Leased' || !values[i]
      ? '0' : values[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return obj
  }, {})
  const popup = (popupbox) => (
    <MyDiv>
      <Box className="dialog_box">
        <Box className="dialog_header">
          <DialogTitle className="dialog_title">Assets Detail </DialogTitle>
          <IconButton className="icon" onClick={handlePopup}><Close /></IconButton>
        </Box>
        <Divider />
        <DialogContent className="dialog_content">
          <Typography className="heading">{modalData?.asset_id} (Appraisal)</Typography>
          <Grid container mt={1.5} rowSpacing={1} columnSpacing={{md: 4, sm: 4}}>
            <Grid item md={6}>
              <Box className="d-flex">
                <Typography className="label">Appraisal</Typography>
                <Typography className="label_data">{modalData?.appraiser} </Typography>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box className="d-flex">
                <Typography className="label">Date Appraised</Typography>
                <Typography className="label_data">{modalData?.date_appraised}</Typography>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box className="d-flex">
                <Typography className="label">Project Name</Typography>
                <Typography className="label_data">{modalData?.project_name}</Typography>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box className="d-flex">
                <Typography className="label">Serial</Typography>
                <Typography className="label_data">{modalData?.serial_number}</Typography>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box className="d-flex">
                <Typography className="label">Facility</Typography>
                <Typography className="label_data">
                  {modalData?.location_name}, {modalData?.billing_address_street}, {modalData?.billing_address_city}, {modalData?.billing_address_state}{modalData?.billing_address_postalcode ? `-${modalData?.billing_address_postalcode}` : ''} {modalData?.billing_address_country}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box className="d-flex">
                <Typography className="label">Quantity</Typography>
                <Typography className="label_data">{modalData?.quantity}</Typography>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box className="d-flex">
                <Typography className="label">Make</Typography>
                <Typography className="label_data">{modalData?.make}</Typography>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box className="d-flex">
                <Typography className="label">Model</Typography>
                <Typography className="label_data">{modalData?.model}</Typography>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box className="d-flex">
                <Typography className="label">Capacity</Typography>
                <Typography className="label_data">{modalData?.capacity}</Typography>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box className="d-flex">
                <Typography className="label">Asset Type</Typography>
                <Typography className="label_data">{modalData?.asset_type}</Typography>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box className="d-flex">
                <Typography className="label">Year</Typography>
                <Typography className="label_data">{modalData?.year}</Typography>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Typography className="label_cost">Cost</Typography>
            </Grid>
            <Grid item md={3}>
              <Box className="d-flex" sx={{columnGap: '3px'}}>
                <Typography className="label_data">FLV</Typography>
                <Typography className="label_data">${valueResults?.FLV?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 0}</Typography>
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className="d-flex" sx={{columnGap: '5px'}}>
                <Typography className="label_data">OLV </Typography>
                <Typography className="label_data">${valueResults?.OLV?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 0}</Typography>
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className="d-flex" sx={{columnGap: '5px'}}>
                <Typography className="label_data">OLVIP</Typography>
                <Typography className="label_data">${valueResults?.OLVIP?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 0}</Typography>
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className="d-flex" sx={{columnGap: '5px'}}>
                <Typography className="label_data">FAS</Typography>
                <Typography className="label_data">${valueResults?.FAS?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 0}</Typography>
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className="d-flex" sx={{columnGap: '5px'}}>
                <Typography className="label_data">FMV</Typography>
                <Typography className="label_data">${valueResults?.FMV?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 0}</Typography>
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className="d-flex" sx={{columnGap: '5px'}}>
                <Typography className="label_data">FMVIP</Typography>
                <Typography className="label_data">${valueResults?.FMVIP?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 0}</Typography>
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className="d-flex" sx={{columnGap: '5px'}}>
                <Typography className="label_data">FMVR</Typography>
                <Typography className="label_data">${valueResults?.FMVR?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 0}</Typography>
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className="d-flex" sx={{columnGap: '5px'}}>
                <Typography className="label_data">RCN</Typography>
                <Typography className="label_data">${valueResults?.RCN?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 0}</Typography>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box className="d-flex">
                <Typography className="label">Description</Typography>
                <Typography className="label_data">{modalData?.description}</Typography>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box className="d-flex">
                <Typography className="label">Notes</Typography>
                <Typography className="label_data" />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {isIndex > 0 ?
            <IconButton className="icon" onClick={handlePrevious}>
              <KeyboardArrowLeftIcon />
            </IconButton> : ''}
          <IconButton className="icon" onClick={handleNext}>
            <KeyboardArrowRightIcon />
          </IconButton>
        </DialogActions>
      </Box>
    </MyDiv>
  )
  return (
    <Dialog
      popupbox="openDialog"
      open={showDataPopup}
      TransitionComponent={Transition}
      onClose={handlePopup}
      maxWidth="md"
      fullWidth
    >
      {popup('openDialog')}
    </Dialog>
  )
}
