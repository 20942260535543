import {postAddAssets} from '../../../services/assetListService'
import {assetsListActions} from '../assets'

const postAddAssetAction = (payload, type = null, id = null) => {
  return (dispatch) => {
    dispatch(assetsListActions.postAddAssetRequest())
    postAddAssets(payload).then((res) => {
      if (res.status === 200) {
        if (type === 'edit') {
          dispatch(assetsListActions.setMessageData({message: 'Asset Details Updated successfully.', status: 'success'}))
          localStorage.removeItem('typeIdForEdit')
          localStorage.setItem('type_id', id === 'previousIdActive' ? (payload?.type_id || payload?.copy_type_id) : res.data.id)
        } else {
          dispatch(assetsListActions.setMessageData({message: 'Asset Details Added successfully.', status: 'success'}))
          localStorage.removeItem('typeIdForEdit')
          if (id === 'previousIdActive' && (payload?.type_id || payload?.copy_type_id)) { // !just fixing it up. Will have to make it cleaner sooner or later
            localStorage.setItem('type_id', (payload?.type_id || payload?.copy_type_id))
          } else {
            localStorage.setItem('type_id', res.data.id)
          }
        }
      }
      dispatch(assetsListActions.postAddAssetSuccess(res))
      dispatch(assetsListActions.setDoFetchAssetList(true))
    }).catch((error) => {
      dispatch(assetsListActions.setMessageData({message: 'Something Went Wrong!', status: 'warning'}))
      dispatch(assetsListActions.postAddAssetFailure(error))
    })

  }
}

export default postAddAssetAction
