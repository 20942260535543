import apiInstance from '../config/api/axios'

// GET Lines List
const fetchReportList = async(payload) => {
  try {
    const response = await apiInstance.get(`location?pageReport=${payload.pageReport}&project_id_crm=${payload.project_id_crm}`)
    return response.data.location
  } catch (error) {
    throw error
  }
}

// GET project data
const fetchProjectData = async(payload) => {
  try {
    const response = await apiInstance.get(`project/${payload?.project_id_crm}`)
    return response
  } catch (error) {
    throw error
  }
}

// Put Area Ordering
const postAreaOrder = async(payload) => {
  try {
    const response = await apiInstance.put(`location/${payload.id}`, payload)
    return response
  } catch (error) {
    throw error
  }
}

const postReports = async(payload) => {
  try {
    const response = await apiInstance.post('reports', payload)
    return response
  } catch (error) {
    throw error
  }
}

const getReportAssets = async(data) => {
  try {
    const response = await apiInstance.post('asset-list', data)
    return response.data
  } catch (error) {
    throw error
  }
}

const getFASReport = async(payload) => {
  try {
    const response = await apiInstance.post('reports', payload)
    return response
  } catch (error) {
    throw error
  }
}

const getLocations = async(getAppraisersOnly, proj) => {
  try {
    const res = await apiInstance.get(`location?getAppraisersOnly=${getAppraisersOnly}&project_id_crm=${proj}`)
    return res
  } catch (error) {
    throw error
  }
}

const getProjectData = async(projectId) => {
  try {
    const res = await apiInstance.get(`project/${projectId}`)
    return res
  } catch (error) {
    throw error
  }
}
const getProjectReport = async(type, proj) => {
  try {
    const res = await apiInstance.get(`location?pageReport=${type}&project_id_crm=${proj}`)
    return res
  } catch (error) {
    throw error
  }
}
const getPhotosByAsset = async(page, proj) => {
  try {
    const res = await apiInstance.get(`photos-by-asset?pageReport=${page}&project_id=${proj}`)
    return res
  } catch (error) {
    throw error
  }
}
const getProjAssets = async(payload) => {
  try {
    const res = await apiInstance.post('asset-list', payload)
    return res
  } catch (error) {
    throw error
  }
}
const reOrderLocations = async(payload) => {
  try {
    const res = await apiInstance.put(`location/${payload.id}`, payload)
    return res
  } catch (error) {
    throw error
  }
}

export {
  fetchReportList,
  getProjAssets,
  getProjectReport,
  getPhotosByAsset,
  getProjectData,
  postAreaOrder,
  postReports,
  fetchProjectData,
  getReportAssets,
  getFASReport,
  getLocations,
  reOrderLocations,
}

