/* eslint-disable prefer-template */
/* eslint-disable no-undef */
/* eslint-disable radix */
/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react'
import 'jspdf-autotable'
import {kaReducer} from 'ka-table'
import {
  DataType,
  SortingMode,
  EditingMode,
} from 'ka-table/enums'
import {useSelector} from 'react-redux'
import {CustomTable} from '../../../../components'
import MyDiv from './editLineTable.style'


export default function EditLineTable(props) {

  const homeState = useSelector((state) => state.home)
  const projectPriceValues = homeState?.locationsList?.locationsList?.data?.projectPriceValues

  const tablePropsInit = {
    columns: [
      {
        dataType: DataType.String,
        key: 'asset_id',
        style: {width: 100},
        title: 'Asset Id',
        isEditable: false,
      },
      {
        dataType: DataType.String,
        key: 'make',
        style: {width: 120},
        title: 'Make',
        isEditable: false,
      },
      {
        dataType: DataType.String,
        key: 'model',
        style: {width: 120},
        title: 'Model',
        isEditable: false,
      },
      {
        dataType: DataType.String,
        key: 'capacity',
        style: {width: 120},
        title: 'Capacity',
        isEditable: false,
      },
      // {
      //   dataType: DataType.String,
      //   key: 'FLV',
      //   style: {width: 120},
      //   title: 'FLV ($)',
      //   isEditable: false,
      // },
      // {
      //   dataType: DataType.String,
      //   key: 'OLV',
      //   style: {width: 120},
      //   title: 'OLV($)',
      //   isEditable: false,
      // },
      // {
      //   dataType: DataType.String,
      //   key: 'FMVIP',
      //   style: {width: 120},
      //   title: 'FMVIP($)',
      //   isEditable: false,
      // },
    ],
    data: [],
    rowKeyField: 'type_id',
    sortingMode: SortingMode.Single,
    editingMode: EditingMode.Cell,
  }
  const [editLineTable, seteditLineTable] = useState(tablePropsInit)
  console.log('🚀 ~ EditLineTable ~ editLineTable:', editLineTable)

  // function removeDuplicateTitles(data) {
  //   // Create an object to store unique titles
  //   let uniqueTitles = {}

  //   // Filter out duplicates
  //   data = data?.filter((item) => {
  //     if (!uniqueTitles[item.title]) {
  //       uniqueTitles[item.title] = true
  //       return true
  //     }
  //     return false
  //   })

  //   return data
  // }

  useEffect(() => {
    let updatedColumn = [...tablePropsInit?.columns]
    let ValueNames = []
    const updatedAssets = props?.lineAssetList?.lineAssets?.map((item) => {
      const {value_code, value, ...rest} = item
      const valueNames = value_code.split('#')
      const valueCodes = value.split('#')
      ValueNames.push(valueNames)
      const newObj = {}
      valueNames.forEach((key, index) => {
        newObj[key] = parseInt(valueCodes[index])
      })

      return {
        ...rest,
        ...newObj,
      }
    })
    props?.lineAssetList?.lineAssets?.map((item) => {
      // const {value_code} = item
      // const valueNames = value_code.split('#')
      // console.log(projectPriceValues, 'project values')
      projectPriceValues?.forEach((key, index) => {
        let data = {
          dataType: 'string',
          key: key,
          style: {width: 120},
          title: key + '($)',
          isEditable: false, // sortDirection: SortDirection.Descend,
        }
        updatedColumn.push(data)
      })
    })
    let crossColumn = {
      key: 'cross_icon',
      width: '5%',
      title: '',
      isEditable: false,
    }
    if (props?.data?.key?.name === 'Edit Line') {
      updatedColumn.push(crossColumn)
    }
    const uniqueColumnnames = new Set()
    const uniqueColumnnData = []

    updatedColumn?.forEach((entry) => {
      if (!uniqueColumnnames.has(entry.title)) {
        uniqueColumnnames.add(entry.title)
        uniqueColumnnData.push(entry)
      }
    })
    seteditLineTable({...editLineTable, data: updatedAssets, columns: uniqueColumnnData})
  }, [props?.lineAssetList, projectPriceValues])
  // useEffect(() => {
  //   seteditLineTable(tablePropsInit)
  // }, [props?.lineAssetList])
  const dispatchResearch = (action) => {
    seteditLineTable((prevState) => kaReducer(prevState, action))
  }
  return (
    <MyDiv >
      <div style={{marginTop: '43px'}}>
        <CustomTable
          tableProps={editLineTable}
          dispatch={dispatchResearch}
          handleDeleteLine={props.handleDelete}
        />
      </div>
    </MyDiv>
  )
}
