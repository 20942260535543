import React from 'react'
import {Box} from '@mui/material'
import MyDiv from './selectionDropdown.style'

export default function SelectionDropdown({selectedValue, data, handleChange, toggleClass, handleToggle, rowId, currentRowId}) {
  return (
    <MyDiv>
      {toggleClass && currentRowId?.type_id === rowId ?
        <select value={selectedValue || ''} onChange={(e) => handleChange(e.target.value)} name="assetClass">
          {data?.map((item) => {
            return (
              <option value={item.code} key={item.asset_class_id}>{item.code}</option>
            )
          })}
        </select> : <Box onClick={() => handleToggle(rowId)} style={{color: selectedValue === 'NA' ? 'transparent' : 'black'}}>{selectedValue}</Box>
      }
    </MyDiv>
  )
}
