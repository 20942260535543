import styled from 'styled-components'

const MyDiv = styled.div`
.dialog_title{
    padding: 10px 0px;
    font-size: 20px;
    font-weight: 600;
    font-family: "Arial";
}
.text_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.dialog_row{
    display:flex;
    flex-direction:row;
    justify-content: space-around;
    align-items:center;
}
.dialog_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dialog_content{
    padding: 20px 0px 10px;
    margin:10px;
}
.dialog_box{
    padding: 0px 20px;
}
.icon{
 color: #000;
}
.d-flex{
    display: flex;
    width: 100%
    justify-content: end;
    column-gap: 20px;
}
.mb-20{
    margin-bottom:20px;
}
.label{
    font-size: 14px;
    font-weight: 500;
    font-family: "Arial";
    color: #333;
}
.jhxGsJ > .wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid #aaa;
    border-radius: 4px;
    background-color: white;
    color: #212121;
    font-size: 14px;
    font-family: "Arial";
}
.jhxGsJ {
    position: relative;
    height: 37px;
    width: 200px;
}

.input_box{
    margin-top: 20px;
}
.input_box p{
    font-weight: 500;
    color: #555;
    font-size: 14px;
    font-family: "Arial";
}
.dropdown-menu{
    border: 1px solid #ddd;
    z-index: 1;
    border-bottom: 0;
    width: 100%!important;
    overflow-x: hidden!important;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(1.6em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #555;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control:focus-visible {
    border-color: #1976d2;
    border-width: 2px;
    outline: none!important;
}
a.dropdown-item{
    display: block;
    background: #fff;
    color: #000;
    font-size: 15px;
    font-weight: 400;
    text-decoration: none;
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
}
.rbt-aux {
    align-items: center;
    display: flex;
    bottom: 0;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 2rem;
}
.rbt-aux .rbt-close {
    // margin-top: -0.25rem;
    pointer-events: auto;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 1;
    cursor: pointer;
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}
button.close{
    background-color: transparent;
    border: 0;
}
`
export default MyDiv
