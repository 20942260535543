import styled from 'styled-components'

const MyDiv = styled.div`
.dialog_title{
    padding: 10px 0px;
    font-size: 20px;
    font-weight: 600;
    font-family: "Arial";
}
.dialog_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.address{
    // display:flex;
    // flex-direction:row;
    height: 12px;
}
.dialog_content{
    padding: 20px 0px;
    overflow-y:hidden;
}
.dialog_box{
    padding: 0px 20px;
}
.icon{
 color: #000;
}
.label{
    font-size: 14px;
    font-weight: 600;
    font-family: "Arial";
    color: #fff;
    // text-transform:capitalize;
}
.no-data{
    font-size: 16px;
    font-weight: 500;
    font-family: "Arial";
    color: #000;
}
.label_data{
    font-size: 12px;
    font-weight: 400;
    font-family: "Arial";
    color: #fff;
}
.label_box{
    background: #2e6da4;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

`
export default MyDiv
