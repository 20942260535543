import React from 'react'
import {updatePageSize} from 'ka-table/actionCreators'
import MyDiv from './pageSize.style'


export default function PageSize({pageSize, pageSizes, dispatch}) {
  const PAGE_SIZE = localStorage.getItem('itemsPerPage')
  return (
    <MyDiv>
      Items Per Page:
      <select
        className="select_box"
        value={PAGE_SIZE ?? pageSize}
        onChange={(event) => {
          const {value} = event.currentTarget
          localStorage.setItem('itemsPerPage', +value)
          dispatch(updatePageSize(Number(value)))
        }}
      >
        {/* setting the current selected page size to either what the user just selected or what is already in the local storage  */}
        {pageSizes?.map((value, index) => (
          <option key={index} selected={value === PAGE_SIZE ?? pageSize} value={value}>
            {value}
          </option>
        ))}
      </select>
    </MyDiv>
  )
}
