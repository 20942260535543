import {removeAssetType} from '../../../services/adminService'
import {adminListActions} from '../admin'
import getAssetClassesListAction from './getAssetClassesListAction'
import getAssetTypesClassesAction from './getAssetTypesClassesAction'

const deleteAssetTypeClassAction = (payload) => {
  return (dispatch) => {
    dispatch(adminListActions.deleteAssetTypeClassRequest())
    removeAssetType(payload).then((res) => {
      if (res.status === 200) {
        if (payload.action === 'disassociate') {
          dispatch(adminListActions.setMessageData({message: 'Asset Type Disassociated Successfully.', status: 'success'}))
          dispatch(getAssetClassesListAction())
          dispatch(getAssetTypesClassesAction({action: 'FetchAssetType'}))
        }
        if (payload.action === 'associate') {
          dispatch(adminListActions.setMessageData({message: 'Asset Type Associated Successfully.', status: 'success'}))
          dispatch(getAssetClassesListAction())
          dispatch(getAssetTypesClassesAction({action: 'FetchAssetType'}))
        }
        dispatch(adminListActions.deleteAssetTypeClassSuccess(res))
        // dispatch(getAssetTypesClassesAction({action: payload.classListAction})) // extracting the action for our API (value - FetchAssetType)
      }
    }).catch((error) => {
      dispatch(adminListActions.deleteAssetTypeClassFailure(error))
    })
  }
}

export default deleteAssetTypeClassAction
