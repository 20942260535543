/* eslint-disable no-debugger */
import {postMakeAdd} from '../../../services/adminService'
import {adminListActions} from '../admin'
import getMakeListAction from './getMakeListAction'

const postMakeListAction = (payload, mode) => {
  let flag = localStorage.getItem('isFlagged')
  return async(dispatch) => {
    dispatch(adminListActions.postMakeListRequest())
    if (!payload.name) return
    postMakeAdd({action: 'duplicate', name: payload.name}).then((res) => {
      if (res.status === 200) {
        if (res.data.make[0].count === 1) {
          dispatch(adminListActions.setMessageData({message: 'Make Already Exists.', status: 'failed'}))
          dispatch(adminListActions.postMakeListFailure())
          dispatch(getMakeListAction(flag))
          return
        }
        if (mode === 'add') {
          postMakeAdd({...payload, action: 'add'}).then(() => {
            dispatch(getMakeListAction(flag))
            dispatch(adminListActions.setMessageData({message: 'Make Added Successfully.', status: 'success'}))
            dispatch(adminListActions.postMakeListSuccess(res))
          })
        }
        if (mode === 'edit') {
          postMakeAdd({...payload, action: 'edit'}).then(() => {
            dispatch(getMakeListAction(flag))
            dispatch(adminListActions.setMessageData({message: 'Make Edit Successfully.', status: 'success'}))
            dispatch(adminListActions.postMakeListSuccess(res))
          })
        }
        if (mode === 'editWithoutMessage') {
          postMakeAdd({...payload, action: 'edit'}).then(() => {
            dispatch(getMakeListAction(flag))
            dispatch(adminListActions.postMakeListSuccess(res))
          })
        }
      }
      // dispatch(adminListActions.postMakeListSuccess(res))
    }).catch((error) => {
      dispatch(adminListActions.postMakeListFailure(error))
    })
  }
}

export default postMakeListAction
