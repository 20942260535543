import styled from 'styled-components'

const MyDiv = styled.div`
.footer {
    background: #f6f6f6;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 20px 25px;
    margin-top: 20px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
}
img{
    width: 250px;
    height: 100%;
}
`
export default MyDiv
