import styled from 'styled-components'

const MyDiv = styled.div`
position: relative;

.ka-cell-text .icon_btn {
    padding: 4px 10px;
}
`
export default MyDiv
