/* eslint-disable no-unused-vars */
import {postLineDetail} from '../../../services/lineServices'
import {lineListActions} from '../line'
// import * as routesNames from '../../../constants/routes'
// import getLineByAreaListAction from './getLineByAreaListAction'

const postLineDetailAction = (payload, action) => {
  return (dispatch) => {
    dispatch(lineListActions.postLineDetailRequest())
    postLineDetail(payload).then((res) => {
      if (res.status === 200) {
        if (payload.formAction === 'copyLine') {
          localStorage.removeItem('current_type_ref')
          localStorage.removeItem('typeIdForEdit')
          localStorage.setItem('type_id', res.data.id)
          dispatch(lineListActions.setMessageData({message: 'Copy Line successfully.', status: 'success'}))
        } else if (action === 'addLine') {
          dispatch(lineListActions.setMessageData({message: 'Line added successfully.', status: 'success'}))
        } else {
          dispatch(lineListActions.setMessageData({message: 'Line update successfully.', status: 'success'}))
        }
        // history.push(routesNames.ASSETLIST) // not working
      }
      dispatch(lineListActions.postLineDetailSuccess(res))
    }).catch((error) => {
      dispatch(lineListActions.postLineDetailFailure(error))
    })

  }
}

export default postLineDetailAction
