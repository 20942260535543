import {uploadFile} from '../../../services/importServices'
import {importListActions} from '../import'

const uploadAction = (data, payload, history) => {
  return (dispatch) => {
    dispatch(importListActions.uploadFileRequest())
    uploadFile(data, payload).then((res) => {
      dispatch(importListActions.uploadFileSuccess(res))
    }).catch((error) => {
      dispatch(importListActions.uploadFileFailure(error))
    })

  }
}

export default uploadAction
