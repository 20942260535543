/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-duplicate-imports */
import React from 'react'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import {Box, Typography, IconButton, DialogTitle} from '@mui/material'
import {Close} from '@mui/icons-material'
import styled from 'styled-components'


import MyDiv from './fullSizeImage.style'

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

// const StyledMenuItem = styled()(() => ({
//   '&.MuiMenuItem-root': {
//     borderBottom: '1px solid #ccc',
//     color: '#000000',
//     fontWeight: '400',
//     fontSize: '14px',
//     fontFamily: 'Arial',
//   },
//   '&.MuiMenuItem-root:first-child': {
//     marginTop: '-8px',
//   },
//   '&.MuiMenuItem-root:last-child': {
//     marginBottom: '-8px',
//   },
// }))


export default function FullSizeImagePopUp(props) {
  const popup = (popupbox) => (
    <MyDiv>
      <Box className="dialog_box">
        <Box className="dialog_header">
          <DialogTitle className="dialog_title">{props.imagePath?.path.split('/').pop()}</DialogTitle>
          <IconButton className="icon" onClick={props.onClose}><Close /></IconButton>
        </Box>
        <div className="img-wrapper" style={{width: '100%', height: '100%', textAlign: 'center'}}>
          <img style={{width: '100%', height: '100%'}} src={props.url + props.imagePath?.path} alt={props.imagePath?.path.split('/').pop()} />
        </div>
      </Box>
    </MyDiv>
  )
  return (
    <Dialog
      popupbox="openDialog"
      open={props.openDialog}
      TransitionComponent={Transition}
      onClose={props.onClose}
      fullWidth
      maxWidth={'md'}
    >
      {popup('openDialog')}
    </Dialog>
  )
}
