import {logoutUser} from '../../../services/authService'
import {authListActions} from '../auth'

const logoutUserActions = (payload) => {
  return (dispatch) => {
    dispatch(authListActions.logoutUserRequest())
    logoutUser(payload).then((res) => {
      dispatch(authListActions.logoutUserSuccess(res))
    }).catch((error) => {
      dispatch(authListActions.logoutUserFailure(error))
    })
  }
}

export default logoutUserActions
