import styled from 'styled-components'

const MyDiv = styled.div`
.toggle_btn .Mui-checked {
    color: rgb(46, 125, 50);
}
.toggle_btn .Mui-checked + .MuiSwitch-track {
    background-color: rgb(46, 125, 50);
}
`
export default MyDiv
