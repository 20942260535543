import * as routesNames from '../constants/routes'

export const ErrorTimeOut = 2000

export const condition = [
  {
    id: 1,
    name: 'New',
    value: 'N',
  },
  {
    id: 2,
    name: 'Excellent',
    value: 'E',
  },
  {
    id: 3,
    name: 'Very Good',
    value: 'VG',
  },
  {
    id: 4,
    name: 'Good',
    value: 'G',
  },
  {
    id: 5,
    name: 'Fair',
    value: 'F',
  },
  {
    id: 6,
    name: 'Poor',
    value: 'P',
  },
  {
    id: 7,
    name: 'NA',
    value: 'NA',
  },
  {
    id: 8,
    name: 'Scrap',
    value: 'X',
  },
  {
    id: 9,
    name: 'Salvage',
    value: 'S',
  },
]

export const mileage = [
  {
    id: 1,
    name: 'mileage',
  },
  {
    id: 2,
    name: 'hours',
  },
]

export const specialNote = [
  {
    id: 0,
    name: '',
  },
  {
    id: 1,
    name: 'Leased',
  },
  {
    id: 2,
    name: 'Not Valued',
  },
  {
    id: 3,
    name: 'Valued for Salvageable Components Only',
  },
  {
    id: 4,
    name: 'Disassembled at Time of Inspection',
  },
  {
    id: 5,
    name: 'Not Inspected by Appraiser',
  },
  {
    id: 6,
    name: 'Not Inspected by Appraiser. Information Provided by Company.',
  },
  {
    id: 7,
    name: 'Out of Service at Time of Inspection',
  },
  {
    id: 8,
    name: 'Disconnected and Out of Service at Time of Inspection.',
  },
  {
    id: 9,
    name: 'Not in Use at Time of Inspection',
  },
  {
    id: 10,
    name: 'Not Yet Installed at Time of Inspection',
  },
  {
    id: 11,
    name: 'Appraised Utilizing Cost Approach',
  },
  {
    id: 12,
    name: 'Located Outside',
  },
  {
    id: 13,
    name: 'Create a New Note',
  },
]

export const specialNoteTypes = [
  'Leased',
  'Create a New Note',
  'Located Outside',
  'Appraised Utilizing Cost Approach',
  'Not Yet Installed at Time of Inspection',
  'Not in Use at Time of Inspection',
  'Disconnected and Out of Service at Time of Inspection.',
  'Out of Service at Time of Inspection',
  'Not Inspected by Appraiser. Information Provided by Company.',
  'Not Inspected by Appraiser',
  'Disassembled at Time of Inspection',
  'Valued for Salvageable Components Only',
  'Not Valued',
]

export const quantity = [
  {
    id: 1,
    name: 'Lot',
    type: 'lot',
  },
  {
    id: 2,
    name: 'Quantity',
    type: 'number',
  },
]

export const Menus = [
  {
    id: 0,
    menuName: 'Home',
    link: routesNames.HOME,
  },
  {
    id: 1,
    menuName: 'Assets',
    submenu: [
      {
        id: 11,
        submenuName: 'Asset List',
        link: routesNames.ASSETLIST,
      },
      {
        id: 12,
        submenuName: 'Add Asset',
        link: '/asset-detail',
      },
      {
        id: 13,
        submenuName: 'Map Photos',
        link: routesNames.MAPPHOTOS,
      },
      {
        id: 14,
        submenuName: 'Find & Replace',
        link: routesNames.FINDREPLACE,
      },
    ],
  },
  {
    id: 2,
    menuName: 'Lines',
    submenu: [
      {
        id: 21,
        submenuName: 'Line List',
        link: routesNames.LINELIST,
      },
      {
        id: 22,
        submenuName: 'Add Line',
        link: routesNames.ADDLINE,
      },
    ],
  },
  {
    id: 3,
    menuName: 'Reports',
    submenu: [
      {
        id: 31,
        submenuName: 'Final Asset List Report',
        link: routesNames.ASSETREPORTLIST,
      },
      {
        id: 32,
        submenuName: 'Valuation by class/ condition',
        link: routesNames.REPORTVALUATION,
      },
      {
        id: 33,
        submenuName: 'Photo Reports',
        link: routesNames.PHOTOREPORTS,
      },
    ],
  },
  {
    id: 4,
    menuName: 'Research',
    link: routesNames.RESEARCH,
  },
  {
    id: 5,
    menuName: 'Import',
    submenu: [
      {
        id: 51,
        submenuName: 'Asset Import',
        link: routesNames.IMPORTASSET,
      },
      {
        id: 52,
        submenuName: 'Find & Replace Imported Asset',
        link: routesNames.FINDREPLACEIMPORTEDASSET,
      },
    ],
  },
  {
    id: 6,
    menuName: 'Admin',
    submenu: [
      {
        id: 61,
        submenuName: 'Assign Appraiser',
        link: routesNames.ASSIGNAPPRAISER,
      },
      {
        id: 62,
        submenuName: 'Manage Asset Classes',
        link: routesNames.MANAGEASSETCLASS,
      },
      {
        id: 63,
        submenuName: 'Manage Area',
        link: routesNames.MANAGEAREA,
      },
      {
        id: 64,
        submenuName: 'Manage Serial Labels',
        link: routesNames.MANAGESERAILLABELS,
      },
      {
        id: 65,
        submenuName: 'Manage Asset Types',
        link: routesNames.MANAGEASSETTYPE,
      },
      {
        id: 66,
        submenuName: 'Manage Make',
        link: routesNames.MANAGEMAKE,
      },
      {
        id: 67,
        submenuName: 'Manage Model',
        link: routesNames.MANAGEMODEL,
      },
      {
        id: 68,
        submenuName: 'Manage User',
        link: routesNames.MANAGEUSER,
      },
      {
        id: 69,
        submenuName: 'Validate Model',
        link: routesNames.VALIDATEMODEL,
      },
    ],
  },
]
