/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {Box, Typography} from '@mui/material'
import {authUserActions} from '../../redux/auth/AuthActions'
import {Loader} from '../../components'
import {getModelByMakeAction} from '../../redux/assets/AssetsActions'
import MyDiv from './AuthenticateUser.style'

export default function AuthenticateUser(props) {

  const dispatch = useDispatch()
  const history = useHistory()
  const params = new URLSearchParams(props.location.search)
  const username = params.get('a')
  const role = params.get('r')
  const project_id_crm = params.get('appraisal_id')

  const authState = useSelector((state) => state.auth)
  // const isMakeModalLoading = useSelector((state) => state.assets.getModelByMake.loading)

  const strUserName = username?.replace(/\+/g, '-')?.replace(/\//g, '_')?.replace(/\=+$/, '')
  const strRole = role?.replace(/\+/g, '-')?.replace(/\//g, '_')?.replace(/\=+$/, '')

  useEffect(() => {
    if (authState.authUser.isAuthenticated && !authState.authUser.loading) {
      // passing down history to the action creator.
      dispatch(getModelByMakeAction())
      history.push('/')
    }
  }, [authState])

  useEffect(() => {
    localStorage.clear()
  }, [])


  useEffect(() => {
    if (!localStorage.getItem('isAuth') && strUserName && strRole && project_id_crm) {
      let data = {
        type: 'verifyuser',
        username: strUserName,
        role: strRole,
        project_id_crm,
      }
      dispatch(authUserActions(data))
      localStorage.setItem('project_id_crm', project_id_crm)
    }
  }, [dispatch])
  return (
    <MyDiv>
      {authState.authUser.loading ?
        <Box>
          <Typography className="authenticate_msg">Please Wait...Authenticating...</Typography>
          <Loader />
        </Box>
        :
        <Box className="logout_users">
          <Typography className="auth_msg">Welcome to the Hilco M&E</Typography>
          <Typography className="logout_msg">You have successfully logged in.</Typography>
          {/* <Typography className="login_msg">Please authenticate again.</Typography> */}
        </Box>
      }
    </MyDiv>
  )
}
