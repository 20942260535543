/* eslint-disable no-unused-vars */
import React, {useEffect, useRef, useState} from 'react'
import {Box, Checkbox, FormControlLabel, Grid, Typography} from '@mui/material'
import {Upload} from '@mui/icons-material'
import {useSelector, useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {decode as base64Decode} from 'base-64'
import {CustomButton, CustomTextBox, Loader} from '../../../components'
import * as routesNames from '../../../constants/routes'
import {getImportedAssets, uploadAction} from '../../../redux/import/ImportActions'
import MyDiv from './importAssets.style'

export default function ImportAssets(props) {

  const dispatch = useDispatch()
  const imageRef = useRef()
  const history = useHistory()
  const importState = useSelector((state) => state.import)
  const [skipRows, setSkipRows] = useState('')
  const [append, setAppend] = useState(false)
  const assetsImport = importState?.getImportedAssets?.getImportedAssets

  let projectIDCrm = localStorage.getItem('project_id_crm')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string
  let redirectUrl = importState?.getImportedAssets?.getImportedAssets?.redirect_url

  let obj = {
    action: 'fetch_project_imported_asset_count',
    project_id_crm: decodedProjectId,
  }

  useEffect(() => {
    dispatch(getImportedAssets(obj))
  }, [dispatch])

  // useEffect(() => {
  //   if (importState?.getImportedAssets?.getImportedAssets?.code === 2) {
  //     window.location.href = redirectUrl
  //   }
  // }, [importState?.getImportedAssets?.getImportedAssets])

  useEffect(() => {
    if (importState?.uploadFile?.uploadFile?.status === 200) {
      history.push(routesNames.FIELDMAPPING, {data: skipRows})
    }
  }, [importState?.uploadFile?.uploadFile])

  const showOpenFileDialog = () => {
    imageRef.current.click()
  }

  const handleChange = (e) => {
    let value = e.target.value
    const isValidInput = /^[0-9,]*$/.test(value)

    if (isValidInput) {
      setSkipRows(e.target.value)
    }
  }

  const handleChangeFile = (e) => {
    let obj = {
      skipRows: skipRows ?? '',
      append,
    }
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    dispatch(uploadAction(obj, formData))
  }
  const handleBack = () => {
    history.push('/')
  }

  return (
    <MyDiv>
      {(importState?.uploadFile?.loading || importState?.getImportedAssets?.loading) &&
      <div>
        <Loader />
      </div>
      }
      <Box className="page_heading">
        <Typography>Import Assets</Typography>
      </Box>
      <Box className="wrapper_box">
        {assetsImport?.code === 1 ?
          <Box>
            <Grid container columnSpacing={{sm: 4, md: 4}}>
              <Grid item md={6}>
                <Typography className="textStyle">Company Name: </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography className="textStyle">Client Name: </Typography>
              </Grid>
            </Grid>
            <Box className="alert-warning">
              <Typography><b>{assetsImport?.project_imported_asset_count}</b> Imported Assets found. Current Asset will be deleted if new asset is imported.</Typography>
            </Box>
          </Box>
          : null
        }
        <FormControlLabel
          onChange={() => setAppend(!append)}
          className="checked_label"
          control={<Checkbox />}
          label="Append"
        />
        <Box className="input_box" mt={1}>
          <Typography>Skip Rows</Typography>
          <CustomTextBox name="page" value={skipRows}className="page_input" onChange={handleChange} />
          <Typography className="note">
            Note: Please enter comma separated row numbers.
          </Typography>
        </Box>
        <Box className="input_box upload_btn" mt={2}>
          <Typography>Import File</Typography>
          <>
            <CustomButton
              onClick={showOpenFileDialog}
              title="Import"
              className="btn_theme"
              startIcon={<Upload />}
            />
            <input
              ref={imageRef}
              type="file"
              style={{display: 'none'}}
              onChange={handleChangeFile}
              accept=".xls, .xlsx, .csv"
            />
          </>
        </Box>
        <Box className="back_btn">
          <CustomButton
            title="Back"
            variant="contained"
            className="btn_theme"
            onClick={handleBack}
          />
        </Box>
      </Box>
    </MyDiv>
  )
}
