/* eslint-disable */
import React from 'react'
import {Checkbox} from '@mui/material'
// import {deselectAllFilteredRows, selectAllFilteredRows} from 'ka-table/actionCreators'
import {useDispatch} from 'react-redux'
import {deselectRow, selectRow, selectRowsRange} from 'ka-table/actionCreators'
import {assetsListActions} from '../../../../../redux/assets/assets'
// import {useLocation} from 'react-router-dom'

export default function SelectionLine(props) {
  const reduxDispatch = useDispatch()
  const selectionList = [props.rowData.type_id]
  const handleChange = (event) => {
    props.rows.map((item) => {
      if (item.parent_line === props.rowData.type_id) {
        selectionList.push(item.type_id)
      }
    })
    if (event.nativeEvent.shiftKey) {
    } else if (event.currentTarget.checked) {
      reduxDispatch(assetsListActions.setSelectedItems({...selectionList, type: 'add'}))
       selectionList.forEach((element) => {
        //! https://stackblitz.com/edit/table-selection-ts-1dy6az?file=Demo.tsx
        props.dispatch(selectRow(element)) // !since ka tables selectSelectedRows method is not working, sticking to one at a time
       })
    //   dispatch(selectRow({...selectionList}))
    } else {
      reduxDispatch(assetsListActions.setSelectedItems({...selectionList, type: 'remove'}))
       selectionList.forEach((element) => {
        props.dispatch(deselectRow(element)) //! same in case of deselecting
       })
    //   dispatch(deselectRow({...selectionList}))
    }
  }
  return (
    <Checkbox
      checked={props.areAllRowsSelected}
      onChange={handleChange}
    />
  )
}
