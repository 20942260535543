import {updateAssetClass} from '../../../services/assetListService'
import {assetsListActions} from '../assets'

const postAssetClassAction = (payload, type) => {
  return (dispatch) => {
    dispatch(assetsListActions.postAssetClassRequest())
    updateAssetClass(payload).then((res) => {
      if (res.status === 200) {
        if (type === 'class') {
          dispatch(assetsListActions.setMessageData({message: 'Class updated successfully.', status: 'success'}))
          dispatch(assetsListActions.setDoFetchAssetList(true))
        } else if (type === 'values') {
          dispatch(assetsListActions.setMessageData({message: 'Value updated successfully.', status: 'success'}))
          dispatch(assetsListActions.setDoFetchAssetList(true))
        } else if (type === 'condition') {
          dispatch(assetsListActions.setMessageData({message: 'Condition updated successfully.', status: 'success'}))
          dispatch(assetsListActions.setDoFetchAssetList(true))
        } else if (type === 'year') {
          dispatch(assetsListActions.setMessageData({message: 'Year updated successfully.', status: 'success'}))
          dispatch(assetsListActions.setDoFetchAssetList(true))
        }
      }
      dispatch(assetsListActions.postAssetClassSuccess(res))
    }).catch((error) => {
      dispatch(assetsListActions.postAssetClassFailure(error))
    })

  }
}

export default postAssetClassAction
