import {importAssets} from '../../../services/importServices'
import {importListActions} from '../import'

const importAsset = (payload) => {
  return (dispatch) => {
    dispatch(importListActions.postImportAssetsRequest())
    importAssets(payload).then((res) => {
      dispatch(importListActions.postImportAssetsSuccess(res))
    }).catch((error) => {
      dispatch(importListActions.postImportAssetsFailure(error))
    })

  }
}

export default importAsset
