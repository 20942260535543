/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import {fetchLocationValues} from '../../../services/homeService'
import {postReportsPdfAction} from '../../reports/ReportActions'
import {homeListActions} from '../home'

const getLocationPriceValuesAction = (payload, finalData) => {
  return (dispatch) => {
    dispatch(homeListActions.locationsPriceValuesRequest())
    fetchLocationValues(payload).then((res) => {
      // const reportData = res.data.asset
      // const locationObject = {
      //   location_label: `${finalData.locationDataFull[0].project_name}, ${finalData.locationDataFull[0].street}, ${finalData.locationDataFull[0].city}, ${finalData.locationDataFull[0].state}, ${finalData.locationDataFull[0].country}`,
      //   location_label_id: finalData.locationDataFull[0].location_id,
      // }
      // const areaObject = {
      //   area_label: 'Undefined Area',
      //   location_id: finalData.locationDataFull[0].location_id,
      // }

      // reportData.splice(0, 0, locationObject)
      // reportData.splice(1, 0, areaObject)

      if (payload.action === 'getAssets') {
        if (res.status === 200) {
          dispatch(postReportsPdfAction({...finalData, refData: res.data.refData, reportData: reportData}))
        }
      }
      dispatch(homeListActions.locationsPriceValuesSuccess(res))
    }).catch((error) => {
      dispatch(homeListActions.locationsPriceValuesFailure(error))
    })
  }
}

export default getLocationPriceValuesAction
