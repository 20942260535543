/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react'
import 'jspdf-autotable'
import {kaReducer} from 'ka-table'
import {
  DataType,
  SortingMode,
  EditingMode,
  FilteringMode,
} from 'ka-table/enums'
import {useDispatch, useSelector} from 'react-redux'
import {Box} from '@mui/material'
import {AlertMessage, CustomButton, CustomTable} from '../../../../../components'
import {deleteMakeAction, postMakeListAction} from '../../../../../redux/admin/AdminActions'
import {adminListActions} from '../../../../../redux/admin/admin'
import {validateFieldForRequest} from '../../../../../services/adminService'
import MyDiv from './manageMakeTable.style'


export default function ManageMakeTable(props) {

  const tablePropsInit = {
    columns: [
      {
        key: 'selection-cell',
        style: {width: '2%'},
      },
      {
        dataType: DataType.Number,
        key: 'isFlagged',
        style: {width: '5%'},
        title: 'Flag',
        isEditable: false,
      },
      {
        dataType: DataType.String,
        key: 'name',
        style: {width: 120},
        title: 'Name',
        isEditable: true,
        name: 'make',
      },
      {
        dataType: DataType.String,
        key: 'created_on',
        style: {width: 120},
        title: 'Created On',
        isEditable: false,
      },
    ],
    selectedRows: [],
    data: props.makeList,
    rowKeyField: 'make_id',
    height: '50vh',
    virtualScrolling: {
      enabled: true,
    },
    sortingMode: SortingMode.Single,
    editingMode: EditingMode.Cell,
    filteringMode: FilteringMode.FilterRow,
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: 250,
      pageSizes: [250, 500, 1000],
    },
  }
  const [makeListTable, setMakeListTable] = useState(tablePropsInit)
  const dispatch = useDispatch()
  // eslint-disable-next-line no-unused-vars
  const adminState = useSelector((state) => state.admin)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')

  // useEffect(() => {
  //   setMakeListTable(tablePropsInit)
  // }, [props?.makeList])

  useEffect(() => {
    if (props.searchValue !== '') {
      const result = props.makeList.filter((item) => {
        return item?.name?.toLowerCase().includes(props.searchValue.toLowerCase())
      })
      setMakeListTable({...makeListTable, data: result})
    } else {
      setMakeListTable({...makeListTable, data: props.makeList})
    }
  }, [props.makeList])


  useEffect(() => {
    return () => {
      dispatch(adminListActions.clearSelectedItems()) // clearing the global state of checkboxes for every component
    }
  }, [])

  let userId = localStorage.getItem('userId')

  // const CheckValue = (action, prevState) => {
  //   const previousValue = prevState.data.find(({make_id}) => +make_id === action.rowKeyValue)
  //   let inputValues = action.value
  //   let flag
  //   if (previousValue[action.columnKey].toLowerCase() === inputValues.toLowerCase()) {
  //     flag = true
  //   } else {
  //     flag = false
  //   }

  //   if (flag) {
  //     return previousValue[action.columnKey]

  //   } else {
  //     return inputValues
  //   }
  // }
  const dispatchResearch = (action) => {
    if (action.type === 'UpdateCellValue') {
      if (action.value) {
        let obj = {
          modified_by: Number(userId),
          // name: action.value,
          name: action.value,
          make_id: action.rowKeyValue,
        }
        validateFieldForRequest({
          name: action.value,
          action: 'duplicate',
        }, 'make').then((res) => {
          if (res?.data?.make[0]?.count > 0) {
            setMessage('Make already exists.')
            setStatus('warning')
          } else {
            dispatch(postMakeListAction(obj, 'edit'))
          }
        }).catch((err) => {
          console.log(err)
          setMessage('Something went wrong???')
          setStatus('warning')
        })
      } else {
        // setMessage('Please enter Make.')
        // setStatus('warning')
        props.handleMessageData('Please enter Make.', 'warning')
      }
    } else {
      setMakeListTable((prevState) => kaReducer(prevState, action))
    }
  }

  const handleDelete = () => {
    if (makeListTable?.selectedRows.length > 0) {
      let obj = {
        action: 'deleteList',
        make_id: makeListTable?.selectedRows.join('^'),
      }
      dispatch(deleteMakeAction(obj))
      dispatch(adminListActions.clearSelectedItems())
    } else {
      setMessage('Please select at least one Make.')
      setStatus('warning')
    }
  }

  const handleCancel = () => {
    setMakeListTable({...makeListTable, paging: makeListTable.paging, selectedRows: []})
  }


  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    dispatch(adminListActions.clearMessageData())
  }

  return (
    <MyDiv>
      <CustomTable
        tableProps={makeListTable}
        dispatch={dispatchResearch}
        handleCheck={props.handleCheck}
      />
      <Box className="d-flex bottom_actions">
        <CustomButton onClick={handleDelete} title="Delete" className="btn_theme" variant="contained" />
        <CustomButton title="Cancel" onClick={handleCancel} className="btn_reset" variant="contained" />
      </Box>
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
