/* eslint-disable no-debugger */
import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  messageData: {
    message: null,
    status: null,
  },
  authUser: {
    loading: false,
    isAuthenticated: false,
    authData: [],
    error: null,
  },
  logoutUser: {
    loading: false,
    logoutData: [],
    error: null,
  },
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // defining the actions
    setMessageData(state, action) {
      state.messageData.message = action.payload.message
      state.messageData.status = action.payload.status
    },
    clearMessageData(state, action) {
      state.messageData.message = null
      state.messageData.status = null
    },
    authUserRequest(state, action) {
      state.authUser.loading = true
    },
    authUserSuccess(state, action) {
      state.authUser.isAuthenticated = true
      state.authUser.authData = action.payload
      state.authUser.loading = false
    },
    authUserFailure(state, action) {
      state.authUser.isAuthenticated = false
      state.authUser.error = action.payload
      state.authUser.loading = false
    },
    logoutUserRequest(state, action) {
      state.logoutUser.loading = true
    },
    logoutUserSuccess(state, action) {
      state.logoutUser.logoutData = action.payload
      state.logoutUser.loading = false
    },
    logoutUserFailure(state, action) {
      state.logoutUser.error = action.payload
      state.logoutUser.loading = false
    },
  },
})

export const authListActions = authSlice.actions

export default authSlice.reducer
