export const generateKey = (key) => {
  switch (key) {
    case 'FLV':
      return 'Forced Liquidation Value'
    case 'OLV':
      return 'Orderly Liquidation Value'
    case 'FMV':
      return 'Fair Market Value'
    case 'FMVIP':
      return 'Fair Market Value - In Place'
    case 'FMVR':
      return 'Fair Market Value - Removal'
    case 'FAS':
      return 'Fair Value'
    case 'NFLV':
      return 'Net Forced Liquidation Value'
    case 'NOLV':
      return 'Net Orderly Liquidation Value'
    case 'OLVIP':
      return 'Orderly Liquidation Value - In Place'
    case 'RCN':
      return 'Replacement Cost New'
    case 'CV':
      return 'Custom Value'
    case 'year':
      return 'year'
    default:
      break
  }
}
export const generateKeyFromName = (key) => {
  switch (key) {
    case 'Forced Liquidation Value':
      return 'FLV'
    case 'Orderly Liquidation Value':
      return 'OLV'
    case 'Fair Market Value':
      return 'FMV'
    case 'Fair Market Value - In Place':
      return 'FMVIP'
    case 'Fair Market Value - Removal':
      return 'FMVR'
    case 'Fair Value':
      return 'FAS'
    case 'Net Forced Liquidation Value':
      return 'NFLV'
    case 'Net Orderly Liquidation Value':
      return 'NOLV'
    case 'Orderly Liquidation Value - In Place':
      return 'OLVIP'
    case 'Replacement Cost New':
      return 'RCN'
    case 'Custom Value':
      return 'CV'
    case 'year':
      return 'year'
    default:
      break
  }
}

export const generateSelected = (key) => {
  switch (key) {
    case 'FLV':
      return 'FLV'
    case 'OLV':
      return 'OLV'
    case 'FMV':
      return 'FMV'
    case 'FMVIP':
      return 'FMVIP'
    case 'FMVR':
      return 'FMVR'
    case 'FAS':
      return 'FAS'
    case 'NFLV':
      return 'NFLV'
    case 'NOLV':
      return 'NOLV'
    case 'OLVIP':
      return 'OLVIP'
    case 'RCN':
      return 'RCN'
    case 'CV':
      return 'CV'
    default:
      break
  }
}

export const convertConditionToConditionValue = (condition) => {
  switch (condition) {
    case 'New':
      return 'N'
    case 'Excellent':
      return 'E'
    case 'Very Good':
      return 'VG'
    case 'Good':
      return 'G'
    case 'Fair':
      return 'F'
    case 'Poor':
      return 'P'
    case 'NA':
      return 'NA'
    case 'Scrap':
      return 'X'
    case 'Salvage':
      return 'S'
    default:
      return condition
  }
}
export const convertConditionValueToCondition = (condition) => {
  switch (condition) {
    case 'N':
      return 'New'
    case 'E':
      return 'Excellent'
    case 'VG':
      return 'Very Good'
    case 'G':
      return 'Good'
    case 'F':
      return 'Fair'
    case 'P':
      return 'Poor'
    case 'NA':
      return 'NA'
    case 'X':
      return 'Scrap'
    case 'S':
      return 'Salvage'
    default:
      return condition
  }
}
export const convertClassValueToClass = (key) => {
  switch (key) {
    case 'P':
      return 'Production Equipment'
    case 'S':
      return 'Production Support Equipment'
    case 'M':
      return 'Mobile Equipment'
    case 'T':
      return 'Transportation'
    case 'G':
      return 'General Plant Support Equipment'
    case 'F':
      return 'Office Furniture, Fixtures & Equipment'
    default:
      return ''
  }
}


export const reOrderArray = (arr, fromIndex, toIndex) => {
  let element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
  return arr
}

export const myDebounce = (func, wait) => {
  let timeout
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}
