import React, {useState} from 'react'
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import {DragIndicator as DragIndicatorIcon} from '@mui/icons-material'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import {CustomButton} from '../../../../components'
import MyDiv from './researchColumns.style'

export default function ResearchColumns(props) {

  const [isSelect, setIsSelected] = useState(true)

  const handleSelect = () => {
    setIsSelected(!isSelect)
  }
  // todo - fix the controlled/uncontrolled issues all over
  // const isSelected = (id) => {
  //   return props?.isChecked?.find((data) => data.id === id)
  // }

  return (
    <MyDiv>
      <Box className="wrapper_box">
        <Grid container columnSpacing={{sm: 4, md: 4}} mb={3}>
          <Grid item md={12} className="top_grid">
            <Typography className="heading">
              {!isSelect ? 'Sort Columns' : 'Display Columns'}
            </Typography>
            <CustomButton
              title={isSelect ? 'Sort Columns' : 'Display Columns'}
              variant="contained"
              className="btn_theme"
              onClick={handleSelect}
            />
          </Grid>
          <Grid item md={12}>
            <Divider />
          </Grid>
          {isSelect ? (
            <>
              {props?.researchData?.displyColumns?.map((item, index) => {
                return (
                  <Grid item md={6} key={index}>
                    <FormControlLabel className="check_data"
                      control={
                        <Checkbox
                          onChange={(e) => props.handleChange(e, item)}
                          value={item.label}
                          checked={props?.isChecked?.find((data) => data.id === item.id) ?? false}
                          id={item.id}
                        />
                      }
                      label={item.name}
                    />
                  </Grid>
                )
              })}
              <Grid item md={12} mb={1} mt={1}>
                <Typography className="heading">Values</Typography>
                <Divider />
              </Grid>
              {props?.researchData?.values?.map((item, index) => {
                return (
                  <Grid item md={6} key={index}>
                    <FormControlLabel className="check_data"
                      control={
                        <Checkbox
                          onChange={(e) => props.handleChange(e, item)}
                          value={item.label}
                          checked={props?.isChecked?.find((data) => data.id === item.id) ?? false}
                          id={item.id}
                          name={item?.label}
                        />
                      }
                      label={item.label}
                    />
                  </Grid>
                )
              })}
            </>
          ) :
            (
              <Grid item md={12}>
                <DragDropContext onDragEnd={props.onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div className="dragList" ref={provided.innerRef} {...provided.droppableProps}>
                        {props.orderedItems.map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided) => (
                              <li
                                className="drag_box"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Grid container>
                                  <Grid item md={11}>
                                    <Box className="card_box">
                                      <Typography>{item.value}</Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item md={1}>
                                    <IconButton>
                                      <DragIndicatorIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </li>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Grid>
            )
          }
        </Grid>
      </Box>
    </MyDiv>
  )
}
