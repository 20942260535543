/* eslint-disable no-useless-concat */
/* eslint-disable no-duplicate-imports */
import React, {useState, useEffect} from 'react'
import {Box, IconButton, Typography} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import {kaReducer} from 'ka-table'
import {
  DataType,
  SortingMode,
  FilteringMode,
} from 'ka-table/enums'
import {useDispatch, useSelector} from 'react-redux'
import {decode as base64Decode} from 'base-64'
import {useHistory} from 'react-router-dom'
import CustomButton from '../../../components/Inputs/Button'
import {getAppraiserTrueAction} from '../../../redux/admin/AdminActions'
import {AlertMessage, CustomTable, Loader} from '../../../components'
import {getLinesListAction} from '../../../redux/lines/LineActions'
import {getProjectByCrmIdAction} from '../../../redux/home/HomeActions'
import {lineListActions} from '../../../redux/lines/line'
import MyDiv from './lineListTable.style'
import EditButton from './EditButton'

export default function LineListTable() {

  const dispatch = useDispatch()
  const history = useHistory()
  const [columnSetting, setColumnSetting] = useState(false)
  const [linesData, setLinesData] = useState([])
  const [isFilter, setFilter] = useState(false)
  const [visible, setVisible] = useState(false)
  const [posX, setPosX] = useState()
  const [posY, setPosY] = useState()
  const [flagId, setFlagId] = useState()
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [parentRowData, setParentRowData] = useState()
  const tablePropsInit = {
    columns: [
      {
        dataType: DataType.Number,
        key: 'asset_id',
        style: {width: 120},
        title: 'Asset Id',
      },
      {
        dataType: DataType.String,
        key: 'make',
        style: {width: 120},
        title: 'Make',
      },
      {
        dataType: DataType.String,
        key: 'line_name',
        style: {width: 120},
        title: 'Line Name',
      },
      {
        dataType: DataType.String,
        key: 'asset_type',
        style: {width: 120},
        title: 'Asset Type',
      },
      {
        dataType: DataType.String,
        key: 'line_unique_name',
        style: {width: 120},
        isSortable: false,
        title: 'Unique Name',
      },
      {
        dataType: DataType.String,
        key: 'year',
        style: {width: 120},
        isSortable: false,
        title: 'Year',
      },
      {
        dataType: DataType.String,
        key: 'asset_no',
        style: {width: 120},
        isSortable: false,
        title: 'Asset#',
      },
    ],
    data: [],
    rowKeyField: 'asset_id',
    height: '75vh',
    sortingMode: SortingMode.Single,
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: 250,
      pageSizes: [250, 500, 1000],
    },
  }
  const [linesTableData, setLinesTableData] = useState(tablePropsInit)
  const lineState = useSelector((state) => state.line)
  const adminState = useSelector((state) => state.line)

  let projectIDCrm = localStorage.getItem('project_id_crm')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string

  const getProjectByCrmId = {
    project_id_crm: decodedProjectId,
  }

  const appraiserTrue = {
    getAppraisersOnly: true,
    project_id_crm: decodedProjectId,
  }
  const handleToggle = () => {
    setFilter(!isFilter)
  }
  useEffect(() => {
    dispatch(getAppraiserTrueAction(appraiserTrue))
    dispatch(getLinesListAction({project_id: decodedProjectId}))
    dispatch(getProjectByCrmIdAction(getProjectByCrmId))
    // setLinesTableData(tablePropsInit)

  }, [])

  useEffect(() => {
    const showMessage = () => {
      if (lineState?.messageData.status === 'success') {
        setMessage(lineState?.messageData?.message)
        setStatus('success')
      }
      if (lineState?.messageData.status === 'failed') {
        setMessage(lineState?.messageData?.message)
        setStatus('warning')
      }
    }

    const timer = setTimeout(() => {
      showMessage()
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [lineState?.messageData])

  useEffect(() => {
    return () => {
      dispatch(lineListActions.clearMessageData())
    }
  }, [])
  useEffect(() => {
    setLinesData(adminState?.linesList?.linesList)
  }, [adminState?.linesList?.linesList])

  useEffect(() => {
    setLinesTableData({...linesTableData, data: linesData})
  }, [linesData])

  const dispatchResearch = (action) => {
    setLinesTableData((prevState) => kaReducer(prevState, action))
  }

  const handleSidebar = () => {
    setColumnSetting(!columnSetting)
  }
  const handleMenuItem = (key) => {
    history.push({
      pathname: 'line-detail' + `/${flagId}`,
      state: {data: {parentRowData, key, route: 'line-list'}},
    })
  }
  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    // dispatch(lineListActions.clearMessageData())
  }

  const AddEditButton = (event, cellProps) => {
    if (event.button === 2) {
      setFlagId(cellProps.childProps.rowData.type_id)
      setParentRowData(cellProps?.childProps?.rowData)
      setPosX(event.clientX)
      setPosY(event.clientY - 50)
      if (event.clientX > 0 || event.clientY > 0) {
        setVisible(true)
      }
      // }
    }
  }
  return (
    <MyDiv>
      {(adminState?.linesList?.loading) &&
      <div>
        <Loader />
      </div>
      }
      <Box className="page_heading">
        <Typography>Line List</Typography>
        <CustomButton
          title="Toggle Search Bar"
          variant="contained"
          className="btn_theme"
          onClick={() => handleToggle()}
        />
      </Box>
      <IconButton onClick={handleSidebar} className="column_setting">
        <MenuIcon />
      </IconButton>
      <CustomTable
        tableProps={linesTableData}
        dispatch={dispatchResearch}
        columnSetting={columnSetting}
        handleSidebar={handleSidebar}
        filteringMode={isFilter ? FilteringMode.FilterRow : ''}
        setFilter={setFilter}
        isFilter={isFilter}
        AddEditButton={AddEditButton}
        flag="lineListTable"
      />
      <EditButton visible={visible}
        posX={posX}
        posY={posY}
        handleClose={() => setVisible(false)}
        handleMenuItem={handleMenuItem}
      />
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
