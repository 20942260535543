import React from 'react'
import {Box} from '@mui/material'
import {NavLink} from 'react-router-dom'
import Logo from '../../../images/footer_logo.png'
import MyDiv from './footer.style'

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: smooth scroll animation
    })
  }
  return (
    <MyDiv>
      <Box className="footer">
        <NavLink to="/">
          <div onClick={scrollToTop}>
            <img src={Logo} alt="Hilco" />
          </div>
        </NavLink>
      </Box>
    </MyDiv>
  )
}
