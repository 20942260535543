/* eslint-disable no-unused-vars */
import React from 'react'
import {
  Route, BrowserRouter, Routes, Switch, Redirect,
} from 'react-router-dom'
import {Loader} from '../../components'

import AppLayout from '../../config/layouts'
import * as routesNames from '../../constants/routes'
import {AuthenticateUser} from '..'
// import CustomReports from '../Reports/components/CustomReports'
// import customReports from '../Reports/components/CustomReports'
// import {
//   AddAssets,
//   AddLine,
//   AssetClasses,
//   AssetList, AssetTypes, FindReplace, AssignAppraiser, FinalAssetListReport, LineListTable, ManageArea, ManageMake, ManageModel, ManageUser,
//  PhotoReports, SerialLabels, ValuationReports, FindReplaceImport, ImportAssets, MapPhotos, AssetsList
// } from '..'

// const Home = lazy(() => import('../../views/Home'))
import Home from '../../views/Home'
import Research from '../../views/Research'
import AssignAppraiser from '../../views/Admin/AssignAppraiser'
import AssetClasses from '../../views/Admin/AssetClasses'
import ManageArea from '../../views/Admin/ManageArea'
import SerialLabels from '../../views/Admin/SerialLabels'
import AssetTypes from '../../views/Admin/AssetTypes'
import ManageMake from '../../views/Admin/ManageMake'
import ManageModel from '../../views/Admin/ManageModel'
import ManageUser from '../../views/Admin/ManageUser'
import ValidateModel from '../../views/Admin/ValidateModel'
import LineListTable from '../../views/Lines/LineListTable'
import AddLine from '../../views/Lines/AddLine'
import EditLine from '../../views/Lines/EditLine'
import PhotoReports from '../../views/Reports/PhotoReports'
import ValuationReports from '../../views/Reports/ValuationReports'
import FinalAssetListReport from '../../views/Reports/FinalAssetListReport'
import AssetList from '../../views/Assets/AssetList'
import AddAssets from '../../views/Assets/AddAssets'
import MapPhotos from '../../views/Assets/MapPhotos'
import FindReplace from '../../views/Assets/FindReplace'
import ImportAssets from '../../views/Import/ImportAssets'
import FindReplaceImport from '../../views/Import/FindReplaceImport'
import FieldMapping from '../../views/Import/FieldMapping'

// const Research = lazy(() => import('../../views/Research'))
// const AssignAppraiser = lazy(() => import('../../views/Admin/AssignAppraiser'))
// const AssetClasses = lazy(() => import('../../views/Admin/AssetClasses'))
// const ManageArea = lazy(() => import('../../views/Admin/ManageArea'))
// const SerialLabels = lazy(() => import('../../views/Admin/SerialLabels'))
// const AssetTypes = lazy(() => import('../../views/Admin/AssetTypes'))
// const ManageMake = lazy(() => import('../../views/Admin/ManageMake'))
// const ManageModel = lazy(() => import('../../views/Admin/ManageModel'))
// const ManageUser = lazy(() => import('../../views/Admin/ManageUser'))
// const ValidateModel = lazy(() => import('../../views/Admin/ValidateModel'))
// const LineListTable = lazy(() => import('../../views/Lines/LineListTable'))
// const AddLine = lazy(() => import('../../views/Lines/AddLine'))
// const EditLine = lazy(() => import('../../views/Lines/EditLine'))
// const PhotoReports = lazy(() => import('../../views/Reports/PhotoReports'))
// const ValuationReports = lazy(() => import('../../views/Reports/ValuationReports'))
// const FinalAssetListReport = lazy(() => import('../../views/Reports/FinalAssetListReport'))
// const AssetList = lazy(() => import('../../views/Assets/AssetList'))
// const AddAssets = lazy(() => import('../../views/Assets/AddAssets'))
// const MapPhotos = lazy(() => import('../../views/Assets/MapPhotos'))
// const FindReplace = lazy(() => import('../../views/Assets/FindReplace'))
// const ImportAssets = lazy(() => import('../../views/Import/ImportAssets'))
// const FindReplaceImport = lazy(() => import('../../views/Import/FindReplaceImport'))
// const FieldMapping = lazy(() => import('../../views/Import/FieldMapping'))

const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path={routesNames.AUTH} component={AuthenticateUser} />

      <Redirect from="/" exact to={{pathname: routesNames.HOME}} />
      {/* <Suspense fallback={<Loader />}> */}
      <AppLayout>

        <Route exact path={routesNames.HOME} component={Home} />

        <Route exact path={routesNames.RESEARCH} component={Research} />

        {/* Admin module routes */}
        <Route exact path={routesNames.ASSIGNAPPRAISER} component={AssignAppraiser} />
        <Route exact path={routesNames.MANAGEASSETCLASS} component={AssetClasses} />
        <Route exact path={routesNames.MANAGEAREA} component={ManageArea} />
        <Route exact path={routesNames.MANAGESERAILLABELS} component={SerialLabels} />
        <Route exact path={routesNames.MANAGEASSETTYPE} component={AssetTypes} />
        <Route exact path={routesNames.MANAGEMAKE} component={ManageMake} />
        <Route exact path={routesNames.MANAGEMODEL} component={ManageModel} />
        <Route exact path={routesNames.MANAGEUSER} component={ManageUser} />
        <Route exact path={routesNames.VALIDATEMODEL} component={ValidateModel} />

        {/* Line module Routes */}
        <Route exact path={routesNames.LINELIST} component={LineListTable} />
        <Route exact path={routesNames.ADDLINE} component={AddLine} />
        <Route path="/line-detail/:id" component={EditLine} />
        {/* Reports module Routes */}
        <Route path="/asset-list-reports/:id" component={FinalAssetListReport} />
        <Route exact path={routesNames.PHOTOREPORTS} component={PhotoReports} />
        <Route exact path={routesNames.REPORTVALUATION} component={ValuationReports} />
        <Route exact path={routesNames.ASSETREPORTLIST} component={FinalAssetListReport} />

        {/* Assets module Routes */}
        <Route exact path={routesNames.ASSETLIST} component={AssetList} />
        <Route exact path="/asset-detail" component={AddAssets} />
        <Route path="/asset-detail/:id" component={AddAssets} />
        {/* <Route exact path="/map-photos" component={MapPhotos} /> */}
        <Route path="/map-photos/:id" component={MapPhotos} />
        <Route exact path={routesNames.MAPPHOTOS} component={MapPhotos} />
        <Route exact path={routesNames.FINDREPLACE} component={FindReplace} />

        {/* Import module Routes */}
        <Route exact path={routesNames.IMPORTASSET} component={ImportAssets} />
        <Route exact path={routesNames.FINDREPLACEIMPORTEDASSET} component={FindReplaceImport} />
        <Route exact path={routesNames.FIELDMAPPING} component={FieldMapping} />
      </AppLayout>
      {/* </Suspense> */}
    </Switch>
  )
}

export default AppRoutes
