import {assetsListActions} from '../assets'

// this action is to simply store the data once decompressed in the app.js and never needs to be used for any other case
const saveModelData = (payload) => {
  return (dispatch) => {
    dispatch(assetsListActions.getModelByMakeSuccess(payload))
  }
}


export default saveModelData
